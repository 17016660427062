import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { qualityColor, viewPositionText } from '../../../../../helpers/qualityProjection';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';
import * as actions from '../../../../../store/index';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import { ReactComponent as QualityIcon } from '../../../../../resources/icons/b_quality_meter.svg';
import EditIcon from '@material-ui/icons/Edit';
import { getCurrentModuleStatus } from '../SummaryCommon/SummaryCommon';
import TooltipBadgeFab from '../../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../../CardActions/ReviewSelect/ReviewSelect';
import { reviewerName } from '../../../../../helpers/reviewerName';

const useStyles = makeStyles((theme) => ({
  projection_grid_container: {
    display: 'flex',
    overflow: 'hidden',
    height: '50%',
  },

  projection_text: {
    fontSize: '0.6rem',
    minWidth: '35px',
    color: '#c2c2c2',
    padding: '4%',
    width: '45px',
  },

  proj_left: {
    marginLeft: '30%',
  },

  proj_right: {
    marginLeft: '30%',
  },

  summary_edit_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  quality_fabs: {
    height: '22px',
    minWidth: '135px',
    minHeight: '22px',
    margin: '4px',
    cursor: 'help',
  },

  quality_module_annotator_fabs: {
    height: '22px',
    minWidth: '135px',
    minHeight: '22px',
    margin: '4px',
    '&:hover': {
      background: `${theme.palette.complementary.turquoise} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  tooltip_text: {
    letterSpacing: '0.09rem',
    fontSize: '12px',
  },

  reviewer_badge: {
    marginBottom: '2px',
    minWidth: '70px',
    height: '20px',
    minHeight: '20px',
    fontSize: theme.fonts.responsiveMedium,
    minWidth: '70px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },

  reviewer_title_shape: {
    marginLeft: '10px',
  },

  module_fab_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px',
  },

  card_main_container: {
    margin: '0',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

export default function QualitySummary(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const anchorQualityRef = useRef();

  const { zoomedProjection, landscape } = useSelector((state) => state.gui);
  const { premium_features } = useSelector((state) => state.global_settings);

  const { diagnostics_microcalc_module, diagnostics_opacities_module, density_module, data_annotation } =
    premium_features;
  const { applied_quality, confirmed_quality, shown_quality } = useSelector((state) => state.results);

  const annotatorRole = data_annotation && localStorage.getItem('annotation') === 'true';
  const isConfirmed = applied_quality === 'bbox' && confirmed_quality;
  const isRelabeled = confirmed_quality && applied_quality !== 'bbox';
  const shownQuality = shown_quality || applied_quality;

  const [reviewerTooltip, setReviewerTooltip] = useState(false);
  const [qualityPopperOpen, setQualityPopperOpen] = useState(false);

  // Effects
  useEffect(() => {
    setQualityPopperOpen(false);
  }, []);

  const handlePopperToggle = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen((prevOpen) => !prevOpen);
  };

  const clickAwayHandler = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen(false);
  };

  const projectionsGridItems = [
    {
      label: t('Dashboard.rcc'),
      key: 'rcc',
      borderRight: true,
      borderBottom: true,
    },
    {
      label: t('Dashboard.lcc'),
      key: 'lcc',
      borderRight: false,
      borderBottom: true,
    },
    {
      label: t('Dashboard.rmlo'),
      key: 'rmlo',
      borderRight: true,
      borderBottom: false,
    },
    {
      label: t('Dashboard.lmlo'),
      key: 'lmlo',
      borderRight: false,
      borderBottom: false,
    },
  ];

  const QualityCustomChip = ({ result, height, explanation, projection }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Tooltip
          arrow
          placement={projection === 'rcc' || projection === 'rmlo' ? 'left' : 'right'}
          title={
            <>
              <Typography
                style={{
                  letterSpacing: '0.1rem',
                  fontSize: theme.fonts.responsiveMedium,
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                {`${viewPositionText(projection, t)} ${t('ResultView.quality')}: ${
                  result ?? t('ResultView.not_evaluated')
                }`}
              </Typography>
              {explanation && explanation.length > 0 && (
                <>
                  <Divider style={{ margin: '3% 0' }} />
                  {explanation.map((explanation, index) => (
                    <Typography
                      key={index}
                      style={{
                        letterSpacing: '0.1rem',
                        fontSize: theme.fonts.responsiveMedium,
                      }}
                    >
                      {`- ${explanation}`}
                    </Typography>
                  ))}
                </>
              )}
            </>
          }
        >
          <Chip
            variant={result ? 'default' : 'outlined'}
            label={useQualityTranslation(result, t)?.toUpperCase() ?? t('ResultView.is_not_evaluated')}
            style={{
              cursor: 'pointer',
              height: height || '20px',
              minWidth: '70%',
              maxWidth: '95%',
              background: result ? qualityColor(result, theme) : 'transparent',
              color: result ? theme.palette.texts.white : theme.palette.projections.not_evaluated,
              fontSize: theme.fonts.responsiveMedium,
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const handleEditButtonClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setQualityInRevisionMode(true));
  };

  const handleTitleClick = () => {
    dispatch(actions.setTabsIndex(1));
  };

  const onConfirmClick = () => {
    const state = shown_quality === applied_quality && confirmed_quality;

    dispatch(actions.confirmEvaluation(shownQuality, 'quality', !state, t));
  };

  const generalQualityChip = () => {
    const projection =
      props?.results?.rcc || props?.results?.lcc || props?.results?.rmlo || props?.results?.lmlo;

    if (projection?.study_quality) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 15% 0 6%',
          }}
        >
          <Typography
            className={classes.projection_text}
            style={{
              marginRight: '7%',
            }}
          >
            {t('ResultView.general_quality')}
          </Typography>
          <QualityCustomChip result={projection.study_quality} height="22px" />
        </div>
      );
    }
    return null;
  };

  return (
    <Card
      className={classes.card_main_container}
      style={{
        width: (props.allPremiumShown || props.diagnosticsShown) && density_module ? '65%' : '100%',
        height: !props.allPremiumShown && '100%',
      }}
    >
      <CardHeader
        style={{
          padding: '0',
          background: theme.palette.complementary.generalTitleBackgrounds,
        }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0px 8px',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SummaryParagraphHeader
                header={t('b_box.b_quality')}
                clickable={true}
                onClick={() => handleTitleClick()}
                tootlipTitle={t('ResultView.view_more_quality')}
              />
              <ClickAwayListener onClickAway={() => clickAwayHandler('quality')} mouseEvent="onClick">
                <div className={classes.reviewer_title_shape}>
                  <TooltipBadgeFab
                    content={
                      <>
                        <Typography className={classes.tooltip_text} color="inherit">
                          {t('ResultView.current_results_shown')}: {reviewerName(shownQuality)}
                        </Typography>
                        <Divider />
                        <Typography className={classes.tooltip_text} color="inherit">
                          {t('ResultView.current_applied')}: {reviewerName(applied_quality)}
                        </Typography>
                        <p>{t('ResultView.change_reviewer')}</p>
                      </>
                    }
                    open={reviewerTooltip}
                    badgeContent={shownQuality}
                    onClick={() => handlePopperToggle('quality')}
                    onMouseEnter={() => setReviewerTooltip(true)}
                    onMouseLeave={() => setReviewerTooltip(false)}
                    popperContent={
                      <ReviewSelect type="quality" setQualityPopperOpen={setQualityPopperOpen} />
                    }
                    anchorRef={anchorQualityRef}
                    popperOpen={qualityPopperOpen}
                    classes={classes}
                    theme={theme}
                    t={t}
                  />
                </div>
              </ClickAwayListener>
            </div>

            {annotatorRole && (
              <Tooltip
                arrow
                placement="left-end"
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {t('ResultView.tooltip_revise')}
                  </Typography>
                }
              >
                <IconButton
                  size="small"
                  onClick={() => handleEditButtonClick()}
                  className={classes.summary_edit_fabs}
                >
                  <EditIcon
                    fontSize="medium"
                    style={{
                      width: '18px',
                      height: '18px',
                      color: 'white',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      />

      <CardContent style={{ padding: '0' }}>
        {generalQualityChip()}
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '22px',
            height: !landscape
              ? '6vh'
              : (diagnostics_microcalc_module || diagnostics_opacities_module) &&
                localStorage.getItem('diagnostics') === 'true'
              ? '9vh'
              : '11vh',
            cursor: 'pointer',
          }}
          onClick={() => annotatorRole && handleTitleClick()}
        >
          {zoomedProjection ? (
            <Grid item xs={10} className={classes.projection_grid_container}>
              <Typography className={classes.projection_text}>{zoomedProjection.toUpperCase()}</Typography>
              <QualityCustomChip
                result={props?.results?.[zoomedProjection]?.quality}
                explanation={props?.results?.[zoomedProjection]?.quality_explanation}
                projection={zoomedProjection}
              />
            </Grid>
          ) : (
            projectionsGridItems.map((item) => (
              <Grid
                item
                xs={5}
                key={item.key}
                className={classes.projection_grid_container}
                style={{
                  borderRight: item.borderRight ? '1px solid rgba(255, 255, 255, 0.08)' : 'none',
                  borderBottom: item.borderBottom ? '1px solid rgba(255, 255, 255, 0.08)' : 'none',
                }}
              >
                <Typography className={classes.projection_text}>{item.label}</Typography>
                <QualityCustomChip
                  result={props?.results?.[item.key]?.quality}
                  explanation={props?.results?.[item.key]?.quality_explanation}
                  projection={item.key}
                />
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.module_fab_container}>
        <Tooltip
          title={
            <>
              <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                {
                  getCurrentModuleStatus(
                    applied_quality,
                    shownQuality,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.quality'),
                    theme,
                    t
                  ).currentApplied
                }
              </Typography>
              {annotatorRole && (
                <>
                  <Divider style={{ margin: '3% 0' }} />
                  <Typography className={classes.tooltipText}>
                    {
                      getCurrentModuleStatus(
                        applied_quality,
                        shownQuality,
                        isConfirmed,
                        isRelabeled,
                        t('ResultView.quality'),
                        theme,
                        t
                      ).tooltipAction
                    }
                  </Typography>
                </>
              )}
            </>
          }
          arrow
          placement="top-start"
        >
          <span>
            <Button
              size="small"
              disabled={!annotatorRole}
              onClick={() => onConfirmClick()}
              color="primary"
              className={annotatorRole ? classes.quality_module_annotator_fabs : classes.quality_fabs}
              startIcon={
                getCurrentModuleStatus(
                  applied_quality,
                  shownQuality,
                  isConfirmed,
                  isRelabeled,
                  t('ResultView.quality'),
                  theme,
                  t
                ).fabIcon
              }
              style={{
                color: 'white',
                background: `${
                  getCurrentModuleStatus(
                    applied_quality,
                    shownQuality,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.quality'),
                    theme,
                    t
                  ).color
                }`,
              }}
            >
              {
                getCurrentModuleStatus(
                  applied_quality,
                  shownQuality,
                  isConfirmed,
                  isRelabeled,
                  t('ResultView.quality'),
                  theme,
                  t
                ).buttonText
              }
            </Button>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
