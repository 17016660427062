import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import { Fab, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../store/index';

const useStyles = makeStyles((theme) => ({
  ZoomView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },

  topRow: {
    display: 'flex',
    alignSelf: 'end',
    margin: '0 auto min(0.6vh, 0.6vw) auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
  },

  bottomRow: {
    display: 'flex',
    alignSelf: 'start',
    margin: 'min(0.6vh, 0.6vw) auto 0 auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
  },

  topProjectionRow: {
    display: 'flex',
    alignSelf: 'end',
    margin: '0 auto min(0.3vh, 0.3vw) auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },

  bottomProjectionRow: {
    display: 'flex',
    alignSelf: 'start',
    margin: 'min(0.3vh, 0.3vw) auto 0 auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },

  rcc: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top left',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lcc: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top right',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  rmlo: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom left',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lmlo: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom right',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  ZoomLeft: {
    position: 'absolute',
    left: '8px',
    top: '6px',
    zIndex: 50,
    opacity: 0,
    boxShadow: 'none',
    animation: '$appear 600ms forwards',
    background: theme.palette.complementary.turquoiseDark,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px -1px #FFFFF',
    },
  },

  ZoomRight: {
    position: 'absolute',
    right: '8px',
    top: '6px',
    zIndex: 50,
    opacity: 0,
    boxShadow: 'none',
    animation: '$appear 600ms forwards',
    background: theme.palette.complementary.turquoiseDark,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    transform: 'scaleX(-1)',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px -1px #FFFF',
    },
  },

  '@keyframes appear': {
    '70%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.8,
    },
  },

  zoomIcon: {
    color: 'white',
    fontSize: theme.fonts.responsiveHeader,
  },

  zoom: {
    transform: 'scale(2.02)',
    transition: '0.3s',
    zIndex: 80,
  },

  shrink: {
    transform: 'scale(0)',
    transition: '0.3s',
  },
}));

const ZoomView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { zoomedProjection } = useSelector((state) => state.gui);
  const dispatch = useDispatch();

  const ZoomIcon = zoomedProjection ? (
    <ZoomOut className={classes.zoomIcon} />
  ) : (
    <ZoomIn className={classes.zoomIcon} />
  );

  const classesRcc = [classes.rcc];
  const classesLcc = [classes.lcc];
  const classesRmlo = [classes.rmlo];
  const classesLmlo = [classes.lmlo];
  if (zoomedProjection === 'rcc') {
    classesRcc.push(classes.zoom);
    classesLcc.push(classes.shrink);
    classesRmlo.push(classes.shrink);
    classesLmlo.push(classes.shrink);
  } else if (zoomedProjection === 'lcc') {
    classesLcc.push(classes.zoom);
    classesRcc.push(classes.shrink);
    classesRmlo.push(classes.shrink);
    classesLmlo.push(classes.shrink);
  } else if (zoomedProjection === 'rmlo') {
    classesRmlo.push(classes.zoom);
    classesLcc.push(classes.shrink);
    classesLmlo.push(classes.shrink);
    classesRcc.push(classes.shrink);
  } else if (zoomedProjection === 'lmlo') {
    classesLmlo.push(classes.zoom);
    classesLcc.push(classes.shrink);
    classesRmlo.push(classes.shrink);
    classesRcc.push(classes.shrink);
  }

  const setZoom = (proj) => {
    if (zoomedProjection === proj) dispatch(actions.setZoomedProjection(null));
    else dispatch(actions.setZoomedProjection(proj));
  };

  return (
    <div className={[classes.ZoomView, props.className].join(' ')}>
      <div className={props.className ? classes.topProjectionRow : classes.topRow}>
        <div
          className={classesRcc.join(' ')}
          style={{
            background: props.projectionRcc && '#000000',
          }}
        >
          {props.projectionRcc && (
            <Tooltip
              arrow
              placement="right-end"
              title={zoomedProjection === 'rcc' ? t('projections.zoom_out') : t('projections.zoom_in')}
            >
              <Fab size="small" className={classes.ZoomLeft} onClick={() => setZoom('rcc')}>
                {ZoomIcon}
              </Fab>
            </Tooltip>
          )}
          {props.children[0]}
        </div>
        <div
          className={classesLcc.join(' ')}
          style={{
            background: props.projectionLcc && '#000000',
          }}
        >
          {props.projectionLcc && (
            <Tooltip
              arrow
              placement="right-end"
              title={zoomedProjection === 'lcc' ? t('projections.zoom_out') : t('projections.zoom_in')}
            >
              <Fab size="small" className={classes.ZoomRight} onClick={() => setZoom('lcc')}>
                {ZoomIcon}
              </Fab>
            </Tooltip>
          )}
          {props.children[1]}
        </div>
      </div>
      <div className={props.className ? classes.bottomProjectionRow : classes.bottomRow}>
        <div
          className={classesRmlo.join(' ')}
          style={{
            background: props.projectionRmlo && '#000000',
          }}
        >
          {props.projectionRmlo && (
            <Tooltip
              arrow
              placement="right-end"
              title={zoomedProjection === 'rmlo' ? t('projections.zoom_out') : t('projections.zoom_in')}
            >
              <Fab size="small" className={classes.ZoomLeft} onClick={() => setZoom('rmlo')}>
                {ZoomIcon}
              </Fab>
            </Tooltip>
          )}
          {props.children[2]}
        </div>
        <div
          className={classesLmlo.join(' ')}
          style={{
            background: props.projectionLmlo && '#000000',
          }}
        >
          {props.projectionLmlo && (
            <Tooltip
              arrow
              placement="right-end"
              title={zoomedProjection === 'lmlo' ? t('projections.zoom_out') : t('projections.zoom_in')}
            >
              <Fab size="small" className={classes.ZoomRight} onClick={() => setZoom('lmlo')}>
                {ZoomIcon}
              </Fab>
            </Tooltip>
          )}
          {props.children[3]}
        </div>
      </div>
    </div>
  );
};

export default ZoomView;
