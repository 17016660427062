import React, { useEffect, useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../store/index';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { setPagination, setOrder, setOrderBy } from '../../../../store';
import { fetchPost } from '../../../../helpers/fetching';
import ExaminationPlaceholder from './ExaminationTable/Helpers/ExaminationPlaceholder';
import DropzoneAreaTable from './ExaminationTable/Helpers/DropzoneAreaTable';
import ExaminationTableRows from './ExaminationTable/ExaminationTableRows';

function ExaminationsListTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableEl = props.tableEl;

  const [rows, setRows] = useState([]);
  const [fileObjects, setFileObjects] = useState([]);
  const [isDropAreaVisible, setDropAreaVisible] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(100);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [hasScrollListener, setHasScrollListener] = useState(false);

  const {
    exam_list,
    priors_list,
    fetchingExamList,
    pagination,
    totalExaminations,
    order,
    orderBy,
    currentExam,
    scrollPosition,
  } = useSelector((state) => state.examinations);
  const dates = useSelector((state) => state.dates);
  const filter = useSelector((state) => state.filter);
  const premium_features = useSelector((store) => store.global_settings.premium_features);

  const examinationListDrawer = useSelector((state) => state.gui.examinationListDrawer);

  const risk_module = premium_features?.risk_module ?? false;

  const tableHeader = [
    {
      name: 'name',
      label: t('Table.name'),
      align: 'left',
      padding: 'none',
      sort: true,
    },
    { name: 'priors', label: '', align: 'center', padding: 'none', sort: false },

    { name: 'icons', label: '', align: 'center', padding: 'none', sort: false },
    {
      name: 'date',
      label: t('Table.date'),
      align: 'center',
      padding: 'none',
      sort: true,
    },
    {
      name: 'risk',
      label: risk_module ? t('Table.risk') : '',
      align: 'right',
      padding: 'none',
      sort: false,
    },
    { name: 'actions', label: '', align: 'left', padding: 'none', sort: false },
  ];

  const scrollListener = useCallback(() => {
    const tableRef = tableEl?.current;
    const bottomPage = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    const bottomReach = bottomPage - tableEl.current.scrollTop;

    if (!canFetchMore || fetchingExamList) {
      tableRef?.removeEventListener('scroll', scrollListener);
      return;
    }

    if (bottomReach < 5 && exam_list.length < totalExaminations && canFetchMore) {
      setCanFetchMore(false);
      dispatch(setPagination([pagination[0], pagination[1] + 30]));
      setHasScrollListener(true);
    }
  }, [exam_list, pagination[1], fetchingExamList, totalExaminations, canFetchMore, dispatch]);

  useLayoutEffect(() => {
    if (tableEl.current) {
      const tableRef = tableEl.current;
      tableRef.addEventListener('scroll', scrollListener);

      return () => {
        tableRef.removeEventListener('scroll', scrollListener);
      };
    }
  }, [scrollListener, hasScrollListener]);

  const prepareData = useCallback(() => {
    const examSet = new Map();
    const addedPriors = new Set();

    for (let i = 0; i < exam_list.length; i++) {
      const exam = exam_list[i];

      const priorsList = priors_list[exam.patient_id];

      if (examSet.has(exam.patient_id)) {
        examSet.get(exam.patient_id).push(exam);
      } else {
        examSet.set(exam.patient_id, [{ ...exam, priors_found: !!priorsList && priorsList.length > 0 }]);
      }

      if (!!priorsList && !addedPriors.has(exam.patient_id)) {
        addedPriors.add(exam.patient_id);
        for (let j = 0; j < priorsList.length; j++) {
          examSet.get(exam.patient_id).push({
            ...priorsList[j],
            is_prior: true,
          });
        }
      }
    }
    return Array.from(examSet.values());
  }, [exam_list, priors_list]);

  useEffect(() => {
    if (examinationListDrawer) {
      if (tableEl.current) {
        const currentTable = tableEl.current;
        const timer = setTimeout(() => {
          if (currentExam) {
            if (currentTable.scrollTop !== undefined) {
              currentTable.scrollTop = scrollPosition || 0;
            }
          } else {
            if (currentTable.scrollTop !== undefined) {
              currentTable.scrollTop = 0;
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }
    }
  }, [examinationListDrawer]);

  useEffect(() => {
    const preparedRows = prepareData();
    setRows(preparedRows);
    // console.log('Prepared rows', preparedRows);
    if (!fetchingExamList) {
      setCanFetchMore(true);
      if (tableEl.current && hasScrollListener && canFetchMore && exam_list.length < totalExaminations) {
        const bottomPage = tableEl.current.scrollHeight - tableEl.current.clientHeight;
        const scrollPosition = bottomPage - 120;
        tableEl.current.scrollTop = scrollPosition;
      }
    }
  }, [exam_list, prepareData, fetchingExamList, canFetchMore, hasScrollListener, totalExaminations]);

  useEffect(() => {
    setDropAreaVisible(fileObjects.length > 0);
  }, [fileObjects]);

  const createSortHandler = (columnName) => () => {
    let newOrderBy = columnName;
    let newOrder = 'DESC';

    if (columnName === 'date') {
      if (orderBy === 'date' && order === 'DESC') {
        newOrder = 'ASC';
      } else if (orderBy === 'date' && order === 'ASC') {
        newOrderBy = 'date_uploaded';
        newOrder = 'DESC';
      } else {
        newOrderBy = 'date';
        newOrder = 'DESC';
      }
    } else {
      const isDesc = orderBy === columnName && order === 'DESC';
      newOrder = isDesc ? 'ASC' : 'DESC';
    }

    dispatch(setOrder(newOrder));
    dispatch(setOrderBy(newOrderBy));
  };

  const sendToOrthanc = async () => {
    if (!fileObjects.length) return;
    if (uploadingProgress !== 100) return;

    setUploadingProgress(0);
    for (let i = 0; i < fileObjects.length; i++) {
      const response = await fetchPost('dicoms', fileObjects[i].data.split(',')[1], 'bbox');
      setUploadingProgress(((i + 1) / fileObjects.length) * 100);
      const message = response.success
        ? `${i + 1} / ${fileObjects.length} ${t('Upload.snack_uploaded')}`
        : response.msg;
      dispatch(
        actions.setSnackbar({
          msg: message,
          severity: response.success ? 'success' : 'error',
        })
      );
    }
    setFileObjects([]);
  };
  const sortInfo = (head, currentRow) => {
    const headTitle = head === 'name' ? t('DatePicker.name') : t('DatePicker.date');
    const orderText = order === 'ASC' ? t('DatePicker.asc') : t('DatePicker.des');

    if (currentRow && head === orderBy) {
      return t('DatePicker.sorted_in', {
        column: headTitle,
        sorted: orderText,
      });
    } else {
      return head === 'name' ? t('DatePicker.no_sort') : t('DatePicker.upload_date');
    }
  };

  return (
    <>
      <TableContainer
        onDragEnter={() => setDropAreaVisible(true)}
        style={{
          display: isDropAreaVisible && 'none',
          overflowX: 'hidden',
          height: '90%',
        }}
        ref={tableEl}
      >
        {rows.length || exam_list.length > 0 ? (
          <Table stickyHeader aria-label="collapsible-table" style={{ marginLeft: '2px' }}>
            <TableHead>
              <TableRow>
                <TableCell />
                {tableHeader.map((head, i) => (
                  <TableCell
                    key={head.label + i}
                    align={head.align}
                    padding={head.padding}
                    sortDirection={orderBy === head.name ? order.toLowerCase() : false}
                  >
                    {head.sort ? (
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          <Typography
                            style={{
                              letterSpacing: '0.1rem',
                              fontSize: '12px',
                            }}
                          >
                            {sortInfo(head.name, orderBy === head.name)}
                          </Typography>
                        }
                      >
                        <TableSortLabel
                          active={orderBy === head.name}
                          direction={orderBy === head.name ? order.toLowerCase() : 'asc'}
                          onClick={createSortHandler(head.name)}
                        >
                          {head.label}
                        </TableSortLabel>
                      </Tooltip>
                    ) : (
                      head.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <ExaminationTableRows
              rows={rows}
              setCanFetchMore={setCanFetchMore}
              currentTableRow={tableEl?.current?.scrollTop}
            />
          </Table>
        ) : (
          <ExaminationPlaceholder />
        )}
      </TableContainer>

      {isDropAreaVisible && (
        <DropzoneAreaTable
          fileObjects={fileObjects}
          setFileObjects={setFileObjects}
          setDropAreaVisible={setDropAreaVisible}
          sendToOrthanc={sendToOrthanc}
        />
      )}
    </>
  );
}

export default ExaminationsListTable;
