import React, { useEffect, useState } from 'react';
import { Grid, Button, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container_insummary: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },

  limitations_chip: {
    minWidth: '90px',
    whiteSpace: 'nowrap',
    fontSize: theme.fonts.responsiveMedium,
    height: '20px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },
}));

export default function ButtonGroupRestrictions(props) {
  const patient_id = useSelector((store) => store.results.patient_id);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { painButtonClicked, immobilityButtonClicked, sizeButtonClicked } = props;

  const limitationsClicked = (restriction) => {
    if (restriction === 'Pain') {
      return props.clickPain();
    }
    if (restriction === 'Immobility') {
      return props.clickImmobility();
    }
    if (restriction === 'Size') {
      return props.clickSize();
    }
  };

  return (
    <Grid
      className={classes.container_insummary}
      wrap="wrap"
      container
      direction={props?.direction ? props.direction : 'row'}
      style={{ margin: '10px' }}
    >
      <Chip
        variant="outlined"
        className={classes.limitations_chip}
        id="pain"
        size="small"
        onClick={() => limitationsClicked('Pain')}
        style={{
          margin: '4px',
        }}
        disabled={painButtonClicked}
        label={t('ResultView.pain')}
      />

      <Chip
        variant="outlined"
        className={classes.limitations_chip}
        id="immobility"
        size="small"
        onClick={() => limitationsClicked('Immobility')}
        style={{
          margin: '4px',
        }}
        disabled={immobilityButtonClicked}
        label={t('ResultView.immobility')}
      />

      <Chip
        variant="outlined"
        className={classes.limitations_chip}
        id="size"
        size="small"
        onClick={() => limitationsClicked('Size')}
        disabled={sizeButtonClicked}
        label={t('ResultView.breast_size')}
        style={{
          margin: '4px',
        }}
      />
    </Grid>
  );
}
