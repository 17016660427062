import React, { useState, useCallback, useEffect } from 'react';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Typography,
  Select,
  FormControl,
  MenuItem,
  useTheme,
  Button,
  InputLabel
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchGet, fetchPost, fetchDelete } from '../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../resources/coorporate_images/brayz_logo_small.png';
import { filter } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { set } from 'react-hook-form';
import { DATA_ENDPOINT } from '../../../../config';

const useStyle = makeStyles(() => ({
  MainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '80%',
  },

  CheckboxContainer: {
    display: 'flex',
  },

  ReviewSelect: {
    width: '40%',
    marginTop: '5%'
  },

  Job: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid black',
    padding: '10px',
    margin: '10px',
  },

  Task: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3%',
  },

  DropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    width: '100%',
  },
}));

const defaultUserList = [{ username: 'bbox' }, { username: 'any' }];

const DataExport = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();

  // Store state
  const filter = useSelector(state => state.filter);
  const examinations = useSelector(state => state.examinations);

  // Local state
  const [exportImages, setExportImages] = useState(false);
  const [exportData, setExportData] = useState(false);
  const [exportXLS, setExportXLS] = useState(true);
  const [users, setUsers] = useState(defaultUserList);
  const [createdBy, setCreatedBy] = useState('any');
  const [confirmedBy, setConfirmedBy] = useState('any');
  const [dataStatus, setDataStatus] = useState(null);
  const [studyIDs, setStudyIDs] = useState(null);

  // Handlers
  async function onJobCreate() {
    const body = {
      study_ids: studyIDs,
      filters: {
        created_by: createdBy === 'any' ? null : createdBy,
        confirmed_by: confirmedBy === 'any' ? null : confirmedBy,
      },
      flags: {
        data: exportData,
        images: exportImages,
        xls: exportXLS,
      }
    }
    const response = await fetchPost('export', body, 'data');
    if (!response.success) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }
    fetchDataStatus();
  }

  async function onJobDelete(id) {
    const response = await fetchDelete(`export/delete/${id}`, {}, 'data');
    if (!response.success) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }
    fetchDataStatus();
  }

  function onClose() {
    props.onClose();
    props.setDataIsReady(false);
  }

  // Fetching
  const fetchDataStatus = async () => {
    const response = await fetchGet('export/status', 'data');
    if (!response.success) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }
    setDataStatus(response.data);
  };

  const fetchUsers = useCallback(async () => {
    const response = await fetchGet('users');
    if (!response.success) return;
    const users = response.data
      .filter(user => user.annotation_permission);
    setUsers(users);
  }, []);

  const fetchStudyIDs = async () => {
      const response = await fetchPost('study_ids', { filter });
      if (!response.success) {
        dispatch(setSnackbar({
          msg: response.msg,
          severity: 'error',
        }));
        return;
      }
      setStudyIDs(response.data.map((item) => item.id));
  };

  // Effect
  useEffect(() => {
    props.setDataIsReady(false);
    fetchDataStatus();
    fetchStudyIDs();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Elements
  function getDataStatusElement() {
    if (studyIDs === null || dataStatus === null) {
      return "loading the status..."; // TODO: replace with spinner
    }
    if (!Array.isArray(dataStatus)) {
      return "Error: invalid data status";
    }
    if (dataStatus.length === 0) {
      return "No pending jobs"; // TODO: reformulate and add translations
    }
  
    return dataStatus.map(job => ( // TODO: please, make it a separate component and style it
      <div className={classes.Job} key={job.id}>
        {'xls' in job && (
          <div className={classes.Task}>
            <p style={{fontWeight: 'bold'}}>{`Spreadsheet`}</p>
            <p>{`Status: ${job.xls.status}`}</p>
            <p>{`Expires in: ${job.xls.expires_in}`}</p>
            {job.xls.status === 'completed' && (
              <a href={DATA_ENDPOINT+job.xls.download_url} download>{`Download`}</a>
            )}
          </div>
        )}
        {'zip' in job && (
          <div className={classes.Task}>
            <p style={{fontWeight: 'bold'}}>{`Data`}</p>
            <p>{`Status: ${job.zip.status}`}</p>
            <p>{`Expires in: ${job.zip.expires_in}`}</p>
            {job.zip.status === 'completed' && (
              <a href={DATA_ENDPOINT+job.zip.download_url} download>{`Download`}</a>
            )}
          </div>
        )}
        <Button onClick={() => onJobDelete(job.task_id)}>
          {'Delete'}
        </Button>
      </div>
    ))
  }

  return (
    <DraggableDialog
      maxWidth="md"
      title={t('export.exportTitle')}
      open={props.open}
      actions={[
        {
          onClick: onJobCreate,
          color: 'primary',
          label: t('export.export'),
        },
        {
          onClick: onClose,
          color: 'secondary',
          label: t('export.cancel'),
        },
      ]}
      onClose={onClose}
    >
      <div className={classes.MainContainer}>
        <div className={classes.DataStatus}>
          {getDataStatusElement()}
        </div>
        <div className={classes.Controls}>
          <div className={classes.CheckboxContainer}>
            <FormControlLabel
              style={{ marginLeft: 'inherit' }}
              key={'xls'}
              label={<Typography>{t('export.exportResults')}</Typography>} // TODO: update translation
              control={
                <Checkbox
                  checked={exportXLS}
                  onChange={() => setExportXLS(prev => !prev)}
                />
              }
            />

            <FormControlLabel
              style={{ marginLeft: 'inherit' }}
              key="images"
              label={<Typography>{t('export.images')}</Typography>}
              control={
                <Checkbox
                  checked={exportImages}
                  onChange={() => setExportImages((prev) => !prev)}
                />
              }
            />

            <FormControlLabel
              style={{ marginLeft: 'inherit' }}
              key="data"
              label={<Typography>{t('export.overlays')}</Typography>} // TODO: update translation
              control={
                <Checkbox
                  checked={exportData}
                  onChange={() => setExportData((prev) => !prev)}
                  name="images"
                />
              }
            />
          </div>
          <div className={classes.DropDownContainer}>
            <FormControl className={classes.ReviewSelect}>
              <InputLabel >Created By</InputLabel>
              <Select
                value={createdBy}
                onChange={(e) => setCreatedBy(e.target.value)}
              >
                {users.concat(defaultUserList).map(user => (
                  <MenuItem 
                    key={user.username} 
                    value={user.username}
                  >
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.ReviewSelect}>
            <InputLabel >Confirmed By</InputLabel>
              <Select
                value={confirmedBy}
                onChange={(e) => setConfirmedBy(e.target.value)}
              >
                {users.concat([{username: 'any'}]).map(user => (
                  <MenuItem 
                    key={user.username} 
                    value={user.username}
                  >
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </DraggableDialog>
  );
};

export default DataExport;
