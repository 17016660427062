import { Badge, Chip, Fab, Paper, Popper, Tooltip } from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { reviewerName } from '../../../../../helpers/reviewerName';

function TooltipBadgeFab({
  content,
  open,
  badgeContent,
  onClick,
  onMouseEnter,
  onMouseLeave,
  popperContent,
  anchorRef,
  popperOpen,
  classes,
  theme,
  t,
}) {
  return (
    <Tooltip placement="right-end" arrow title={content} open={open}>
      <span>
        <Chip
          className={classes.reviewer_badge}
          label={reviewerName(badgeContent)}
          variant="outlined"
          onClick={onClick}
          size="small"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={anchorRef}
        />

        <Popper
          open={popperOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          disablePortal={false}
          keepMounted={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          <Paper className={classes.popperPaper}>{popperContent}</Paper>
        </Popper>
      </span>
    </Tooltip>
  );
}

export default TooltipBadgeFab;
