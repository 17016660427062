import React from 'react';
import { Chip, Divider, Tooltip, Typography } from '@material-ui/core';
import symmetryVertical from '@iconify/icons-bi/symmetry-vertical';
import { Icon } from '@iconify/react';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';
import HeightIcon from '@material-ui/icons/Height';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

export const parenchymaClass = (parenchymaResult, t) => {
  const resultMapping = {
    correct: {
      text: t('ResultView.quality_parenchyma_ok'),
      classColor: 'correct',
    },
    moderate: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'moderate',
    },
    insufficient: {
      text: t('ResultView.quality_parenc_not'),
      classColor: 'insufficient',
    },
  };

  const defaultResult = {
    text: t('ResultView.is_not_evaluated'),
    classColor: 'none',
  };

  return resultMapping[parenchymaResult] || defaultResult;
};

export const pnlTooltip = (PNL, view_position, higherPNL, pnlDifference, t) => {
  const currentStatePNL = higherPNL ? t('ResultView.more') : t('ResultView.less');
  const projPNL = view_position?.includes('cc')
    ? view_position === 'rcc'
      ? 'RMLO'
      : 'LMLO'
    : view_position === 'rmlo'
    ? 'RCC'
    : 'LCC';

  if (!PNL) {
    return { text: t('ResultView.is_not_evaluated'), classColor: 'none' };
  }

  const pnlMap = {
    correct: {
      text: t('ResultView.pnl_line_range_ok'),
    },
    moderate: {
      text: `${t('ResultView.pnl_line_range_moderate')}. `,
      textHelper: ` ${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t('ResultView.than')} ${projPNL}`,
    },
    insufficient: {
      text: `${t('ResultView.pnl_line_not_range')}. `,
      textHelper: `${view_position?.toUpperCase()} ${t(
        'ResultView.pnl_measures'
      )} ${pnlDifference} ${currentStatePNL} ${t('ResultView.than')} ${projPNL}`,
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      text: t('ResultView.pnl_line_not_compare'),
      classColor: 'neutral',
    }
  );
};

export const pnlColor = (result) => {
  const { PNL } = result ?? {};
  if (!result) {
    return {
      color: 'neutral',
    };
  }
  const pnlMap = {
    correct: {
      color: 'correct',
    },
    moderate: {
      color: 'moderate',
    },
    insufficient: {
      color: 'insufficient',
    },
    default: {
      color: 'neutral',
    },
  };

  const pnlResult = PNL ? pnlMap[PNL] : pnlMap.default;

  return (
    pnlResult || {
      color: 'neutral',
    }
  );
};

export const nippleProfileClass = (nippleResult, t) => {
  if (!nippleResult || nippleResult === 'not_evaluated') {
    return {
      text: t('ResultView.is_not_evaluated'),
      classColor: 'none',
    };
  }

  const resultTextMap = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const classColorMap = {
    correct: 'correct',
    moderate: 'moderate',
    insufficient: 'insufficient',
  };

  const resultText = resultTextMap[nippleResult] || '';
  const classColor = classColorMap[nippleResult] || classColorMap.none;

  return {
    text: `${t('ResultView.tooltip_nipple')} ${resultText}`,
    classColor: classColor,
  };
};

export const nippleOrientationClass = (nippleResult, nippleAngle) => {
  const defaultValues = {
    pnlY: '42%',
  };

  if (nippleResult === 'moderate' || nippleResult === 'insufficient') {
    const pnlY = nippleAngle > 0 ? '38%' : '46%';

    return { pnlY };
  }

  if (nippleResult === 'correct' || !nippleResult) {
    return defaultValues;
  }
};

export const pectoMuscClass = (pectoResult, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.correctly')}`,
    moderate: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.moderately')}`,
    insufficient: `${t('ResultView.tooltip_pect_musc')}: ${t('ResultView.insufficiently')}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  return {
    text: resultMapping[pectoResult] || defaultText,
    classColor: pectoResult ? pectoResult : defaultClassColor,
  };
};

export const pectoAngleClass = (angleResult, angleValue, t) => {
  const pectAngleValue = angleValue ? `${angleValue.toFixed(1)}°` : '';
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[angleResult] || defaultText;
  const classColor = angleResult ? angleResult : defaultClassColor;

  if (!angleResult || !angleValue)
    return {
      text: defaultText,
      classColor: classColor,
    };
  else
    return {
      text: `${t('ResultView.tooltip_pect_ang')} ${resultText}: ${pectAngleValue}`,
      classColor: classColor,
    };
};

export const inframFoldClass = (inframFold, t) => {
  const resultMapping = {
    correct: t('ResultView.correctly'),
    moderate: t('ResultView.moderately'),
    insufficient: t('ResultView.insufficiently'),
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const defaultClassColor = 'none';

  const resultText = resultMapping[inframFold] || defaultText;
  const classColor = inframFold ? inframFold : defaultClassColor;

  if (!inframFold)
    return {
      text: resultText,
      classColor: classColor,
    };
  return {
    text: `${t('ResultView.tooltip_infram')} ${resultText}`,
    classColor: classColor,
  };
};

const pectoLevelColor = (pectoLevel, pect_level_classes) => {
  if (!pectoLevel) return 'none';

  if (pect_level_classes === 2) {
    return pectoLevel;
  } else {
    return pectoLevel === 'moderate' ? 'correct' : pectoLevel;
  }
};

export const pectoLevelClass = (pectoLevel, pect_level_classes, t) => {
  const resultMapping = {
    correct: `${t('ResultView.tooltip_pect_level')} ${t('ResultView.correctly')}`,
    moderate: `${t('ResultView.tooltip_pect_level')} ${t('ResultView.moderately')}`,
    insufficient: `${t('ResultView.tooltip_pect_level')} ${t('ResultView.insufficiently')}`,
  };

  const defaultText = t('ResultView.is_not_evaluated');
  const resultText = resultMapping[pectoLevel] || defaultText;

  return {
    text: resultText,
  };
};

// Class comprare
export const findHigherClass = (class1, class2) => {
  const classOrder = ['adequate', 'correct', 'limited', 'moderate', 'insufficient', 'inadequate'];
  const class1Index = classOrder.indexOf(class1);
  const class2Index = classOrder.indexOf(class2);
  return class1Index > class2Index ? class1 : class2;
};

// Skin Folds Helper
export const skinFoldsInfo = (skinFolds, view_position, t, theme) => {
  const severityValues = {
    mild: 0,
    medium: 1,
    severe: 2,
  };

  const getColor = (foldsList) => {
    if (foldsList?.length > 0) {
      const skinFoldsSeverityNumbers = foldsList.map((fold) => severityValues[fold.severity]);
      const highestSeverity = Math.max(...skinFoldsSeverityNumbers);
      if (highestSeverity === 0) {
        return 'mild';
      }
      if (highestSeverity === 1) {
        return 'moderate';
      }
      if (highestSeverity === 2) {
        return 'insufficient';
      }
      return theme.palette.lesions.skinFolds;
    }
    return 'correct';
  };

  const getTooltip = (skinFolds) => {
    return (
      <>
        {skinFolds ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
                letterSpacing: '0.1rem',
                textTransform: 'uppercase',
                fontSize: theme.fonts.responsiveMedium,
                fontWeight: 'bold',
              }}
            >
              {`${view_position?.toUpperCase()}
                      ${t('ResultView.skin_folds')}`}
            </Typography>

            <Divider style={{ margin: '0', width: '100%' }} variant="middle" />

            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
                letterSpacing: '0.1rem',
                fontSize: theme.fonts.responsiveMedium,
              }}
            >
              <Brightness1Icon
                fontSize="inherit"
                style={{
                  color: theme.palette.lesions.skinFoldsSevere,
                  marginRight: '4px',
                }}
              />
              {t('ResultView.skin_folds_severe')}
            </Typography>

            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
                letterSpacing: '0.1rem',
                fontSize: theme.fonts.responsiveMedium,
              }}
            >
              <Brightness1Icon
                fontSize="inherit"
                style={{
                  color: theme.palette.lesions.skinFoldsMedium,
                  marginRight: '4px',
                }}
              />
              {t('ResultView.skin_folds_medium')}
            </Typography>

            <Typography
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
                letterSpacing: '0.1rem',
                fontSize: theme.fonts.responsiveMedium,
              }}
            >
              <Brightness1Icon
                fontSize="inherit"
                style={{
                  color: theme.palette.lesions.skinFoldsMild,
                  marginRight: '4px',
                }}
              />
              {t('ResultView.skin_folds_mild')}
            </Typography>
          </div>
        ) : (
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.no_skin_folds')}
          </Typography>
        )}
      </>
    );
  };

  return {
    class: getColor(skinFolds),
    tooltip: getTooltip(skinFolds),
  };
};

// Blur Helper
export const blurredAreaInfo = (blur, theme, t) => {
  let label = '';
  let tooltip = '';

  if (blur === 'correct' || blur === 'sharp') {
    label = t('ResultView.correct_blurred_area');
    tooltip = t('ResultView.no_blurred_area');
  } else if (blur === 'insufficient') {
    label = t('ResultView.blurred_area');
    tooltip = t('ResultView.has_blurred_area');
  } else if (!blur || blur === 'not_evaluated') {
    label = t('ResultView.blur_not_evaluated');
    tooltip = t('ResultView.not_eval_blurred_area');
  }

  return {
    label,
    tooltip,
  };
};

// Bucky Height Helper
export const buckyHeightInfo = (elevation, t) => {
  let color, label, icon, tooltipLabel;

  if (elevation === 'correct') {
    color = 'correct';
    tooltipLabel = t('ResultView.breast_center');
    label = t('ResultView.centered');
    icon = (
      <HeightIcon
        fontSize="small"
        style={{
          color: 'inherit',
          cursor: 'help',
          width: '18px',
        }}
      />
    );
  } else if (elevation === 'high') {
    color = 'insufficient';
    tooltipLabel = t('ResultView.breast_low');
    label = `${t('ResultView.bucky_height')}: ${t('ResultView.high')}`;
    icon = (
      <ArrowUpwardIcon
        fontSize="small"
        style={{
          color: 'inherit',
          cursor: 'help',
          width: '18px',
        }}
      />
    );
  } else if (elevation === 'low') {
    color = 'moderate';
    tooltipLabel = t('ResultView.breast_high');
    label = `${t('ResultView.bucky_height')}: ${t('ResultView.low')}`;
    icon = (
      <ArrowDownwardIcon
        fontSize="small"
        style={{
          color: 'inherit',
          cursor: 'help',
          width: '18px',
        }}
      />
    );
  } else if (elevation === 'not_evaluated' || !elevation) {
    color = 'not_evaluated';
    tooltipLabel = t('ResultView.elev_not_eval');
    label = t('ResultView.is_not_evaluated');
    icon = (
      <SettingsEthernetIcon
        fontSize="small"
        style={{
          color: 'inherit',
          cursor: 'help',
          width: '18px',
        }}
      />
    );
  }

  return { color, label, icon, tooltipLabel };
};

// Compression Helper
export const compressionInfo = (
  compressionClass,
  currentCompression,
  compressionLow,
  compressionHigh,
  compressionTolerance,
  theme,
  t
) => {
  const lowToleranceDown = compressionLow - compressionTolerance;
  const higherToleranceUp = compressionHigh + compressionTolerance;
  if (!currentCompression) {
    return (
      <Typography
        style={{
          letterSpacing: '0.1rem',
          fontSize: theme.fonts.responsiveMedium,
        }}
      >
        {t('projections.no_compression')}
      </Typography>
    );
  }

  if (currentCompression >= compressionLow && currentCompression <= compressionHigh)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${compressionClass} (${currentCompression?.toFixed()} N)`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_ok', {
            compression_low: `${compressionLow}`,
            compression_high: `${compressionHigh}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression >= lowToleranceDown && currentCompression <= higherToleranceUp) {
    if (currentCompression < compressionLow)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_low', {
              compression: `${compressionLow} ${t('ResultView.limits_tolerance')} ${compressionTolerance}`,
            })}
          </Typography>
        </>
      );
    else if (currentCompression > compressionHigh)
      return (
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
          </Typography>
          <Divider style={{ margin: '3% 0' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
            }}
          >
            {t('ResultView.comp_high', {
              compression: `${compressionHigh}N ${t('ResultView.limits_tolerance')} ${compressionTolerance}`,
            })}
          </Typography>
        </>
      );
  }

  if (currentCompression < lowToleranceDown)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />
        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_low', {
            compression: `${compressionLow} and below the tolerance range of ${compressionTolerance}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression > higherToleranceUp)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${compressionHigh}N ${t('ResultView.above_tolerance')} ${compressionTolerance}`,
          })}
        </Typography>
      </>
    );

  if (currentCompression > compressionHigh && currentCompression <= higherToleranceUp)
    return (
      <>
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
        >
          {`${t('ResultView.cmp')}: ${currentCompression?.toFixed()} N`}
        </Typography>
        <Divider style={{ margin: '3% 0' }} />

        <Typography
          style={{
            letterSpacing: '0.1rem',
            fontSize: theme.fonts.responsiveMedium,
          }}
        >
          {t('ResultView.comp_high', {
            compression: `${compressionHigh}N ${t('ResultView.limits_tolerance')} ${compressionTolerance}`,
          })}
        </Typography>
      </>
    );
};

// Symmetry Helper
export const symmetryInfo = (evaluation, currentProjection, theme, t) => {
  return (
    <Typography
      style={{
        letterSpacing: '0.1rem',
        textTransform: 'Capitalize',
        fontSize: theme.fonts.responsiveMedium,
      }}
    >
      {evaluation === 'not_evaluated'
        ? t('ResultView.not_eval_feature')
        : `${currentProjection} ${t('ResultView.symmetry')}: ${useQualityTranslation(evaluation, t)}`}
    </Typography>
  );
};

export const evalPNL = (pnlLengthCC, pnlLengthMLO, pnl_diff_thresholds, pnl_diff_classes) => {
  if (!pnlLengthCC || !pnlLengthMLO || !pnl_diff_thresholds || !pnl_diff_classes) return;

  const pnlDiff = Math.abs(pnlLengthCC - pnlLengthMLO);

  if (isNaN(pnlDiff)) return;

  if (pnl_diff_thresholds[0] < 1) {
    // If the thresholds are < 1 use percentage instead of absolute values
    const pnlDiffPercentage = pnlDiff / Math.max(pnlLengthCC, pnlLengthMLO);

    if (pnlDiffPercentage < pnl_diff_thresholds?.[0]) return pnl_diff_classes?.[0] ?? null;
    if (pnlDiffPercentage < pnl_diff_thresholds?.[1]) return pnl_diff_classes?.[1] ?? null;
    else return pnl_diff_classes?.[2] ?? pnl_diff_classes?.[1] ?? null;
  } else {
    if (pnlDiff < pnl_diff_thresholds?.[0]) return pnl_diff_classes?.[0] ?? null;
    if (pnlDiff < pnl_diff_thresholds?.[1]) return pnl_diff_classes?.[1] ?? null;
    else return pnl_diff_classes?.[2] ?? pnl_diff_classes?.[1] ?? null;
  }
};

export const getTooltipText = (
  projName,
  feature,
  evaluations,
  applied_quality,
  customQuality,
  localChangesQuality
) => {
  if (!evaluations?.hasOwnProperty(applied_quality)) return t('ResultView.not_available');

  const projections = {
    rcc: {
      predicted: evaluations?.quality?.bbox?.rcc ?? {},
      unsaved: customQuality?.rcc,
      applied: evaluations?.quality?.[applied_quality]?.rcc ?? {},
    },
    lcc: {
      predicted: evaluations?.quality?.bbox?.lcc ?? {},
      unsaved: customQuality?.lcc,
      applied: evaluations?.quality?.[applied_quality]?.lcc ?? {},
    },
    rmlo: {
      predicted: evaluations?.quality?.bbox?.rmlo ?? {},
      unsaved: customQuality?.rmlo,
      applied: evaluations?.quality?.[applied_quality]?.rmlo ?? {},
    },
    lmlo: {
      predicted: evaluations?.quality?.bbox?.lmlo ?? {},
      unsaved: customQuality?.lmlo,
      applied: evaluations?.quality?.[applied_quality]?.lmlo ?? {},
    },
  };

  // Unsaved changes
  if (!projections[projName].predicted.hasOwnProperty(feature)) return t('ResultView.not_available');

  if (
    localChangesQuality &&
    projections[projName]?.applied[feature] !== projections[projName]?.unsaved[feature]
  )
    return (
      <>
        <p style={{ fontSize: '1.3vh' }}>{t('ResultView.unsaved')}</p>
        <p style={{ fontSize: '1.3vh' }}>
          {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
        </p>
      </>
    );

  if (
    Object.keys(evaluations).length > 1 &&
    applied_quality !== 'bbox' &&
    projections[projName]?.applied &&
    projections[projName]?.applied[feature] !== projections[projName]?.predicted[feature]
  ) {
    if (Object.keys(evaluations).length === 1) return 'No active revision';

    return (
      <>
        <p style={{ fontSize: '1.3vh' }}>{`${t('ResultView.relabeled')} ${applied_quality}`}</p>
        <p style={{ fontSize: '1.3vh' }}>
          {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
        </p>
      </>
    );
  }

  return (
    <>
      <p style={{ fontSize: '1.3vh' }}>{t('ResultView.no_changes')}</p>
      <p style={{ fontSize: '1.3vh' }}>
        {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
      </p>
    </>
  );
};
