import React, { useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  Divider,
  Grid,
  IconButton,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  Typography,
  Avatar,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { Language } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword';
import NeuroConnector from './../../../resources/coorporate_images/neuro_network_small.png';
import CloseIcon from '@material-ui/icons/Close';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: theme.drawerWidthAvatar,
    overflow: 'hidden',
  },

  drawerHeader: {
    display: 'grid',
    margin: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    minHeight: '6% !important',
  },

  avatar_container: {
    display: 'flex',
    alignItems: 'center',
    gridColumn: '2',
    justifyContent: 'flex-start',
  },

  drawerContainer: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  profile_avatar: {
    color: theme.palette.complementary.turquoise,
    height: '30px',
    width: '30px',
    marginRight: '8px',
  },

  avatar_profile_text: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.complementary.turquoise,
  },

  close_container: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  close_button: { margin: theme.spacing(0.5), padding: '3px' },
  profile_container: {
    margin: '20px',
  },

  icon: {
    color: theme.palette.texts.secondary,
    marginRight: '10px',
  },

  aliases_container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },

  aliases_chips: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  listHeader: {
    fontSize: theme.fonts.responsiveMedium,
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
}));

export default function DrawerAvatar(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const userAliases = localStorage.getItem('aliases') ?? null;

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getInitials(string) {
    if (!string) {
      history.push('/logout');
      return;
    }
    const tokens = string.trim().split(' ');
    return tokens.map((token) => token[0].toUpperCase()).join('');
  }

  return (
    <Drawer
      anchor={'right'}
      transitionDuration={600}
      open={props.open}
      onClose={(_e, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          props.onClose();
        }
        props.onClose();
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-test-id='drawer-avatar'
    >
      <div className={classes.drawerHeader}>
        <div className={classes.close_container}>
          <Tooltip
            arrow
            placement='right'
            title={<p className={classes.tooltip}>{t('Console.close')}</p>}
            aria-label='add'
          >
            <IconButton
              edge='start'
              className={classes.close_button}
              color='inherit'
              aria-label='menu'
              onClick={() => props.onAvatarClick()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.avatar_container}>
          <Avatar className={classes.profile_avatar}>
            {getInitials(localStorage.getItem('username'))}
          </Avatar>
          <Typography className={classes.avatar_profile_text}>
            {localStorage.getItem('username')?.toUpperCase()}
          </Typography>
        </div>
      </div>
      <Divider />

      <div className={classes.profile_container}>
        {userAliases && userAliases !== 'undefined' && (
          <div className={classes.aliases_container}>
            <div className={classes.aliases_chips}>
              <AccountBoxIcon className={classes.icon} fontSize='small' />
              <Typography className={classes.listHeader}>{t('users.dicomTag')}</Typography>
            </div>
            <div>
              {userAliases?.split(';').map((alias, i) => (
                <Chip
                  key={i}
                  style={{ margin: '6px' }}
                  size='small'
                  label={alias.trim() ? alias.trim() : '-'}
                />
              ))}
            </div>
          </div>
        )}

        <ChangePassword />
      </div>
      <Grid
        container
        direction='column'
        justifyContent='flex-end'
        className={classes.drawerContainer}
        wrap='nowrap'
        style={{
          backgroundImage: `url(${NeuroConnector})`,
          backgroundSize: '900px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundPositionX: 'left',
          backgroundPositionY: 'bottom',
          opacity: '30%',
        }}
      ></Grid>
    </Drawer>
  );
}
