import history from '../history';
import { API_ENDPOINT, AI_ENDPOINT, DATA_ENDPOINT } from '../config';

const endpoints = {
  bbox: AI_ENDPOINT,
  server: API_ENDPOINT,
  data: DATA_ENDPOINT,
  models: null,
};

const fetchGet = async (
  route,
  backend = 'server',
  cache = true,
  resType = 'json',
  signal = null
) => {
  try {
    const url = endpoints[backend] + '/' + route;

    const headers = { 'Content-Type': 'application/json' };
    if (!cache) {
      headers.pragma = 'no-cache';
    }
    const response = await fetch(url, {
      method: 'get',
      withCredentials: true,
      credentials: 'include',
      headers,
      cache: cache ? 'default' : 'no-cache',
      signal,
    });

    if (response.status === 403) {
      history.push('/logout');
      return {
        success: false,
        msg: 'You are not authorized',
        duration: 100,
      };
    }
    if (resType === 'json') {
      const result = await response.json();
      return result;
    }
    if (resType === 'blob') {
      const blob = await response.blob();
      const result = {
        blob,
        status: response.status,
      };
      return result;
    }
  } catch (err) {
    console.log(err);
    return {
      success: false,
      msg: `Failed to get ${err.message}`,
    };
  }
};

const fetchPost = async (
  route,
  body,
  backend = 'server',
  cache = true,
  resType = 'json',
  signal = null
) => {
  try {
    const url = endpoints[backend] + '/' + route;

    const headers = { 'Content-Type': 'application/json' };
    if (!cache) {
      headers.pragma = 'no-cache';
    }
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(body),
      signal,
    });
    if (response.status === 403) {
      history.push('/logout');
      return {
        success: false,
        msg: 'You are not authorized',
        duration: 100,
      };
    }
    if (resType === 'json') {
      const result = await response.json();
      return result;
    }
    if (resType === 'blob') {
      const blob = await response.blob();
      const result = {
        blob,
        status: response.status,
      };
      return result;
    }
  } catch (err) {
    console.log(err);
    return {
      success: false,
      msg: `Failed to post ${err.message}`,
    };
  }
};

const fetchPut = async (route, body, backend = 'server', signal = null) => {
  try {
    const url = endpoints[backend] + '/' + route;

    const response = await fetch(url, {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(body),
      signal,
    });
    if (response.status === 403) {
      history.push('/logout');
      return {
        success: false,
        msg: 'You are not authorized',
        duration: 100,
      };
    }
    const result = await response.json();
    return result;
  } catch (err) {
    console.log(err);
    return {
      success: false,
      msg: `Failed to put ${err.message}`,
    };
  }
};

const fetchDelete = async (route, body = null, backend = 'server', signal = null) => {
  try {
    const url = endpoints[backend] + '/' + route;

    const response = await fetch(url, {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      credentials: 'include',
      body: body ? JSON.stringify(body) : null,
      signal,
    });
    if (response.status === 403) {
      history.push('/logout');
      return {
        success: false,
        msg: 'You are not authorized',
        duration: 100,
      };
    }
    const result = await response.json();
    return result;
  } catch (err) {
    console.log(err);
    return {
      success: false,
      msg: `Failed to delete ${err.message}`,
    };
  }
};

export { fetchGet, fetchPost, fetchPut, fetchDelete };
