import { createSlice } from '@reduxjs/toolkit';
import fakeJson from '../../helpers/variables.json';
import { fetchGet, fetchDelete } from '../../helpers/fetching';

const initialState = {
  client: '',
  client_id: '',
  compression_high: 0,
  compression_low: 0,
  compression_tolerance: 0,
  premium_features: {
    data_annotation: false,
    data_export: false,
    density_module: false,
    diagnostics_microcalc_module: false,
    diagnostics_opacities_module: false,
    model_calibration: false,
    retrospective_processing: false,
    risk_module: false,
  },
  quality_system: {
    quality_system: 'swiss',
    quality_values: ['perfect', 'good', 'moderate', 'inadequate'],
    overall_quality: false,
    histogram_chart: false,
    quality_features: {
      artifacts_classes: [],
      blur_classes: [],
      breast_volume_classes: [],
      compression_classes: [],
      contrast_classes: [],
      elevation_classes: [],
      exposure_classes: [],
      image_labeling_classes: [],
      imf_classes: [],
      nipple_orientation_classes: [],
      nipple_profile_classes: [],
      noise_classes: [],
      parenchyma_classes: [],
      pect_angle_classes: [],
      pect_level_classes: [],
      pect_shape_classes: [],
      pectoral_muscle_classes: [],
      pnl_diff_classes: [],
      skin_folds_classes: [],
      symmetry_classes: [],
    },
    quality_thresholds: {
      pnl_from_edge: false,
      nipple_angle_thresholds: [0, 0],
      parenchyma_cuts_thresholds: [0, 0],
      pnl_diff_thresholds: [0, 0],
      symmetry_thresholds: [0, 0],
    },
  },
  user_settings: {
    language: 'en',
    presentation_lesions: 'boxes',
    presentation_quality: 'boxes',
  },
};

export const globalSettingsSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setGlobalSettings: (state, action) => {
      const { user_settings, ...rest } = action.payload;

      state.client = rest.client || '';
      state.client_id = rest.client_id || '';
      state.compression_high = rest.compression_high || 0;
      state.compression_low = rest.compression_low || 0;
      state.compression_tolerance = rest.compression_tolerance || 0;
      state.premium_features = rest.premium_features || initialState.premium_features;
      state.quality_system = rest.quality_system || initialState.quality_system;

      if (user_settings) {
        state.user_settings.language = user_settings.language || 'en';
        state.user_settings.presentation_lesions = user_settings.presentation_lesions || 'boxes';
        state.user_settings.presentation_quality = user_settings.presentation_parenchyma || 'boxes';
      }
    },
  },
});

export const { setGlobalSettings } = globalSettingsSlice.actions;

export default globalSettingsSlice.reducer;

export const fetchGlobalSettings = () => {
  return async (dispatch) => {
    const response = await fetchGet('global_settings', 'bbox');
    if (response.success) dispatch(setGlobalSettings(response.data));
  };
};
