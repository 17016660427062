import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, Divider, Tooltip, Fab, Button } from '@material-ui/core';
import { getCurrentModuleStatus } from '../../../../Summary/SummaryCommon/SummaryCommon';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import {
  saveResults,
  discardCurrentChanges,
  onConfirmClick,
} from '../../Helpers/DiagnosticRevisionTableCommon';
import { useStyles } from '../../DiagnosticRevisionTable.style';

const ModuleTableActions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { localChangesMicrocalc, localChangesOpacities } = useSelector((state) => state.labeling);

  const {
    module,
    resultEvaluation,
    moduleShown,
    moduleState,
    isModuleConfirmed,
    isModuleRelabeled,
    appliedRevision,
  } = props;

  const currentModuleChanges =
    (module === 'microcalc' && localChangesMicrocalc) || (module === 'opacities' && localChangesOpacities);

  const otherModuleChanges =
    (module === 'microcalc' && localChangesOpacities) || (module === 'opacities' && localChangesMicrocalc);

  return (
    <>
      {!currentModuleChanges && !otherModuleChanges && (
        <Tooltip
          title={
            <>
              <Typography className={classes.tooltipText}>
                {
                  getCurrentModuleStatus(
                    appliedRevision,
                    moduleShown,
                    isModuleConfirmed,
                    isModuleRelabeled,
                    t('ResultView.diagnose_microcalc'),
                    theme,
                    t
                  ).currentApplied
                }
              </Typography>
              <Divider style={{ margin: '3% 0' }} />
              {
                getCurrentModuleStatus(
                  appliedRevision,
                  moduleShown,
                  isModuleConfirmed,
                  isModuleRelabeled,
                  t('ResultView.diagnose_microcalc'),
                  theme,
                  t
                ).tooltipAction
              }
            </>
          }
          arrow
          placement="top-start"
        >
          <Button
            color="primary"
            startIcon={
              getCurrentModuleStatus(
                appliedRevision,
                moduleShown,
                isModuleConfirmed,
                isModuleRelabeled,
                t('ResultView.diagnose_microcalc'),
                theme,
                t
              ).fabIcon
            }
            style={{
              color: '#fff',
              background: `${
                getCurrentModuleStatus(
                  appliedRevision,
                  moduleShown,
                  isModuleConfirmed,
                  isModuleRelabeled,
                  t('ResultView.diagnose_microcalc'),
                  theme,
                  t
                ).color
              }`,
              textWrap: 'nowrap',
            }}
            className={classes.confirm_action_fabs}
            onClick={() => {
              onConfirmClick(module, moduleShown, moduleState, dispatch, actions, t);
            }}
          >
            {
              getCurrentModuleStatus(
                appliedRevision,
                moduleShown,
                isModuleConfirmed,
                isModuleRelabeled,
                t('ResultView.diagnose_microcalc'),
                theme,
                t
              ).buttonText
            }
          </Button>
        </Tooltip>
      )}

      {currentModuleChanges && !otherModuleChanges && (
        <>
          {/* Discard */}
          <Tooltip
            title={<Typography className={classes.tooltipText}>{t('ResultView.btn_discard')}</Typography>}
            arrow
            placement="top-start"
          >
            <Fab
              size="small"
              className={classes.discard_action_fabs}
              color="primary"
              onClick={() => {
                discardCurrentChanges(module, resultEvaluation, dispatch, actions);
              }}
            >
              <ReplayIcon fontSize="small" style={{ width: '20px', height: '20px' }} />
            </Fab>
          </Tooltip>
          {/* Save */}
          <Tooltip
            title={<Typography className={classes.tooltipText}>{t('ResultView.btn_save')}</Typography>}
            arrow
            placement="top-start"
          >
            <Button
              size="small"
              onClick={() => saveResults(module, dispatch, actions, t)}
              className={classes.save_action_fabs}
              startIcon={<SaveIcon fontSize="medium" style={{ width: '20px', height: '20px' }} />}
            >
              {t('ResultView.save')}
            </Button>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default ModuleTableActions;
