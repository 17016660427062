import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';

import DicomFilters from './DicomFilters/DicomFilters';
import { fetchGet, fetchPut } from '../../../../helpers/fetching';
import DraggableDialog from '../../Dialogs/DraggableDialog/DraggableDialog';
import ReportSettings from './Report/Report';
import Other from './Other/Other';
import Configuration from './Configuration/Configuration';
import ReportServers from './Report/ReportServers';

import Processing from './Processing/Processing';
import { setSnackbar, setInfo, fetchGlobalSettings } from '../../../../store';

const useStyles = makeStyles((theme) => ({
  Dialog: {
    '& .MuiDialog-paper': {
      minHeight: '65vh',
    },
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    alignItems: 'center',
  },
}));

const initialSettings = {
  filter_dcm: false,
  anonymize: false,
  diagnostic_sensitivity: 'medium',
  hide_operator_name: false,
  store_dicoms: false,
  discFree: 0,
  discSize: 0,
  dicomCount: 0,
  presentation_lesions: '',
  presentation_quality: '',
  language: 'en',
  remove_after_days: null,
  report_customization: {},
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function Settings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const currentRole = localStorage.getItem('role');
  const global_settings = useSelector((state) => state.global_settings);

  // State
  const [dicomFilters, setDicomFilters] = useState([]);
  const [dicomServers, setDicomServers] = useState([]);
  const [serverActive, setServerActive] = useState(false);
  const [watcherActive, setWatcherActive] = useState(false);
  const [settings, setSettings] = useState(initialSettings);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [sensitivityChanged, setSensitivityChanged] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [serversChanged, setServerChanged] = useState(false);
  const [anonymized, setAnonimized] = useState(0);
  const [value, setValue] = useState(0);
  const [loadingOrthanc, setLoadingOrthanc] = useState(false);
  const [loadingWatcher, setLoadingWatcher] = useState(false);
  const activeDialog = useSelector((state) => state.gui.activeDialog);

  const managementAccess =
    currentRole === 'admin' || currentRole === 'supervisor' || currentRole === 'radiologist';

  useEffect(() => {
    if (!managementAccess) setValue(3);
    else if (managementAccess && currentRole !== 'admin') setValue(1);
    else setValue(0);
  }, []);

  // Fetching
  const fetchFilters = useCallback(async () => {
    const response = await fetchGet('filters');
    if (response.success) setDicomFilters(response.data);
    else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  }, [dispatch]);

  const fetchSettings = useCallback(async () => {
    const response = await fetchGet('settings');
    if (response.success) {
      response.data.presentation_quality = response.data.presentation_parenchyma || 'contours';
      response.data.presentation_lesions = response.data.presentation_lesions || 'boxes';
      if (response.data.presentation_lesions.endsWith('_boxes')) {
        response.data.presentation_lesions = response.data.presentation_lesions.replace('_boxes', '');
      }

      if (!response.data.report_customization) {
        response.data.report_customization = initialSettings.report_customization;
      }
      setSettings(response.data);
      setAnonimized(response.data.anonymize);
    } else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  }, [dispatch]);

  const fetchServers = useCallback(async () => {
    const response = await fetchGet('dicom_servers');
    if (response.success) setDicomServers(response.data);
    else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  }, [dispatch]);

  const fetchOrthancState = useCallback(async () => {
    const response = await fetchGet('orthanc', 'bbox');
    if (response.success) setServerActive(response.data);
    else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  }, [dispatch]);

  const fetchWatcherState = useCallback(async () => {
    const response = await fetchGet('watcher', 'bbox');
    if (response.success) setWatcherActive(response.data);
    else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  }, [dispatch]);

  // Effects
  useEffect(() => {
    fetchFilters();
    fetchSettings();
    fetchServers();
    fetchOrthancState();
    fetchWatcherState();
  }, [fetchFilters, fetchSettings, fetchServers, fetchOrthancState, fetchWatcherState]);

  // Handlers
  function addDicomFilter(newFilter) {
    setDicomFilters((currentFilters) => [...currentFilters, newFilter]);
    setFiltersChanged(true);
  }

  function addDicomServer(newServer) {
    const newServerList = dicomServers.filter((s) => s.Name !== newServer.Name);
    newServerList.push(newServer);
    setDicomServers(newServerList);
    setServerChanged(true);
  }

  function deleteDicomFilter(toDelete) {
    setDicomFilters((currentFilters) => currentFilters.filter((f) => f.name !== toDelete.name));
    setFiltersChanged(true);
  }

  function deleteDicomServer(toDelete) {
    setDicomServers((servers) => servers.filter((s) => s.Name !== toDelete.Name));
    setServerChanged(true);
  }

  function updateSettingsCheckbox(event) {
    const { checked, name } = event.target;
    const newSettings = { ...settings };
    newSettings[name] = checked ? 1 : 0;
    setSettings(newSettings);
    setSettingsChanged(true);
  }

  function updateSettingsValue(event) {
    let { value, name } = event.target;
    if (value) {
      value = parseInt(value);
      if (!Number.isInteger(value)) return;
    }
    const newSettings = { ...settings };
    newSettings[name] = value;
    setSettings(newSettings);
    setSettingsChanged(true);
  }

  function updateLanguage(event) {
    let { value, name } = event.target;
    const newSettings = { ...settings };
    newSettings[name] = value;

    setSettings(newSettings);
    setSettingsChanged(true);

    i18n.changeLanguage(value);
  }

  const updateQualityPresentationDisplay = (event) => {
    const { value, type, checked } = event.target;
    const newSettings = { ...settings };

    const updateSettings = (key, newValue) => {
      newSettings[key] = newValue;
    };

    if (type === 'radio') {
      ['presentation_quality'].forEach((key) => updateSettings(key, value));
    } else if (type === 'checkbox') {
      const suffix = checked ? '_boxes' : '';
      const baseValue = settings.presentation_quality.replace(/_boxes$/, '');

      ['presentation_quality'].forEach((key) => updateSettings(key, `${baseValue}${suffix}`));
    }

    setSettings(newSettings);
    setSettingsChanged(true);
  };

  const updateDiagnosticsPresentationDisplay = (event) => {
    const { value, type, checked } = event.target;
    const newSettings = { ...settings };

    const updateSettings = (key, newValue) => {
      newSettings[key] = newValue;
    };

    if (type === 'radio') {
      ['presentation_lesions'].forEach((key) => updateSettings(key, value));
    } else if (type === 'checkbox') {
      const suffix = checked ? '_boxes' : '';
      const baseValue = settings.presentation_lesions.replace(/_boxes$/, '');

      ['presentation_lesions'].forEach((key) => updateSettings(key, `${baseValue}${suffix}`));
    }

    setSettings(newSettings);

    setSettingsChanged(true);
  };

  function updateSettingsText(event) {
    let { value, name } = event.target;
    const newSettings = { ...settings };
    newSettings[name] = value;
    setSettings(newSettings);
    setSettingsChanged(true);
  }

  async function putSettings() {
    settings.presentation_parenchyma = settings.presentation_quality || 'contours';
    let response = await fetchPut('settings', settings);
    if (response.success) {
      fetchPut('language', { language: settings.language }, 'bbox');
      dispatch(
        setSnackbar({
          msg: t('Settings.snack_settings_saved'),
          severity: 'success',
        })
      );

      if (anonymized !== settings.anonymize) {
        dispatch(actions.fetchExaminations());
      }

      dispatch(fetchGlobalSettings());
    } else
      dispatch(
        setSnackbar({
          msg: t('Settings.error_saving'),
          severity: 'error',
        })
      );
  }

  async function putFilters() {
    const response = await fetchPut('filters', dicomFilters);
    if (response.success)
      dispatch(
        setSnackbar({
          msg: t('Settings.snack_filters_saved'),
          severity: 'success',
        })
      );
    else
      dispatch(
        setSnackbar({
          msg: t('Settings.cnack_filters_failed'),
          severity: 'error',
        })
      );
  }

  async function putServers() {
    const response = await fetchPut('dicom_servers', dicomServers);
    if (response.success)
      dispatch(
        setSnackbar({
          msg: t('Settings.snack_dicom_saved'),
          severity: 'success',
        })
      );
    else
      dispatch(
        setSnackbar({
          msg: t('Settings.snack_dicom_failed'),
          severity: 'error',
        })
      );
  }

  async function setOrthanc(event) {
    setLoadingOrthanc(true);
    const { checked } = event.target;
    const response = await fetchPut('orthanc', { state: checked }, 'bbox');
    if (response.success) {
      setServerActive(checked);
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'success',
        })
      );
      setLoadingOrthanc(false);
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      setLoadingOrthanc(false);
    }
  }

  async function setWatcher(event) {
    setLoadingWatcher(true);
    const isActive = event.target.checked;
    const response = await fetchPut('watcher', { state: isActive }, 'bbox');
    if (response.success) {
      setWatcherActive(isActive);
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'success',
        })
      );
      setLoadingWatcher(false);
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      setLoadingWatcher(false);
    }
  }

  function saveChanges(_) {
    if (settingsChanged) putSettings();

    if (filtersChanged) putFilters();

    if (serversChanged) putServers();

    if (sensitivityChanged) dispatch(setInfo(t('Settings.sensitivity_info')));

    setSettingsChanged(false);
    setFiltersChanged(false);
    setServerChanged(false);
    setSensitivityChanged(false);

    dispatch(actions.setActiveDialog(null));
  }

  function updateReportConfig(event) {
    let { name } = event.target;
    const newSettings = { ...settings };
    newSettings.report_customization[name] = !newSettings.report_customization[name];
    setSettings(newSettings);
    setSettingsChanged(true);
  }

  function onClose(_) {
    dispatch(actions.setActiveDialog(null));

    if (global_settings.user_settings.language === settings.language) {
    } else {
      i18n.changeLanguage(global_settings.user_settings.language);
      fetchPut('language', { language: global_settings.user_settings.language }, 'bbox');
    }
  }

  function setSensitivity(val) {
    const sensitivityMapping = {
      0: 'low',
      1: 'medium',
      2: 'high',
    };

    if (!sensitivityMapping.hasOwnProperty(val)) {
      console.log(`Bad sensitivity value: ${val}`);
      return;
    }

    setSettings((oldSettings) => ({
      ...oldSettings,
      diagnostic_sensitivity: sensitivityMapping[val],
    }));
    setSettingsChanged(true);
    setSensitivityChanged(true);
  }

  return (
    <DraggableDialog
      className={classes.Dialog}
      maxWidth="lg"
      title={t('Settings.settings')}
      open={activeDialog === 'settings'}
      style={{ zIndex: theme.zIndex.main_drawer }}
      actions={[
        // {
        //   onClick: onClose,
        //   color: 'primary',
        //   label: t('Settings.cancel_settings'),
        // },
        {
          onClick: saveChanges,
          color: 'primary',
          label: t('Settings.save_settings'),
        },
      ]}
      onClose={onClose}
    >
      <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={1}>
        <Grid item xs={2}>
          <Tabs
            className={classes.tabs}
            orientation="vertical"
            value={value}
            onChange={(_, newVal) => setValue(newVal)}
          >
            <Tab label={t('Settings.filters')} id="filter_dcm" disabled={currentRole !== 'admin'} />
            <Tab label={t('Settings.report')} id="report" disabled={!managementAccess} />
            <Tab
              label={t('Settings.report_servers')}
              id="report_servers"
              disabled={currentRole !== 'admin'}
            />
            <Tab label={t('Settings.processing')} id="processing" />
            <Tab label={t('Settings.configuration')} id="configuration" />
            <Tab label={t('Settings.other')} id="other" />
          </Tabs>
        </Grid>

        <Grid item xs={10}>
          {currentRole === 'admin' && (
            <TabPanel value={value} index={0}>
              <DicomFilters
                items={dicomFilters}
                addItem={addDicomFilter}
                deleteItem={deleteDicomFilter}
                checked={!!settings.filter_dcm}
                onChange={updateSettingsCheckbox}
                name="filter_dcm"
                setSnackbar={(obj) => dispatch(setSnackbar(obj))}
              />
            </TabPanel>
          )}

          {managementAccess && (
            <TabPanel value={value} index={1}>
              <ReportSettings
                settings={settings}
                updateSettingsCheckbox={updateSettingsCheckbox}
                updateSettingsText={updateSettingsText}
                updateSettingsValue={updateSettingsValue}
                updateReportConfig={updateReportConfig}
                setSnackbar={(obj) => dispatch(setSnackbar(obj))}
              />
            </TabPanel>
          )}

          <TabPanel value={value} index={2}>
            <ReportServers
              items={dicomServers}
              addItem={addDicomServer}
              deleteItem={deleteDicomServer}
              settings={settings}
              setSnackbar={(obj) => dispatch(setSnackbar(obj))}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Processing
              loadingOrthanc={loadingOrthanc}
              loadingWatcher={loadingWatcher}
              serverActive={serverActive}
              setOrthanc={setOrthanc}
              setWatcher={setWatcher}
              watcherActive={watcherActive}
              managementAccess={currentRole === 'admin'}
            />
          </TabPanel>

          <TabPanel value={value} index={4}>
            <Configuration
              settings={settings}
              updateSettingsValue={updateSettingsValue}
              diagnostic_sensitivity={settings.diagnostic_sensitivity}
              setSensitivity={setSensitivity}
              updateQualityPresentationDisplay={updateQualityPresentationDisplay}
              updateDiagnosticsPresentationDisplay={updateDiagnosticsPresentationDisplay}
              managementAccess={managementAccess}
            />
          </TabPanel>

          <TabPanel value={value} index={5}>
            <Other
              settings={settings}
              updateSettingsCheckbox={updateSettingsCheckbox}
              updateSettingsValue={updateSettingsValue}
              updateLanguage={updateLanguage}
              managementAccess={managementAccess}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </DraggableDialog>
  );
}

export default Settings;
