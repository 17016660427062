import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fetchGet } from '../../helpers/fetching';
import animation from '../../resources/processing_animation.gif';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const IFUPdf = () => {
  const { lang } = useParams();
  const { i18n, t } = useTranslation();
  const username = localStorage.getItem('username');
  const userLanguage = useSelector((state) => state.global_settings?.user_settings?.language ?? 'en');
  const selectedLang = lang || userLanguage;

  const [selectedPdf, setSelectedPdf] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang, i18n]);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const result = await fetchGet(`ifu/${selectedLang}.pdf`, 'server', true, 'blob');

        if (result.blob) {
          const url = URL.createObjectURL(result.blob);
          setSelectedPdf(url);
        } else {
          setError(`${t('support.error')}: ${result.msg || 'Unknown error'}`);
        }
      } catch (error) {
        setError(`${t('support.error')}: ${error.message}`);
      }
    };

    fetchPdf();

    return () => {
      if (selectedPdf) {
        URL.revokeObjectURL(selectedPdf);
      }
    };
  }, [selectedLang]);

  return (
    <Dialog open={!!username} maxWidth="md" fullWidth>
      <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {selectedPdf ? (
          <embed src={selectedPdf} width="100%" height="900px" type="application/pdf" />
        ) : error ? (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <ErrorOutlineIcon />
            <p>{error}</p>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              src={animation}
              alt="loading animation"
            />
            <p>{t('Dashboard.loading')}</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IFUPdf;
