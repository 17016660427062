import { useTheme, useMediaQuery } from '@material-ui/core';

const useResponsive = () => {
  const theme = useTheme();

  const isXSmallScreen = useMediaQuery('(max-width: 600px)');
  const isSmallScreen = useMediaQuery('(min-width: 601px) and (max-width: 999px)');
  const isMediumScreen = useMediaQuery('(min-width: 1000px) and (max-width: 1420px)');
  const isLargeScreen = useMediaQuery('(min-width: 1421px) and (max-width: 1600px)');
  const isXLargeScreen = useMediaQuery('(min-width: 1601px)');

  return {
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
  };
};

export default useResponsive;
