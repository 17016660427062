import React from 'react';
import PropTypes from 'prop-types';
import { Chip, Fab, Icon, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { evaluationClassColor } from '../../../../../helpers/qualityColor';

const useStyles = makeStyles((theme) => ({
  chips_general: {
    margin: theme.spacing(1),
    textTransform: 'capitalize',
    height: '20px',
    margin: '2px 0',
    minWidth: '110px',
  },
}));

const QualityChips = ({
  chipIcon,
  evaluationClass,
  evaluationLabel,
  tooltipText,
  chipAction,
  extraStyles,
  tooltipAction,
  tooltipLeave,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const qualityInRevision = useSelector((state) => state.labeling?.qualityInRevisionMode);

  return (
    <Tooltip title={<div>{tooltipText}</div>} placement="top" arrow>
      <Chip
        onMouseOver={tooltipAction || undefined}
        onMouseLeave={tooltipLeave || undefined}
        icon={chipIcon}
        label={
          <Typography style={{ paddingTop: '1px', fontSize: '0.8rem', lineHeight: '0.8rem' }}>
            {evaluationLabel}
          </Typography>
        }
        onClick={() => {
          if (chipAction && qualityInRevision) chipAction();
        }}
        variant="outlined"
        className={classes.chips_general}
        style={{
          border: `1px solid ${evaluationClassColor(evaluationClass, theme)}`,
          color: evaluationClassColor(evaluationClass, theme),
          fontSize: theme.fonts.responsiveMedium,
          cursor: qualityInRevision ? 'pointer' : 'help',
          ...extraStyles,
        }}
      />
    </Tooltip>
  );
};

QualityChips.propTypes = {
  chipIcon: PropTypes.element,
  evaluationClass: PropTypes.string,
  evaluationLabel: PropTypes.string,
  chipAction: PropTypes.func,
  tooltipText: PropTypes.node,
  extraStyles: PropTypes.object,
  tooltipLeave: PropTypes.func,
  tooltipAction: PropTypes.func,
};

QualityChips.defaultProps = {
  chipIcon: null,
  evaluationClass: '',
  evaluationLabel: '',
  chipAction: null,
  tooltipText: <Typography>''</Typography>,
  extraStyles: {},
  tooltipLeave: null,
  tooltipAction: null,
};

export default QualityChips;
