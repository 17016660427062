export const onClickQualityChip = (view_position, t, dispatch, actions) => {
  dispatch(actions.switchQualityEvaluation(view_position, t));
};

export const onClickQualityFeaturesChip = (featureName, proj, symmetry_classes, dispatch, actions) => {
  dispatch(actions.switchQuality(proj, featureName, symmetry_classes));
};

export const onClickParen = (view_position, laterality = '', parenchyma_classes, dispatch, actions) => {
  const key = 'ParenchymaCuts' + laterality;
  dispatch(actions.switchQuality(view_position, key, parenchyma_classes));
};

export const onClickNippleOrientation = (view_position, nipple_orientation_classes, dispatch, actions) => {
  dispatch(actions.switchNippleTiltQuality(view_position, 'NippleCentered', nipple_orientation_classes));
};

// Proj Fab Clicks
export const onClickChip = (featureChip, view_position, feature_classes, dispatch, actions) => {
  dispatch(actions.switchQuality(view_position, featureChip, feature_classes, false));
};
