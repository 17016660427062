import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './slices/filter';
import datesReducer from './slices/dates';
import examinationsReducer from './slices/examinations';
import guiReducer from './slices/gui';
import statisticsReducer from './slices/statistics';
import resultsReducer from './slices/results';
import labelingReducer from './slices/labeling';
import imagesReducer from './slices/images';
import opDevReducer from './slices/operators_devices';
import globalSettingsReducer from './slices/global_settings';
// import modelsReducer from './slices/models';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
  reducer: {
    filter: filterReducer,
    dates: datesReducer,
    examinations: examinationsReducer,
    gui: guiReducer,
    global_settings: globalSettingsReducer,
    statistics: statisticsReducer,
    results: resultsReducer,
    labeling: labelingReducer,
    images: imagesReducer,
    operators_devices: opDevReducer,
    // models: modelsReducer,
  },
});

export default store;
