import { useSelector } from 'react-redux';
import useResponsive from './helpers/hooks/useResponsive';
import { createTheme, useMediaQuery } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export function createCustomTheme(
  mainBackgroundColor,
  mainPaperColor,
  accentColor,
  mainPrimaryColor,
  mainPrimaryColorDark,
  mainSecondaryColor,
  mainSecondaryColorDark
) {
  const { isXSmallScreen, isSmallScreen, isMediumScreen } = useResponsive();
  const darkState = useSelector((store) => store.gui.darkState);

  const smallDrawer = isXSmallScreen || isSmallScreen || isMediumScreen;

  let myTheme = createTheme({
    typography: {
      fontFamily: ['Inter', 'sans-serif'],
    },

    palette: {
      type: darkState ? 'dark' : 'light',

      background: {
        default: darkState ? mainBackgroundColor : '#EEF2F6',
        paper: darkState ? mainPaperColor : '#FFFFFF',
      },

      primary: {
        main: mainPrimaryColor,
        dark: mainPrimaryColorDark,
        accent: accentColor,
      },

      secondary: {
        main: mainSecondaryColor,
        dark: mainSecondaryColorDark,
        accent: accentColor,
      },

      secondaryBlack: {
        main: mainBackgroundColor,
        dark: mainBackgroundColor,
        accent: accentColor,
      },

      secondaryGray: {
        main: mainPaperColor,
        dark: mainPaperColor,
        accent: accentColor,
      },

      complementary: {
        darker: darkState ? '#303030' : '#d1d1d1',
        mainDark: darkState ? '#424242' : '#FFFFFF',
        mainLight: darkState ? '#696969' : 'rgb(238,238,238)',
        mainLighter: darkState ? '#585858' : 'rgb(196, 188, 188)',
        smallCardsBackground: darkState ? '#4b4b4b80' : '#e5e5e580',
        generalTexts: darkState ? 'rgb(255, 255, 255)' : 'rgb(78, 78, 78)',
        generalTitleBackgrounds: darkState ? '#535353' : '#b9b9b9',
        turquoise: '#46b0b0',
        turquoiseDark: '#237979',
        mainActions: '#F44336',
        highlightLight: darkState ? '#4d4d4d' : '#c1c1c1',
        highlightDarker: darkState ? '#5b5b5b' : '#e3e3e3',
      },

      lesions: {
        parenchyma: 'rgb(6 252 246)',
        parenchymaCorrect: '#47ba5d',
        parenchymaModerate: '#ca9c39a8',
        parenchymaInsufficient: '#a7554fa8',
        parenchymaProj: '#47cfcbb0',
        clips: 'rgb(6, 117, 252)',
        skinFolds: 'rgb(200, 138, 255, 1)',
        scars: '#be3ea2',
        pectoralis: 'rgb(255, 20, 147)',
        pectoralisProj: '#dd1c8491',
        blur: 'pink',
        skinFoldsSevere: '#e74034',
        skinFoldsSevereProj: '#bf4037d4',
        skinFoldsMedium: '#ff9800',
        skinFoldsMild: '#c1c643',
        noSkinFolds: '#47ba5d',
        cutsModerate: '#ff9800',
        cutsInsufficient: 'rgb(221, 47, 47)',
        cutsNoEval: '#719aa5',
        birads2: 'rgb(96, 170, 77)',
        birads3: '#ff9800',
        birads4: '#ff6b00',
        birads5: 'rgb(221, 48, 47)',
        lesionKnown: '#5da4b8',
        vessel: 'rgb(48, 221, 47)',
      },

      projections: {
        rcc: '#2a75ca',
        lcc: '#719aa5',
        rmlo: '#606dc1',
        lmlo: '#a071af',
        perfect: '#47ba5d',
        good: '#60aa4d',
        moderate: '#ff9800',
        adequate: '#47ba5d',
        inadequate: '#cc4238',
        optionOne: '#377193',
        optionTwo: '#2c7075',
        suspicious: '#e74034',
        unsuspicious: '#ff9800',
        unsuspiciousLight: '#ffb4008f',
        none: '#1f8604',
        acrA: '#47ba5d',
        acrB: '#60aa4d',
        acrC: '#ff9800',
        acrD: '#e74034',
        compLow: '#e74034',
        compOptimal: '#47ba5d',
        compHigh: '#ff9800',
        nippleTiltUpSevere: '#e74034',
        nippleTiltUp: '#ff9800',
        nippleStraight: '#47ba5d',
        nippleTiltDown: '#a9a540',
        nippleTiltDownSevere: '#e74034',
        correct: '#1f8604',
        insufficient: '#e74034',
        not_evaluated: 'gray',
        risk_high: '#e74034',
        risk_moderate: '#ff9800',
        risk_low: '#47ba5d',
        volumeUp: '#47ba5d',
        volumeDown: '#e74034',
        symmetryCorrect: '#47ba5d',
        symmetryModerate: '#ff9800',
        symmetryInsufficient: '#e74034',
        buckyCorrect: '#47ba5d',
        buckyInsufficient: '#e74034',
        blur_ok: '#47ba5d',
        blur_insufficient: '#e74034',
        report_insufficient: '#ff4c3f',
      },

      dashboard: {
        perfect: '#108d10',
        adequate: 'rgb(96, 170, 77)',
        good: 'rgb(96, 170, 77)',
        mild: '#399c5e',
        moderate: '#ff6b00',
        inadequate: 'rgb(204, 66, 56)',
        insufficient: 'rgb(204, 66, 56)',
        none: '#4e4e4e',
        noSkinFolds: '#6ca782',
        acrA: '#108d10',
        acrB: 'rgb(96, 170, 77)',
        acrC: '#ff6b00',
        acrD: 'rgb(204, 66, 56)',
      },

      texts: {
        primary: darkState ? '#d4d4d4' : '#212121',
        secondary: darkState ? '#d4d4d4' : '#414141',
        helper: 'gray',
        white: '#ffffff',
      },

      chips: {
        perfect: '#47ba5d',
        good: '#60aa4d',
        moderate: '#ff9800',
        insufficient: '#e74034',
        correct: '#47ba5d',
        adequate: '#47ba5d',
        inadequate: '#cc4238',
        low: '#e74034',
        high: '#e74034',
      },
    },

    fonts: {
      responsiveLarge: 'min(6.5vh, 8rem)',
      responsiveHeader: 'min(2.4vh, 1.5rem)',
      responsiveBig: 'min(1.9vh, 1.4rem)',
      responsiveMediumBig: 'min(1.4vh, 1.2rem)',
      responsiveMedium: 'min(1.2vh, 1rem)',
      responsiveMediumSmall: 'min(0.8vw, 12px)',
      responsiveSmall: 'min(1vh, 0.9rem)',
      responsiveXSmall: 'min(0.875rem, 8px)',
      responsiveText: 'max(0.875rem, 12px)',
      responsiveTextSmall: 'max(0.775rem, 11px)',
    },

    drawerWidth: smallDrawer ? 324 : 380,
    drawerWidthAvatar: smallDrawer ? 310 : 360,
    drawerWidthMenu: '64px',
    zIndex: {
      main_drawer: 1284,
      nav_menu: 1500,
      backdrops_drawer: 1300,
      backdrops: 2000,
      chips: 400,
    },
  });

  myTheme.overrides = {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.4em',
          scrollBehavior: 'smooth',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: grey[600],
          borderRadius: '5px',
        },
      },
    },

    MuiButton: {
      root: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: '5px',
      },
      containedSizeSmall: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: '5px',
      },
      outlinedSizeSmall: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: '5px',
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: myTheme.fonts.responsiveBig,
        },
      },
    },
  };

  // Apply responsiveFontSizes here if you want
  // myTheme = responsiveFontSizes(myTheme);

  return myTheme;
}
