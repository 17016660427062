import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../store/index';
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fetchPost, fetchPut } from '../../../../../helpers/fetching';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useForm, Controller } from 'react-hook-form';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    width: 'auto',
    height: '20vh',
  },
  form: {
    width: '80%',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.complementary.mainDark} inset`,
      borderRadius: 'initial',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  loginInfo: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  register: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  mediaLinks: {
    marginBottom: theme.spacing(1),
  },
  errorText: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    background: theme.palette.complementary.turquoiseDark,
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    transform: 'scale(1.1)',
  },
  title: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },

  DialogContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overflow: 'hidden',
    overflowY: 'auto',
  },
}));

export default function RegisterDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit, control, watch, setError, clearErrors, reset } = useForm({
    defaultValues: {
      newUsername: '',
      aliases: '',
      role: 'technician',
      newPassword: '',
      newRepeatPassword: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const currentRole = localStorage.getItem('role');

  const onSubmit = async (data) => {
    setLoginError('');
    setLoading(true);
    if (!formValidate(data)) {
      setLoading(false);
      return;
    }

    const newUserData = {
      username: data.newUsername,
      role: data.role,
      aliases: data.aliases,
      password: data.newPassword,
    };

    try {
      const response = await fetchPost('users', newUserData);
      if (response.success) {
        reset();
        props.setNewRow(false);

        const activeField = { active: 1 };
        const activeUserResponse = await fetchPut(`user/${data.newUsername}`, activeField);
        if (activeUserResponse.success) {
          dispatch(
            actions.setSnackbar({
              msg: t('users.user_registered'),
              severity: 'success',
            })
          );
        } else {
          setLoginError(activeUserResponse.msg);
        }
      } else {
        setLoginError(response.msg);
      }
    } catch (error) {
      setLoginError(t('users.users_error'));
    } finally {
      setLoading(false);
    }
  };

  function formValidate(data) {
    for (let key in data) {
      if (!data[key]) {
        if ((data.role === 'radiologist' || data.role === 'supervisor') && key === 'aliases') continue;
        setError('form', {
          type: 'manual',
          message: t('LoginRegister.all_required'),
        });
        return false;
      }
    }
    if (data.newPassword !== data.newRepeatPassword) {
      setError('newRepeatPassword', {
        type: 'manual',
        message: t('LoginRegister.not_match'),
      });
      return false;
    }
    if (data.newUsername.includes('_')) {
      setError('newUsername', {
        type: 'manual',
        message: t('LoginRegister.error_underscore'),
      });
      return false;
    }
    clearErrors('form');
    return true;
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <Dialog
      open={props.newRow}
      fullWidth
      maxWidth="sm"
      onClose={() => {
        props.setNewRow(false);
        setLoginError(null);
      }}
    >
      <DialogTitle className={classes.draggableTitle} id="draggable-title">
        <Typography className={classes.title}>{t('users.new_user')}</Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={() => props.setNewRow(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="newUsername"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                size="small"
                variant="outlined"
                required
                autoComplete="off"
                margin="normal"
                fullWidth
                label={t('LoginRegister.username')}
                autoFocus
                onKeyDown={keyPress}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
                data-test-id="newUsername"
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <TextField
                size="small"
                margin="normal"
                select
                required
                autoComplete="off"
                label={t('LoginRegister.role')}
                fullWidth
                variant="outlined"
                onChange={(e) => field.onChange(e.target.value)}
                value={field.value}
                data-test-id="register-menu-role"
              >
                <MenuItem value="technician">{t('users.Technician')}</MenuItem>
                <MenuItem value="radiologist">{t('users.Radiologist')}</MenuItem>
                {currentRole === 'admin' && <MenuItem value="supervisor">{t('users.Supervisor')}</MenuItem>}
              </TextField>
            )}
          />
          {watch('role') === 'technician' && (
            <Controller
              name="aliases"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                  autoComplete="off"
                  label={t('LoginRegister.aliases')}
                  onKeyDown={keyPress}
                  error={!!fieldState.error}
                  {...field}
                  data-test-id="register-alias"
                  helperText={fieldState.error ? fieldState.error.message : t('users.helper_alias')}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                />
              )}
            />
          )}
          <Controller
            name="newPassword"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoComplete="off"
                label={t('LoginRegister.password')}
                type={showPassword ? 'text' : 'password'}
                onKeyDown={keyPress}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                data-test-id="newPassword"
              />
            )}
          />
          <Controller
            name="newRepeatPassword"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                label={t('LoginRegister.repeat')}
                type={showRepeatPassword ? 'text' : 'password'}
                autoComplete="off"
                onKeyDown={keyPress}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle repeat password visibility"
                        onClick={handleClickShowRepeatPassword}
                        edge="end"
                      >
                        {showRepeatPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                data-test-id="register-password-repeat"
              />
            )}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            {loginError && (
              <Chip
                icon={<ErrorOutlineIcon />}
                label={loginError}
                onDelete={() => setLoginError('')}
                color={'secondary'}
                variant="default"
                className={classes.errorText}
              />
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              type="submit"
              size="large"
              data-test-id="register-button"
            >
              {t('users.add_user')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
