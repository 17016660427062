import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

am4core.useTheme(am4themes_animated);

class MultilineChart extends React.Component {
  componentDidMount() {
    this.initChart();
  }

  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.XYChart);
    chart.data = this.props.data;
    chart.padding(0, 20, 10, 20);

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.fontSize = this.props.theme.fonts.responsiveMedium;
    dateAxis.renderer.labels.template.fill = am4core.color(this.props.theme.palette.texts.secondary);

    dateAxis.renderer.line.strokeOpacity = 0.5;
    dateAxis.renderer.line.strokeWidth = 1;
    dateAxis.renderer.line.stroke = am4core.color(this.props.theme.palette.texts.secondary);
    dateAxis.renderer.grid.template.stroke = am4core.color(this.props.theme.palette.texts.secondary);
    dateAxis.renderer.grid.template.opacity = 0.8;

    dateAxis.renderer.minGridDistance = 300;

    // Value Axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.max = this.props.high_limit + 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.line.strokeWidth = 1;
    valueAxis.renderer.line.stroke = am4core.color(this.props.theme.palette.texts.secondary);
    valueAxis.renderer.labels.template.disabled = true;

    // Create series rcc
    const series1 = chart.series.push(new am4charts.LineSeries());
    series1.name = 'RCC';
    series1.dataFields.valueY = 'rcc';
    series1.dataFields.categoryX = 'date';
    series1.strokeWidth = 2;
    series1.stroke = am4core.color(this.props.theme.palette.projections.rcc);
    series1.tensionX = 0.9;
    series1.tensionY = 0.9;
    series1.connect = false;

    // Series lcc
    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = 'LCC';
    series2.dataFields.valueY = 'lcc';
    series2.dataFields.categoryX = 'date';
    series2.strokeWidth = 2;
    series2.stroke = am4core.color(this.props.theme.palette.projections.lcc);
    series2.tensionX = 0.9;
    series2.tensionY = 0.9;
    series2.connect = false;

    // Series rmlo
    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.name = 'RMLO';
    series3.dataFields.valueY = 'rmlo';
    series3.dataFields.categoryX = 'date';
    series3.strokeWidth = 2;
    series3.stroke = am4core.color(this.props.theme.palette.projections.rmlo);
    series3.tensionX = 0.9;
    series3.tensionY = 0.9;
    series3.connect = false;

    // Series lmlo
    const series4 = chart.series.push(new am4charts.LineSeries());
    series4.name = 'LMLO';
    series4.dataFields.valueY = 'lmlo';
    series4.dataFields.categoryX = 'date';
    series4.strokeWidth = 2;
    series4.stroke = am4core.color(this.props.theme.palette.projections.lmlo);
    series4.tensionX = 0.9;
    series4.tensionY = 0.9;
    series4.connect = false;

    // Add simple bullets
    const bullet1 = series1.bullets.push(new am4charts.CircleBullet());
    bullet1.circle.fill = am4core.color(this.props.theme.palette.projections.rcc);
    bullet1.circle.radius = 4;
    bullet1.circle.fillOpacity = 1;
    bullet1.circle.stroke = am4core.color(this.props.theme.palette.projections.rcc);
    bullet1.circle.strokeWidth = 2;
    bullet1.tooltipText = '{rcc} N \n {name} \n {date}';
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color(this.props.theme.palette.projections.rcc);

    const bullet2 = series2.bullets.push(new am4charts.CircleBullet());
    bullet2.circle.fill = am4core.color(this.props.theme.palette.projections.lcc);
    bullet2.circle.radius = 4;
    bullet2.circle.fillOpacity = 1;
    bullet2.circle.stroke = am4core.color(this.props.theme.palette.projections.lcc);
    bullet2.circle.strokeWidth = 2;
    bullet2.tooltipText = '{lcc} N \n {name} \n {date}';
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color(this.props.theme.palette.projections.lcc);

    const bullet3 = series3.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.fill = am4core.color(this.props.theme.palette.projections.rmlo);
    bullet3.circle.radius = 4;
    bullet3.circle.fillOpacity = 1;
    bullet3.circle.stroke = am4core.color(this.props.theme.palette.projections.rmlo);
    bullet3.circle.strokeWidth = 2;
    bullet3.tooltipText = '{rmlo} N \n {name} \n {date}';
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.background.fill = am4core.color(this.props.theme.palette.projections.rmlo);

    const bullet4 = series4.bullets.push(new am4charts.CircleBullet());
    bullet4.circle.fill = am4core.color(this.props.theme.palette.projections.lmlo);
    bullet4.circle.radius = 4;
    bullet4.circle.fillOpacity = 1;
    bullet4.circle.stroke = am4core.color(this.props.theme.palette.projections.lmlo);
    bullet4.circle.strokeWidth = 2;
    bullet4.tooltipText = '{lmlo} N \n {name} \n {date}';
    series4.tooltip.getFillFromObject = false;
    series4.tooltip.background.fill = am4core.color(this.props.theme.palette.projections.lmlo);

    bullet1.events.on('hit', (event) => {
      this.props.onBulletClick(event.target.dataItem.dataContext);
    });

    bullet2.events.on('hit', (event) => {
      this.props.onBulletClick(event.target.dataItem.dataContext);
    });

    bullet3.events.on('hit', (event) => {
      this.props.onBulletClick(event.target.dataItem.dataContext);
    });

    bullet4.events.on('hit', (event) => {
      this.props.onBulletClick(event.target.dataItem.dataContext);
    });

    bullet1.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    bullet2.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    bullet3.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    bullet4.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    // Add ranges
    const { low_limit, high_limit } = this.props;

    if (!!low_limit && !!high_limit) {
      //Internal label
      const compressionValue = (value, endValue, compressionValue, labelColor) => {
        const range = valueAxis.axisRanges.create();
        range.value = value;
        range.endValue = endValue;
        range.label.text = `${compressionValue} N`;
        range.label.fill = labelColor;
        range.label.fontSize = '1.2vh';
        range.label.inside = true;
        range.grid.strokeOpacity = 0;
        range.label.location = 1;
        (range.label.centerX = 0), (range.label.dx = 11);
      };

      //Low range
      const range_low = valueAxis.axisRanges.create();
      range_low.value = 0;
      range_low.endValue = low_limit;
      range_low.axisFill.fill = am4core.color(this.props.theme.palette.secondary.light);
      range_low.axisFill.fillOpacity = 0.4;
      range_low.grid.strokeOpacity = 0;
      range_low.label.text = this.props.t('Dashboard.low');
      range_low.label.dy = 25;
      range_low.label.rotation = -90;
      range_low.label.fill = am4core.color(this.props.theme.palette.projections.compLow);
      range_low.label.fontSize = '1.2vh';
      let gradient = new am4core.LinearGradient();

      gradient.addColor(am4core.color(this.props.theme.palette.projections.compLow), 0.4);
      gradient.addColor(am4core.color(this.props.theme.palette.secondary.light), 0);
      gradient.rotation = 90;
      range_low.axisFill.fill = gradient;

      compressionValue(0, low_limit, low_limit, 'white');

      //Optimal Range
      const range_optimal = valueAxis.axisRanges.create();
      range_optimal.value = low_limit;
      range_optimal.endValue = high_limit;
      range_optimal.axisFill.fill = am4core.color(this.props.theme.palette.primary.main);
      range_optimal.axisFill.fillOpacity = 0.4;
      range_optimal.grid.strokeOpacity = 0;
      range_optimal.label.text = this.props.t('Dashboard.opt');
      range_optimal.label.dy = 25;
      range_optimal.label.rotation = -90;
      range_optimal.label.fill = am4core.color(this.props.theme.palette.projections.compOptimal);
      range_optimal.label.fontSize = '1.2vh';
      gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color(this.props.theme.palette.projections.compOptimal), 0.4);
      gradient.addColor(am4core.color(this.props.theme.palette.projections.compLow), 0.4);
      gradient.rotation = 90;
      range_optimal.axisFill.fill = gradient;

      //High Range
      const range_high = valueAxis.axisRanges.create();
      range_high.value = high_limit;
      range_high.endValue = high_limit + 100;
      range_high.axisFill.fill = am4core.color(this.props.theme.palette.primary.dark);
      range_high.axisFill.fillOpacity = 0.4;
      range_high.grid.strokeOpacity = 0;
      range_high.label.text = this.props.t('Dashboard.high');
      range_high.label.dy = 25;
      range_high.label.rotation = -90;
      range_high.label.fill = am4core.color(this.props.theme.palette.projections.compHigh);
      range_high.label.fontSize = '1.2vh';
      gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color(this.props.theme.palette.projections.compHigh), 0.4);
      gradient.addColor(am4core.color(this.props.theme.palette.projections.compOptimal), 0.4);
      gradient.rotation = 90;
      range_high.axisFill.fill = gradient;

      compressionValue(low_limit, high_limit, high_limit, 'white');

      const stroke_lower = valueAxis.axisRanges.create();
      stroke_lower.value = low_limit;
      stroke_lower.grid.stroke = am4core.color(this.props.theme.palette.texts.secondary);
      stroke_lower.grid.strokeWidth = 2;
      stroke_lower.grid.strokeOpacity = 0.4;
      stroke_lower.grid.strokeDasharray = '3,3';

      const stroke_higher = valueAxis.axisRanges.create();
      stroke_higher.value = high_limit;
      stroke_higher.grid.stroke = am4core.color(this.props.theme.palette.texts.secondary);
      stroke_higher.grid.strokeWidth = 2;
      stroke_higher.grid.strokeOpacity = 0.4;
      stroke_higher.grid.strokeDasharray = '3,3';
    }

    this.chart = chart;

    this.show_hide_projections();
  }

  show_hide_projections() {
    this.chart.series.values.forEach((series) => {
      series.hide();
    });

    this.props.active_projections.forEach((proj) => {
      this.chart.series.values[proj].show();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main
    ) {
      this.chart.dispose();
      this.initChart();
    } else {
      if (this.props.data.length !== prevProps.data.length) {
        this.chart.data = this.props.data;
        this.chart.validateData();
        for (let s of this.chart.series.values) s.tooltip.hide();
      }
      if (this.props.active_projections.length !== prevProps.active_projections.length)
        this.show_hide_projections();
    }

    if (prevProps.low_limit !== this.props.low_limit || prevProps.high_limit !== this.props.high_limit) {
      this.initChart();
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.dispose();
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

function mapStateToProps(state, myProps) {
  return { data: state.statistics[myProps.dataKey] };
}

export default connect(mapStateToProps)(MultilineChart);
