import { createSlice } from '@reduxjs/toolkit';

import { fetchGet, fetchDelete } from '../../helpers/fetching';

const initialState = {
  snackbar: null,
  info: null,
  activeDialog: null,
  examinationListDrawer: false,
  actionToConfirm: null,
  notifications: [],
  darkState: true,
  contextMenu: null,
  landscape: true,
  serverLogs: '',

  language: null,

  zoomedProjection: null,

  tabsIndex: 0,
};

export const guiSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setActiveDialog: (state, action) => {
      state.activeDialog = action.payload;
    },
    setExaminationListDrawer: (state, action) => {
      state.examinationListDrawer = action.payload;
    },
    setActionToConfirm: (state, action) => {
      state.actionToConfirm = action.payload;
    },
    setContextMenu: (state, action) => {
      state.contextMenu = action.payload;
    },
    setLandscape: (state, action) => {
      state.landscape = action.payload;
    },
    setInfo: (state, action) => {
      state.info = action.payload;
    },
    setServerLogs: (state, action) => {
      state.serverLogs = action.payload;
    },
    setDarkState: (state, action) => {
      state.darkState = action.payload;
    },
    setZoomedProjection: (state, action) => {
      state.zoomedProjection = action.payload;
    },
    setTabsIndex: (state, action) => {
      state.tabsIndex = action.payload;
    },
  },
});

export const {
  setSnackbar,
  setNotifications,
  setActiveDialog,
  setExaminationListDrawer,
  setActionToConfirm,
  setContextMenu,
  setLandscape,
  setGlobalSettings,
  setServerLogs,
  setDarkState,
  setInfo,
  setZoomedProjection,
  setTabsIndex,
} = guiSlice.actions;

export default guiSlice.reducer;


// Async
export const deleteNotification = (id) => {
  return async (dispatch, getState) => {
    const response = await fetchDelete(`notifications`, { id });
    if (response.success) {
      const reducedNotifications = getState().gui.notifications.filter(
        (note) => note.id !== id
      );
      dispatch(setNotifications(reducedNotifications));
    } else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  };
};

export const fetchNotifications = (username) => {
  return async (dispatch) => {
    const response = await fetchGet(`notifications/${username}`);
    if (response.success) {
      dispatch(setNotifications(response.data));
    }
  };
};

export const toggleDarkState = () => {
  return (dispatch, getState) => {
    const curDarkState = getState().gui.darkState;
    dispatch(guiSlice.actions.setDarkState(!curDarkState));
  };
};
