import React, { useState, useEffect, useCallback } from 'react';
import PieChart from './charts/pieChart';
import MultiLineChartTwo from './charts/multiLineChartTwo';
import QualityFeaturesChart from './charts/QualityFeaturesChart';
import BarChartTwo from './charts/barChartTwo';
import Histogram from './charts/histogram';
import { Card, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonGroupCompression from './ButtonGroup/ButtonGroupCompression';
import ButtonGroupProjections from './ButtonGroup/ButtonGroupProjections';
import ButtonGroupQualityFeats from './ButtonGroup/ButtonGroupPGMI';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/index';
import { formatDate, daysInMonth } from '../../../helpers/formatDate';
import { Helmet } from 'react-helmet';
import MultilineChart from './charts/multilineChart';
import ButtonGroupDiagnostics from './ButtonGroup/ButtonGroupDiagnostics';
import ButtonGroupQuality from './ButtonGroup/ButtonGroupQuality';
import ChartCard from './ChartCard/ChartCard';

const useStyles = makeStyles((theme) => ({
  dashboard_general: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridRowGap: '10px',
    gridColumnGap: '10px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
    justifyItems: 'stretch',
  },

  dashboard_responsive: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(7, 1fr)',
    display: 'grid',
    gridRowGap: '6px',
    gridColumnGap: '6px',
    justifyItems: 'stretch',
    width: '100%',
  },

  icon: {
    fontSize: '3vh',
  },

  CardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '80%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  gridRowOneRanking: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  InfoIcon: {
    padding: 0,
    color: 'gray',
    fontSize: '1rem',
  },

  featuresFilter: {
    letterSpacing: '0.1rem',
    maxHeight: '20px',
    textTransform: 'capitalize',
    fontSize: '0.7em',
    display: 'flex',
    margin: '0 5%',
    padding: '4px',
    '& p': {
      color: theme.palette.secondary.main,
      margin: 0,
    },
  },

  title_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store State
  const totalExaminations = useSelector((state) => state.examinations.totalExaminations);
  const gui = useSelector((state) => state.gui);
  const dates = useSelector((state) => state.dates);
  const filter = useSelector((state) => state.filter);
  const averageDose = useSelector((state) => state.statistics.doseData.aver);
  const averageQualityPerformance = useSelector((state) => state.statistics.performanceHistogramData.aver);
  const barQualityData = useSelector((state) => state.statistics.barQualityData);
  const examinationListDrawer = useSelector((state) => state.gui.examinationListDrawer);
  const globalSettings = useSelector((state) => state.global_settings ?? {});

  // Loading states
  const loadingExams = useSelector((state) => state.statistics.loadingExams);
  const qualityStudiesData = useSelector((state) => state.statistics.pieStudyQualityData);
  const loadingDensity = useSelector((state) => state.statistics.loadingDensity);
  const loadingPerformance = useSelector((state) => state.statistics.loadingPerformance);
  const loadingCompression = useSelector((state) => state.statistics.loadingCompression);
  const loadingDose = useSelector((state) => state.statistics.loadingDose);
  const loadingFeatures = useSelector((state) => state.statistics.loadingFeatures);
  const loadingQuality = useSelector((state) => state.statistics.loadingQuality);
  const loadingOpacities = useSelector((state) => state.statistics.loadingOpacities);
  const loadingMicrocalc = useSelector((state) => state.statistics.loadingMicrocalc);

  // Local state
  const [activeProjection, setActiveProjection] = useState('rcc');
  const [cmpProjections, setCmpProjections] = useState([0, 1, 2, 3]);
  const [diagnosticCategory, setDiagnosticCategory] = useState('opacities');
  const [qualityData, setQualityData] = useState('projections');
  const [performanceProjections, setPerformanceProjections] = useState(['rcc', 'lcc', 'rmlo', 'lmlo']);

  const { diagnostics_microcalc_module, diagnostics_opacities_module, density_module } =
    globalSettings.premium_features;
  const { compression_low, compression_high, compression_tolerance } = globalSettings;
  const quality_values = globalSettings?.quality_system?.quality_values;

  const somePremiumDiagnostics =
    (diagnostics_microcalc_module || diagnostics_opacities_module) &&
    localStorage.getItem('diagnostics') === 'true';

  // Effects
  useEffect(() => {
    if (diagnostics_opacities_module) setDiagnosticCategory('opacities');
    else if (diagnostics_microcalc_module) setDiagnosticCategory('microcalc');
  }, []);

  useEffect(() => {
    dispatch(actions.setCurrentExam(null));
    return () => {
      dispatch(actions.setInitialStatistics());
    };
  }, [dispatch]);

  useEffect(() => {
    const abortMostStats = new AbortController();
    dispatch(actions.fetchMostStatistics(theme, t, abortMostStats.signal));
    return () => abortMostStats.abort();
  }, [dispatch, totalExaminations]);

  useEffect(() => {
    const abortPerfStats = new AbortController();
    dispatch(actions.fetchPerformanceAction(abortPerfStats.signal));
    return () => abortPerfStats.abort();
  }, [dispatch, totalExaminations, dates.performanceDates]);

  useEffect(() => {
    const abortCompStats = new AbortController();
    dispatch(actions.fetchCompressionAction(abortCompStats.signal));
    return () => abortCompStats.abort();
  }, [dispatch, totalExaminations, dates.compressionDates]);

  // Handlers
  function setActiveProjCompression(n, btn_id) {
    const button = document.getElementById(btn_id);

    const cmp_projections = cmpProjections.filter((value) => value !== n);

    if (button.className.split(' ').some((c) => /MuiButton-contained.*/.test(c))) {
    } else {
      cmp_projections.push(n);
    }

    setCmpProjections(cmp_projections);
  }

  function activePerformanceProj(proj) {
    setPerformanceProjections((performanceProjections) => {
      if (performanceProjections.includes(proj)) {
        return performanceProjections.filter((value) => value !== proj);
      } else {
        return [...performanceProjections, proj];
      }
    });
  }

  function featuresQualityHandler(label, feature, value) {
    const featuresQuality = {
      label,
      feature,
      value,
      projection: activeProjection.toUpperCase(),
    };
    dispatch(actions.setFilterProperty(['featuresQuality', featuresQuality]));
  }

  const filterChartHandler = useCallback(
    (feature, chart) => {
      if (chart === 'pieDensityData') {
        dispatch(actions.setFilterProperty(['density', feature]));
      } else if (chart === 'pieQualityData') {
        dispatch(actions.setFilterProperty(['quality', feature]));
      } else if (chart === 'pieStudyQualityData') {
        dispatch(actions.setFilterProperty(['study_quality', feature]));
      } else if (chart === 'microcalc') {
        dispatch(actions.setFilterProperty(['microcalc', feature]));
      } else if (chart === 'opacities') {
        dispatch(actions.setFilterProperty(['opacities', feature]));
      }
    },
    [filter, dispatch]
  );

  const onPerformanceBulletClick = (data) => {
    if (data.uid !== undefined) dispatch(actions.showExamination(data.uid));
    else if (data.date !== undefined) {
      let from = formatDate(data.date).trim();
      let to = from;
      if (from.length < 8) {
        from = from + '-01';
        to = to + '-' + daysInMonth(to.slice(5, 7), to.slice(0, 4));
      }
      dispatch(actions.setPerformanceDates([from, to]));
    }
  };

  const onCompressionBulletClick = (data) => {
    if (data.uid !== undefined) dispatch(actions.showExamination(data.uid));
    else if (data.date !== undefined) {
      let from = formatDate(data.date).trim();
      let to = from;
      if (from.length < 8) {
        from = from + '-01';
        to = to + '-' + daysInMonth(to.slice(5, 7), to.slice(0, 4));
      }
      dispatch(actions.setCompressionDates([from, to]));
    }
  };

  const getChipLabel = (chart) => {
    if (chart === 'compression') {
      if (dates.compressionDates[0] === dates.compressionDates[1]) return dates.compressionDates[0];

      if (dates.compressionDates[0] !== dates.compressionDates[1])
        return `${dates.compressionDates[0]} -
                            ${dates.compressionDates[1]}`;
    }
    if (chart === 'performance') {
      if (dates.performanceDates[0] === dates.performanceDates[1]) return dates.performanceDates[0];

      if (dates.performanceDates[0] !== dates.performanceDates[1])
        return `${dates.performanceDates[0]} -
                            ${dates.performanceDates[1]}`;
    }
  };

  const pgmiChipColor = (value) => {
    if (value === 'none') return theme.palette.dashboard.noSkinFolds;
    if (
      value === 'correct' ||
      value === 'mild' ||
      value === 'straight' ||
      value === 'mild' ||
      value === 'convex'
    )
      return theme.palette.projections.good;
    if (value === 'moderate' || value === 'medium') return theme.palette.projections.moderate;
    if (value === 'insufficient' || value === 'severe' || value === 'concave')
      return theme.palette.projections.insufficient;
  };

  const diagnosisChipLabel = (label) => {
    if (!label[0] || label[0] == 'null' || label[0].toLowerCase() === 'not_eval') {
      return t(`Dashboard.not_eval`);
    }
    return label[0].toUpperCase();
  };

  const filterChipColor = (chipLabel) => {
    if (!chipLabel || !chipLabel[0]) {
      return theme.palette.complementary.turquoise;
    }
    if (chipLabel[0] === 'A' || chipLabel[0] === 'perfect' || chipLabel[0] === 'adequate') {
      return theme.palette.projections.perfect;
    }
    if (chipLabel[0] === 'B' || chipLabel[0] === 'good' || chipLabel[0] === 'none')
      return theme.palette.projections.good;
    if (chipLabel[0] === 'C' || chipLabel[0] === 'moderate' || chipLabel[0].includes('benign'))
      return theme.palette.projections.moderate;
    if (chipLabel[0] === 'D' || chipLabel[0] === 'inadequate' || chipLabel[0].includes('malignant'))
      return theme.palette.projections.insufficient;

    return theme.palette.complementary.turquoise;
  };

  const cardsToRender = [
    //  Examinations
    <Card
      style={{
        gridColumn: gui.landscape ? (!density_module || !somePremiumDiagnostics ? '1 / 3' : '1') : 'span 6',
        gridRow: '1',
      }}
      key="exams"
    >
      <ChartCard
        loading={loadingExams}
        CardContentClass={classes.CardContent}
        title={t('Dashboard.examinations')}
        subheader={`${totalExaminations} ${t('Dashboard.examinations')}`}
      >
        <BarChartTwo chartdiv="bar_exams" theme={theme} darkState={gui.darkState} />
      </ChartCard>
    </Card>,

    //  Dose
    <Card
      style={{
        gridColumn: gui.landscape
          ? !density_module || !somePremiumDiagnostics
            ? '3'
            : '2'
          : !density_module && !somePremiumDiagnostics
          ? 'span 6'
          : somePremiumDiagnostics && !density_module
          ? 'span 6'
          : '1 / 3',
        gridRow: gui.landscape ? '1' : '2',
      }}
      key="dose"
    >
      <ChartCard
        loading={loadingDose}
        CardContentClass={classes.CardContent}
        title={t('Dashboard.dose')}
        subheader={averageDose ? `${t('Dashboard.average')} ${averageDose?.toFixed(0)}mGy` : ''}
        uniqueStyle={true}
      >
        <Histogram chartdiv="histogram_dose" theme={theme} darkState={gui.darkState} />
      </ChartCard>
    </Card>,

    // Density
    density_module && (
      <Card
        style={{
          gridColumn: gui.landscape ? (somePremiumDiagnostics ? '3' : '4') : 'span 4',
          gridRow: gui.landscape ? '1' : '2',
        }}
        key="dens"
      >
        <ChartCard
          loading={loadingDensity}
          CardContentClass={classes.CardContent}
          title={
            <div className={classes.title_header}>
              {t('Dashboard.density')}
              {'density' in filter && (
                <Chip
                  className={classes.featuresFilter}
                  label={`ACR ${diagnosisChipLabel(filter.density)}`}
                  variant="outlined"
                  onDelete={() => dispatch(actions.setFilterProperty(['density', null]))}
                  size="small"
                  style={{
                    border: `1px solid ${filterChipColor(filter?.density)}`,
                    color: filterChipColor(filter?.density),
                  }}
                />
              )}
            </div>
          }
          subheader={'ACR'}
        >
          <PieChart
            chartdiv="pie_density"
            theme={theme}
            dataKey="pieDensityData"
            examinationListDrawer={examinationListDrawer}
            setFilterChartHandler={filterChartHandler}
            t={t}
          />
        </ChartCard>
      </Card>
    ),

    // Quality
    <Card
      style={{
        gridColumn:
          !density_module && somePremiumDiagnostics
            ? gui.landscape
              ? '4'
              : '1 / 3'
            : !density_module && !somePremiumDiagnostics
            ? gui.landscape
              ? 'span 2'
              : 'span 6'
            : gui.landscape
            ? density_module && !somePremiumDiagnostics
              ? '5'
              : '4'
            : somePremiumDiagnostics
            ? '1 / 3'
            : 'span 6',
        gridRow: gui.landscape ? '1' : '3',
      }}
      key="qual"
    >
      <ChartCard
        loading={loadingQuality}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {t('Dashboard.quality')}
            {'quality' in filter && (
              <Chip
                className={classes.featuresFilter}
                label={`${diagnosisChipLabel(filter.quality)}`}
                variant="outlined"
                onDelete={() => dispatch(actions.setFilterProperty(['quality', null]))}
                size="small"
                style={{
                  border: `1px solid ${filterChipColor(filter?.quality)}`,
                  color: filterChipColor(filter?.quality),
                }}
              />
            )}
          </div>
        }
        subheader={
          qualityData === 'projections'
            ? t('Dashboard.quality_per_projections')
            : t('Dashboard.quality_per_studies')
        }
        action={
          !('quality' in filter) &&
          globalSettings?.quality_system?.overall_quality &&
          !(
            qualityStudiesData?.[0]?.category === 'Not evaluated' && qualityStudiesData?.[0]?.value === 100
          ) && <ButtonGroupQuality setQualityData={setQualityData} qualityData={qualityData} />
        }
      >
        <PieChart
          chartdiv="pie_quality"
          theme={theme}
          dataKey={qualityData === 'projections' ? 'pieQualityData' : 'pieStudyQualityData'}
          examinationListDrawer={examinationListDrawer}
          setFilterChartHandler={filterChartHandler}
          t={t}
        />
      </ChartCard>
    </Card>,

    // Diagnostics visibility from .ENV
    somePremiumDiagnostics && (
      <Card
        style={{
          gridColumn: gui.landscape ? '5' : 'span 4',
          gridRow: gui.landscape ? '1' : '3',
        }}
        key="diagnostics"
      >
        <ChartCard
          loading={loadingOpacities}
          CardContentClass={classes.CardContent}
          title={
            <div className={classes.title_header}>
              {examinationListDrawer ? 'Diagnost.' : t('Dashboard.diagnostics')}
              {diagnosticCategory in filter && (
                <Chip
                  className={classes.featuresFilter}
                  label={diagnosisChipLabel(filter[diagnosticCategory])}
                  variant="outlined"
                  onDelete={() => dispatch(actions.setFilterProperty([diagnosticCategory, null]))}
                  size="small"
                  style={{
                    border: `1px solid ${filterChipColor(filter[diagnosticCategory])}`,
                    color: filterChipColor(filter[diagnosticCategory]),
                  }}
                />
              )}
            </div>
          }
          subheader={
            diagnosticCategory === 'microcalc'
              ? t('ResultView.diagnosticsMicrocalc')
              : t('ResultView.diagnosticsLesions')
          }
          action={
            !(diagnosticCategory in filter) && (
              <ButtonGroupDiagnostics
                setDiagnosticCategory={setDiagnosticCategory}
                diagnosticCategory={diagnosticCategory}
                showButtons={diagnostics_microcalc_module && diagnostics_opacities_module}
              />
            )
          }
        >
          <PieChart
            chartdiv="pie_diagnostics"
            theme={theme}
            dataKey={diagnosticCategory}
            examinationListDrawer={examinationListDrawer}
            setFilterChartHandler={filterChartHandler}
            t={t}
          />
        </ChartCard>
      </Card>
    ),

    // Compression
    <Card
      style={{
        gridColumn: gui.landscape ? '1 / 3' : 'span 6',
        gridRow: gui.landscape ? '2' : '4',
      }}
      key="compression"
    >
      <ChartCard
        loading={loadingCompression}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {t('Dashboard.compression')}
            {!!dates.compressionDates && (
              <Chip
                className={classes.featuresFilter}
                label={getChipLabel('compression')}
                variant="outlined"
                onDelete={() => dispatch(actions.setCompressionDates(null))}
                color="secondary"
                size="small"
              />
            )}
          </div>
        }
        action={
          <ButtonGroupCompression
            onClick={(index, btn_id) => setActiveProjCompression(index, btn_id)}
            cmp_projections={cmpProjections}
          />
        }
      >
        <MultilineChart
          chartdiv="chart_compression"
          active_projections={cmpProjections}
          theme={theme}
          darkState={gui.darkState}
          onBulletClick={onCompressionBulletClick}
          low_limit={compression_low}
          high_limit={compression_high}
          tolerance={compression_tolerance}
          dataKey="lineCompressionData"
          t={t}
        />
      </ChartCard>
    </Card>,

    //PGMI - QUALITY FEATURES
    <Card
      style={{
        gridColumn: gui.landscape ? '3 span' : 'span 6',
        gridRow: gui.landscape ? '2' : '5',
      }}
      key="bar_quality"
    >
      <ChartCard
        loading={loadingFeatures}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {t('Dashboard.quality_features')}
            {!!filter.featuresQuality && (
              <Chip
                className={classes.featuresFilter}
                label={`${filter.featuresQuality.label} ${filter.featuresQuality.value}`}
                variant="outlined"
                onDelete={() => dispatch(actions.setFilterProperty(['featuresQuality', null]))}
                size="small"
                style={{
                  border: `1px solid ${pgmiChipColor(filter?.featuresQuality?.value)}`,
                  color: pgmiChipColor(filter?.featuresQuality?.value),
                }}
              />
            )}
          </div>
        }
        action={
          <ButtonGroupQualityFeats onClick={setActiveProjection} active_projection={activeProjection} />
        }
        subheader={
          !barQualityData[activeProjection] || barQualityData[activeProjection].length == 0
            ? t('Dashboard.no_features_placeholder')
            : activeProjection?.toUpperCase()
        }
      >
        <QualityFeaturesChart
          chartdiv="bar_quality"
          theme={theme}
          darkState={gui.darkState}
          setFeaturesQuality={featuresQualityHandler}
          activeProjection={activeProjection}
        />
      </ChartCard>
    </Card>,

    //  Quality Point histogram
    globalSettings?.quality_system?.histogram_chart && (
      <Card
        style={{
          gridColumn: gui.landscape ? 'span 2' : 'span 6',
          gridRow: gui.landscape ? '3' : '7',
        }}
        key="histogram_quality"
      >
        <ChartCard
          loading={loadingDose}
          CardContentClass={classes.CardContent}
          title={t('Dashboard.quality_points')}
          uniqueStyle={true}
          subheader={
            averageQualityPerformance
              ? `${t('Dashboard.average')} ${averageQualityPerformance?.toFixed(0)}%`
              : ''
          }
        >
          <Histogram chartdiv="histogram_quality" theme={theme} darkState={gui.darkState} />
        </ChartCard>
      </Card>
    ),

    // Quality Performance
    <Card
      style={{
        gridColumn: globalSettings?.quality_system?.histogram_chart
          ? gui.landscape
            ? '3 span'
            : 'span 6'
          : gui.landscape
          ? 'span 5'
          : 'span 6',
        gridRow: gui.landscape ? '3' : globalSettings?.quality_system?.histogram_chart ? '6' : 'span 7',
      }}
      key="performance_line"
    >
      <ChartCard
        loading={loadingPerformance}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {t('Dashboard.performance')}
            {!!dates.performanceDates && (
              <Chip
                className={classes.featuresFilter}
                label={getChipLabel('performance')}
                variant="outlined"
                onDelete={() => dispatch(actions.setPerformanceDates(null))}
                color="secondary"
                size="small"
              />
            )}
          </div>
        }
        action={
          <ButtonGroupProjections
            onClick={activePerformanceProj}
            performanceProjections={performanceProjections}
          />
        }
      >
        <MultiLineChartTwo
          chartdiv="line_pgmi"
          activeProjections={performanceProjections}
          theme={theme}
          darkState={gui.darkState}
          onBulletClick={onPerformanceBulletClick}
          dataKey="performanceQualityData"
          quality_values={quality_values}
        />
      </ChartCard>
    </Card>,
  ];

  return (
    <>
      <Helmet>
        <title>{t('b_box.pat_dashboard')}</title>
        <meta name="patient_dashboard" />
      </Helmet>

      <div className={gui.landscape ? classes.dashboard_general : classes.dashboard_responsive}>
        {cardsToRender}
      </div>
    </>
  );
};

export default Dashboard;
