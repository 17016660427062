import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../store/index';
import { useTranslation } from 'react-i18next';
import { fetchPut } from '../../../../../helpers/fetching';
import { setSnackbar, fetchResults, setPostSurgery, setQualityOverlaysToShow } from '../../../../../store';
import { Tooltip, Typography, Divider } from '@material-ui/core';
import { breastVolumeInfo } from './common/ProjectionCommon';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import { densityColor } from '../../../../../helpers/density';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';
import { compressionInfo } from '../../QualityView/utils/qualityUtils';
import { evaluationClassColor } from '../../../../../helpers/qualityColor';
import Projection from './Projection';
import { Icon } from '@iconify/react';
import { onClickChip } from '../../QualityView/hooks/useQualityHandlers';
import circleCoursor from '../../../../../icons/circle.png';
import CollectionsIcon from '@material-ui/icons/Collections';
import patch20Regular from '@iconify/icons-fluent/patch-20-regular';
import patch20Filled from '@iconify/icons-fluent/patch-20-filled';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import GrainIcon from '@material-ui/icons/Grain';
import radioactiveIcon from '@iconify-icons/mdi/radioactive';
import blurRadial from '@iconify-icons/mdi/google-podcast';
import contrastIcon from '@iconify-icons/carbon/contrast';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';
import ProjectionFabs from './common/ProjectionFabs';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';

const useStyles = makeStyles((theme) => ({
  projection_card_container: {
    // minWidth: 'min(200px, 100%)',
    display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    justifyContent: 'inherit',
    backgroundColor: 'black',
    borderRadius: '5px',
    minHeight: '10vh',
  },

  projection_evaluations_upper: {
    position: 'absolute',
    top: '6px',
    color: '#d3d3d3',
    cursor: 'pointer',
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMedium,
  },

  projection_evaluations_equip: {
    zIndex: 140,
    position: 'absolute',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 6px',
    height: 'auto',
    overflow: 'hidden',
  },

  volumeText: {
    margin: '0 6px',
    cursor: 'help',
  },
}));

const ProjectionsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store state
  const results = useSelector((state) => state.results);
  const { projections, applied_quality, applied_density, shown_quality, shown_density } = results;
  const { qualityOverlaysToShow } = useSelector((state) => state.images);
  const { tabsIndex } = useSelector((state) => state.gui);
  const {
    qualityInRevisionMode,
    qualityInDrawingMode,
    densityInRevisionMode,
    diagnosticsInRevisionMode,
    customOpacities,
    customMicrocalc,
  } = useSelector((state) => state.labeling);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const density_module = useSelector((state) => state.global_settings.premium_features.density_module);
  const { compression_low, compression_high, compression_tolerance } = useSelector(
    (state) => state.global_settings
  );
  const { compression_classes, contrast_classes, noise_classes, exposure_classes, artifacts_classes } =
    useSelector((state) => state.global_settings.quality_system.quality_features);

  // Local state
  const [fetchingEval, setFetchingEval] = useState(false);
  const [cursor, setCursor] = useState('default');
  const [invertedColor, setInvertedColor] = useState({ rcc: false, rmlo: false, lcc: false, lmlo: false });

  // Variables
  const qualityShown = shown_quality || applied_quality;
  const densityShown = shown_density || applied_density;

  const resultQualityEvaluation = useAppliedEvaluation('quality', qualityShown, qualityInRevisionMode);
  const resultDensityEvaluation = useAppliedEvaluation('density', densityShown, densityInRevisionMode);

  const postOpClipEval = resultQualityEvaluation?.[props.proj_name]?.clips;
  const postOpEval = resultQualityEvaluation?.[props.proj_name]?.PostSurgery;
  const contrastEval = resultQualityEvaluation?.[props.proj_name]?.contrast;
  const noiseEval = resultQualityEvaluation?.[props.proj_name]?.noise;
  const artifactsEval = resultQualityEvaluation?.[props.proj_name]?.artifacts;
  const exposureEval = resultQualityEvaluation?.[props.proj_name]?.exposureClass;

  // Current Breast Volume
  const pastBreastVolume = projections?.[props?.proj_name]?.pastBreastVolumes;
  const mloBreastArea =
    props.proj_name === 'rcc'
      ? resultQualityEvaluation?.rmlo?.BreastVolume
      : resultQualityEvaluation?.lmlo?.BreastVolume;
  const ccBreastArea =
    props.proj_name === 'rmlo'
      ? resultQualityEvaluation?.rcc?.BreastVolume
      : resultQualityEvaluation?.lcc?.BreastVolume;

  const breastAreaDifference = (
    resultQualityEvaluation?.[props?.proj_name]?.BreastVolume -
    (props.proj_name === 'rcc' || props.proj_name === 'lcc' ? mloBreastArea : ccBreastArea)
  ).toFixed(0);

  useEffect(() => {
    if (qualityInDrawingMode) setCursor(`url(${circleCoursor}) 10 10, auto`);
    else if (diagnosticsInRevisionMode) setCursor('crosshair');
    else setCursor('default');
  }, [tabsIndex, qualityInDrawingMode, diagnosticsInRevisionMode]);

  useEffect(() => {
    setInvertedColor({ rcc: false, rmlo: false, lcc: false, lmlo: false });
  }, [tabsIndex, qualityInRevisionMode]);

  // Handlers
  const setEvaluated = async (state) => {
    setFetchingEval(true);
    const response = await fetchPut(
      'evaluate',
      {
        projection_id: projections?.[props.proj_name]?.uid,
        state,
      },
      'bbox'
    );

    if (response.success) {
      dispatch(fetchResults());
      setFetchingEval(false);
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      setFetchingEval(false);
    }
  };

  const showClips = (currentProj, shouldShow) => {
    const newLesionsToShow = {
      [`clips${currentProj.toUpperCase()}`]: shouldShow,
    };
    if (tabsIndex === 0 || (tabsIndex === 1 && !qualityInDrawingMode)) {
      dispatch(setQualityOverlaysToShow(newLesionsToShow));
    }
  };

  const postopHandler = (operation) => {
    let newEvaluationState = [...postOpEval];
    if (operation.includes('reductionScars')) {
      newEvaluationState = newEvaluationState.filter((item) => item !== 'reductionScars');
    } else {
      newEvaluationState.push('reductionScars');
    }
    dispatch(setPostSurgery(currentExam, props.proj_name, newEvaluationState));
  };

  return (
    <div className={classes.projection_card_container} style={{ cursor: cursor }}>
      {!!results && !qualityInDrawingMode && (
        <>
          {/* Upper icons / info */}
          <div
            className={classes.projection_evaluations_upper}
            style={{
              left: props.left ? undefined : '40px',
              right: props.left ? '40px' : undefined,
              flexDirection: props.left ? 'row-reverse' : 'row',
            }}
          >
            {/* Second captures */}
            {!!props.onCaptureClick && (
              <Tooltip
                arrow
                title={
                  <Typography className={classes.tooltip_text}>{t('ResultView.secondCaptures')}</Typography>
                }
                placement="bottom-end"
              >
                <CollectionsIcon fontSize="medium" onClick={props?.onCaptureClick} />
              </Tooltip>
            )}

            {/* Breast Volume  */}
            {(tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode)) &&
              !!resultQualityEvaluation?.[props?.proj_name]?.BreastVolume &&
              breastVolumeInfo(
                resultQualityEvaluation,
                breastAreaDifference,
                pastBreastVolume,
                props.proj_name,
                props.left,
                theme,
                classes,
                t,
                dispatch,
                actions
              )}
          </div>

          {/* Postop Fab Icons */}
          {tabsIndex !== 2 && resultQualityEvaluation?.[props.proj_name] && (
            <div
              className={classes.projection_evaluations_equip}
              style={{
                left: props.left ? undefined : 0,
                right: props.left ? 0 : undefined,
                top: '35px',
              }}
            >
              {/* POSTOP CTA Fab*/}
              <ProjectionFabs
                tooltipText={
                  <>
                    <Typography
                      style={{
                        letterSpacing: '0.1rem',
                        textTransform: 'uppercase',
                        fontSize: theme.fonts.responsiveMedium,
                        fontWeight: 'bold',
                      }}
                    >
                      {postOpEval?.includes('reductionScars')
                        ? t('ResultView.reductionScars')
                        : t('ResultView.no_reductionScars')}
                    </Typography>
                    <Divider style={{ margin: '3%' }} />
                    <Typography
                      style={{
                        letterSpacing: '0.1rem',
                        fontSize: theme.fonts.responsiveMedium,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      color="inherit"
                    >
                      <TouchAppIcon fontSize="medium" />
                      {postOpEval?.includes('reductionScars')
                        ? t('ResultView.remove_operated', {
                            postop: t('projections.reductionScars'),
                          })
                        : t('ResultView.set_operated', {
                            postop: t('projections.reductionScars'),
                          })}
                    </Typography>
                  </>
                }
                chipAction={() => postopHandler(postOpEval)}
                extraStyles={{
                  color: !!postOpClipEval ? 'inherit' : '#3e3e3e',
                  cursor: 'pointer',
                }}
                chipIcon={
                  postOpEval?.includes('reductionScars') ? (
                    <LocalHospitalIcon
                      fontSize="medium"
                      style={{
                        color: '#a48023',
                      }}
                    />
                  ) : (
                    <LocalHospitalIcon
                      fontSize="medium"
                      style={{
                        color: '#3e3e3e',
                      }}
                    />
                  )
                }
                clickableChip={true}
              />
              {/* CLIPS Fab*/}
              <ProjectionFabs
                tooltipText={
                  <Typography className={classes.tooltip_text}>
                    {!!postOpClipEval ? t('ResultView.postop_clips') : t('ResultView.no_clips')}
                  </Typography>
                }
                tooltipAction={(shouldShow) => showClips(props.proj_name, shouldShow)}
                tooltipLeave={(shouldShow) => showClips(props.proj_name, shouldShow)}
                extraStyles={{
                  color: !!postOpClipEval ? theme.palette.lesions.clips : '#3e3e3e',
                }}
                chipIcon={
                  <Icon
                    width={24}
                    height={24}
                    icon={
                      !!postOpClipEval
                        ? qualityOverlaysToShow['clips' + props.proj_name.toUpperCase()]
                          ? patch20Filled
                          : patch20Regular
                        : patch20Regular
                    }
                    style={{
                      color: !!postOpClipEval ? 'inherit' : '#3e3e3e',
                    }}
                  />
                }
              />
            </div>
          )}

          {/* Equip image evaluation */}
          {(tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode)) && (
            <div
              className={classes.projection_evaluations_equip}
              style={{
                bottom: '40px',
                left: props.left ? undefined : 0,
                right: props.left ? 0 : undefined,
                minHeight: '50%',
              }}
            >
              {/* Invert color Fab */}
              {
                <ProjectionFabs
                  evaluationClass="not_evaluated"
                  tooltipText={
                    <Typography className={classes.tooltip_text}>{t('projections.invert')}</Typography>
                  }
                  extraStyles={{
                    color: invertedColor?.[props.proj_name] === true ? 'rgb(48, 48, 48)' : 'rgb(62, 62, 62)',
                    cursor: 'pointer',
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                  }}
                  chipIcon={<BrightnessMediumIcon />}
                  chipAction={() => {
                    setInvertedColor({
                      ...invertedColor,
                      [props.proj_name]: !invertedColor?.[props.proj_name],
                    });
                  }}
                  clickableChip={true}
                />
              }

              {/* COMPRESSION Fab */}
              {compression_classes?.length > 0 && (
                <ProjectionFabs
                  chipIcon={
                    <Icon
                      icon={arrowCollapseVertical}
                      width={24}
                      height={24}
                      color={evaluationClassColor(
                        resultQualityEvaluation?.[props.proj_name]?.compressionClass,
                        theme
                      )}
                    />
                  }
                  evaluationClass={resultQualityEvaluation?.[props.proj_name]?.compressionClass}
                  tooltipText={compressionInfo(
                    resultQualityEvaluation?.[props.proj_name]?.compressionClass,
                    projections?.[props.proj_name]?.compression,
                    compression_low,
                    compression_high,
                    compression_tolerance,
                    theme,
                    t
                  )}
                  chipAction={() => {
                    onClickChip('compressionClass', props.proj_name, compression_classes, dispatch, actions);
                  }}
                />
              )}

              {/* CONTRAST Fab */}
              {contrast_classes?.length > 0 && (
                <ProjectionFabs
                  evaluationClass={contrastEval}
                  tooltipText={
                    <Typography className={classes.tooltip_text}>
                      {t('ResultView.contrast')}: {useQualityTranslation(contrastEval, t)}
                    </Typography>
                  }
                  extraStyles={{
                    color: contrastEval ? evaluationClassColor(contrastEval, theme) : '#3e3e3e',
                  }}
                  chipIcon={<Icon width={24} height={24} icon={contrastIcon} />}
                  chipAction={() => {
                    onClickChip('contrast', props.proj_name, contrast_classes, dispatch, actions);
                  }}
                />
              )}

              {/* NOISE Fab noiseEval*/}
              {noise_classes?.length > 0 && (
                <ProjectionFabs
                  evaluationClass={noiseEval}
                  tooltipText={
                    <Typography className={classes.tooltip_text}>
                      {t('ResultView.noise')}: {useQualityTranslation(noiseEval, t)}
                    </Typography>
                  }
                  extraStyles={{
                    color: noiseEval ? evaluationClassColor(noiseEval, theme) : '#3e3e3e',
                  }}
                  chipIcon={<Icon width={24} height={24} icon={blurRadial} />}
                  chipAction={() => {
                    onClickChip('noise', props.proj_name, noise_classes, dispatch, actions);
                  }}
                />
              )}

              {/* EXPOSURE Fab exposureEval*/}
              {exposure_classes?.length > 0 && (
                <ProjectionFabs
                  evaluationClass={exposureEval}
                  tooltipText={
                    <Typography className={classes.tooltip_text}>
                      {t('ResultView.exposure')}: {useQualityTranslation(exposureEval, t)}
                    </Typography>
                  }
                  extraStyles={{
                    color: exposureEval ? evaluationClassColor(exposureEval, theme) : '#3e3e3e',
                  }}
                  chipIcon={<Icon icon={radioactiveIcon} width={24} height={24} />}
                  chipAction={() => {
                    onClickChip('exposureClass', props.proj_name, exposure_classes, dispatch, actions);
                  }}
                />
              )}

              {/* ARTIFACTS Fab artifactsEval*/}
              {artifacts_classes?.length > 0 && (
                <ProjectionFabs
                  evaluationClass={artifactsEval}
                  tooltipText={
                    <Typography className={classes.tooltip_text}>
                      {t('ResultView.artifacts')}: {useQualityTranslation(artifactsEval, t)}
                    </Typography>
                  }
                  extraStyles={{
                    color: artifactsEval ? evaluationClassColor(artifactsEval, theme) : '#3e3e3e',
                  }}
                  chipIcon={<GrainIcon fontSize="medium" />}
                  chipAction={() => {
                    onClickChip('artifacts', props.proj_name, artifacts_classes, dispatch, actions);
                  }}
                />
              )}
            </div>
          )}

          {/* Density */}
          {density_module && (
            <ProjectionFabs
              evaluationClass={resultDensityEvaluation?.[props.proj_name]?.DensClass}
              tooltipText={
                <Typography
                  className={classes.tooltip_text}
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {`${props?.proj_name?.toUpperCase()} ${t('ResultView.density')}: ${
                    resultDensityEvaluation?.[props.proj_name]?.DensClass
                  }`}
                </Typography>
              }
              extraStyles={{
                background: resultDensityEvaluation?.[props.proj_name]?.DensClass
                  ? densityColor(resultDensityEvaluation?.[props.proj_name]?.DensClass, theme)
                  : 'transparent',
                bottom: '6px',
                right: props.left ? '6px' : undefined,
                left: props.left ? undefined : '6px',
                position: 'absolute',
              }}
              chipIcon={
                <Typography
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {resultDensityEvaluation?.[props.proj_name]?.DensClass}
                </Typography>
              }
            />
          )}
        </>
      )}

      <Projection
        proj_name={props.proj_name}
        onCaptureClick={props.onCaptureClick}
        onLoad={props.onLoad}
        uid={props?.uid}
        evaluation={resultQualityEvaluation?.[props?.proj_name]}
        setEvaluated={setEvaluated}
        left={props.left}
        src={props.src}
        fetchingEval={fetchingEval}
        projection={projections?.[props?.proj_name]}
        customOpacities={customOpacities?.[props?.proj_name]}
        customMicrocalc={customMicrocalc?.[props?.proj_name]}
        invertedColor={invertedColor?.[props?.proj_name]}
      />
    </div>
  );
};

export default ProjectionsCard;
