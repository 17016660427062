import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, Box } from '@material-ui/core';
import * as actions from '../../../../store/index';

import QualitySummary from './Quality/QualitySummary';
import DensitySummary from './Density/DensitySummary';
import DiagnosticsSummary from './Diagnostics/DiagnosticsSummary';
import RiskSummary from './Risk/RiskSummary';
import NotesSummary from './Limitations/NotesSummary';

function Summary(props) {
  const dispatch = useDispatch();
  // Store state
  const results = useSelector((state) => state.results);
  const zoomedProjection = useSelector((state) => state.gui?.zoomedProjection);
  const {
    applied_quality,
    applied_density,
    shown_quality,
    shown_density,
    shown_opacities,
    applied_opacities,
    shown_microcalc,
    applied_microcalc,
  } = useSelector((state) => state.results);
  const { premium_features } = useSelector((state) => state.global_settings);

  const {
    diagnostics_microcalc_module,
    diagnostics_opacities_module,
    density_module,
    risk_module,
  } = premium_features;

  const shownQuality = shown_quality || applied_quality;
  const shownDensity = shown_density || applied_density;
  const shownOpacities = shown_opacities || applied_opacities;
  const today = new Date();
  const birthDate = new Date(results?.birth);

  //Loged user role & premium modules
  let diagnosticsShown;
  try {
    diagnosticsShown =
      (diagnostics_microcalc_module || diagnostics_opacities_module) &&
      localStorage.getItem('diagnostics') === 'true';
  } catch {
    diagnosticsShown = false;
  }
  const premiumDensity = density_module;
  const allPremiumShown = diagnosticsShown && premiumDensity;
  const somePremiumCardsShown =
    (diagnosticsShown && !density_module) ||
    (!diagnosticsShown && density_module);
  const noPremiumCardsShown = !diagnosticsShown && !density_module;

  useEffect(() => {
    if (!shown_quality) dispatch(actions.setShownQuality(applied_quality));
    if (!shown_density) dispatch(actions.setShownDensity(applied_density));
    if (!shown_opacities)
      dispatch(actions.setShownOpacities(applied_opacities));
    if (!shown_microcalc)
      dispatch(actions.setShownMicrocalc(applied_microcalc));
  }, [results]);

  // Handlers
  const getAge = () => {
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <Box
      flexGrow={1}
      flexDirection={'column'}
      style={{
        height: '100%',
        width: '100%',
      }}
      sx={{
        display: 'grid',
        gap: 6,
        gridTemplateRows: 'auto auto',
        gridAutoRows: 'max-content',
      }}
    >
      {allPremiumShown || diagnosticsShown ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 6,
            width: '100%',
          }}
          sx={{
            gridRow: '1',
          }}
        >
          {/* Quality Summary */}
          <QualitySummary
            results={results?.evaluations.quality?.[shownQuality]}
            diagnosticsShown={diagnosticsShown}
          />

          {/* Density Summary */}
          {density_module && (
            <DensitySummary
              results={results?.evaluations.density?.[shownDensity]}
              zoomedProjection={zoomedProjection}
              diagnosticsShown={diagnosticsShown}
            />
          )}
        </Box>
      ) : (
        <>
          <Box sx={{ gridRow: '1', width: '100%' }}>
            <QualitySummary
              results={results?.evaluations.quality?.[shownQuality]}
              allPremiumShown={allPremiumShown}
            />
          </Box>
          {density_module && (
            <Box sx={{ gridRow: '2', width: '100%' }}>
              <DensitySummary
                results={results?.evaluations.density?.[shownDensity]}
                zoomedProjection={zoomedProjection}
                allPremiumShown={allPremiumShown}
              />
            </Box>
          )}
        </>
      )}

      {/* Diagnostics summary */}
      {diagnosticsShown && (
        <Box sx={{ gridRow: '2' }}>
          <DiagnosticsSummary />
        </Box>
      )}

      {/* Risk summary */}
      {risk_module && (
        <Box sx={{ gridRow: noPremiumCardsShown ? '2' : '3' }}>
          <RiskSummary age={getAge()} results={results} />
        </Box>
      )}

      {/* Limitations summary */}
      <Box
        sx={{
          gridRow:
            (diagnosticsShown && risk_module) || (risk_module && premiumDensity)
              ? '4'
              : noPremiumCardsShown && !risk_module
              ? '2'
              : '3',
        }}
      >
        <NotesSummary />
      </Box>
    </Box>
  );
}

export default Summary;
