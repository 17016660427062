import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../store';
import { useAppliedEvaluation } from '../../../../helpers/hooks/useEvaluation';
import {
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import QualityCC from './QualityProjections/QualityCC';
import QualityMLO from './QualityProjections/QualityMLO';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import QualityFeatures from './QualityRevisionMode/QualityFeatures';
import TooltipBadgeFab from '../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../CardActions/ReviewSelect/ReviewSelect';
import { reviewerName } from '../../../../helpers/reviewerName';
import { evalPNL, getTooltipText } from './utils/qualityUtils';
import { onClickQualityFeaturesChip } from './hooks/useQualityHandlers';
import { SymmetrySection } from './common/SymmetryChip';

const useStyles = makeStyles((theme) => ({
  card_content_container: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'center',
    alignItems: 'center',
    height: '96%',
  },

  tooltip_text: {
    letterSpacing: '0.09rem',
    fontSize: '12px',
  },

  InfoIcon: {
    cursor: 'pointer',
  },

  container_quality_pgmi: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    height: '100%',
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  container_symmetry: {
    position: 'relative',
    minHeight: '25px',
    display: 'flex',
    width: ' 100%',
    justifyContent: 'center',
  },

  quality_head_container: {
    margin: '0 16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  revision_quality_chips_container: {
    width: '100%',
    margin: '0',
    padding: '0',
    height: '30px',
  },

  revision_info_icon: {
    position: 'absolute',
    top: '10vh',
    right: '2%',
  },
  reviewer_badge: {
    height: '20px',
    minHeight: '20px',
    fontSize: theme.fonts.responsiveMedium,
    minWidth: '70px',
    marginBottom: '2px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },

  reviewer_title_shape: {
    marginLeft: '10px',
  },

  topRow: {
    display: 'flex',
    alignSelf: 'end',
    margin: '0 auto min(0.6vh, 0.6vw) auto',
    position: 'relative',
    maxHeight: '50%',
    width: '100%',
    justifyContent: 'center',
  },

  bottomRow: {
    display: 'flex',
    alignSelf: 'start',
    margin: 'min(0.6vh, 0.6vw) auto 0 auto',
    position: 'relative',
    maxHeight: '50%',
    width: '100%',
    justifyContent: 'center',
  },

  rcc: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top left',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lcc: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top right',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  rmlo: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom left',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lmlo: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom right',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  '@keyframes appear': {
    '70%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.8,
    },
  },

  zoom: {
    transform: 'scale(2.02)',
    transition: '0.3s',
    zIndex: 80,
  },

  shrink: {
    transform: 'scale(0)',
    transition: '0.3s',
  },

  symmetry_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: '4px',
    justifyContent: 'space-around',
    cursor: 'help',
  },
}));

const QualityView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const anchorQualityRef = useRef();

  const qualityInRevisionMode = useSelector((state) => state.labeling.qualityInRevisionMode);
  const { quality_features, quality_thresholds } = useSelector(
    (state) => state.global_settings.quality_system
  );
  const { customQuality, localChangesQuality, qualityInDrawingMode } = useSelector((state) => state.labeling);
  const { zoomedProjection, tabsIndex } = useSelector((state) => state.gui);
  const {
    projections: projectionData,
    evaluations,
    applied_quality,
    shown_quality,
  } = useSelector((state) => state.results);

  const qualityInRevisionTab = tabsIndex === 2 && qualityInRevisionMode;
  const { symmetry_classes, pnl_diff_classes } = quality_features;
  const { pnl_diff_thresholds } = quality_thresholds;

  // Local state
  const [reviewerTooltip, setReviewerTooltip] = useState(false);
  const [qualityPopperOpen, setQualityPopperOpen] = useState(false);

  // Variables
  const shownQuality = shown_quality || applied_quality;
  const qualityEvaluation = useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode);
  const projectionsType = ['rcc', 'lcc', 'rmlo', 'lmlo'];

  // Effects

  useEffect(() => {
    setQualityPopperOpen(false);
    dispatch(actions.setInitialImages());
    dispatch(actions.setDiagnosticsInRevisionMode(false));
    dispatch(actions.setDensityInRevisionMode(false));
    dispatch(actions.setShownQualityOverlayType(''));
    if (tabsIndex === 1) dispatch(actions.setQualityInRevisionMode(false));

    if (!shown_quality) dispatch(actions.setShownQuality(applied_quality));
  }, []);

  useEffect(() => {
    if (!localChangesQuality) return;

    const pnlClassRight = evalPNL(
      qualityEvaluation?.rmlo?.PectoNippleLength,
      qualityEvaluation?.rcc?.PectoNippleLength,
      pnl_diff_thresholds,
      pnl_diff_classes
    );
    if (pnlClassRight) dispatch(actions.updateCustomQualityFeature('RCC', 'PNL', pnlClassRight));

    const pnlClassLeft = evalPNL(
      qualityEvaluation?.lmlo?.PectoNippleLength,
      qualityEvaluation?.lcc?.PectoNippleLength,
      pnl_diff_thresholds,
      pnl_diff_classes
    );
    if (pnlClassLeft) dispatch(actions.updateCustomQualityFeature('LCC', 'PNL', pnlClassLeft));
  }, [
    qualityEvaluation?.rcc?.PectoNippleLength,
    qualityEvaluation?.lcc?.PectoNippleLength,
    qualityEvaluation?.rmlo?.PectoNippleLength,
    qualityEvaluation?.lmlo?.PectoNippleLength,
    localChangesQuality,
  ]);

  const classesMap = {
    rcc: [classes.rcc],
    lcc: [classes.lcc],
    rmlo: [classes.rmlo],
    lmlo: [classes.lmlo],
  };

  projectionsType.forEach((projection) => {
    if (zoomedProjection === projection) {
      classesMap[projection].push(classes.zoom);
    } else if (zoomedProjection) {
      classesMap[projection].push(classes.shrink);
    }
  });

  const { rcc: classesRcc, lcc: classesLcc, rmlo: classesRmlo, lmlo: classesLmlo } = classesMap;

  //Reviewer menu handler
  const handlePopperToggle = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen((prevOpen) => !prevOpen);
  };

  const clickAwayHandler = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen(false);
  };

  return (
    <Card elevation={0} style={{ height: '100%' }}>
      <CardHeader
        style={{ padding: 0, margin: 0, height: '4%' }}
        title={
          qualityInRevisionTab ? (
            <div className={classes.revision_quality_chips_container}>
              <QualityFeatures />
            </div>
          ) : (
            <div className={classes.quality_head_container}>
              <ClickAwayListener onClickAway={() => clickAwayHandler('quality')} mouseEvent="onClick">
                <div className={classes.reviewer_title_shape}>
                  <TooltipBadgeFab
                    content={
                      <>
                        <Typography className={classes.tooltip_text} color="inherit">
                          {t('ResultView.current_results_shown')}: {reviewerName(shownQuality)}
                        </Typography>
                        <Divider />
                        <Typography className={classes.tooltip_text} color="inherit">
                          {t('ResultView.current_applied')}: {reviewerName(applied_quality)}
                        </Typography>
                        <p>{t('ResultView.change_reviewer')}</p>
                      </>
                    }
                    open={reviewerTooltip}
                    badgeContent={shownQuality}
                    onClick={() => handlePopperToggle('quality')}
                    onMouseEnter={() => setReviewerTooltip(true)}
                    onMouseLeave={() => setReviewerTooltip(false)}
                    popperContent={
                      <ReviewSelect type="quality" setQualityPopperOpen={setQualityPopperOpen} />
                    }
                    anchorRef={anchorQualityRef}
                    popperOpen={qualityPopperOpen}
                    classes={classes}
                    theme={theme}
                    t={t}
                  />
                </div>
              </ClickAwayListener>
              <Tooltip arrow title={t('Dashboard.pgmi_info')} placement="right-end">
                <InfoIcon
                  className={classes.InfoIcon}
                  fontSize="small"
                  onClick={() => {
                    window.open(`/bbox/ifu_${i18n.language}`, '_blank');
                  }}
                />
              </Tooltip>
            </div>
          )
        }
      />

      <CardContent className={classes.card_content_container}>
        {qualityInRevisionTab && (
          <Tooltip
            arrow
            placement={'top-end'}
            title={
              <>
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    textTransform: 'uppercase',
                    fontSize: theme.fonts.responsiveMedium,
                    fontWeight: 'bold',
                  }}
                >
                  {t('ResultView.revision_mode')}
                </Typography>
                <Divider style={{ margin: '3% 0' }} />
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                  }}
                >
                  - {t('ResultView.click_to_revise')}
                </Typography>

                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                  }}
                >
                  - {t('ResultView.click_to_draw')}
                </Typography>
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                    marginTop: '6px',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {t('ResultView.revise_disclaimer')}
                </Typography>
              </>
            }
          >
            <InfoIcon className={classes.revision_info_icon} fontSize="small" />
          </Tooltip>
        )}
        <div className={classes.container_quality_pgmi}>
          {!zoomedProjection && (
            <div
              className={classes.container_symmetry}
              style={{
                alignItems: 'flex-start',
              }}
            >
              {!!symmetry_classes && !qualityInDrawingMode && (
                <SymmetrySection
                  qualityEvaluation={qualityEvaluation}
                  onClickSymmetry={onClickQualityFeaturesChip}
                  projections={['rcc', 'lcc']}
                  symmetry_classes={symmetry_classes}
                  qualityInRevisionMode={qualityInRevisionMode}
                  classes={classes}
                  theme={theme}
                  t={t}
                  dispatch={dispatch}
                  actions={actions}
                />
              )}
            </div>
          )}
          <div className={classes.topRow}>
            <div className={classesRcc.join(' ')}>
              <QualityCC
                view_position={'rcc'}
                result={qualityEvaluation?.rcc}
                tooltipProj={(feature) =>
                  !!projectionData.rcc
                    ? getTooltipText(
                        'rcc',
                        feature,
                        evaluations,
                        applied_quality,
                        customQuality,
                        localChangesQuality
                      )
                    : t('ResultView.not_available')
                }
              />
            </div>
            <div className={classesLcc.join(' ')}>
              <QualityCC
                view_position={'lcc'}
                result={qualityEvaluation?.lcc}
                tooltipProj={(feature) =>
                  !!projectionData.lcc
                    ? getTooltipText(
                        'lcc',
                        feature,
                        evaluations,
                        applied_quality,
                        customQuality,
                        localChangesQuality
                      )
                    : t('ResultView.not_available')
                }
              />
            </div>
          </div>
          <div className={classes.bottomRow}>
            <div className={classesRmlo.join(' ')}>
              <QualityMLO
                view_position={'rmlo'}
                result={qualityEvaluation?.rmlo}
                tooltipProj={(feature) =>
                  !!projectionData.rmlo
                    ? getTooltipText(
                        'rmlo',
                        feature,
                        evaluations,
                        applied_quality,
                        customQuality,
                        localChangesQuality
                      )
                    : t('ResultView.not_available')
                }
              />
            </div>
            <div className={classesLmlo.join(' ')}>
              <QualityMLO
                view_position={'lmlo'}
                result={qualityEvaluation?.lmlo}
                tooltipProj={(feature) =>
                  !!projectionData.lmlo
                    ? getTooltipText(
                        'lmlo',
                        feature,
                        evaluations,
                        applied_quality,
                        customQuality,
                        localChangesQuality
                      )
                    : t('ResultView.not_available')
                }
              />
            </div>
          </div>
          {!zoomedProjection && (
            <div
              className={classes.container_symmetry}
              style={{
                alignItems: 'flex-end',
              }}
            >
              {!!symmetry_classes && !qualityInDrawingMode && (
                <SymmetrySection
                  qualityEvaluation={qualityEvaluation}
                  onClickSymmetry={onClickQualityFeaturesChip}
                  projections={['rmlo', 'lmlo']}
                  symmetry_classes={symmetry_classes}
                  qualityInRevisionMode={qualityInRevisionMode}
                  classes={classes}
                  theme={theme}
                  t={t}
                  dispatch={dispatch}
                  actions={actions}
                />
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default QualityView;
