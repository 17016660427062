import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Chip, Fab, Icon, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  fabs_general: {
    background: 'transparent',
    width: '30px',
    height: '30px',
    minHeight: '30px',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ProjectionFabs = ({
  chipIcon,
  evaluationClass,
  tooltipText,
  chipAction,
  extraStyles,
  tooltipAction,
  tooltipLeave,
  clickableChip,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const qualityInRevision = useSelector((state) => state.labeling?.qualityInRevisionMode);

  return (
    <Tooltip
      title={<div>{tooltipText}</div>}
      placement="top"
      arrow
      onOpen={() => tooltipAction && tooltipAction(true)}
      onClose={() => tooltipLeave && tooltipLeave(false)}
    >
      <Avatar
        size="small"
        className={classes.fabs_general}
        onClick={() => {
          if ((chipAction && qualityInRevision) || clickableChip) chipAction();
        }}
        style={{
          cursor: qualityInRevision ? 'pointer' : 'help',
          '&:hover': {
            boxShadow: '0px 0px 5px 1px rgb(51 51 51)',
          },
          ...extraStyles,
        }}
      >
        {chipIcon}
      </Avatar>
    </Tooltip>
  );
};

ProjectionFabs.propTypes = {
  chipIcon: PropTypes.element,
  evaluationClass: PropTypes.string,
  chipAction: PropTypes.func,
  tooltipText: PropTypes.node.isRequired,
  extraStyles: PropTypes.object,
  tooltipLeave: PropTypes.func,
  tooltipAction: PropTypes.func,
};

ProjectionFabs.defaultProps = {
  chipIcon: null,
  evaluationClass: '',
  chipAction: null,
  tooltipText: <Typography>''</Typography>,
  extraStyles: {},
  tooltipLeave: null,
  tooltipAction: null,
};

export default ProjectionFabs;
