import React, { useEffect, useRef, useState } from 'react';
import * as actions from '../../../../../store/index';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core';
import {
  Divider,
  Grid,
  Tooltip,
  Typography,
  Chip,
  FormControlLabel,
  Switch,
  CardContent,
  CardHeader,
  ClickAwayListener,
  IconButton,
  Card,
  Button,
} from '@material-ui/core';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import { getCurrentModuleStatus } from '../SummaryCommon/SummaryCommon';
import TooltipBadgeFab from '../../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../../CardActions/ReviewSelect/ReviewSelect';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import EditIcon from '@material-ui/icons/Edit';
import { reviewerName } from '../../../../../helpers/reviewerName';

const useStyles = makeStyles((theme) => ({
  tooltip_text: {
    letterSpacing: '0.09rem',
    fontSize: '12px',
  },

  diagnostic_table_container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },

  diagnostic_table_title: {
    fontSize: theme.fonts.responsiveMediumBig,
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
  },

  diagnostics_classes_chips: {
    width: '100px',
    margin: '6px auto',
    height: '22px',
  },

  diagnostics_classes_birads_chips: {
    margin: '6px auto',
    height: '22px',
  },

  lesion_title_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.14)',
    marginTop: '6px',
  },

  lesions_container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '1% 2%',
    borderRadius: '0 0 5px 5px',
    minHeight: '5vh',
    alignContent: 'center',
  },

  title_container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  title_rev_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '8px',
  },

  show_all_handler: {
    // minWidth: '40px',
    fontSize: theme.fonts.responsiveSmall,
  },

  summary_edit_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    borderRadius: '50%',

    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  diagnostic_table_shapes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
  },

  reviewer_badge: {
    marginBottom: '1px',
    height: '18px',
    minHeight: '18px',
    fontSize: theme.fonts.responsiveMedium,
    minWidth: '70px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },

  module_status_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    margin: '4px',
    cursor: 'help',
    boxShadow: 'none',
  },

  module_status_annotator_fabs: {
    height: '22px',
    minWidth: '135px',
    minHeight: '22px',
    '&:hover': {
      background: `${theme.palette.complementary.turquoise} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  class_text: { fontSize: theme.fonts.responsiveSmall, color: 'gray' },

  main_class_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  class_container: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '2px',
  },

  opacities_class_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '2px',
  },

  class_line: { height: '4px', width: '95%', borderRadius: '5px' },

  module_fab_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 4px',
  },

  card_main_container: {
    margin: '0',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

export default function DiagnosticsSummary(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const anchorMicrocalcRef = useRef(null);
  const anchorOpacitiesRef = useRef(null);

  // Store state
  const results = useSelector((state) => state.results);
  const { microcalcOverlaysToShow, opacitiesOverlaysToShow, opacitiesShowAll, microcalcShowAll } =
    useSelector((state) => state.images);
  const {
    applied_opacities,
    applied_microcalc,
    confirmed_opacities,
    confirmed_microcalc,
    shown_microcalc,
    shown_opacities,
  } = useSelector((state) => state.results);
  const { darkState, tabsIndex, examinationListDrawer, zoomedProjection } = useSelector((store) => store.gui);
  const { premium_features } = useSelector((state) => state.global_settings);

  // Local state
  const [microcalcReviewerTooltip, setMicrocalcReviewerTooltip] = useState(false);
  const [opacitiesReviewerTooltip, setOpacitiesReviewerTooltip] = useState(false);
  const [microcalcPopperOpen, setMicrocalcPopperOpen] = useState(false);
  const [opacitiesPopperOpen, setOpacitiesPopperOpen] = useState(false);
  const [hoveredClass, setHoveredClass] = useState([]);

  const { diagnostics_microcalc_module, diagnostics_opacities_module, data_annotation } = premium_features;

  const annotatorRole = data_annotation && localStorage.getItem('annotation') === 'true';
  const isMicrocalcConfirmed = applied_microcalc === 'bbox' && confirmed_microcalc;
  const isMicrocalcRelabeled = confirmed_microcalc && applied_microcalc !== 'bbox';
  const isOpacityConfirmed = applied_opacities === 'bbox' && confirmed_opacities;
  const isOpacityRelabeled = confirmed_opacities && applied_opacities !== 'bbox';

  const microcalcShown = shown_microcalc || applied_microcalc;
  const opacitiesShown = shown_opacities || applied_opacities;

  const microcalcificationsResult = useAppliedEvaluation('microcalc', microcalcShown, false);
  const opacitiesResult = useAppliedEvaluation('opacities', opacitiesShown, false);

  const overlayZoomExist = (type, data) => {
    return data?.[type]?.length > 0;
  };

  const overlaysExist = (type, data) => {
    if (!data) return false;

    for (const projection in data) {
      const projectionData = data[projection];
      if (projectionData && projectionData[type]?.length > 0) {
        return true;
      }
    }
    return false;
  };

  const microcalcBiradsTwoShown = overlaysExist('birads2', microcalcificationsResult)
    ? Object.values(microcalcOverlaysToShow['birads2']).every((value) => value === true)
    : true;
  const microcalcBiradsThreeShown = overlaysExist('birads3', microcalcificationsResult)
    ? Object.values(microcalcOverlaysToShow['birads3']).every((value) => value === true)
    : true;
  const microcalcBiradsFourShown = overlaysExist('birads4', microcalcificationsResult)
    ? Object.values(microcalcOverlaysToShow['birads4']).every((value) => value === true)
    : true;
  const microcalcBiradsFiveShown = overlaysExist('birads5', microcalcificationsResult)
    ? Object.values(microcalcOverlaysToShow['birads5']).every((value) => value === true)
    : true;

  const lesionKnownShown = overlaysExist('lesionKnown', opacitiesResult)
    ? Object.values(opacitiesOverlaysToShow['lesionKnown']).every((value) => value === true)
    : true;

  const biradsTwoShown = overlaysExist('birads2', opacitiesResult)
    ? Object.values(opacitiesOverlaysToShow['birads2']).every((value) => value === true)
    : true;
  const biradsThreeShown = overlaysExist('birads3', opacitiesResult)
    ? Object.values(opacitiesOverlaysToShow['birads3']).every((value) => value === true)
    : true;
  const biradsFourShown = overlaysExist('birads4', opacitiesResult)
    ? Object.values(opacitiesOverlaysToShow['birads4']).every((value) => value === true)
    : true;
  const biradsFiveShown = overlaysExist('birads5', opacitiesResult)
    ? Object.values(opacitiesOverlaysToShow['birads5']).every((value) => value === true)
    : true;

  const allMicrocalcShown =
    microcalcBiradsTwoShown &&
    microcalcBiradsThreeShown &&
    microcalcBiradsFourShown &&
    microcalcBiradsFiveShown;

  const allOpacitiesShown = biradsTwoShown && biradsThreeShown && biradsFourShown && biradsFiveShown;

  const microcalcLesionsZoomed = microcalcificationsResult?.[zoomedProjection?.toLowerCase()];
  const opacitiesLesionsZoomed = opacitiesResult?.[zoomedProjection?.toLowerCase()];
  const microcalcEmpty = lesionExists(microcalcificationsResult);
  const opacitiesEmpty = lesionExists(opacitiesResult);

  const microcalcBenignExist =
    (zoomedProjection
      ? overlayZoomExist('birads2', microcalcLesionsZoomed)
      : overlaysExist('birads2', microcalcificationsResult)) ||
    (zoomedProjection
      ? overlayZoomExist('birads3', microcalcLesionsZoomed)
      : overlaysExist('birads3', microcalcificationsResult));

  const microcalcMalignantExist =
    (zoomedProjection
      ? overlayZoomExist('birads4', microcalcLesionsZoomed)
      : overlaysExist('birads4', microcalcificationsResult)) ||
    (zoomedProjection
      ? overlayZoomExist('birads5', microcalcLesionsZoomed)
      : overlaysExist('birads5', microcalcificationsResult));

  const opacitiesBenignExist =
    (zoomedProjection
      ? overlayZoomExist('birads2', opacitiesLesionsZoomed)
      : overlaysExist('birads2', opacitiesResult)) ||
    (zoomedProjection
      ? overlayZoomExist('birads3', opacitiesLesionsZoomed)
      : overlaysExist('birads3', opacitiesResult));

  const opacitiesMalignantExist =
    (zoomedProjection
      ? overlayZoomExist('birads4', opacitiesLesionsZoomed)
      : overlaysExist('birads4', opacitiesResult)) ||
    (zoomedProjection
      ? overlayZoomExist('birads5', opacitiesLesionsZoomed)
      : overlaysExist('birads5', opacitiesResult));

  const lesionKnownExist = zoomedProjection
    ? overlayZoomExist('lesionKnown', opacitiesLesionsZoomed)
    : overlaysExist('lesionKnown', opacitiesResult);

  const microcalcLesions = [
    {
      overlayType: t('diagnose.type_microcalc_birads_2'),
      label: 'BI-RADS 2',
      color: theme.palette.lesions.birads2,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads2', microcalcLesionsZoomed)
        : overlaysExist('birads2', microcalcificationsResult),

      isShown: microcalcBiradsTwoShown,
      overlaysToShow: 'birads2',
      tooltip_title: t('diagnose.unsusp_calc'),
      curentClass: t('projections.unsuspicious'),
    },
    {
      overlayType: t('diagnose.type_microcalc_birads_3'),
      label: 'BI-RADS 3',
      color: theme.palette.lesions.birads3,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads3', microcalcLesionsZoomed)
        : overlaysExist('birads3', microcalcificationsResult),

      isShown: microcalcBiradsThreeShown,
      overlaysToShow: 'birads3',
      tooltip_title: t('diagnose.unsusp_calc'),
      curentClass: t('projections.unsuspicious'),
    },
    {
      overlayType: t('diagnose.type_microcalc_birads_4'),
      label: 'BI-RADS 4',
      color: theme.palette.lesions.birads4,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads4', microcalcLesionsZoomed)
        : overlaysExist('birads4', microcalcificationsResult),
      isShown: microcalcBiradsFourShown,
      overlaysToShow: 'birads4',
      tooltip_title: t('diagnose.susp_calc'),
      curentClass: t('projections.suspicious'),
    },
    {
      overlayType: t('diagnose.type_microcalc_birads_5'),
      label: 'BI-RADS 5',
      color: theme.palette.lesions.birads5,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads5', microcalcLesionsZoomed)
        : overlaysExist('birads5', microcalcificationsResult),
      isShown: microcalcBiradsFiveShown,
      overlaysToShow: 'birads5',
      tooltip_title: t('diagnose.susp_calc'),
      curentClass: t('projections.suspicious'),
    },
  ];

  const opacitiesLesions = [
    {
      overlayType: t('projections.lesionKnown'),
      label: t('ResultView.lesion_known'),
      color: theme.palette.lesions.lesionKnown,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('lesionKnown', opacitiesLesionsZoomed)
        : overlaysExist('lesionKnown', opacitiesResult),
      isShown: lesionKnownShown,
      overlaysToShow: 'lesionKnown',
      tooltip_title: t('projections.lesionKnownHelper'),
      curentClass: t('projections.lesionKnown'),
    },

    {
      overlayType: t('diagnose.type_lesion_birads_2'),
      label: 'BI-RADS 2',
      color: theme.palette.lesions.birads2,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads2', opacitiesLesionsZoomed)
        : overlaysExist('birads2', opacitiesResult),
      isShown: biradsTwoShown,
      overlaysToShow: 'birads2',
      tooltip_title: t('diagnose.birads2'),
    },
    {
      overlayType: t('diagnose.type_lesion_birads_3'),
      label: 'BI-RADS 3',
      color: theme.palette.lesions.birads3,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads3', opacitiesLesionsZoomed)
        : overlaysExist('birads3', opacitiesResult),
      isShown: biradsThreeShown,
      overlaysToShow: 'birads3',
      tooltip_title: t('diagnose.birads3'),
    },
    {
      overlayType: t('diagnose.type_lesion_birads_4'),
      label: 'BI-RADS 4',
      color: theme.palette.lesions.birads4,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads4', opacitiesLesionsZoomed)
        : overlaysExist('birads4', opacitiesResult),
      isShown: biradsFourShown,
      overlaysToShow: 'birads4',
      tooltip_title: t('diagnose.birads4'),
    },
    {
      overlayType: t('diagnose.type_lesion_birads_5'),
      label: 'BI-RADS 5',
      color: theme.palette.lesions.birads5,
      overlaysExist: zoomedProjection
        ? overlayZoomExist('birads5', opacitiesLesionsZoomed)
        : overlaysExist('birads5', opacitiesResult),
      isShown: biradsFiveShown,
      overlaysToShow: 'birads5',
      tooltip_title: t('diagnose.birads5'),
    },
  ];

  function lesionExists(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!lesionExists(obj[key])) {
            return false;
          }
        } else if (obj[key] !== null && obj[key] !== undefined) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
    setMicrocalcReviewerTooltip(false);
    setOpacitiesReviewerTooltip(false);
    setMicrocalcPopperOpen(false);
    setOpacitiesPopperOpen(false);
  }, []);

  useEffect(() => {
    if (opacitiesShowAll) {
      opacitiesLesions.map((item, key) => {
        dispatch(actions.changeOpacitiesToShow(item?.overlaysToShow, true));
      });
    }

    if (microcalcShowAll) {
      microcalcLesions.map((item, key) => {
        dispatch(actions.changeMicrocalcToShow(item?.overlaysToShow, true));
      });
    }

    if (!opacitiesShowAll && !microcalcShowAll) {
      dispatch(actions.hideAllLesions());
    }
  }, [results, tabsIndex]);

  //Reviewer menu handler
  const handlePopperToggle = (modulePopper) => {
    if (modulePopper === 'microcalc') {
      setMicrocalcPopperOpen((prevOpen) => !prevOpen);
      setMicrocalcReviewerTooltip(false);
    }
    if (modulePopper === 'opacities') {
      setOpacitiesPopperOpen((prevOpen) => !prevOpen);
      setOpacitiesReviewerTooltip(false);
    }
  };

  const clickAwayHandler = (modulePopper) => {
    if (modulePopper === 'microcalc') {
      setMicrocalcPopperOpen(false);
      setMicrocalcReviewerTooltip(false);
    } else if (modulePopper === 'opacities') {
      setOpacitiesPopperOpen(false);
      setOpacitiesReviewerTooltip(false);
    }
  };

  const handleEditButtonClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setDiagnosticsInRevisionMode(true));
  };

  const handleTitleClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setDiagnosticsInRevisionMode(true));
  };

  const handleChipClick = (lesion, biradsClass, value) => {
    // TODO: Revert logic when microcalc model is working with classes
    if (lesion === 'microcalc') {
      if (biradsClass === 'birads2' || biradsClass === 'birads3') {
        dispatch(actions.changeMicrocalcToShow('birads2', value));
        dispatch(actions.changeMicrocalcToShow('birads3', value));
      } else if (biradsClass === 'birads4' || biradsClass === 'birads5') {
        dispatch(actions.changeMicrocalcToShow('birads4', value));
        dispatch(actions.changeMicrocalcToShow('birads5', value));
      } else dispatch(actions.changeMicrocalcToShow(biradsClass, value));
    } else if (lesion === 'opacities') dispatch(actions.changeOpacitiesToShow(biradsClass, value));
  };

  const handleAllChipClick = (lesion) => {
    if (lesion === 'microcalc') {
      if (allMicrocalcShown) {
        microcalcLesions.map((item, key) => {
          dispatch(actions.setMicrocalcShowAll(false));
          dispatch(actions.changeMicrocalcToShow(item?.overlaysToShow, false));
          //  dispatch(actions.hideAllMicrocalc(item?.overlaysToShow, false));
        });
      } else {
        microcalcLesions.map((item, key) => {
          dispatch(actions.setMicrocalcShowAll(true));
          dispatch(actions.changeMicrocalcToShow(item?.overlaysToShow, true));
        });
      }
    } else if (lesion === 'opacities') {
      if (allOpacitiesShown) {
        opacitiesLesions.map((item, key) => {
          dispatch(actions.setOpacitiesShowAll(false));
          dispatch(actions.changeOpacitiesToShow(item?.overlaysToShow, false));
        });
      } else {
        opacitiesLesions.map((item, key) => {
          dispatch(actions.setOpacitiesShowAll(true));
          dispatch(actions.changeOpacitiesToShow(item?.overlaysToShow, true));
        });
      }
    }
  };

  const onMicrocalcConfirmClick = () => {
    const state = shown_microcalc === applied_microcalc && confirmed_microcalc;
    dispatch(actions.confirmEvaluation(microcalcShown, 'microcalc', !state, t));
  };

  const onOpacitiesConfirmClick = () => {
    const state = shown_opacities === applied_opacities && confirmed_opacities;
    dispatch(actions.confirmEvaluation(opacitiesShown, 'opacities', !state, t));
  };

  const handleBiradsClassBarClick = (module, type) => {
    if (type === 'suspicious') {
      handleChipClick(module, 'birads4');
      handleChipClick(module, 'birads5');
    } else if (type === 'unsuspicious') {
      handleChipClick(module, 'birads2');
      handleChipClick(module, 'birads3');
    }
  };

  const handleChipMouseOver = (module, exist, isShown, lesionType) => {
    setHoveredClass([module, lesionType, isShown]);
    if (!exist) return;
    else {
      if (isShown) {
        return;
      } else {
        if (module === 'opacities') {
          dispatch(actions.changeOpacitiesToShow(lesionType, true));
        } else if (module === 'microcalc') {
          dispatch(actions.changeMicrocalcToShow(lesionType, true));
        }
      }
    }
    return;
  };

  const handleChipMouseLeave = (module, lesionType) => {
    if (lesionType === hoveredClass[1] && hoveredClass[2] === false) {
      if (module === 'opacities') {
        dispatch(actions.changeOpacitiesToShow(lesionType, false));
      } else if (module === 'microcalc') {
        dispatch(actions.changeMicrocalcToShow(lesionType, false));
      }
    } else {
      return;
    }
  };

  const classChipclick = (module, isShown, exists, classToShow) => {
    setHoveredClass([]);

    if (hoveredClass[2] === false || (hoveredClass === [] && exists)) {
      handleChipClick(module, classToShow, isShown);
    } else if (!isShown && exists) {
      handleChipClick(module, classToShow, true);
    } else {
      handleChipClick(module, classToShow, false);
    }
  };

  return (
    <Card className={classes.card_main_container}>
      <CardHeader
        style={{
          padding: '0',
          background: theme.palette.complementary.generalTitleBackgrounds,
        }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0px 8px',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '30px',
            }}
          >
            <SummaryParagraphHeader
              clickable={annotatorRole}
              header={t('b_box.b_diagnose')}
              onClick={() => annotatorRole && handleTitleClick()}
              tootlipTitle={t('ResultView.view_more_diagnose')}
            />
            {annotatorRole && (
              <Tooltip
                arrow
                placement="left-end"
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {t('ResultView.tooltip_revise')}
                  </Typography>
                }
              >
                <IconButton
                  size="small"
                  onClick={() => handleEditButtonClick()}
                  className={classes.summary_edit_fabs}
                >
                  <EditIcon
                    fontSize="medium"
                    style={{
                      width: '18px',
                      height: '18px',
                      color: 'white',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      />
      <CardContent style={{ padding: '0' }} className={classes.diagnostic_table_container}>
        {/* MICROCALCIFICATIONS  */}
        {diagnostics_microcalc_module && (
          <Grid
            container
            spacing={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Grid
              item
              xs={12}
              className={classes.lesion_title_container}
              style={{ background: darkState ? '#494949' : '#d5d5d5' }}
            >
              <div className={classes.title_container}>
                <div className={classes.title_rev_container}>
                  <Typography className={classes.diagnostic_table_title}>
                    {t('ResultView.diagnosticsMicrocalc')}
                  </Typography>

                  <ClickAwayListener onClickAway={() => clickAwayHandler('microcalc')} mouseEvent="onClick">
                    <div className={classes.diagnostic_table_shapes}>
                      <TooltipBadgeFab
                        content={
                          <>
                            <Typography className={classes.tooltip_text} color="inherit">
                              {t('ResultView.current_results_shown')}: {reviewerName(microcalcShown)}
                            </Typography>
                            <Divider />
                            <Typography className={classes.tooltip_text} color="inherit">
                              {t('ResultView.current_applied')}: {reviewerName(applied_microcalc)}
                            </Typography>
                            <p>{t('ResultView.change_reviewer')}</p>
                          </>
                        }
                        open={microcalcReviewerTooltip}
                        badgeContent={microcalcShown}
                        onClick={() => handlePopperToggle('microcalc')}
                        onMouseEnter={() => setMicrocalcReviewerTooltip(true)}
                        onMouseLeave={() => setMicrocalcReviewerTooltip(false)}
                        popperContent={
                          <ReviewSelect type="microcalc" setMicrocalcPopperOpen={setMicrocalcPopperOpen} />
                        }
                        anchorRef={anchorMicrocalcRef}
                        popperOpen={microcalcPopperOpen}
                        classes={classes}
                        theme={theme}
                        t={t}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
              <Tooltip
                placement="top-end"
                arrow
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {microcalcEmpty
                      ? t('ResultView.no_microcalc')
                      : allMicrocalcShown
                      ? `${t('ResultView.hide_all')} ${t('ResultView.diagnosticsMicrocalc')}`
                      : `${t('ResultView.view_all')} ${t('ResultView.diagnosticsMicrocalc')}`}
                  </Typography>
                }
              >
                <FormControlLabel
                  checked={allMicrocalcShown && !microcalcEmpty ? true : false}
                  control={
                    <Switch
                      size="small"
                      onClick={() => handleAllChipClick('microcalc', allMicrocalcShown)}
                      color="secondary"
                    />
                  }
                  label={
                    <Typography className={classes.show_all_handler}>
                      {!microcalcEmpty
                        ? allMicrocalcShown
                          ? t('ResultView.hide_all')
                          : t('projections.show_all')
                        : ''}
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} className={classes.lesions_container}>
              {microcalcLesions &&
                microcalcLesions.map((lesion, index) => (
                  <div key={`${lesion}${index}`}>
                    <Tooltip
                      arrow
                      placement="top-end"
                      key={lesion + index}
                      title={
                        lesion?.overlaysExist ? (
                          <>
                            <Typography className={classes.tooltip_title}>{lesion?.tooltip_title}</Typography>
                          </>
                        ) : (
                          <Typography className={classes.tooltip_helper}>
                            {t('diagnose.not_found', {
                              overlay_type: `${lesion?.overlayType}`,
                            })}
                          </Typography>
                        )
                      }
                    >
                      <Chip
                        key={index}
                        size="small"
                        className={
                          microcalcLesions.length <= 2
                            ? classes.diagnostics_classes_chips
                            : classes.diagnostics_classes_birads_chips
                        }
                        label={lesion.label}
                        variant={'outlined'}
                        style={{
                          backgroundColor: lesion.overlaysExist && lesion.isShown && lesion.color,
                          border: lesion.overlaysExist ? `1px solid ${lesion.color}` : '1px solid gray',
                          color: lesion.overlaysExist ? (lesion.isShown ? 'white' : lesion.color) : 'gray',
                          cursor: 'pointer',
                          width: examinationListDrawer ? '7vw' : '8vw',
                          minWidth: 'fit-content',
                          fontSize: examinationListDrawer && theme.fonts.responsiveMediumSmall,
                        }}
                        onMouseEnter={() => {
                          handleChipMouseOver(
                            'microcalc',
                            lesion.overlaysExist,
                            lesion.isShown,
                            lesion.overlaysToShow
                          );
                        }}
                        onMouseLeave={() => {
                          handleChipMouseLeave('microcalc', lesion.overlaysToShow);
                        }}
                        onClick={() => {
                          classChipclick(
                            'microcalc',
                            lesion.isShown,
                            lesion.overlaysExist,
                            lesion.overlaysToShow
                          );
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              <div className={classes.main_class_container}>
                <div
                  className={classes.class_container}
                  onClick={() => handleBiradsClassBarClick('microcalc', 'unsuspicious')}
                >
                  <div
                    className={classes.class_line}
                    style={{
                      background: microcalcBenignExist
                        ? 'linear-gradient(to right, rgb(96, 170, 77), rgb(255, 152, 0))'
                        : 'gray',
                    }}
                  />
                  <Typography className={classes.class_text}>{t('ResultView.unsuspicious')}</Typography>
                </div>
                <div
                  className={classes.class_container}
                  onClick={() => handleBiradsClassBarClick('microcalc', 'suspicious')}
                >
                  <div
                    className={classes.class_line}
                    style={{
                      background: microcalcMalignantExist
                        ? 'linear-gradient(to right, rgb(255, 152, 0), rgb(221, 48, 47))'
                        : 'gray',
                    }}
                  />
                  <Typography className={classes.class_text}>{t('ResultView.suspicious')}</Typography>
                </div>
              </div>
            </Grid>
            <div className={classes.module_fab_container}>
              <Tooltip
                title={
                  <>
                    <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                      {
                        getCurrentModuleStatus(
                          applied_microcalc,
                          microcalcShown,
                          isMicrocalcConfirmed,
                          isMicrocalcRelabeled,
                          t('ResultView.diagnose_microcalc'),
                          theme,
                          t
                        ).currentApplied
                      }
                    </Typography>
                    {annotatorRole && (
                      <>
                        <Divider style={{ margin: '3% 0' }} />
                        <Typography className={classes.tooltipText}>
                          {
                            getCurrentModuleStatus(
                              applied_microcalc,
                              microcalcShown,
                              isMicrocalcConfirmed,
                              isMicrocalcRelabeled,
                              t('ResultView.diagnose_microcalc'),
                              theme,
                              t
                            ).tooltipAction
                          }
                        </Typography>
                      </>
                    )}
                  </>
                }
                arrow
                placement="top-start"
              >
                <span>
                  <Button
                    size="small"
                    onClick={() => annotatorRole && onMicrocalcConfirmClick()}
                    startIcon={
                      getCurrentModuleStatus(
                        applied_microcalc,
                        microcalcShown,
                        isMicrocalcConfirmed,
                        isMicrocalcRelabeled,
                        t('ResultView.diagnose_microcalc'),
                        theme,
                        t
                      ).fabIcon
                    }
                    color="primary"
                    className={
                      annotatorRole ? classes.module_status_annotator_fabs : classes.module_status_fabs
                    }
                    style={{
                      color: 'white',
                      margin: '1px 4px',
                      background: `${
                        getCurrentModuleStatus(
                          applied_microcalc,
                          microcalcShown,
                          isMicrocalcConfirmed,
                          isMicrocalcRelabeled,
                          t('ResultView.diagnose_microcalc'),
                          theme,
                          t
                        ).color
                      }`,
                    }}
                  >
                    {
                      getCurrentModuleStatus(
                        applied_microcalc,
                        microcalcShown,
                        isMicrocalcConfirmed,
                        isMicrocalcRelabeled,
                        t('ResultView.diagnose_microcalc'),
                        theme,
                        t
                      ).buttonText
                    }
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        )}

        {/* OPACITIES  */}

        {diagnostics_opacities_module && (
          <Grid
            container
            spacing={0}
            style={{
              display: 'flex',
              alignItems: 'center',

              height: '100%',
            }}
          >
            <Grid
              item
              xs={12}
              className={classes.lesion_title_container}
              style={{ background: darkState ? '#494949' : '#d5d5d5' }}
            >
              <div className={classes.title_container}>
                <div className={classes.title_rev_container}>
                  <Typography className={classes.diagnostic_table_title}>
                    {t('ResultView.diagnosticsLesions')}
                  </Typography>

                  <ClickAwayListener onClickAway={() => clickAwayHandler('opacities')} mouseEvent="onClick">
                    <div className={classes.diagnostic_table_shapes}>
                      <TooltipBadgeFab
                        content={
                          <>
                            <Typography className={classes.tooltip_text} color="inherit">
                              {t('ResultView.current_results_shown')}: {reviewerName(opacitiesShown)}
                            </Typography>
                            <Divider />
                            <Typography className={classes.tooltip_text} color="inherit">
                              {t('ResultView.current_applied')}: {reviewerName(applied_opacities)}
                            </Typography>
                            <p>{t('ResultView.change_reviewer')}</p>
                          </>
                        }
                        open={opacitiesReviewerTooltip}
                        badgeContent={opacitiesShown}
                        onClick={() => handlePopperToggle('opacities')}
                        onMouseEnter={() => setOpacitiesReviewerTooltip(true)}
                        onMouseLeave={() => setOpacitiesReviewerTooltip(false)}
                        popperContent={
                          <ReviewSelect type="opacities" setOpacitiesPopperOpen={setOpacitiesPopperOpen} />
                        }
                        anchorRef={anchorOpacitiesRef}
                        popperOpen={opacitiesPopperOpen}
                        classes={classes}
                        theme={theme}
                        t={t}
                      />
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
              <Tooltip
                placement="top-end"
                arrow
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {opacitiesEmpty
                      ? t('ResultView.no_opacities')
                      : allOpacitiesShown
                      ? `${t('ResultView.hide_all')} ${t('ResultView.diagnosticsLesions')}`
                      : `${t('ResultView.view_all')} ${t('ResultView.diagnosticsLesions')}`}
                  </Typography>
                }
              >
                <FormControlLabel
                  checked={allOpacitiesShown && !opacitiesEmpty ? true : false}
                  control={
                    <Switch
                      size="small"
                      onClick={() => handleAllChipClick('opacities', allOpacitiesShown)}
                      color="secondary"
                    />
                  }
                  label={
                    <Typography className={classes.show_all_handler}>
                      {!opacitiesEmpty
                        ? allOpacitiesShown
                          ? t('ResultView.hide_all')
                          : t('projections.show_all')
                        : ''}
                    </Typography>
                  }
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} className={classes.lesions_container}>
              {opacitiesLesions &&
                opacitiesLesions.map((lesion, index) => (
                  <div key={`${lesion}${index}`}>
                    <Tooltip
                      arrow
                      placement="top-end"
                      key={lesion + index}
                      title={
                        lesion?.overlaysExist ? (
                          <>
                            <Typography className={classes.tooltip_title}>{lesion?.tooltip_title}</Typography>
                          </>
                        ) : (
                          <Typography className={classes.tooltip_helper}>
                            {t('diagnose.not_found', {
                              overlay_type: `${lesion?.overlayType}`,
                            })}
                          </Typography>
                        )
                      }
                    >
                      <Chip
                        key={index}
                        size="small"
                        className={classes.diagnostics_classes_birads_chips}
                        label={lesion.label}
                        variant={'outlined'}
                        style={{
                          backgroundColor: lesion.overlaysExist && lesion.isShown && lesion.color,
                          border: lesion.overlaysExist ? `1px solid ${lesion.color}` : '1px solid gray',
                          color: lesion.overlaysExist ? (lesion.isShown ? 'white' : lesion.color) : 'gray',
                          cursor: 'pointer',
                          width: examinationListDrawer ? '6vw' : '7vw',
                          minWidth: 'fit-content',
                          fontSize: examinationListDrawer && theme.fonts.responsiveMediumSmall,
                        }}
                        onMouseEnter={() => {
                          handleChipMouseOver(
                            'opacities',
                            lesion.overlaysExist,
                            lesion.isShown,
                            lesion.overlaysToShow
                          );
                        }}
                        onMouseLeave={() => {
                          handleChipMouseLeave('opacities', lesion.overlaysToShow);
                        }}
                        onClick={() => {
                          classChipclick(
                            'opacities',
                            lesion.isShown,
                            lesion.overlaysExist,
                            lesion.overlaysToShow
                          );
                        }}
                      />
                    </Tooltip>
                  </div>
                ))}
              <div className={classes.main_class_container}>
                <div className={classes.opacities_class_container} style={{ width: '20%' }}>
                  <div
                    className={classes.class_line}
                    style={{
                      background: lesionKnownExist
                        ? 'linear-gradient(90deg, rgba(113,154,165,1) 0%, rgba(131,131,131,1) 50%, rgba(113,154,165,1) 100%)'
                        : 'gray',
                    }}
                  />
                  <Typography className={classes.class_text}>
                    {t('ResultView.lesion_known_confirmed')}
                  </Typography>
                </div>

                <div
                  className={classes.opacities_class_container}
                  onClick={() => handleBiradsClassBarClick('opacities', 'unsuspicious')}
                  style={{ width: '40%' }}
                >
                  <div
                    className={classes.class_line}
                    style={{
                      background: opacitiesBenignExist
                        ? 'linear-gradient(to right, rgb(96, 170, 77), rgb(255, 152, 0))'
                        : 'gray',
                    }}
                  />
                  <Typography className={classes.class_text}>{t('ResultView.unsuspicious')}</Typography>
                </div>
                <div
                  className={classes.opacities_class_container}
                  onClick={() => handleBiradsClassBarClick('opacities', 'suspicious')}
                  style={{ width: '40%' }}
                >
                  <div
                    className={classes.class_line}
                    style={{
                      background: opacitiesMalignantExist
                        ? 'linear-gradient(to right, rgb(255, 152, 0), rgb(221, 48, 47))'
                        : 'gray',
                    }}
                  />
                  <Typography className={classes.class_text}>{t('ResultView.suspicious')}</Typography>
                </div>
              </div>
            </Grid>
            <div className={classes.module_fab_container}>
              <Tooltip
                title={
                  <>
                    <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                      {
                        getCurrentModuleStatus(
                          applied_opacities,
                          opacitiesShown,
                          isOpacityConfirmed,
                          isOpacityRelabeled,
                          t('ResultView.diagnose_opacities'),
                          theme,
                          t
                        ).currentApplied
                      }
                    </Typography>
                    {annotatorRole && (
                      <>
                        <Divider style={{ margin: '3% 0' }} />
                        <Typography className={classes.tooltipText}>
                          {
                            getCurrentModuleStatus(
                              applied_opacities,
                              opacitiesShown,
                              isOpacityConfirmed,
                              isOpacityRelabeled,
                              t('ResultView.diagnose_opacities'),
                              theme,
                              t
                            ).tooltipAction
                          }
                        </Typography>
                      </>
                    )}
                  </>
                }
                arrow
                placement="top-start"
              >
                <span>
                  <Button
                    size="small"
                    startIcon={
                      getCurrentModuleStatus(
                        applied_opacities,
                        opacitiesShown,
                        isOpacityConfirmed,
                        isOpacityRelabeled,
                        t('ResultView.diagnose_opacities'),
                        theme,
                        t
                      ).fabIcon
                    }
                    onClick={() => annotatorRole && onOpacitiesConfirmClick()}
                    color="primary"
                    className={
                      annotatorRole ? classes.module_status_annotator_fabs : classes.module_status_fabs
                    }
                    style={{
                      color: 'white',
                      margin: '4px',
                      background: `${
                        getCurrentModuleStatus(
                          applied_opacities,
                          opacitiesShown,
                          isOpacityConfirmed,
                          isOpacityRelabeled,
                          t('ResultView.diagnose_opacities'),
                          theme,
                          t
                        ).color
                      }`,
                    }}
                  >
                    {
                      getCurrentModuleStatus(
                        applied_opacities,
                        opacitiesShown,
                        isOpacityConfirmed,
                        isOpacityRelabeled,
                        t('ResultView.diagnose_opacities'),
                        theme,
                        t
                      ).buttonText
                    }
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
