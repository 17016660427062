import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import * as actions from '../../../../../store';
import useResponsive from '../../../../../helpers/hooks/useResponsive';
import { qualityEvaluationChip } from '../../../../../helpers/qualityProjection';
import {
  pnlTooltip,
  parenchymaClass,
  nippleProfileClass,
  nippleOrientationClass,
  pectoMuscClass,
  pnlColor,
  blurredAreaInfo,
  skinFoldsInfo,
} from '../utils/qualityUtils';
import QualityChips from '../common/QualityChips';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';

import style from '../styles/QualityProjections.module.css';
import { useStyles } from '../styles/QualityProjections.style';

import concept_rcc from '../../../../../resources/concept_images/Concept_RCC.png';
import concept_rcc_light from '../../../../../resources/concept_images/Concept_RCC_light.png';
import concept_rcc_light_top from '../../../../../resources/concept_images/Concept_RCC_light_top.png';
import concept_rcc_light_bottom from '../../../../../resources/concept_images/Concept_RCC_light_bottom.png';
import concept_rcc_top from '../../../../../resources/concept_images/Concept_RCC_top.png';
import concept_rcc_bottom from '../../../../../resources/concept_images/Concept_RCC_bottom.png';

import { Icon } from '@iconify/react';
import { Divider, Tooltip, Typography, Zoom } from '@material-ui/core';
import RedoIcon from '@material-ui/icons/Redo';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import WavesIcon from '@material-ui/icons/Waves';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';
import {
  onClickNippleOrientation,
  onClickParen,
  onClickQualityChip,
  onClickQualityFeaturesChip,
} from '../hooks/useQualityHandlers';

const QualityCC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  // Store state
  const { qualityInRevisionMode, qualityInDrawingMode } = useSelector((state) => state.labeling);
  const { projections, applied_quality, shown_quality } = useSelector((state) => state.results);

  const { isXSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen, isXLargeScreen } = useResponsive();
  const { tabsIndex, examinationListDrawer, landscape, darkState, zoomedProjection } = useSelector(
    (state) => state.gui
  );

  const { compression_low, compression_high, compression_tolerance, premium_features } = useSelector(
    (state) => state.global_settings
  );

  const {
    blur_classes,
    skin_folds_classes,
    nipple_orientation_classes,
    nipple_profile_classes,
    pnl_diff_classes,
    pectoral_muscle_classes,
    parenchyma_classes,
  } = useSelector((state) => state.global_settings?.quality_system.quality_features);

  // Local state

  const [pnlIsHovered, setPnlIsHovered] = useState(false);
  const [flip, setFlip] = useState(null);
  const [conceptImg, setConceptImg] = useState(null);

  //Variables
  const { view_position, result } = props;
  const shownQuality = shown_quality || applied_quality;
  const upperProjection = view_position?.toUpperCase();
  const PectoNippleLength = result?.PectoNippleLength;
  const blurPremium = premium_features?.blur;

  const smallView =
    zoomedProjection ||
    isXSmallScreen ||
    isSmallScreen ||
    isMediumScreen ||
    (examinationListDrawer && isLargeScreen);

  const left = view_position.includes('l');

  // Revision mode
  const qualityInfoMode =
    tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode);

  const qualityInRevison = tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode;

  const qualityInDrawingRevision = tabsIndex === 2 && qualityInRevisionMode && qualityInDrawingMode;

  // PNL differences
  const calculatePercentageDiff = (ccValue, mloValue) => {
    const difference = Math.abs(ccValue - Number(mloValue));
    const higherValue = Math.max(ccValue, Number(mloValue));
    const percentageDifference = (difference / higherValue) * 100;
    return percentageDifference.toFixed(1);
  };

  const pectoNippleLengthRMLO =
    useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode)?.rmlo?.PectoNippleLength || null;

  const pectoNippleLengthLMLO =
    useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode)?.lmlo?.PectoNippleLength || null;

  const mloNippleLength =
    view_position === 'rcc' ? pectoNippleLengthRMLO?.toFixed(0) : pectoNippleLengthLMLO?.toFixed(0);

  const higherPNLValue = Math.max(PectoNippleLength, Number(mloNippleLength));

  const pnlDifference = `${Math.abs(PectoNippleLength - Number(mloNippleLength)).toFixed(0)}mm
    (${calculatePercentageDiff(PectoNippleLength, mloNippleLength)}%)`;

  const higherCCPNL = higherPNLValue?.toFixed(0) === PectoNippleLength?.toFixed(0);

  // Effects
  useEffect(() => {
    if (left) setFlip({ transform: 'scaleX(-1)' });
    else setFlip(null);
  }, [props]);

  useEffect(() => {
    const preloadImages = [
      concept_rcc,
      concept_rcc_light,
      concept_rcc_light_top,
      concept_rcc_light_bottom,
      concept_rcc_top,
      concept_rcc_bottom,
    ];

    preloadImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    let selectedConceptImage = concept_rcc;
    if (result?.NippleCentered === 'moderate' || result?.NippleCentered === 'insufficient') {
      selectedConceptImage =
        result?.NippleAngle > 0
          ? darkState
            ? concept_rcc_top
            : concept_rcc_light_top
          : darkState
          ? concept_rcc_bottom
          : concept_rcc_light_bottom;
    } else if (result?.NippleCentered === 'correct' || !result?.NippleCentered) {
      selectedConceptImage = darkState ? concept_rcc : concept_rcc_light;
    }

    setConceptImg(selectedConceptImage);
  }, [result?.NippleAngle, darkState]);

  // PNL
  const handlePnlMouseEnter = () => {
    setPnlIsHovered(true);
    dispatch(actions.setLinesToShow(view_position));
  };

  const handlePnlMouseLeave = () => {
    setPnlIsHovered(false);
    dispatch(actions.hideLinesShown());
  };

  //PARENCHYMA
  const showParencOverlays = (parencResult, parenchymaLocation) => {
    const parenLocation = `${view_position}${parenchymaLocation}`;

    if (parencResult !== 'correct' && parenLocation) dispatch(actions.setParenchymaCutsToShow(parenLocation));

    if (parencResult === 'correct') dispatch(actions.hideParenchymaCutsShown(parenLocation));

    const newLesionsToShow = {
      [`parenchyma${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setLinesToShow(view_position));

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hideParencOverlays = (proj, parenchymaLocation) => {
    const parenCutsToHide = [`${proj}${parenchymaLocation}`];

    dispatch(actions.hideParenchymaCutsShown(parenCutsToHide));
    dispatch(actions.hideAllLesions());
    if (!pnlIsHovered) dispatch(actions.hideLinesShown());
  };

  //PECTORALIS
  const showPectOverlays = () => {
    const newLesionsToShow = {
      [`pectoralis${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hidePectOverlays = () => {
    dispatch(actions.hideAllLesions());
  };

  //NIPPLE
  const showNippleProfile = () => {
    dispatch(actions.setNippleProfileToShow(view_position));
  };

  const nippleTiltColor = (nippleClass) => {
    if (nippleClass === 'moderate') return theme.palette.projections.nippleTiltUp;
    if (nippleClass === 'insufficient') return theme.palette.projections.nippleTiltDownSevere;
  };

  // NIPPLE ORIENTATION
  const nippleOrientationIcon = () => {
    if (!result || Object.keys(result).length === 0 || result?.NippleCentered === undefined) return;

    if (result?.NippleCentered === 'correct') {
      return (
        <>
          <Tooltip
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {t('ResultView.nipple_tilt_ok')}
              </Typography>
            }
            arrow
          >
            <TrendingFlatIcon
              style={{
                position: 'absolute',
                left: qualityInfoMode ? '-3%' : '-2%',
                top: isXLargeScreen ? '47%' : '48%',
                transform: 'rotate(-180deg)',
                color: qualityInDrawingRevision ? 'gray' : theme.palette.projections.nippleStraight,
                cursor: qualityInRevison ? 'pointer' : 'help',
              }}
              className={classes.goodNippleIconHover}
              onClick={() =>
                qualityInRevison &&
                onClickNippleOrientation(view_position, nipple_orientation_classes, dispatch, actions)
              }
            />
          </Tooltip>
        </>
      );
    } else {
      if (result.NippleAngle > 0) {
        return (
          <>
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {`${t('ResultView.nipple_tilted_up')}: ${result?.NippleAngle?.toFixed(2)}°`}
                </Typography>
              }
              arrow
            >
              <RedoIcon
                onClick={() => {
                  qualityInRevison &&
                    onClickNippleOrientation(upperProjection, nipple_orientation_classes, dispatch, actions);
                }}
                fontSize="small"
                style={{
                  position: 'absolute',
                  left: '0%',
                  top: '32%',
                  transform: 'rotate(300deg)',
                  color: qualityInDrawingRevision ? 'gray' : nippleTiltColor(result?.NippleCentered),
                  cursor: qualityInRevison ? 'pointer' : 'help',
                }}
                className={
                  result?.NippleCentered === 'moderate'
                    ? classes.tiltedModerateNippleIconHover
                    : classes.tiltedNippleIconHover
                }
              />
            </Tooltip>
          </>
        );
      } else if (result.NippleAngle < 0) {
        return (
          <>
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {`${t('ResultView.nipple_tilted_down')}: ${Math.abs(result?.NippleAngle?.toFixed(2))}°`}
                </Typography>
              }
              arrow
            >
              <RedoIcon
                onClick={() => {
                  qualityInRevison &&
                    onClickNippleOrientation(upperProjection, nipple_orientation_classes, dispatch, actions);
                }}
                fontSize="small"
                style={{
                  position: 'absolute',
                  bottom: '32%',
                  left: '0%',
                  transform: 'rotate(225deg) scaleX(-1)',
                  color: nippleTiltColor(result.NippleCentered),
                  cursor: qualityInRevison ? 'pointer' : 'help',
                }}
                className={
                  result.NippleCentered === 'moderate'
                    ? classes.tiltedModerateNippleIconHover
                    : classes.tiltedNippleIconHover
                }
              />
            </Tooltip>
          </>
        );
      }
    }

    return;
  };

  const showSkinFolds = () => {
    if (result?.skinFolds) {
      const newLesionsToShow = {
        [`skinFolds${view_position.toUpperCase()}`]: true,
      };

      dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
    }
  };

  return (
    <div
      className={classes.quality_projection_container}
      style={{ justifyContent: flip ? 'flex-start' : 'flex-end' }}
    >
      {/* Quality Features Chips */}
      {!!result && (
        <div
          className={classes.quality_chips_container}
          style={{ right: flip ? '25px' : undefined, left: flip ? undefined : '25px' }}
        >
          {/* SKIN FOLDS CHIP */}
          {skin_folds_classes?.length > 0 && !qualityInRevisionMode && (
            <QualityChips
              chipIcon={<WavesIcon fontSize="small" className={classes.chips_icon} />}
              evaluationClass={skinFoldsInfo(result?.skinFolds, view_position, t, theme).class}
              evaluationLabel={
                result?.skinFolds ? t('ResultView.skin_folds') : t('ResultView.none_skin_folds')
              }
              chipAction={() => null}
              tooltipText={skinFoldsInfo(result?.skinFolds, view_position, t, theme).tooltip}
              tooltipAction={showSkinFolds}
              tooltipLeave={hidePectOverlays}
              extraStyles={{ cursor: 'help' }}
            />
          )}

          {/* BLUR CHIP */}
          {blur_classes?.length > 0 && !qualityInDrawingMode && blurPremium && (
            <QualityChips
              chipIcon={<BlurCircularIcon fontSize="small" className={classes.chips_icon} />}
              evaluationClass={result?.blur}
              evaluationLabel={blurredAreaInfo(result?.blur, theme, t)?.label}
              chipAction={() =>
                onClickQualityFeaturesChip('blur', upperProjection, blur_classes, dispatch, actions)
              }
              tooltipText={
                <Typography className={classes.tooltip_texts}>
                  {blurredAreaInfo(result?.blur, theme, t)?.tooltip}
                </Typography>
              }
            />
          )}
        </div>
      )}

      {/* Quality Evaluation Chip */}
      <div
        className={classes.quality_evaluation_chip_container}
        style={{
          right: left ? '10px' : undefined,
          left: left ? undefined : '10px',
          alignItems: smallView ? 'flex-end' : 'center',
          justifyContent: smallView ? (left ? 'flex-end' : 'flex-start') : 'center',
          height: smallView ? '94%' : '100%',
        }}
      >
        {!!result &&
          !qualityInDrawingMode &&
          qualityEvaluationChip(
            view_position,
            result?.quality,
            result?.quality_explanation,
            smallView,
            () => onClickQualityChip(upperProjection, t, dispatch, actions),
            qualityInfoMode,
            qualityInRevison,
            theme,
            t
          )}
      </div>

      {/* Concept Img */}
      <div className={style.QualityProj} style={flip}>
        <img
          style={{
            maxHeight: landscape ? '35vh' : 'calc(33vh - 170px)',
            filter: darkState ? '' : 'contrast(0.1)',
          }}
          src={conceptImg || concept_rcc}
          alt="not found"
        />

        {/* Nipple */}
        <>
          {result &&
            !qualityInDrawingMode &&
            nipple_orientation_classes?.length > 0 &&
            nippleOrientationIcon()}

          {result?.Nipple && nipple_profile_classes?.length > 0 && (
            <Tooltip
              title={
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {nippleProfileClass(result?.Nipple, t)?.text}
                </Typography>
              }
              arrow
              interactive
              placement="bottom"
              TransitionComponent={Zoom}
              onOpen={() => qualityInfoMode && showNippleProfile()}
              onClose={() => qualityInfoMode && dispatch(actions.hideLinesShown())}
            >
              <button
                style={{
                  cursor: qualityInRevison ? 'pointer' : 'help',
                  top: nippleOrientationClass(result?.NippleCentered, result?.NippleAngle)?.pnlY,
                  zIndex: theme.zIndex.tooltip,
                }}
                className={
                  qualityInDrawingRevision
                    ? [classes.nippleCCBase, classes.neutral]
                    : `${classes.nippleCCBase} ${classes[nippleProfileClass(result?.Nipple, t)?.classColor]}`
                }
                onClick={() => {
                  if (!!result && qualityInRevison)
                    onClickQualityFeaturesChip(
                      'Nipple',
                      upperProjection,
                      nipple_profile_classes,
                      dispatch,
                      actions
                    );
                }}
              />
            </Tooltip>
          )}
        </>

        {/* Pecto Muscle */}
        {pectoral_muscle_classes?.length > 0 && (
          <Tooltip
            title={
              <Typography
                style={{
                  lineHeight: 1.3,
                  fontSize: '1.3vh',
                }}
              >
                {pectoMuscClass(result?.PectoralisCC, t)?.text}
              </Typography>
            }
            arrow
            interactive
            onOpen={() => qualityInfoMode && showPectOverlays()}
            onClose={() => qualityInfoMode && hidePectOverlays()}
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
          >
            <button
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              className={
                qualityInDrawingRevision
                  ? [classes.pectMuscleBase, classes.neutral]
                  : `${classes.pectMuscleBase} ${
                      classes[pectoMuscClass(result?.PectoralisCC, t)?.classColor]
                    }`
              }
              id={style.pecto_cc}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickQualityFeaturesChip(
                    'PectoralisCC',
                    upperProjection,
                    pectoral_muscle_classes,
                    dispatch,
                    actions
                  );
              }}
            ></button>
          </Tooltip>
        )}

        {/* Parenchyma Lateral */}
        {parenchyma_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && showParencOverlays(result?.ParenchymaCutsLateral, 'Lateral')}
            onClose={() => qualityInfoMode && hideParencOverlays(view_position, 'Lateral')}
            title={
              <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
                {t('ResultView.lateral')} {parenchymaClass(result?.ParenchymaCutsLateral, t).text}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
          >
            <button
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              className={
                qualityInDrawingRevision
                  ? [classes.parenLateralCCBase, classes.neutral]
                  : `${classes.parenLateralCCBase} ${
                      classes[parenchymaClass(result?.ParenchymaCutsLateral, t)?.classColor]
                    }`
              }
              id={style.parenCC}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickParen(upperProjection, 'Lateral', parenchyma_classes, dispatch, actions);
              }}
            />
          </Tooltip>
        )}

        {/* Parenchyma Medial */}
        {parenchyma_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && showParencOverlays(result?.ParenchymaCutsMedial, 'Medial')}
            onClose={() => qualityInfoMode && hideParencOverlays(view_position, 'Medial')}
            title={
              <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
                {t('ResultView.medial')} {parenchymaClass(result?.ParenchymaCutsMedial, t).text}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
          >
            <button
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              className={
                qualityInDrawingRevision
                  ? [classes.parenCCMedialBase, classes.neutral]
                  : `${classes.parenCCMedialBase} ${
                      classes[parenchymaClass(result?.ParenchymaCutsMedial, t)?.classColor]
                    }`
              }
              id={style.parenCC}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickParen(upperProjection, 'Medial', parenchyma_classes, dispatch, actions);
              }}
            />
          </Tooltip>
        )}

        {/* PNL */}
        {pnl_diff_classes?.length > 0 && (
          <Tooltip
            arrow
            onOpen={() => qualityInfoMode && handlePnlMouseEnter()}
            onClose={() => qualityInfoMode && handlePnlMouseLeave()}
            title={
              <>
                <Typography
                  style={{
                    fontSize: '1.3vh',
                    lineHeight: 1.3,
                  }}
                >
                  {pnlTooltip(result?.PNL, view_position, higherCCPNL, pnlDifference, t).text}
                </Typography>
                {pnlTooltip(result?.PNL, view_position, higherCCPNL, pnlDifference, t)?.textHelper && (
                  <>
                    <Divider style={{ margin: '3% 0' }} />
                    <Typography
                      style={{
                        fontSize: '1.2vh',
                      }}
                    >
                      {pnlTooltip(result?.PNL, view_position, higherCCPNL, pnlDifference, t)?.textHelper}
                    </Typography>
                  </>
                )}
              </>
            }
          >
            <svg
              style={{
                ...flip,
                top: nippleOrientationClass(result?.NippleCentered, result?.NippleAngle)?.pnlY,
              }}
              className={classes.pnlSvgCC}
            >
              <text
                x={left ? '40%' : '45%'}
                y="70%"
                fill="white"
                style={{
                  fontSize: theme.fonts.responsiveMedium,
                  cursor: 'help',
                  fontWeight: pnlIsHovered ? 'bold' : 'normal',
                  textShadow: pnlIsHovered ? '2px 2px 4px rgb(52 52 52)' : 'none',
                }}
              >
                {result && result.PectoNippleLength ? result.PectoNippleLength.toFixed(0) : null}
              </text>

              <line
                x1="0%"
                y1="90%"
                x2="100%"
                y2="90%"
                className={`${classes.buttonBase} ${classes.pnlBase} ${
                  qualityInDrawingRevision ? classes[pnlColor()?.color] : classes[pnlColor(result)?.color]
                }`}
                // onClick={onClickPNL}
              />
            </svg>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default QualityCC;
