import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, TextField, Link, Paper, Grid, MenuItem, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import LoginImage from './home_screen.png';
import Logo from '../../resources/coorporate_images/brayz_logo_login.png';
import { fetchPost } from '../../helpers/fetching';
import MediaLinks from './MediaLinks';
import NeuroConnector from './../../resources/coorporate_images/neuro_network_small.png';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CopyrightShort } from './CopyrightShort';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  image: {
    background: 'linear-gradient(90deg, rgb(95 87 87) 0%, rgb(66, 66, 66) 100%)',
  },

  image_light: {
    background: 'linear-gradient(270deg, rgb(255 255 255) 0%, rgb(215 215 215) 100%)',
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    width: 'auto',
    height: '20vh',
  },

  form: {
    width: '80%',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.complementary.mainDark} inset`,
      borderRadius: 'initial',
    },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  loginInfo: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  register: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  mediaLinks: {
    marginBottom: theme.spacing(1),
  },

  connector_image: {
    height: '70vh',
    alignSelf: 'self-end',
    opacity: '70%',
  },

  errorText: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },

  infoText: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    background: theme.palette.complementary.turquoiseDark,
  },
}));

export default function LoginForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const darkState = useSelector((store) => store.gui.darkState);

  const [fieldValues, setFieldValues] = useState({
    username: '',
    password: '',
    repeat: '',
    role: 'technician',
    aliases: '',
  });
  const [loginError, setLoginError] = useState('');
  const [loginInfo, setLoginInfo] = useState('');

  function onTextChange(event) {
    const { value, name } = event.target;
    const newValues = { ...fieldValues };
    newValues[name] = value;
    setFieldValues(newValues);
  }

  function formValidate() {
    for (let key in fieldValues)
      if (!fieldValues[key]) {
        if (fieldValues.role === 'radiologist' && key === 'aliases') continue;
        setLoginError(`${t('LoginRegister.all_required')}`);
        return false;
      }
    if (fieldValues.password !== fieldValues.repeat) {
      setLoginError(`${t('LoginRegister.not_match')}`);
      return false;
    }
    if (fieldValues.username.includes('_')) {
      setLoginError(`${t('LoginRegister.error_underscore')}`);
      return false;
    }
    return true;
  }

  const onSubmit = async () => {
    setLoginError('');
    setLoginInfo('');
    if (!formValidate()) return;
    const response = await fetchPost('users', fieldValues);
    if (response.success) {
      setLoginInfo(response.msg);
      const text = t('LoginRegister.notification', {
        role: fieldValues.role,
        username: fieldValues.username,
      });
      fetchPost('notifications', {
        text,
        recipient: 'admin',
      });
      setFieldValues({
        username: '',
        password: '',
        repeat: '',
        role: 'technician',
        aliases: '',
      });
    } else {
      setLoginError(response.msg);
    }
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={3} className={darkState ? classes.image : classes.image_light} />

      <Grid
        container
        direction="column"
        item
        xs={6}
        component={Paper}
        elevation={0}
        square
        alignItems="center"
        justifyContent="space-between"
        style={{
          zIndex: '1000',
          backgroundColor: theme.palette.complementary.mainDark,
        }}
      >
        <img
          className={classes.avatar}
          src={Logo}
          alt="logo missing"
          onClick={() => {
            window.open('/login', '_self');
          }}
        />

        <div style={{ height: '30px' }}>
          {loginInfo && (
            <Chip
              icon={<ErrorOutlineIcon />}
              label={loginInfo}
              onDelete={() => setLoginInfo('')}
              variant="default"
              className={classes.infoText}
            />
          )}
          {loginError && (
            <Chip
              icon={<ErrorOutlineIcon />}
              label={loginError}
              onDelete={() => setLoginError('')}
              color={'primary'}
              variant="default"
              className={classes.errorText}
            />
          )}
        </div>

        <form className={classes.form}>
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            onChange={onTextChange}
            label={t('LoginRegister.username')}
            name="username"
            autoComplete="off"
            autoFocus
            onKeyDown={keyPress}
            value={fieldValues.username}
            data-test-id="register-username"
          />
          <TextField
            margin="normal"
            select
            autoComplete="off"
            name="role"
            onChange={onTextChange}
            label={t('LoginRegister.role')}
            fullWidth
            variant="outlined"
            value={fieldValues.role}
            data-test-id="register-menu-role"
          >
            <MenuItem value={'technician'}>Technician</MenuItem>
            <MenuItem value={'radiologist'}>Radiologist</MenuItem>
          </TextField>
          {fieldValues.role === 'technician' && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="aliases"
              label={t('LoginRegister.aliases')}
              autoComplete={t('LoginRegister.autocomplete_aliases')}
              onKeyDown={keyPress}
              onChange={onTextChange}
              value={fieldValues.aliases.replaceAll(',', ';')}
              data-test-id="register-alias"
            />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label={t('LoginRegister.password')}
            type="password"
            autoComplete={t('LoginRegister.autocomplete_password')}
            onKeyDown={keyPress}
            onChange={onTextChange}
            value={fieldValues.password}
            data-test-id="register-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="repeat"
            label={t('LoginRegister.repeat')}
            type="password"
            autoComplete={t('LoginRegister.autocomplete_password')}
            onKeyDown={keyPress}
            onChange={onTextChange}
            value={fieldValues.repeat}
            data-test-id="register-password-repeat"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmit}
            size="large"
            data-test-id="register-button"
          >
            {t('LoginRegister.register')}
          </Button>
          <Typography variant="body2" color="textSecondary" align="center" className={classes.loginInfo}>
            {t('LoginRegister.already_registered')}
            <RouterLink className={classes.register} to="/login">
              {t('LoginRegister.login')}
            </RouterLink>
          </Typography>
        </form>
        <Grid
          className={classes.mediaLinks}
          item
          container
          direction="column"
          alignItems="center"
          wrap="nowrap"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <CopyrightShort />
          </Grid>
          <Grid item>
            <MediaLinks />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          background: darkState
            ? 'linear-gradient(270deg, rgb(95 87 87) 0%, rgb(66, 66, 66) 100%)'
            : 'linear-gradient(270deg, rgb(171 171 171) 0%, rgb(255 255 255) 100%)',
        }}
      >
        <img className={classes.connector_image} src={NeuroConnector} alt="b_rayz connector" />
      </Grid>
    </Grid>
  );
}
