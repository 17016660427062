export const evaluationClassColor = (evaluationClass, theme) => {
  const colorMapping = {
    mild: theme.palette.lesions.skinFoldsMild,
    correct: theme.palette.chips.correct,
    moderate: theme.palette.chips.moderate,
    insufficient: theme.palette.chips.insufficient,
    high: theme.palette.chips.high,
    low: theme.palette.chips.low,
  };

  return colorMapping[evaluationClass] || 'gray';
};
