import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store';
import { AppBar, Toolbar, IconButton, Tooltip, Typography, Chip, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BSmart_color from '../../../resources/icons/BSmart.png';
import Mammography_icon from '../../../resources/icons/mammography_dark.png';
import { Autorenew } from '@material-ui/icons';
import TuneIcon from '@material-ui/icons/Tune';
import DatePicker from '../DrawerExamList/DatePicker/DatePicker';
import CustomDatePicker from '../DrawerExamList/DatePicker/CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  app_bar_drawer: {
    top: 0,
    backgroundColor: theme.palette.complementary.mainDark,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    zIndex: '200',
    boxShadow: 'none',
    alignContent: 'space-around',

    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  hidden_header: {
    display: 'none',
  },

  grow_toolbar: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    minHeight: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },

  navButton: {
    background: theme.palette.complementary.turquoise,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px !important',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      background: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    },
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  chip_reset_filter: {
    marginLeft: '10px',
    fontSize: theme.fonts.responsiveMediumBig,
  },

  iconFilter: {
    color: theme.palette.complementary.turquoise,
    '&:hover': {
      color: 'white',
    },
  },

  iconWrapper: {
    padding: '2px',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      cursor: 'pointer',
    },
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tabsIndex, examinationListDrawer, landscape, darkState } = useSelector((state) => state.gui);
  const dates = useSelector((state) => state.dates);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.setCompressionDates(null));
    dispatch(actions.setPerformanceDates(null));
  }, [dates.customDate, dates.selectedRange]);

  const DashboardIcon = () => (
    <img src={BSmart_color} alt="b-SMART icon" style={{ height: '1em', maxHeight: !landscape && '12px' }} />
  );

  const ExaminationIcon = () => (
    <img
      src={Mammography_icon}
      alt="Examination icon"
      style={{ height: '1em', maxHeight: !landscape && '12px' }}
    />
  );

  return (
    <AppBar
      position="fixed"
      className={tabsIndex === 2 ? classes.hidden_header : classes.app_bar_drawer}
      position="fixed"
      style={{ height: landscape ? '4vh' : '3vh' }}
    >
      {!window.location.pathname.includes('ifu') && (
        <Toolbar className={classes.grow_toolbar}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {dates.selectedRange === 'custom' && (
              <CustomDatePicker
                setDates={(dates) => dispatch(actions.setFilterProperty(['dates', dates]))}
                customDate={dates.customDate}
                setCustomDate={(date) => dispatch(actions.setCustomDate(date))}
              />
            )}

            {/* Dates  */}
            <div className={classes.filterDatesGroup}>
              <DatePicker
                setDates={(dates) => dispatch(actions.setFilterProperty(['dates', dates]))}
                customDate={dates.customDate}
                setCustomDate={(date) => dispatch(actions.setCustomDate(date))}
                selectedRange={dates.selectedRange}
                setSelectedRange={(date) => dispatch(actions.setSelectedRange(date))}
              />
            </div>

            {/* Applied filter reset chip*/}
            {props.activeFilter && (
              <Chip
                icon={<Autorenew />}
                className={classes.chip_reset_filter}
                label={t('Menu.reset_filter')}
                color={'secondary'}
                variant="outlined"
                size="small"
                onClick={() => dispatch(actions.resetFilter())}
              />
            )}

            {/* Filter CTA */}
            <Tooltip
              arrow
              placement="right"
              title={
                <Typography color="inherit" className={classes.tooltip_text}>
                  {t('Menu.filter')}
                </Typography>
              }
              aria-label="add"
            >
              <Badge
                color="primary"
                overlap="rectangular"
                variant="dot"
                invisible={!props.activeFilter}
                data-test-id="patient-list-filter"
                style={{ margin: '0 10px' }}
              >
                <IconButton
                  className={classes.iconWrapper}
                  size={landscape ? 'medium' : 'small'}
                  onClick={() => dispatch(actions.setActiveDialog('filter'))}
                >
                  <TuneIcon
                    className={classes.iconFilter}
                    style={{
                      maxHeight: !landscape && '18px',
                      maxWidth: !landscape && '18px',
                    }}
                  />
                </IconButton>
              </Badge>
            </Tooltip>

            {/* Navigation icons */}
            <div>
              <Tooltip
                arrow
                placement="left"
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {window.location.pathname.includes('dashboard')
                      ? t('Menu.view_patient')
                      : t('Menu.view_dashboard')}
                  </Typography>
                }
              >
                <IconButton
                  aria-label="nav-navigation"
                  className={classes.navButton}
                  size="small"
                  onClick={() => {
                    if (window.location.pathname.includes('dashboard')) {
                      history.push('/bbox/results/details');
                      !examinationListDrawer && dispatch(actions.setExaminationListDrawer(true));
                    } else {
                      history.push('/bbox/results/dashboard');
                      dispatch(actions.setExaminationListDrawer(false));
                    }
                  }}
                  style={{
                    width: landscape ? '3vh' : 'auto',
                    height: landscape ? '3vh' : '100%',
                  }}
                >
                  {window.location.pathname.includes('dashboard') ? <ExaminationIcon /> : <DashboardIcon />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Toolbar>
      )}
    </AppBar>
  );
}
