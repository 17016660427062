import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { setActionToConfirm } from '../../../../store';
import * as actions from '../../../../store/index';
import { fetchDelete, fetchGet, fetchPut } from '../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
  Typography,
  IconButton,
  Divider,
  Chip,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Fab,
  Snackbar,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { Delete, Edit, Done, Spellcheck, DoneOutline } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import animation from '../../../../resources/processing_animation.gif';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import ADIcon from '../../../../resources/icons/active_directory.png';

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '40vh',
  },

  listHeader: {
    fontSize: theme.fonts.responsiveMedium,
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },

  chipSmall: {
    margin: '6px',
  },

  helperText: {
    color: '#ff7676',
    fontSize: '0.8rem',
    margin: '1px',
    whiteSpace: 'nowrap',
  },

  add_user_container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    minHeight: '40px',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  tooltip_text_lowercase: {
    letterSpacing: '0.1rem',
    fontSize: '12px',
  },

  table_placeholder: {
    color: 'gray',
    padding: '15%',
    border: 'none',
    fontSize: '10px',
    fontStyle: 'italic',
    padding: '14px 10px 10px',
    borderBottom: 'unset',
  },
  tableGeneral: {
    padding: '6px',
  },

  tableGeneralTextField: {
    padding: '10px 6px 6px',
  },

  newPasswordField: {
    width: '100%',
  },

  saveIconList: {
    margin: '6px 10px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.complementary.turquoise,
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },
}));

function UsersPanel(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const notifications = useSelector((state) => state.gui.notifications);
  const currentRole = localStorage.getItem('role');

  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState('ASC');
  const [orderBy, setOrderBy] = useState('');
  const [editUser, setEditUser] = useState(null);
  const [aliases, setAliases] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ msg: '', severity: '' });
  const [password, setPassword] = useState('');
  const [shortPasswordError, setShortPasswordError] = useState(`${t('UserPanel.password_helper')}`);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const heads = useMemo(() => {
    const baseHeads = [
      { key: 'status', label: '', align: 'center' },
      { key: 'username', label: t('users.name'), align: 'left' },
      { key: 'active', label: t('users.active'), align: 'left' },
      { key: 'aliases', label: t('users.dicomTag'), align: 'left' },
      { key: 'role', label: t('users.role'), align: 'left' },
      {
        key: 'annotation_permission',
        label: t('users.annotator'),
        align: 'center',
      },
      {
        key: 'diagnostics_permission',
        label: t('UserPanel.b_diagnostics'),
        align: 'center',
      },
      { key: 'actions', label: t('users.actions'), align: 'center' },
    ];

    // Add the password column only if in edit mode
    if (editUser) {
      baseHeads.splice(-1, 0, {
        key: 'password',
        label: t('LoginRegister.password'),
        align: 'center',
      });
    }

    return baseHeads;
  }, [editUser]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchGet('users');
      if (response.success) {
        setUsers(response.data.filter((user) => user.username !== 'admin'));
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAliasesChange = (event) => {
    setAliases(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
    setShortPasswordError(t('api_messages.password_too_short'));
  };

  const updateUser = async (user, value, moduleRow) => {
    const updateBody = {
      [moduleRow]: typeof value === 'number' || typeof value === 'boolean' ? !value : value,
    };
    try {
      const response = await fetchPut(`user/${user}`, updateBody);
      if (response.success) {
        fetchUsers();
        setNotification({
          msg: t('UserPanel.snack_user_updated'),
          severity: 'success',
        });

        if (moduleRow !== 'role') {
          setEditUser(false);
          setPassword('');
          setNotification({
            msg: t('UserPanel.snack_user_updated'),
            severity: 'success',
          });
        }
      } else {
        setNotification({
          msg: t('UserPanel.snack_user_failed'),
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to update user', error);
      setNotification({
        msg: t('UserPanel.snack_user_failed'),
        severity: 'error',
      });
    }
    setLoading(false);
  };

  const validateAndSaveUser = async (username) => {
    try {
      // No password, only update alias
      if (!password) {
        await updateUser(username, aliases, 'aliases');
        return;
      }

      // Validate password length
      if (password.length < 8) {
        setShortPasswordError(true);
        return;
      }

      //  Password is ok, so update
      const passwordResponse = await fetchPut('set_password_admin', {
        username,
        new: password,
      });

      if (passwordResponse.success) {
        updateUser(username, aliases, 'aliases');
      } else
        setNotification({
          msg: passwordResponse.msg,
          severity: 'error',
        });
      return;
    } catch (error) {
      setNotification({
        msg: error,
        severity: 'error',
      });
    }
  };

  const updatePermission = async (username, permission, current_status) => {
    let response;
    try {
      if (current_status) {
        response = await fetchDelete('permissions', {
          username,
          permission,
        });
      } else {
        response = await fetchPut('permissions', {
          username,
          permission,
        });
      }

      if (response.success) {
        fetchUsers();
        setNotification({
          msg: t('UserPanel.snack_user_updated'),
          severity: 'success',
        });
      } else {
        setNotification({
          msg: t('UserPanel.permission_failed'),
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Failed to update permission', error);
      setNotification({
        msg: t('UserPanel.permission_failed'),
        severity: 'error',
      });
    }
  };

  const deleteUser = async (username) => {
    if (!username) {
      setNotification({
        msg: t('UserPanel.snack_not_selected'),
        severity: 'warning',
      });
      return;
    }
    const response = await fetchDelete(`user/${username}`, { username });
    if (response.success) {
      setUsers((prevState) => prevState.filter((u) => u.username !== username));
      setNotification({
        msg: t('UserPanel.snack_not_selected'),
        severity: 'success',
      });
      fetchUsers();
    } else {
      setNotification({
        msg: t('UserPanel.snack_delete_failed'),
        severity: 'error',
      });
    }
  };

  const EnhancedTableHead = ({ order, orderBy, onRequestSort }) => {
    const createSortHandler = (property) => (event) => onRequestSort(event, property);
    return (
      <TableHead style={{ background: '#4f4747' }}>
        <TableRow>
          {heads.map((head, index) => (
            <TableCell
              key={`${index}-${head.key}`}
              style={{ fontWeight: 'bold' }}
              align={head.align}
              padding="normal"
              sortDirection={orderBy === head.key ? order.toLowerCase() : false}
            >
              <TableSortLabel
                active={orderBy === head.key}
                direction={orderBy === head.key ? order.toLowerCase() : 'asc'}
                onClick={createSortHandler(head.key)}
              >
                {head.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'DESC'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const closeUsersDialog = () => {
    dispatch(setActiveDialog(null));
    dispatch(deleteNotification(notifications.map(({ id }) => id)));
  };

  const onADRefresh = async () => {
    const response = await fetchGet('ad_refresh', 'bbox');

    if (response.success) {
      await fetchUsers();
      dispatch(
        actions.setSnackbar({
          msg: t('UserPanel.users_synchronized'),
          severity: 'info',
        })
      );
    } else {
      dispatch(
        actions.setSnackbar({
          msg: `${t('users.users_error')} ${response.msg}`,
          severity: 'error',
        })
      );
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              src={animation}
              alt="logoanimation"
            />
            <Typography style={{ fontSize: '10px', color: 'gray', fontStyle: 'italic' }}>
              {t('Dashboard.loading')}
            </Typography>
          </div>
        ) : (
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  heads={heads}
                  rowCount={users.length}
                />
                {users.length > 0 ? (
                  <TableBody>
                    {users.sort(getComparator(order, orderBy)).map((row, i) => (
                      <TableRow hover tabIndex={-1} key={`${row.username}-${i}`}>
                        {heads.map((head) => {
                          if (head.key === 'actions')
                            return currentRole === 'admin' ||
                              (currentRole === 'supervisor' && row.role !== 'supervisor') ? (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              >
                                {editUser === row.username ? (
                                  <>
                                    <Tooltip
                                      arrow
                                      placement="top-start"
                                      title={
                                        <Typography
                                          style={{
                                            letterSpacing: '0.1rem',
                                            textTransform: 'uppercase',
                                            fontSize: theme.fonts.responsiveMedium,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {t('ResultView.btn_save')}
                                        </Typography>
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        className={classes.saveIconList}
                                        onClick={() => {
                                          validateAndSaveUser(row.username);
                                        }}
                                      >
                                        <SaveIcon
                                          fontSize="small"
                                          style={{
                                            width: '20px',
                                            height: '20px',
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      arrow
                                      placement="bottom"
                                      title={
                                        <Typography
                                          style={{
                                            letterSpacing: '0.1rem',
                                            textTransform: 'uppercase',
                                            fontSize: theme.fonts.responsiveMedium,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {t('revision.go_back')}
                                        </Typography>
                                      }
                                    >
                                      <IconButton size="small" onClick={() => setEditUser(null)}>
                                        <ReplayIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <Tooltip
                                      arrow
                                      placement="top-start"
                                      title={
                                        <Typography
                                          style={{
                                            letterSpacing: '0.1rem',
                                            textTransform: 'uppercase',
                                            fontSize: theme.fonts.responsiveMedium,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {t('UserPanel.edit')}
                                        </Typography>
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setEditUser(row.username);
                                          setAliases(row.aliases);
                                          setPassword('');
                                        }}
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      arrow
                                      placement="bottom"
                                      title={
                                        <Typography
                                          style={{
                                            letterSpacing: '0.1rem',
                                            textTransform: 'uppercase',
                                            fontSize: theme.fonts.responsiveMedium,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {t('UserPanel.delete')}
                                        </Typography>
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          dispatch(
                                            setActionToConfirm({
                                              action: () => deleteUser(row.username),
                                              text: `${t('UserPanel.confirm_delete')} ${row.username}?`,
                                            })
                                          )
                                        }
                                      >
                                        <Delete fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                              </TableCell>
                            ) : (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              />
                            );

                          if (head.key === 'active')
                            return (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              >
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={
                                    <>
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          textTransform: 'uppercase',
                                          fontSize: theme.fonts.responsiveMedium,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {!!row.active ? t('UserPanel.enable') : t('UserPanel.disable')}
                                      </Typography>
                                      <Divider style={{ margin: '3% 0' }} />
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          fontSize: theme.fonts.responsiveMedium,
                                        }}
                                      >
                                        {!!row.active
                                          ? t('UserPanel.enable_click')
                                          : t('UserPanel.disable_click')}
                                      </Typography>
                                    </>
                                  }
                                >
                                  <span>
                                    {!!row.active ? (
                                      <Chip
                                        clickable
                                        variant="outlined"
                                        label={t('UserPanel.enable')}
                                        size="small"
                                        disabled={currentRole === 'supervisor' && row.role === 'supervisor'}
                                        onClick={() => updateUser(row.username, row.active, 'active')}
                                        style={{
                                          color: '#18cc73',
                                          border: `1px solid #18cc73`,
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        clickable
                                        variant="default"
                                        label={t('UserPanel.disable')}
                                        size="small"
                                        color="primary"
                                        disabled={currentRole === 'supervisor' && row.role === 'supervisor'}
                                        onClick={() => updateUser(row.username, row.active, 'active')}
                                      />
                                    )}
                                  </span>
                                </Tooltip>
                              </TableCell>
                            );

                          if (head.key === 'annotation_permission')
                            return (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              >
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={
                                    <>
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          textTransform: 'uppercase',
                                          fontSize: theme.fonts.responsiveMedium,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {!!row.annotation_permission
                                          ? t('UserPanel.annotating')
                                          : t('UserPanel.no_annotating')}
                                      </Typography>
                                      <Divider style={{ margin: '3% 0' }} />
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          fontSize: theme.fonts.responsiveMedium,
                                        }}
                                      >
                                        {!!row.annotation_permission
                                          ? t('UserPanel.annotating_click')
                                          : t('UserPanel.no_annotating_click')}
                                      </Typography>
                                    </>
                                  }
                                  aria-label="add"
                                >
                                  <span>
                                    <IconButton
                                      size="small"
                                      disabled={currentRole === 'supervisor' && row.role === 'supervisor'}
                                      onClick={() =>
                                        updatePermission(
                                          row.username,
                                          'annotation',
                                          !!row.annotation_permission
                                        )
                                      }
                                      style={{
                                        color: !!row.annotation_permission ? '#18cc73' : 'inherit',
                                      }}
                                    >
                                      <Spellcheck fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            );

                          if (head.key === 'diagnostics_permission')
                            return (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              >
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={
                                    <>
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          textTransform: 'uppercase',
                                          fontSize: theme.fonts.responsiveMedium,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {!!row.diagnostics_permission
                                          ? t('UserPanel.diagnostics')
                                          : t('UserPanel.no_diagnostics')}
                                      </Typography>
                                      <Divider style={{ margin: '3% 0' }} />
                                      <Typography
                                        style={{
                                          letterSpacing: '0.1rem',
                                          fontSize: theme.fonts.responsiveMedium,
                                        }}
                                      >
                                        {!!row.diagnostics_permission
                                          ? t('UserPanel.diagnostics_click')
                                          : t('UserPanel.no_diagnostics_click')}
                                      </Typography>
                                    </>
                                  }
                                  aria-label="add"
                                >
                                  <span>
                                    <IconButton
                                      size="small"
                                      disabled={currentRole === 'supervisor' && row.role === 'supervisor'}
                                      onClick={() =>
                                        updatePermission(
                                          row.username,
                                          'diagnostics',
                                          !!row.diagnostics_permission
                                        )
                                      }
                                      style={{
                                        color: !!row.diagnostics_permission ? '#18cc73' : 'inherit',
                                      }}
                                    >
                                      {row.diagnostics_permission ? (
                                        <DoneIcon fontSize="small" />
                                      ) : (
                                        <BlockIcon fontSize="small" />
                                      )}
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            );

                          if (head.key === 'aliases')
                            if (editUser === row.username)
                              return (
                                <TableCell
                                  className={classes.tableGeneralTextField}
                                  align={head.align}
                                  key={`${row.username}-${head.key}`}
                                >
                                  <TextField
                                    name="aliases"
                                    label="Aliases"
                                    size="small"
                                    color="secondary"
                                    value={aliases}
                                    onChange={handleAliasesChange}
                                    variant="outlined"
                                    fullWidth
                                    helperText={t('users.helper_alias')}
                                    FormHelperTextProps={{
                                      classes: { root: classes.helperText },
                                    }}
                                  />
                                </TableCell>
                              );
                            else
                              return (
                                <TableCell
                                  className={classes.tableGeneral}
                                  align={head.align}
                                  key={`${row.username}-${head.key}`}
                                >
                                  <Grid item>
                                    {row?.aliases?.split(';').map((alias, j) => (
                                      <Chip
                                        key={`${row.username}-${alias}-${j}`}
                                        className={classes.chipSmall}
                                        size="small"
                                        label={alias.trim() ? alias.trim() : '-'}
                                      />
                                    ))}
                                  </Grid>
                                </TableCell>
                              );

                          if (head.key === 'role' && editUser === row.username)
                            return (
                              <TableCell
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                                style={{ minWidth: '150px' }}
                              >
                                <FormControl
                                  style={{
                                    marginTop: '3%',
                                    width: '90%',
                                    zIndex: 2000,
                                  }}
                                >
                                  <Select
                                    value={row.role}
                                    label="Role"
                                    onChange={(event) => updateUser(row.username, event.target.value, 'role')}
                                    style={{ zIndex: 2200 }}
                                  >
                                    <MenuItem value="technician">{t('users.Technician')}</MenuItem>
                                    <MenuItem value="radiologist">{t('users.Radiologist')}</MenuItem>
                                    {currentRole === 'admin' && (
                                      <MenuItem value="supervisor">{t('users.Supervisor')}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </TableCell>
                            );

                          if (head.key === 'status')
                            return !!row?.ldap_name ? (
                              <TableCell
                                className={classes.tableGeneral}
                                align="center"
                                key={`${row.username}-${head.key}`}
                              >
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={
                                    <Typography
                                      style={{
                                        letterSpacing: '0.1rem',
                                        textTransform: 'uppercase',
                                        fontSize: theme.fonts.responsiveMedium,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      AD (Active Directory)
                                    </Typography>
                                  }
                                >
                                  <img
                                    src={ADIcon}
                                    alt="AD Icon"
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      marginRight: '10%',
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            ) : (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              />
                            );

                          if (head.key === 'password')
                            return editUser && editUser === row.username && currentRole === 'admin' ? (
                              <TableCell
                                className={classes.tableGeneralTextField}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              >
                                <TextField
                                  fullWidth
                                  size="small"
                                  color="secondary"
                                  type={passwordVisibility ? 'text' : 'password'}
                                  name="password"
                                  autoComplete="off"
                                  autoFocus={false}
                                  onChange={onPasswordChange}
                                  label={t('UserPanel.new')}
                                  variant="outlined"
                                  helperText={shortPasswordError}
                                  FormHelperTextProps={{
                                    classes: { root: classes.helperText },
                                  }}
                                  value={password}
                                  InputProps={{
                                    className: classes.newPasswordField,
                                    style: {
                                      backgroundColor: '#515151',
                                    },
                                    endAdornment: (
                                      <IconButton
                                        size="small"
                                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                                        edge="end"
                                      >
                                        {passwordVisibility ? (
                                          <VisibilityOffIcon fontSize="small" />
                                        ) : (
                                          <VisibilityIcon fontSize="small" />
                                        )}
                                      </IconButton>
                                    ),
                                  }}
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                className={classes.tableGeneral}
                                align={head.align}
                                key={`${row.username}-${head.key}`}
                              />
                            );

                          if (head.key === 'username') {
                            return (
                              <TableCell
                                key={`${i}${row.username}-${head.key}${row.displayed_name}`}
                                className={classes.tableGeneral}
                                align={head.align}
                              >
                                {' '}
                                <Tooltip
                                  title={
                                    <Typography className={classes.tooltip_text_lowercase} color="inherit">
                                      {`${t('UserPanel.login_name')}: ${row.username}`}
                                    </Typography>
                                  }
                                  arrow
                                  interactive
                                  placement="left"
                                >
                                  <Typography>{row.displayed_name}</Typography>
                                </Tooltip>
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell
                              className={classes.tableGeneral}
                              align={head.align}
                              key={`${head.key}-${row.username}`}
                            >
                              {row[head.key] !== undefined ? row[head.key] || 0 : '--'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.table_placeholder}>
                          {t('UserPanel.nothing')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        )}

        <div className={classes.add_user_container}>
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('UserPanel.refresh_ad_users')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
          >
            <span>
              <Fab
                size="small"
                color="secondary"
                aria-label="refresh"
                onClick={onADRefresh}
                disabled={props.newRow}
              >
                <RefreshIcon fontSize="small" />
              </Fab>
            </span>
          </Tooltip>
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('users.add_user')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
          >
            <span>
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                onClick={() => props.setNewRow(true)}
                disabled={props.newRow}
              >
                <AddIcon fontSize="small" />
              </Fab>
            </span>
          </Tooltip>
        </div>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={notification.msg ? true : false}
        onClose={() => setNotification({ msg: '', severity: '' })}
        variant="filled"
        key={notification?.msg}
        autoHideDuration={3000}
      >
        <Alert severity={notification?.severity}>{notification?.msg}</Alert>
      </Snackbar>
    </>
  );
}

export default UsersPanel;
