// Network
let protocol;
if (window.__RUNTIME_CONFIG__.TLS === 'yes' || window.__RUNTIME_CONFIG__.PROXY_TLS === 'yes') {
  protocol = 'https';
} else {
  protocol = 'http';
}

// Network
export let API_ENDPOINT;
export let AI_ENDPOINT;
export let DATA_ENDPOINT;

if (window.__RUNTIME_CONFIG__.NODE_ENV === 'development') {
  API_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:3000`;
  AI_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:5000`;
  DATA_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}:4000`;
} else {
  API_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}/API`;
  AI_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}/AI`;
  DATA_ENDPOINT = `${protocol}://${window.__RUNTIME_CONFIG__.SERVER_IP}/data`;
}

// Frontend
export const ACME = window.__RUNTIME_CONFIG__.ACME;
