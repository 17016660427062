import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateCustomQualityOverlays } from '../../../../../store';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import ParenchymaCuts from './ParenchymaCuts';

const useStyles = makeStyles(() => ({
  contour_overlay_container: {
    zIndex: 3,
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  canvas_overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    userSelect: 'none',
    opacity: 0.7,
    zIndex: 300,
  },

  bounding_rect: {
    border: '1px',
    background: 'transparent',
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 4,
  },
}));

const ContourOverlay = ({ canvasRef, drawImageOnCanvas, proj_name, imgSize, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  // Store state
  const { applied_quality, shown_quality } = useSelector((state) => state.results);

  const { savingPending, customQualityOverlays, qualityInRevisionMode, qualityInDrawingMode } = useSelector(
    (state) => state.labeling
  );
  const { qualityOverlaysToShow } = useSelector((state) => state.images);
  const { presentation_quality } = useSelector((state) => state.global_settings.user_settings);
  const shownQuality = shown_quality || applied_quality;
  const resultQualityEvaluation = useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode);
  const [overlayTypes, setOverlayTypes] = useState({});

  // Local state

  const ccMedialParencShown = props.parenchymaCutsToShow[`${proj_name}Medial`];
  const ccLateralParencShown = props.parenchymaCutsToShow[`${proj_name}Lateral`];

  const severityColor = (severity, defaultColor) => {
    if (severity) {
      if (severity === 'severe') return theme.palette.lesions.skinFoldsSevere;
      else if (severity === 'medium') return theme.palette.lesions.skinFoldsMedium;
      else if (severity === 'mild') return theme.palette.lesions.skinFoldsMild;
    } else return defaultColor;
  };

  // Methods
  const drawOverlays = useCallback(() => {
    if (savingPending || !resultQualityEvaluation) return;

    if (!canvasRef || !canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, imgSize[0], imgSize[1]);

    for (let [type, { color, show }] of Object.entries(overlayTypes)) {
      if (!show) continue;

      if (
        proj_name &&
        customQualityOverlays &&
        proj_name in customQualityOverlays &&
        type in customQualityOverlays[proj_name]
      ) {
        let imageDataURL = customQualityOverlays[proj_name][type];
        if (imageDataURL === 'clear_me') {
          context.clearRect(0, 0, imgSize[0], imgSize[1]);
          imageDataURL = canvasRef.current.toDataURL('image/png');
          dispatch(updateCustomQualityOverlays(type, proj_name, imageDataURL));
          return;
        }
        _drawImageOnCanvas(proj_name, imageDataURL, canvasRef.current);
      } else if (resultQualityEvaluation?.[proj_name]?.[type]) {
        const presentation = qualityInDrawingMode ? 'overlay' : presentation_quality;

        drawAllClusters(resultQualityEvaluation[proj_name][type], presentation, context, color);
      }
    }
  }, [
    savingPending,
    overlayTypes,
    customQualityOverlays,
    resultQualityEvaluation,
    proj_name,
    canvasRef?.current?.width,
    canvasRef?.current?.height,
  ]);

  const drawAllClusters = (clusterList, drawAs, context, color) => {
    let fill;
    if (drawAs?.includes('overlay')) {
      fill = true;
    } else if (drawAs?.includes('contour')) {
      fill = false;
    } else {
      console.log(`Bad drawing type: ${drawAs}`);
      return;
    }

    for (let cluster of clusterList) {
      drawCluster(cluster, context, color, fill);
    }
  };

  const drawCluster = (overlay, context, color, fill = true) => {
    for (let contour of overlay.contours) {
      drawShape(contour, context, severityColor(overlay?.severity, color), fill);
    }
  };

  const _drawImageOnCanvas = (projName, imageDataUrl, canvas) => {
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
    };

    img.src = imageDataUrl;
  };

  const drawShape = (points, context, color, fill) => {
    context.globalCompositeOperation = 'source-over';

    context.beginPath();
    const x0 = points[0][0] * imgSize[0];
    const x1 = points[0][1] * imgSize[1];
    context.moveTo(x0, x1);

    let x;
    let y;
    for (let i = 1; i < points.length; i++) {
      x = points[i][0] * imgSize[0];
      y = points[i][1] * imgSize[1];
      context.lineTo(x, y);
    }
    context.closePath();

    if (fill) {
      context.fillStyle = color;
      context.fill();
    } else {
      context.lineWidth = 10;
      context.strokeStyle = color;
      context.stroke();
    }
  };

  // Effects
  useEffect(() => {
    const newOverlayTypes = {
      clips: {
        color: theme.palette.lesions.clips,
        show: qualityOverlaysToShow['clips' + proj_name.toUpperCase()],
      },
      parenchyma: {
        color: theme.palette.lesions.parenchymaProj,
        show: qualityOverlaysToShow['parenchyma' + proj_name.toUpperCase()],
      },
      skinFolds: {
        color: theme.palette.lesions.skinFolds,
        show: qualityOverlaysToShow['skinFolds' + proj_name.toUpperCase()],
      },
      pectoralis: {
        color: theme.palette.lesions.pectoralisProj,
        show: qualityOverlaysToShow['pectoralis' + proj_name.toUpperCase()],
      },
      blur: {
        color: theme.palette.lesions.blur,
        show: qualityOverlaysToShow['blur' + proj_name.toUpperCase()],
      },
    };
    setOverlayTypes(newOverlayTypes);
  }, [qualityOverlaysToShow, presentation_quality]);

  useEffect(() => {
    drawOverlays();
  }, [drawOverlays]);

  const drawBoundingBoxes = (lesionType, color) => {
    if (!resultQualityEvaluation) {
      return null;
    } else {
      return resultQualityEvaluation?.[proj_name]?.[lesionType]?.map((rect, i) => {
        let [x, y, w, h] = rect?.box;

        const textTop = y <= 0.03 ? '2%' : `calc(${100 * y - 7}%)`;
        const textLeft = proj_name?.includes('r')
          ? x > 0.8
            ? `calc(${100 * (x + w)}% - 43px)`
            : `calc(${100 * x}%)`
          : x > 0.8
          ? `calc(${100 * (x + w)}% - 20px)`
          : `calc(${100 * x}%)`;

        const pKey = `${lesionType}${i}p${proj_name}`;

        return (
          <React.Fragment key={pKey}>
            {rect?.location && (
              <p
                style={{
                  left: textLeft,
                  top: textTop,
                  minWidth: '40px',
                  height: '10px',
                  position: 'absolute',
                  fontSize: '12px',
                  borderRadius: '5px',
                  padding: '2px',
                  color: 'rgb(235 111 255)',
                }}
              >
                {rect?.location}
              </p>
            )}

            <div
              className={classes.bounding_rect}
              style={{
                borderStyle: 'solid',
                borderColor: color,
                left: `${100 * x}%`,
                top: `${100 * y}%`,
                width: `${100 * w}%`,
                height: `${100 * h}%`,
                position: 'absolute',
              }}
            />
          </React.Fragment>
        );
      });
    }
  };

  return (
    <div
      className={classes.contour_overlay_container}
      onWheel={props.onWheel}
      onMouseDown={props.onCanvasClick}
      onMouseMove={props.onMouseMove}
      onMouseUp={props.onMouseUp}
      onMouseLeave={props.onMouseLeave}
    >
      <canvas
        key="overlays"
        className={classes.canvas_overlay}
        ref={canvasRef}
        width={imgSize[0]}
        height={imgSize[1]}
      />

      {/* Parenchyma and Cuts  */}
      {(props.parenchymaCutsToShow[proj_name] || ccMedialParencShown || ccLateralParencShown) && (
        <ParenchymaCuts
          showMLO={props.parenchymaCutsToShow[proj_name]}
          showMedial={ccMedialParencShown}
          showLateral={ccLateralParencShown}
          proj_name={proj_name}
          imgSize={imgSize}
        />
      )}

      {Object.keys(overlayTypes).map(
        (type, index) =>
          overlayTypes[type].show &&
          presentation_quality?.includes('boxes') &&
          !qualityInRevisionMode && (
            <React.Fragment key={type + index}>
              {drawBoundingBoxes(type, overlayTypes[type].color)}
            </React.Fragment>
          )
      )}
    </div>
  );
};

export default ContourOverlay;
