import React, { useEffect, useState, useCallback, useRef } from 'react';

// helpers
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../ExaminationTable/ExaminationTable.style';
import * as actions from '../../../../../store/index';
import { formatSimpleDateHour } from '../../../../../helpers/formatDate';
import { formatPatientName } from '../../../../../helpers/misc';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
  Tooltip,
  Divider,
} from '@material-ui/core';

// icons
import LayersIcon from '@material-ui/icons/Layers';
import NotIcon from '@material-ui/icons/NotInterested';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import animation from '../../../../../resources/processing_animation.gif';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ExaminationTableActions from '../ExaminationTable/Helpers/ExaminationTableActions';
import { examinationTooltips } from '../ExaminationTable/Helpers/ExaminationTableCommon';
import ExaminationTableRisk from '../ExaminationTable/Helpers/ExaminationTableRisk';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RepeatIcon from '@material-ui/icons/Repeat';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { movePriorExaminations } from '../../../../../store/index';
import { fetchPost } from '../../../../../helpers/fetching';

const ExaminationTableRows = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const currentRowRef = useRef(null);
  const endListRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentRole = localStorage.getItem('role');

  const { localChangesDensity, localChangesQuality, localChangesMicrocalc, localChangesOpacities } =
    useSelector((state) => state.labeling);
  const {
    currentExam,
    exam_list,
    fetchingExamList,
    pendingReprocessing,
    totalExaminations,
    pagination,
    priors_list,
  } = useSelector((state) => state.examinations);
  const { diagnostics_microcalc_module, diagnostics_opacities_module, density_module, risk_module } = useSelector((state) => state.global_settings.premium_features);

  const [showIcons, setShowIcons] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredPriorIndex, setHoveredPriorIndex] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isHidden, setIsHidden] = useState(true);

  const managementAccess = currentRole === 'admin' || currentRole === 'supervisor' || currentRole === 'radiologist';

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHidden(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const examsWithCurrentId = props.rows.filter((row) => row.some((exam) => exam.id === currentExam));
    const hasMultipleExams = examsWithCurrentId?.[0]?.length > 1;

    if (currentExam && hasMultipleExams) {
      examsWithCurrentId.forEach((row) => {
        const patientId = row[0]?.patient_id || row[1]?.patient_id;
        if (patientId) {
          setExpandedRows((prevState) => ({
            ...prevState,
            [patientId]: true,
          }));
        }
      });
    }
  }, [props.rows, currentExam]);

  const handleRowClick = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const handleHover = useCallback((id) => {
    setHoveredIndex(id);
    setShowIcons(true);
  }, []);

  const handleLeave = useCallback(() => {
    setHoveredIndex(null);
    setShowIcons(false);
  }, []);

  useEffect(() => {
    const examsWithCurrentId = props.rows.filter((row) => row.some((exam) => exam.id === currentExam));
    const hasMultipleExams = examsWithCurrentId?.[0]?.length > 1;

    if (currentExam && hasMultipleExams) {
      examsWithCurrentId.forEach((row) => {
        const patientId = row[0]?.patient_id || row[1]?.patient_id;
        if (patientId) {
          setExpandedRows((prevState) => ({
            ...prevState,
            [patientId]: true,
          }));
        }
      });
    }
  }, [props.rows, currentExam]);

  const nestedPatientSelected = (nestedRows) => {
    const examExists = nestedRows.some((row) => row.id === currentExam);
    return examExists;
  };

  const fetchMorePatients = () => {
    dispatch(actions.setPagination([pagination[0], pagination[1] + 30]));
  };

  const onRowClick = (currentId, isPrior) => {
    console.log('Row Click', { currentId, isPrior });
    dispatch(actions.setScrollPosition(props.currentTableRow));

    if (localChangesDensity || localChangesQuality || localChangesMicrocalc || localChangesOpacities) {
      dispatch(actions.setActiveDialog('unsavedChanges'));
    } else {
      if (isPrior) {
        dispatch(
          actions.setActionToConfirm({
            action: () => {
              dispatch(movePriorExaminations([currentId], t));
            },
            text: t('Confirm.move_prior_confirm'),
            dialogTitle: t('Confirm.move_prior_title'),
            buttonOk: t('Confirm.move_prior_btn'),
          })
        );
      } else {
        dispatch(actions.showExamination(currentId));
      }
    }
  };

  const onPriorIconClick = (patId, isRowExpanded, nestedRows) => {
    if (isRowExpanded || nestedRows <= 1 || !nestedRows) {
      dispatch(
        actions.movePriorExaminations(
          priors_list[patId].map((prior) => prior.id),
          t
        )
      );
    } else {
      handleRowClick(patId);
    }
  };

  const nestedTable = (item, i) => {
    //Nested table
    return (
      <TableRow
        onMouseOver={() => handleHover(item.id)}
        onMouseLeave={handleLeave}
        className={item.id === currentExam ? classes.tr_nested_hover_selected : classes.tr_nested_hover}
        hover
        key={`${item.id}${item.patient_id}${i}`}
        selected={item.id === currentExam}
        ref={item.id === currentExam ? currentRowRef : null}
      >
        <TableCell
          className={classes.tr_nested}
          style={{
            padding: '1px !important',
            width: '4%',
          }}
        >
          <div style={{ width: '14px' }} />
        </TableCell>

        {/* Name */}
        <TableCell
          className={classes.tr_nested}
          key="patient_name"
          align="left"
          onClick={() => onRowClick(item.id, item.is_prior)}
          style={{
            fontWeight: item.id === currentExam && 'bold',
            width: '100%',
            marginLeft: '2px',
            color: item.is_prior && '#999999',
          }}
        >
          {item.is_prior ? (
            <Tooltip
              arrow
              placement="right-start"
              title={
                <Typography className={classes.tooltip_texts}>
                  {`${t('Table.prior_exam')}.
                  ${t('Table.move_unique_prior')}`}
                </Typography>
              }
            >
              <span>{formatPatientName(item.name)}</span>
            </Tooltip>
          ) : (
            formatPatientName(item.name)
          )}
        </TableCell>

        {/* Priors icon */}
        <TableCell className={classes.tr_nested} style={{ width: '18px', padding: '0' }}>
          {item.is_prior &&
            examinationTooltips(
              <>
                <Typography className={classes.tooltip_texts}>{t('Table.prior_exam')}</Typography>
                <Divider style={{ margin: '3%' }} />
                <Typography className={classes.tooltip_texts}>
                  <TouchAppIcon fontSize="small" />
                  {t('Table.move_unique_prior')}
                </Typography>
              </>,
              <IconButton
                aria-label="priors_row"
                size="small"
                onClick={() => dispatch(movePriorExaminations([item.id], t))}
                style={{
                  width: '20px',
                  padding: '0',
                }}
                className={classes.hoverEffect}
              >
                <GetAppSharpIcon
                  fontSize="small"
                  style={{
                    width: '18px',
                    fill: '#dddddd',
                  }}
                />
              </IconButton>,
              'right-start'
            )}
        </TableCell>

        {/* Info */}
        <TableCell
          className={classes.tr_nested}
          align="center"
          key="icons"
          onClick={() => onRowClick(item.id, item.is_prior)}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '30px',
            }}
          >
            {item?.density_confirmed ||
            item?.quality_confirmed ||
            item?.microcalc_confirmed ||
            item?.opacities_confirmed
              ? examinationTooltips(
                  <>
                    {density_module && (
                      <Typography className={classes.tooltip_texts_margin}>
                        {t('ResultView.density')}:
                        {item?.density_confirmed
                          ? t('Table.module_confirmed')
                          : t('Table.module_not_confirmed')}
                      </Typography>
                    )}
                    <Typography className={classes.tooltip_texts_margin}>
                      {t('ResultView.quality')}:
                      {item?.quality_confirmed
                        ? t('Table.module_confirmed')
                        : t('Table.module_not_confirmed')}
                    </Typography>
                    {diagnostics_opacities_module && localStorage.getItem('diagnostics') === 'true' && (
                      <Typography className={classes.tooltip_texts_margin}>
                        {t('ResultView.diagnose_opacities')}:
                        {item?.opacities_confirmed
                          ? t('Table.module_confirmed')
                          : t('Table.module_not_confirmed')}
                      </Typography>
                    )}
                    {diagnostics_microcalc_module && localStorage.getItem('diagnostics') === 'true' && (
                      <Typography className={classes.tooltip_texts_margin}>
                        {t('ResultView.diagnose_microcalc')}:
                        {item?.microcalc_confirmed
                          ? t('Table.module_confirmed')
                          : t('Table.module_not_confirmed')}
                      </Typography>
                    )}
                  </>,
                  <HowToRegIcon
                    fontSize="inherit"
                    style={{
                      opacity: fetchingExamList && '0.5',
                    }}
                  />,
                  'top-start'
                )
              : ''}
            {showIcons && item.id === hoveredIndex && (
              <>
                {item?.max_captures_per_projection > 1 &&
                  examinationTooltips(
                    <Typography className={classes.tooltip_texts}>{t('Table.captures')}</Typography>,
                    <LayersIcon fontSize="inherit" />
                  )}
                {!!item?.not_evaluated &&
                  examinationTooltips(
                    <Typography className={classes.tooltip_texts}>{t('Table.not_eval')}</Typography>,
                    <NotIcon fontSize="inherit" />
                  )}
              </>
            )}
          </div>
        </TableCell>

        {/* Date */}
        <TableCell
          align="center"
          key="date"
          onClick={() => onRowClick(item.id, item.is_prior)}
          style={{
            fontWeight: item.id === currentExam && 'bold',
            color: item.is_prior && '#999999',
          }}
          className={classes.tr_nested}
        >
          {examinationTooltips(
            formatSimpleDateHour(item.date).hour,
            <Typography
              className={classes.table_date_font}
              style={{
                fontWeight: item.id === currentExam && 'bold',
              }}
            >
              {formatSimpleDateHour(item.date).date}
            </Typography>,
            'top-start'
          )}
        </TableCell>

        {/* Risk (shown or hidden from .env)*/}
        <TableCell
          className={classes.tr_padding_nested}
          key="risk"
          align="right"
          onClick={() => onRowClick(item.id, item.is_prior)}
        >
          {risk_module && (
            <ExaminationTableRisk exam_id={item.id} form_filled={item.form_filled} exam_list={exam_list} />
          )}
        </TableCell>

        {/* Actions */}
        <ExaminationTableActions
          study_id={item.id}
          patient_id={item.patient_id}
          isNestedExam={true}
          nestedRowTitle={false}
          singleExam={false}
          prior={item.is_prior}
        />
      </TableRow>
    );
  };

  return (
    <TableBody style={{ cursor: fetchingExamList ? 'progress' : 'pointer' }}>
      {fetchingExamList && (
        <TableRow
          style={{
            position: 'absolute',
            top: '50%',
            opacity: '0.6',
            width: '99%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TableCell style={{ border: 'none' }}>
            <img
              style={{
                display: 'block',
                margin: 'auto',
                width: 'auto',
                height: '100px',
                borderRadius: '50%',
              }}
              src={animation}
              alt="logoanimation"
            />
          </TableCell>
        </TableRow>
      )}
      {props.rows.map((row, idx) => {
        const {
          id: study_id,
          name,
          date,
          density_confirmed,
          quality_confirmed,
          opacities_confirmed,
          microcalc_confirmed,
          not_evaluated,
          form_filled,
          patient_bd,
          max_captures_per_projection,
          patient_id,
          priors_found,
        } = row[0];

        const isRowExpanded = expandedRows[patient_id] || false;
        return (
          <React.Fragment key={idx + name}>
            <TableRow
              key={idx + name}
              hover
              onMouseOver={() => handleHover(study_id)}
              onMouseLeave={handleLeave}
              className={classes.tr_hover}
              selected={study_id === currentExam}
              style={{
                background: isRowExpanded && theme.palette.complementary.highlightLight,
              }}
            >
              {/* Expand icon */}
              <TableCell className={classes.tr_padding} style={{ padding: '0' }}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(patient_id)}>
                  {row.length > 1 &&
                    (isRowExpanded ? (
                      <ExpandLessIcon fontSize="small" />
                    ) : (
                      <ExpandMoreIcon fontSize="small" />
                    ))}
                </IconButton>
              </TableCell>
              {/* Name */}
              <TableCell
                className={classes.table_cell_container}
                component="th"
                align="left"
                onClick={() => onRowClick(study_id)}
                style={{ width: '100%', color: fetchingExamList && '#a1a1a1' }}
              >
                {examinationTooltips(
                  <Typography className={classes.tooltip_texts}>
                    {formatSimpleDateHour(patient_bd).date}
                  </Typography>,
                  <Typography
                    className={
                      (!isRowExpanded && study_id === currentExam) ||
                      (row.length > 1 && nestedPatientSelected(row) && !isRowExpanded)
                        ? classes.row_selected
                        : classes.table_font
                    }
                  >
                    {formatPatientName(name)}
                  </Typography>,
                  'top'
                )}
              </TableCell>

              {/* Priors icon */}
              <TableCell className={classes.tr_padding} style={{ width: '18px', padding: '0' }}>
                {priors_found &&
                  examinationTooltips(
                    isRowExpanded ? (
                      <>
                        <Typography className={classes.tooltip_texts}>
                          {t('Table.some_priors_listed')}
                        </Typography>
                        <Divider style={{ margin: '3%' }} />
                        <Typography className={classes.tooltip_texts}>
                          <TouchAppIcon fontSize="small" />
                          {t('Table.move_all_priors')}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography className={classes.tooltip_texts}>{t('Table.some_priors')}</Typography>
                        <Divider style={{ margin: '3%' }} />
                        {row.length > 1 ? (
                          <>
                            <Typography className={classes.tooltip_texts}>
                              <TouchAppIcon fontSize="small" />
                              {t('Table.expand_row')}
                            </Typography>
                          </>
                        ) : (
                          <Typography className={classes.tooltip_texts}>
                            <TouchAppIcon fontSize="small" />
                            {t('Table.move_all_priors')}
                          </Typography>
                        )}
                      </>
                    ),
                    <IconButton
                      aria-label="priors_row"
                      size="small"
                      onMouseEnter={() => setHoveredPriorIndex(study_id)}
                      onMouseLeave={() => setHoveredPriorIndex(null)}
                      onClick={() => onPriorIconClick(patient_id, isRowExpanded, row.length)}
                      className={isRowExpanded || row.length <= 1 ? classes.hoverEffect : ''}
                      style={{
                        width: '20px',
                        padding: '0',
                      }}
                    >
                      {(isRowExpanded || row.length <= 1) && hoveredPriorIndex === study_id ? (
                        <GetAppSharpIcon fontSize="small" style={{ width: '18px' }} />
                      ) : (
                        <RepeatIcon fontSize="small" style={{ width: '18px' }} />
                      )}
                    </IconButton>,
                    'bottom-start'
                  )}
              </TableCell>

              {/* Info */}
              {row.length > 1 ? (
                <TableCell
                  className={classes.tr_padding}
                  align="right"
                  onClick={() => onRowClick(study_id)}
                  style={{
                    width: '30px',
                  }}
                />
              ) : (
                <TableCell className={classes.tr_padding} align="right" onClick={() => onRowClick(study_id)}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '30px',
                    }}
                  >
                    {[density_confirmed, quality_confirmed, opacities_confirmed, microcalc_confirmed].some(
                      (a) => !!a
                    ) &&
                      examinationTooltips(
                        <>
                          {density_module && (
                            <Typography className={classes.tooltip_texts_margin}>
                              {t('ResultView.density')}:
                              {density_confirmed
                                ? t('Table.module_confirmed')
                                : t('Table.module_not_confirmed')}
                            </Typography>
                          )}
                          <Typography className={classes.tooltip_texts_margin}>
                            {t('ResultView.quality')}:
                            {quality_confirmed
                              ? t('Table.module_confirmed')
                              : t('Table.module_not_confirmed')}
                          </Typography>
                          {diagnostics_opacities_module && localStorage.getItem('diagnostics') === 'true' && (
                            <Typography className={classes.tooltip_texts_margin}>
                              {t('ResultView.diagnose_opacities')}:
                              {opacities_confirmed
                                ? t('Table.module_confirmed')
                                : t('Table.module_not_confirmed')}
                            </Typography>
                          )}
                          {diagnostics_microcalc_module && localStorage.getItem('diagnostics') === 'true' && (
                            <Typography className={classes.tooltip_texts_margin}>
                              {t('ResultView.diagnose_microcalc')}:
                              {microcalc_confirmed
                                ? t('Table.module_confirmed')
                                : t('Table.module_not_confirmed')}
                            </Typography>
                          )}
                        </>,
                        <HowToRegIcon
                          fontSize="inherit"
                          style={{
                            opacity: fetchingExamList && '0.5',
                            // fill: 'rgb(76, 175, 80)',
                          }}
                        />
                      )}
                    {showIcons && study_id === hoveredIndex && (
                      <>
                        {max_captures_per_projection > 1 &&
                          examinationTooltips(
                            <Typography className={classes.tooltip_texts}>{t('Table.captures')}</Typography>,
                            <LayersIcon fontSize="inherit" style={{ opacity: fetchingExamList && '0.5' }} />
                          )}
                        {!!not_evaluated &&
                          examinationTooltips(
                            <Typography className={classes.tooltip_texts}>{t('Table.not_eval')}</Typography>,
                            <NotIcon fontSize="inherit" style={{ opacity: fetchingExamList && '0.5' }} />
                          )}
                      </>
                    )}
                  </div>
                </TableCell>
              )}
              {/* Date */}
              {isRowExpanded ? (
                <TableCell className={classes.table_cell_container} align="center" />
              ) : (
                <TableCell
                  className={classes.table_cell_container}
                  align="center"
                  onClick={() => onRowClick(study_id)}
                  style={{ color: fetchingExamList && '#a1a1a1' }}
                >
                  {examinationTooltips(
                    <Typography className={classes.tooltip_texts}>
                      {formatSimpleDateHour(date).hour}
                    </Typography>,
                    <Typography
                      className={
                        study_id === currentExam || nestedPatientSelected(row)
                          ? classes.row_selected
                          : classes.table_date_font
                      }
                    >
                      {formatSimpleDateHour(date).date}
                    </Typography>,
                    'top-start'
                  )}
                </TableCell>
              )}
              {/* Risk */}
              {isRowExpanded ? (
                <TableCell className={classes.tr_padding} align="center" />
              ) : (
                <TableCell className={classes.tr_padding} align="center" onClick={() => onRowClick(study_id)}>
                  {risk_module && (
                    <ExaminationTableRisk
                      exam_id={study_id}
                      currentExam={currentExam}
                      form_filled={form_filled}
                    />
                  )}
                </TableCell>
              )}
              {/* Actions */}
              <ExaminationTableActions
                study_id={study_id}
                patient_id={patient_id}
                isNestedExam={false}
                nestedRowTitle={isRowExpanded && row.length > 1}
                singleExam={row.length >= 1}
                prior={false}
              />
            </TableRow>

            {/* Nested examinations row container */}
            {row.length > 1 && (
              <TableRow>
                <TableCell
                  style={{
                    padding: '0',
                    backgroundColor: theme.palette.complementary.highlightDarker,
                    borderLeft: `6px solid ${theme.palette.complementary.highlightLight}`,
                  }}
                  colSpan={7}
                >
                  <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="nestedTable">
                      <TableBody>{row.map((item, i) => nestedTable(item, i))}</TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}

      <TableRow>
        {fetchingExamList ? (
          <TableCell colSpan={6} align="center" style={{ borderBottom: 'none', opacity: '0.6' }}>
            <CircularProgress color="primary" size={14} />
          </TableCell>
        ) : (
          <TableCell
            colSpan={7}
            ref={endListRef}
            align="center"
            style={{
              borderBottom: 'none',
              borderTop: `4px solid ${theme.palette.complementary.dark}`,
              height: '20px',
            }}
          >
            {exam_list.length < totalExaminations && !fetchingExamList && (
              <Tooltip title="Fetch more examinations" placement="bottom" arrow>
                <span>
                  <IconButton
                    size="small"
                    onClick={() => fetchMorePatients()}
                    className={isHidden ? classes.hidden : ''}
                  >
                    <ControlPointIcon color="disabled" fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableBody>
  );
};

export default ExaminationTableRows;
