/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Typography,
  Grid,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableCell,
  TableBody,
  TableRow,
  Table,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  lesionExists,
  tableRowClickHandler,
  handleMouseEnterRow,
  handleMouseLeaveRow,
  handleDragEnd,
} from '../Helpers/DiagnosticRevisionTableHelpers';
import { useStyles } from '../DiagnosticRevisionTable.style';
import { ModuleTableHead, RowIconStatus } from './ModulesCommon.js/TableCommon';
import {
  deleteFinding,
  getMatchedProj,
  severityColor,
  severityTableHeadColor,
} from '../Helpers/DiagnosticRevisionTableCommon';
import ModuleTableActions from './ModulesCommon.js/ModuleTableActions';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import InfoIcon from '@material-ui/icons/Info';

const OpacitiesTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const results = useSelector((state) => state.results);
  const labeling = useSelector((state) => state.labeling);
  const { applied_opacities, confirmed_opacities, shown_opacities } = useSelector((state) => state.results);

  const { zoomedProjection, darkState } = useSelector((state) => state.gui);

  const [isDraging, setIsDraging] = useState(false);
  const [dragSource, setDragSource] = useState(null);

  const isOpacitiesConfirmed = applied_opacities === 'bbox' && confirmed_opacities;
  const isOpacitiesRelabeled = confirmed_opacities && applied_opacities !== 'bbox';

  const opacitiesShown = shown_opacities || applied_opacities;
  const resultOpacitiesEvaluation = results?.evaluations?.opacities?.[shown_opacities];
  const { customOpacities, singleBoxHover, groupedOpacities, localChangesOpacities } = labeling;
  const opacitiesState = shown_opacities === applied_opacities && confirmed_opacities;
  const opacitiesEmpty = lesionExists(customOpacities);

  const tableOpacitiesRowData = (boxes, baseIndex) => {
    return boxes.map((lesionInfo, idx) => {
      const lesionAllInfo = customOpacities?.[lesionInfo?.[0]]?.[lesionInfo?.[1]]?.[lesionInfo?.[2]];
      const hoverBoxId = `${lesionInfo?.[0]?.[0]}${baseIndex + 1}`;

      const lesionClass =
        lesionAllInfo?.predicted_class === 'lesionKnown'
          ? t('projections.lesionKnown')
          : `BI-RADS ${lesionAllInfo?.predicted_class?.slice(6, 7)}`;

      const reassignedInfo = lesionAllInfo?.reassigned
        ? lesionAllInfo?.reassigned?.prevType === 'opacities'
          ? `BI-RADS ${lesionAllInfo?.reassigned?.prevClass?.slice(6, 7)}`
          : lesionAllInfo?.reassigned?.prevClass === 'birads5' ||
            lesionAllInfo?.reassigned?.prevClass === 'birads4'
          ? t('diagnose.type_microcalc_malignant')
          : t('diagnose.type_microcalc_benign')
        : null;

      const rowId = lesionInfo
        ? `${boxes?.[idx]?.[2]}${boxes?.[idx]?.[0]}${boxes?.[idx]?.[1]}`
        : `${baseIndex}`;

      const matchInfo = lesionAllInfo?.match
        ? `${lesionAllInfo?.match?.[1]}${getMatchedProj(lesionInfo?.[0])}${lesionAllInfo?.match?.[0]}`
        : '-';

      return (
        <React.Fragment key={rowId}>
          {lesionInfo ? (
            <Draggable key={rowId} draggableId={rowId} index={baseIndex * 10 + idx}>
              {(provided) => (
                <TableRow
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  hover
                  className={
                    singleBoxHover?.box === hoverBoxId ? classes.table_row_selected : classes.table_row
                  }
                  onDoubleClick={() =>
                    tableRowClickHandler(lesionInfo[0], dispatch, actions, zoomedProjection)
                  }
                  onMouseEnter={() => handleMouseEnterRow('opacities', hoverBoxId, dispatch, actions)}
                  onMouseLeave={() => handleMouseLeaveRow(dispatch, actions)}
                >
                  {/* <TableCell className={classes.table_cell_projection} style={{ fontSize: '12px' }}>
                    {`${lesionInfo?.[2]}${lesionInfo?.[0]}${lesionInfo?.[1]} - ${matchInfo}`}
                  </TableCell> */}

                  <TableCell className={classes.table_cell_projection} style={{ fontSize: '12px' }}>
                    {`${lesionInfo?.[0]?.slice(1)}`}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.table_cell_row}
                    style={{
                      color: lesionInfo?.[1] ? severityColor(lesionInfo?.[1], theme) : 'white',
                    }}
                  >
                    {lesionInfo?.[1] === 'lesionKnown'
                      ? t('projections.lesionKnown')
                      : lesionInfo?.[1]?.match(/\d+/)}
                  </TableCell>
                  <TableCell align="left" className={classes.table_cell_row}>
                    <div className={classes.icons_container}>
                      {lesionAllInfo?.predicted_class && (
                        <Tooltip
                          title={
                            <Typography className={classes.tooltip_text}>
                              {t('diagnose.upscale_previous', {
                                prev_type: `${lesionClass}`,
                              })}
                            </Typography>
                          }
                          arrow
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              width: '16px',
                              height: '16px',
                              cursor: 'help',
                              fill: lesionInfo?.[1]
                                ? severityColor(lesionAllInfo?.predicted_class, theme)
                                : 'white',
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.empty_table_cell_row} />
                  {/* {lesionAllInfo?.score ? (
                    <TableCell align='center' className={classes.table_cell_row}>
                      {`${Math.floor(lesionAllInfo?.score * 100)} %`}
                    </TableCell>
                  ) : (
                    <TableCell size='medium' />
                  )} */}

                  <TableCell className={classes.table_cell_info} align="center">
                    <div className={classes.icons_container}>
                      <RowIconStatus
                        lesionAssigned={lesionAllInfo?.assigned}
                        lesionReassigned={lesionAllInfo?.reassigned}
                        confirmed={confirmed_opacities}
                        labeling={localChangesOpacities}
                        reassigned={reassignedInfo}
                        classes={classes}
                        t={t}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.delete_cell_row}>
                    <div className={classes.icons_container}>
                      <Tooltip
                        title={
                          <Typography className={classes.tooltip_text}>{t('projections.delete')}</Typography>
                        }
                        placement={'right-start'}
                        arrow
                      >
                        <DeleteIcon
                          fontSize="small"
                          style={{ width: '16px', height: '16px' }}
                          className={classes.row_actions_icon}
                          onClick={(e) =>
                            deleteFinding('opacities', lesionInfo, customOpacities, dispatch, actions, e)
                          }
                        />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </Draggable>
          ) : (
            <Droppable key={rowId} droppableId={rowId}>
              {(provided, snapshot) => (
                <>
                  <TableRow
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.table_empty_match_row}
                  >
                    <TableCell
                      colSpan={6}
                      style={{
                        padding: 0,
                        backgroundColor: snapshot.isDraggingOver
                          ? theme.palette.complementary.turquoiseDark
                          : 'transparent',
                        transition: 'background-color 0.2s ease, height 0.2s ease',
                      }}
                    >
                      {snapshot.isDraggingOver ? (
                        <div className={classes.drop_box_container}>
                          <Typography className={classes.table_empty_drop_text}>
                            {t('diagnose.drop_box')}
                          </Typography>
                          <VerticalAlignBottomIcon fontSize="small" />
                        </div>
                      ) : (
                        <Typography className={classes.table_empty_row_text}>
                          {t('diagnose.no_match')}
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  {provided.placeholder}
                </>
              )}
            </Droppable>
          )}
        </React.Fragment>
      );
    });
  };

  const handleDragStart = (start) => {
    setIsDraging(true);
    const source = start.source.droppableId.includes('Right') ? 'right' : 'left';
    setDragSource(source);
  };

  return (
    <Accordion
      square
      className={classes.accordion_container}
      expanded={singleBoxHover?.module === 'opacities' || props.expandOpacitiesAccordion}
      onChange={(event, expanded) => props.handleAccordionModule('opacities', expanded)}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMore />}
        className={classes.diagnose_title_container}
        style={{ background: darkState ? '#545353' : '#818181' }}
      >
        <Typography className={classes.diagnose_title}>{t('ResultView.diagnosticsLesions')}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordion_details}
        style={{ minHeight: opacitiesEmpty ? '2vh' : '25vh' }}
      >
        <Grid
          container
          className={classes.breast_table_container}
          wrap="nowrap"
          justifyContent="flex-start"
          spacing={1}
        >
          {opacitiesEmpty ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography style={{ fontSize: '10px', fontStyle: 'italic', color: 'gray' }}>
                {t('diagnose.no_results')}
              </Typography>
            </div>
          ) : (
            <>
              {/* Right Breast Table */}
              <Grid
                item
                xs={6}
                className={classes.breast_container}
                style={{ borderRight: '1px solid gray' }}
              >
                <div className={classes.title_container}>
                  <Typography className={classes.breast_text}>{t('diagnose.right_breast')}</Typography>
                </div>
                <div className={classes.table_container}>
                  <DragDropContext
                    onDragEnd={(e) =>
                      handleDragEnd(e, customOpacities, setIsDraging, groupedOpacities, dispatch, actions, t)
                    }
                    onDragStart={handleDragStart}
                  >
                    <Droppable droppableId="RightBreast">
                      {(provided) => (
                        <Table ref={provided.innerRef} {...provided.droppableProps}>
                          <ModuleTableHead module="opacities" classes={classes} t={t} />
                          <TableBody>
                            {groupedOpacities?.RightBreast.length > 0 ? (
                              groupedOpacities?.RightBreast.map((rightBreastBox, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell
                                      colSpan={6}
                                      align="left"
                                      className={classes.table_index_head_row}
                                      onMouseEnter={() =>
                                        handleMouseEnterRow(
                                          'opacities',
                                          `${rightBreastBox?.[0]?.[0]?.[0]}${index + 1}`,
                                          dispatch,
                                          actions
                                        )
                                      }
                                      onMouseLeave={() => handleMouseLeaveRow(dispatch, actions)}
                                      style={{
                                        background:
                                          severityTableHeadColor(rightBreastBox?.[0]?.[1], theme) ||
                                          theme.palette.complementary.mainLight,
                                        borderRadius: '2px',
                                      }}
                                    >
                                      <Typography className={classes.table_index_head_title}>{`${t(
                                        'diagnose.index'
                                      )} ${index + 1}`}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  {rightBreastBox && tableOpacitiesRowData(rightBreastBox, index)}
                                </React.Fragment>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={5} align="center" className={classes.table_placeholder}>
                                  {t('diagnose.no_r_opacity_found')}
                                </TableCell>
                              </TableRow>
                            )}
                            {provided.placeholder}
                            <Droppable key={'newIndex'} droppableId={`RightNewIndex`}>
                              {(provided, snapshot) => (
                                <>
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={classes.table_empty_row}
                                  >
                                    <TableCell
                                      colSpan={6}
                                      style={{
                                        padding: 0,
                                        backgroundColor: snapshot.isDraggingOver
                                          ? theme.palette.complementary.turquoiseDark
                                          : 'transparent',
                                        transition: 'background-color 0.2s ease, height 0.2s ease',
                                      }}
                                    >
                                      {snapshot.isDraggingOver ? (
                                        <div className={classes.drop_box_container}>
                                          <Typography className={classes.table_empty_drop_text}>
                                            {t('diagnose.drop_new_index_box')}
                                          </Typography>
                                          <VerticalAlignBottomIcon fontSize="small" />
                                        </div>
                                      ) : (
                                        isDraging && (
                                          <div className={classes.drop_container}>
                                            {dragSource === 'right' && (
                                              <>
                                                <Typography className={classes.table_empty_drop_text}>
                                                  {t('diagnose.drop_new_index')}
                                                </Typography>
                                                <CallSplitIcon
                                                  fontSize="small"
                                                  style={{
                                                    fill: 'gray',
                                                    transform: 'rotate(90deg)',
                                                  }}
                                                />
                                              </>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {provided.placeholder}
                                </>
                              )}
                            </Droppable>
                          </TableBody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Grid>

              {/* Left Breast Table */}
              <Grid item xs={6} className={classes.breast_container}>
                <div className={classes.title_container}>
                  <Typography className={classes.breast_text}>{t('diagnose.left_breast')}</Typography>
                </div>
                <div className={classes.table_container}>
                  <DragDropContext
                    onDragEnd={(e) =>
                      handleDragEnd(e, customOpacities, setIsDraging, groupedOpacities, dispatch, actions, t)
                    }
                    onDragStart={handleDragStart}
                  >
                    <Droppable droppableId="LeftBreast">
                      {(provided) => (
                        <Table ref={provided.innerRef} {...provided.droppableProps}>
                          <ModuleTableHead module="opacities" classes={classes} t={t} />
                          <TableBody>
                            {groupedOpacities?.LeftBreast.length > 0 ? (
                              groupedOpacities?.LeftBreast.map((leftBreastBox, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell
                                      colSpan={6}
                                      align="left"
                                      className={classes.table_index_head_row}
                                      onMouseEnter={() =>
                                        handleMouseEnterRow(
                                          'opacities',
                                          `${leftBreastBox?.[0]?.[0]?.[0]}${index + 1}`,
                                          dispatch,
                                          actions
                                        )
                                      }
                                      onMouseLeave={() => handleMouseLeaveRow(dispatch, actions)}
                                      style={{
                                        background:
                                          severityTableHeadColor(leftBreastBox?.[0]?.[1], theme) ||
                                          theme.palette.complementary.mainLight,
                                        borderRadius: '2px',
                                      }}
                                    >
                                      <Typography className={classes.table_index_head_title}>{`${t(
                                        'diagnose.index'
                                      )} ${index + 1}`}</Typography>
                                    </TableCell>
                                  </TableRow>
                                  {leftBreastBox && tableOpacitiesRowData(leftBreastBox, index)}
                                </React.Fragment>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={5} align="center" className={classes.table_placeholder}>
                                  {t('diagnose.no_l_opacity_found')}
                                </TableCell>
                              </TableRow>
                            )}
                            {provided.placeholder}
                            <Droppable key={'newIndex'} droppableId={`LeftNewIndex`}>
                              {(provided, snapshot) => (
                                <>
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={classes.table_empty_row}
                                  >
                                    <TableCell
                                      colSpan={6}
                                      style={{
                                        padding: 0,
                                        backgroundColor: snapshot.isDraggingOver
                                          ? theme.palette.complementary.turquoiseDark
                                          : 'transparent',
                                        transition: 'background-color 0.2s ease, height 0.2s ease',
                                      }}
                                    >
                                      {snapshot.isDraggingOver ? (
                                        <div className={classes.drop_box_container}>
                                          <Typography className={classes.table_empty_drop_text}>
                                            {t('diagnose.drop_new_index_box')}
                                          </Typography>
                                          <VerticalAlignBottomIcon fontSize="small" />
                                        </div>
                                      ) : (
                                        isDraging && (
                                          <div className={classes.drop_container}>
                                            {dragSource === 'left' && (
                                              <>
                                                <Typography className={classes.table_empty_drop_text}>
                                                  {t('diagnose.drop_new_index')}
                                                </Typography>
                                                <CallSplitIcon
                                                  fontSize="small"
                                                  style={{
                                                    fill: 'gray',
                                                    transform: 'rotate(90deg)',
                                                  }}
                                                />
                                              </>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {provided.placeholder}
                                </>
                              )}
                            </Droppable>
                          </TableBody>
                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Grid>
            </>
          )}
        </Grid>

        <div className={classes.module_status_container}>
          <ModuleTableActions
            module={'opacities'}
            resultEvaluation={resultOpacitiesEvaluation}
            moduleShown={opacitiesShown}
            moduleState={opacitiesState}
            isModuleConfirmed={isOpacitiesConfirmed}
            isModuleRelabeled={isOpacitiesRelabeled}
            appliedRevision={applied_opacities}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default OpacitiesTable;
