import { Divider, Tooltip, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { evaluationClassColor } from '../../../../../../helpers/qualityColor';

const getBreastDiffText = (view_position, currentBreastTissueDepicted, breastAreaDifference, t) => {
  const absDifference = Math.abs(breastAreaDifference);
  const direction = breastAreaDifference < 0 ? t('ResultView.less') : t('ResultView.more');
  const viewPositionUpper = view_position?.toUpperCase();
  const oppositeView = view_position.includes('mlo') ? 'CC' : 'MLO';

  return `- ${viewPositionUpper} ${t('ResultView.current_breast_tissue_helper', {
    currentBreast: `${currentBreastTissueDepicted?.toFixed(0)}`,
  })} ${t('ResultView.has')} ${absDifference}cm³ ${direction} ${t('ResultView.than')} ${
    viewPositionUpper[0]
  }${oppositeView}`;
};

const renderTooltipTitle = (title, subTitle = '', color) => (
  <>
    <Typography style={{ fontWeight: 'bold', fontSize: '1.3vh', lineHeight: 1.3, margin: '4px 0px' }}>
      {title}
    </Typography>
    {subTitle && (
      <Typography
        style={{ fontWeight: 'bold', fontSize: '1.3vh', lineHeight: 1.3, margin: '4px 0px', color }}
      >
        {subTitle}
      </Typography>
    )}
  </>
);

export const breastVolumeInfo = (
  resultQualityEvaluation,
  breastAreaDifference,
  pastBreastVolume,
  view_position,
  left,
  theme,
  classes,
  t,
  dispatch,
  actions
) => {
  const currentBreastTissueDepicted = resultQualityEvaluation?.[view_position]?.BreastVolume;
  const volumeProjectionDiff = resultQualityEvaluation?.[view_position]?.VolumeProjectionDiff;
  const volumeLastExamDiff = resultQualityEvaluation?.[view_position]?.VolumeLastExamDiff;

  if (!currentBreastTissueDepicted) {
    return (
      <Tooltip
        placement="top-start"
        arrow
        title={renderTooltipTitle(
          `${t('ResultView.breast_tissue_abbreviation')}: ${t('ResultView.not_available')}`
        )}
      >
        <Typography style={{ color: 'gray', fontSize: '14px' }}>-</Typography>
      </Tooltip>
    );
  }

  const volumeDiffColor = evaluationClassColor(volumeProjectionDiff, theme);
  const volumeLastDiffColor = evaluationClassColor(volumeLastExamDiff, theme);

  if (!pastBreastVolume) {
    return (
      <Tooltip
        placement="top-start"
        arrow
        title={
          <>
            {renderTooltipTitle(
              `${t('ResultView.breast_tissue_abbreviation')}: ${currentBreastTissueDepicted?.toFixed(0)}cm³`
            )}
            {volumeProjectionDiff &&
              volumeProjectionDiff !== 'not_evaluated' &&
              renderTooltipTitle(
                `${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`,
                '',
                volumeDiffColor
              )}
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {volumeProjectionDiff === 'not_evaluated'
                ? t('ResultView.diff_not_available')
                : getBreastDiffText(view_position, currentBreastTissueDepicted, breastAreaDifference, t)}
            </Typography>
          </>
        }
      >
        <Typography
          className={classes.volumeText}
          style={{
            color: volumeDiffColor,
          }}
        >{`${currentBreastTissueDepicted?.toFixed(0)} cm³`}</Typography>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      interactive
      arrow
      placement="top-start"
      title={
        <div onClick={() => dispatch(actions.setCurrentExam(pastBreastVolume?.[0]?.exam_id))}>
          {renderTooltipTitle(
            `${t('ResultView.breast_tissue_abbreviation')}: ${currentBreastTissueDepicted?.toFixed(0)}cm³`
          )}
          {volumeProjectionDiff &&
            renderTooltipTitle(
              `${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`,
              '',
              volumeDiffColor
            )}
          <Typography style={{ fontSize: '1.3vh', marginBottom: '4px', lineHeight: 1.3 }}>
            {getBreastDiffText(view_position, currentBreastTissueDepicted, breastAreaDifference, t)}
          </Typography>
          <Divider />
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.3vh',
              lineHeight: 1.3,
              margin: '4px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t('ResultView.prev_exam')}
            <OpenInNewIcon fontSize="small" style={{ color: volumeLastDiffColor, marginLeft: '2px' }} />
          </Typography>
          {renderTooltipTitle(
            `${t('ResultView.volume_variance')}: ${volumeLastExamDiff}`,
            '',
            volumeLastDiffColor
          )}
          <div>
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {`- ${t('ResultView.current_breast_tissue')}: ${currentBreastTissueDepicted?.toFixed(0)} cm³`}
            </Typography>
            <Typography style={{ fontSize: '1.3vh', display: 'flex', alignItems: 'center' }}>
              {`- ${t('ResultView.past_breast_tissue')}: ${pastBreastVolume?.[0]?.BreastVolume?.toFixed(
                1
              )} cm³ (${pastBreastVolume?.[0]?.date.slice(0, 10)})`}
            </Typography>
          </div>
        </div>
      }
    >
      <Typography
        className={classes.volumeText}
        style={{ color: volumeDiffColor }}
      >{`${currentBreastTissueDepicted?.toFixed(0)} cm³`}</Typography>
    </Tooltip>
  );
};
