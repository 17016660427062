import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../store/index';
import { Grid, IconButton, Typography, Tooltip, Zoom, Drawer, Badge, SvgIcon } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IFU_icon from '../../../resources/icons/IFU_icon.png';
import IFU_icon_light from '../../../resources/icons/IFU_icon_light.png';
import SettingsIcon from '@material-ui/icons/Settings';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useTranslation } from 'react-i18next';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import UsersIcon from '@material-ui/icons/People';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import { ReactComponent as ProfileIcon } from '../../../resources/icons/profile_icon.svg';
import LogoSmall from '../../../resources/coorporate_images/brayz_logo_small.png';
import animation from '../../../resources/reprocess_short_animation.gif';
import history from '../../../history';
import { ExitToApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  drawer_menu_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    minWidth: '30px',
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 'none',
      zIndex: theme.zIndex.main_drawer,
      overflow: 'visible',
      display: 'flex',
      alignItems: 'center',
    },
  },

  drawerPaper: {
    backgroundColor: theme.palette.complementary.mainDark,
    alignItems: 'center',
    width: '64px',
  },

  mainSectionsContainers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    letterSpacing: '0.1em',
    height: '100%',
    marginTop: '2px',
  },

  logout_container: {
    marginTop: '20px',
    borderTop: '1px solid gray',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },

  menuButton: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
  },

  menuButtonText: {
    fontSize: theme.fonts.responsiveXSmall,
    letterSpacing: '0.03rem',
    lineHeight: '0.3rem',
  },

  menuButton_ifu_container: {
    display: 'flex',
    padding: '2px',
  },

  menuButton_ifu: {
    margin: theme.spacing(0),
    padding: theme.spacing(0.5),
    width: 'auto',
    height: '37px',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  logo_tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: '12px',
  },

  logo_drawer: {
    cursor: 'pointer',
    padding: '6px',
    height: '100%',
  },

  animation_gif: {
    height: '100%',
  },

  logo_container: {
    display: 'flex',
    height: '38px',
    width: 'auto',
    margin: '0',
    alignItems: 'center',
  },

  icon_text_container: {
    height: theme.spacing(1.5),
  },

  slowAppear: {
    animation: '$appearAnimation 0.8s ease',
    opacity: 0,
  },
  appeared: {
    opacity: 1,
  },
  '@keyframes appearAnimation': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  slowDisappear: {
    animation: '$disappearAnimation 0.8s ease',
    opacity: 1,
  },
  disappeared: {
    opacity: 0,
  },
  '@keyframes disappearAnimation': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  logout_text: {
    marginBottom: '60%',
    height: theme.spacing(1.5),
  },
}));

export default function DrawerMenu(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const avatarAnchor = useRef(null);
  const dispatch = useDispatch();
  const [menuHovered, setMenuHovered] = useState(false);
  const { examinationListDrawer, darkState } = useSelector((state) => state.gui);
  const { premium_features } = useSelector((store) => store.global_settings || {});
  const { currentlyProcessed, pendingReprocessing, fetchingExamList } = useSelector(
    (state) => state.examinations
  );
  const currentRole = localStorage.getItem('role');
  const generalAccess =
    currentRole === 'admin' || currentRole === 'supervisor' || currentRole === 'radiologist';
  const managementAccess = currentRole === 'admin' || currentRole === 'supervisor';
  const { data_export = false, retrospective_processing = false } = premium_features;

  return (
    <Drawer
      style={{ width: theme.drawerWidthMenu }}
      className={classes.drawer_menu_container}
      variant="permanent"
      anchor="left"
      open={true}
      classes={{ paper: classes.drawerPaper }}
      data-test-id="navbar-menu"
      onMouseEnter={() => setMenuHovered(true)}
      onMouseLeave={() => setMenuHovered(false)}
    >
      <div className={classes.mainSectionsContainers}>
        <Grid
          className={classes.menu_item_container}
          style={{
            margin: '0 0 12px',
            padding: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
          }}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          {currentlyProcessed || pendingReprocessing || fetchingExamList ? (
            <div className={classes.logo_container}>
              <img className={classes.animation_gif} src={animation} alt="processing" />
            </div>
          ) : (
            <Tooltip
              arrow
              placement="left"
              title={
                <Typography
                  className={
                    window.location.pathname.includes('dashboard')
                      ? classes.logo_tooltip_text
                      : classes.tooltip_text
                  }
                  color="inherit"
                >
                  {window.location.pathname.includes('dashboard') ? 'b-rayZ' : t('Menu.view_dashboard')}
                </Typography>
              }
            >
              <div className={classes.logo_container}>
                <img
                  src={LogoSmall}
                  className={classes.logo_drawer}
                  alt="b-rayZ logo"
                  onClick={() => {
                    history.push('/bbox/results/dashboard');
                    dispatch(actions.setTabsIndex(0));
                    dispatch(actions.setExaminationListDrawer(false));
                  }}
                />
              </div>
            </Tooltip>
          )}
        </Grid>

        {/* Patient List */}
        <Grid
          className={classes.menu_item_container}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('Menu.patientList')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="darkmode"
              onClick={props.onExamListOpen}
              style={{
                color: examinationListDrawer ? theme.palette.complementary.turquoise : 'inherit',
                textShadow: examinationListDrawer ? '#646464f5 0px 0px 1px' : '',
                fontWeight: examinationListDrawer ? 'bold' : '',
              }}
            >
              <UsersIcon />
            </IconButton>
          </Tooltip>

          <div className={classes.icon_text_container}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
              style={{
                color: examinationListDrawer ? theme.palette.complementary.turquoise : 'inherit',
              }}
            >
              {t('Menu.patientList')}
            </Typography>
          </div>
        </Grid>

        {/* Profile */}
        <Grid
          className={classes.menu_item_container}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('Menu.my_profile')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.menuButton}
              color={'inherit'}
              aria-label="menu"
              ref={avatarAnchor}
              onClick={() => {
                props.onAvatarClick();
              }}
            >
              <SvgIcon>
                <ProfileIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>

          <div className={classes.icon_text_container}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
            >
              {t('Menu.profile')?.toUpperCase()}
            </Typography>
          </div>
        </Grid>

        {generalAccess && (
          <>
            {/* Upload */}
            <Grid
              className={classes.menu_item_container}
              item
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Tooltip
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {t('Upload.header')}
                  </Typography>
                }
                arrow
                interactive
                placement="left"
                TransitionComponent={Zoom}
              >
                <IconButton
                  color="inherit"
                  aria-label="darkmode"
                  className={classes.menuButton}
                  onClick={() =>
                    retrospective_processing
                      ? props.setActiveDialog('upload')
                      : props.setActiveDialog('premium_upload')
                  }
                >
                  <CloudUploadIcon />
                </IconButton>
              </Tooltip>

              <div className={classes.icon_text_container}>
                <Typography
                  className={`${classes.menuButtonText} ${
                    menuHovered ? classes.slowAppear : classes.slowDisappear
                  } ${menuHovered ? classes.appeared : classes.disappeared}`}
                >
                  {t('Menu.upload')}
                </Typography>
              </div>
            </Grid>

            {/* Export Data */}
            <Grid
              className={classes.menu_item_container}
              item
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Tooltip
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {t('Menu.export_data')}
                  </Typography>
                }
                arrow
                interactive
                placement="left"
                TransitionComponent={Zoom}
              >
                <Badge
                overlap="circular"
                color="primary"
                variant="dot"
                invisible={!props.dataIsReady}
                >
                  <IconButton
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() =>
                      data_export ? props.setActiveDialog('export') : props.setActiveDialog('premium_export')
                    }
                  >
                  <SaveAltIcon />
                  </IconButton>
                </Badge>
              </Tooltip>

              <div className={classes.icon_text_container}>
                <Typography
                  className={`${classes.menuButtonText} ${
                    menuHovered ? classes.slowAppear : classes.slowDisappear
                  } ${menuHovered ? classes.appeared : classes.disappeared}`}
                >
                  {t('Menu.export')}
                </Typography>
              </div>
            </Grid>

            {managementAccess && (
              <Grid
                className={classes.menu_item_container}
                item
                container
                direction="column"
                justifyContent="space-around"
                alignItems="center"
              >
                <Tooltip
                  title={
                    <Typography className={classes.tooltip_text} color="inherit">
                      {t('Menu.users_info')}
                    </Typography>
                  }
                  arrow
                  interactive
                  placement="left"
                  TransitionComponent={Zoom}
                >
                  <IconButton
                    color="inherit"
                    aria-label="darkmode"
                    className={classes.menuButton}
                    onClick={() => props.setActiveDialog('users')}
                  >
                    <Badge overlap="rectangular" badgeContent={props.notificationsLength} color="primary">
                      <SupervisedUserCircleIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>

                <div className={classes.icon_text_container}>
                  <Typography
                    className={`${classes.menuButtonText} ${
                      menuHovered ? classes.slowAppear : classes.slowDisappear
                    } ${menuHovered ? classes.appeared : classes.disappeared}`}
                  >
                    {t('Menu.users')}
                  </Typography>
                </div>
              </Grid>
            )}
          </>
        )}

        {/* Settings */}
        <Grid
          className={classes.menu_item_container}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('Menu.setup')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => props.setActiveDialog('settings')}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>

          <div className={classes.icon_text_container}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
            >
              {t('Menu.settings')}
            </Typography>
          </div>
        </Grid>

        {/* INFO */}
        <Grid
          className={classes.menu_item_container}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('Menu.bbox_info')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => props.setActiveDialog('info')}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>

          <div className={classes.icon_text_container}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
            >
              {t('Menu.info')}
            </Typography>
          </div>
        </Grid>

        {/* IFU */}
        <Grid
          className={classes.menu_item_container}
          item
          container
          direction="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('Menu.ifu')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              className={classes.menuButton_ifu_container}
              color="inherit"
              aria-label="darkmode"
              onClick={() => {
                window.open(`/bbox/ifu_${i18n.language}`, '_blank');
              }}
            >
              <img className={classes.menuButton_ifu} src={darkState ? IFU_icon : IFU_icon_light} alt="ifu" />
            </IconButton>
          </Tooltip>

          <div className={classes.icon_text_container}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
            >
              IFU
            </Typography>
          </div>
        </Grid>

        {/* Logout */}
        <div className={classes.logout_container}>
          <Tooltip
            title={
              <Typography className={classes.tooltip_text} color="inherit">
                {t('UserPanel.logout')}
              </Typography>
            }
            arrow
            interactive
            placement="left"
            TransitionComponent={Zoom}
          >
            <IconButton
              color="inherit"
              aria-label="darkmode"
              className={classes.menuButton}
              onClick={() => history.push('/logout')}
            >
              <ExitToApp />
            </IconButton>
          </Tooltip>

          <div className={classes.logout_text}>
            <Typography
              className={`${classes.menuButtonText} ${
                menuHovered ? classes.slowAppear : classes.slowDisappear
              } ${menuHovered ? classes.appeared : classes.disappeared}`}
            >
              {t('UserPanel.logout')?.toUpperCase()}
            </Typography>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
