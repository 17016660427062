import React, { useLayoutEffect, useRef, useCallback, useEffect } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { connect, useSelector } from 'react-redux';

const QualityFeaturesChart = React.memo((props) => {
  const chartRef = useRef(null);
  const theme = useTheme();
  const darkState = useSelector((store) => store.gui.darkState);
  const hasData = props.data && props.data.length > 0;

  const initChart = useCallback(() => {
    const { chartdiv, data, activeProjection, setFeaturesQuality } = props;

    const chart = am4core.create(chartdiv, am4charts.XYChart);
    chartRef.current = chart;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.fontSize = theme.fonts.responsiveSmall;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.valign = 'middle';
    categoryAxis.renderer.inside = true;
    categoryAxis.fontSize = theme.fonts.responsiveMedium;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.dx = activeProjection.includes('mlo') ? -34 : -38;
    categoryAxis.renderer.labels.template.rotation = -90;
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.horizontalCenter = 'middle';
    categoryAxis.renderer.labels.template.valign = 'middle';
    categoryAxis.renderer.inside = true;
    categoryAxis.renderer.labels.template.fill = am4core.color(theme.palette.texts.secondary);
    categoryAxis.renderer.line.strokeWidth = 1;
    categoryAxis.renderer.line.stroke = am4core.color(theme.palette.texts.secondary);
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.ticks.template.strokeOpacity = 1;
    categoryAxis.renderer.ticks.template.stroke = am4core.color(theme.palette.texts.secondary);
    categoryAxis.renderer.ticks.template.strokeWidth = 1;
    categoryAxis.renderer.minGridDistance = 5;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.maxWidth = 0;
    valueAxis.visible = false;
    valueAxis.renderer.grid.template.disabled = true;

    const series = hasData
      ? [
          { name: 'not_evaluated', color: theme.palette.dashboard.none },
          { name: 'none', color: theme.palette.dashboard.noSkinFolds },
          { name: 'mild', color: theme.palette.dashboard.mild },
          { name: 'medium', color: theme.palette.dashboard.moderate },
          { name: 'severe', color: theme.palette.dashboard.inadequate },
          { name: 'convex', color: theme.palette.dashboard.good },
          { name: 'straight', color: theme.palette.dashboard.perfect },
          { name: 'concave', color: theme.palette.dashboard.inadequate },
          { name: 'correct', color: theme.palette.dashboard.perfect },
          { name: 'moderate', color: theme.palette.dashboard.moderate },
          { name: 'limited', color: theme.palette.dashboard.moderate },
          { name: 'NULL', color: theme.palette.dashboard.none },
          { name: 'insufficient', color: theme.palette.dashboard.inadequate },
          { name: 'inadequate', color: theme.palette.dashboard.inadequate },
          { name: 'high', color: theme.palette.dashboard.inadequate },
          { name: 'low', color: theme.palette.dashboard.inadequate },
        ]
      : [{ name: 'none', color: theme.palette.dashboard.none }];

    for (let singleSeries of series) {
      const s = chart.series.push(new am4charts.ColumnSeries());
      s.columns.template.width = am4core.percent(45);
      s.dataFields.categoryX = 'category';
      s.dataFields.valueY = singleSeries.name + 'Percent';
      s.dataFields.filterKey = 'filterKey';
      s.name = singleSeries.name;

      s.columns.template.fill = am4core.color(singleSeries.color);

      s.events.on('inited', () => {
        s.dataItems.each((dataItem) => {
          const category = dataItem.dataContext.category;

          // if (category === 'PNL Level' && singleSeries.name === 'moderate') {
          //   dataItem.column.fill = am4core.color(theme.palette.dashboard.good);
          // }
        });
      });

      s.columns.template.stroke = am4core.color(theme.palette.background.paper);
      s.columns.template.column.cornerRadius = 2;
      if (hasData) {
        s.stacked = true;
        s.tooltip.pointerOrientation = 'vertical';

        const labelBullet = s.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{valueY}%';
        labelBullet.label.fontSize = '9px';
        labelBullet.label.fill = am4core.color('#d4d4d4');
        labelBullet.label.horizontalCenter = 'middle';
        labelBullet.locationY = 0.5;

        s.columns.template.tooltipText = `{name}: {${singleSeries.name}} projections`;
        s.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        s.columns.template.events.on('hit', (ev) => {
          setFeaturesQuality(ev.target.dataItem.categoryX, ev.target.dataItem.filterKey, singleSeries.name);
        });
      }
    }

    if (hasData) {
      chart.data = data;
      chart.hiddenState.properties.opacity = 0;
      chart.padding(0, 20, 5, 20);
      chart.zoomOutButton.disabled = true;
    }
  }, [props.data, props.activeProjection, theme]);

  useLayoutEffect(() => {
    initChart();
    return () => {
      chartRef.current.dispose();
    };
  }, [initChart]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.xAxes.each((axis) => {
        axis.renderer.labels.template.fill = am4core.color(theme.palette.texts.secondary);
        axis.renderer.line.stroke = am4core.color(theme.palette.texts.secondary);
        axis.renderer.ticks.template.stroke = am4core.color(theme.palette.texts.secondary);
      });
      chart.series.each((s) => {
        s.columns.template.stroke = am4core.color(theme.palette.background.paper);
      });
    }
  }, [darkState]);

  return <div id={props.chartdiv} style={{ width: '100%', height: '100%' }} />;
});

function mapStateToProps(state, myProps) {
  return { data: state.statistics.barQualityData[myProps.activeProjection] };
}

export default connect(mapStateToProps)(QualityFeaturesChart);
