import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  CircularProgress,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Slider,
  TextField,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  Checkbox,
  Tooltip,
  FormLabel,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../store/index';
import DatePicker from '../../../DrawerExamList/DatePicker/DatePicker';
import { API_ENDPOINT, AI_ENDPOINT } from '../../../../../config';
import { fetchDelete } from '../../../../../helpers/fetching';
import { formatDate } from '../../../../../helpers/formatDate';
import { setSnackbar } from '../../../../../store';
import CustomDatePicker from '../../../DrawerExamList/DatePicker/CustomDatePicker';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },

  subtitleSettingsCard: {
    color: theme.palette.secondary.main,
    fontSize: '0.6rem',
  },

  Slider: {
    width: '60%',
  },
}));

function Configuration(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [progress, setProgress] = useState([0, 0]);
  const { premium_features } = useSelector((state) => state.global_settings);

  const { diagnostics_microcalc_module, diagnostics_opacities_module } = premium_features;
  const qualityRadioValue = props?.settings?.presentation_quality ?? '';
  const diagnoseRadioValue = props?.settings?.presentation_lesions ?? '';
  const somePremiumDiagnostics = diagnostics_microcalc_module || diagnostics_opacities_module;

  const sensitivityMarks = [
    // {
    //   value: 16,
    //   label: t('Settings.sensitivity_highest'),
    // },
    {
      value: 0,
      label: t('Settings.sensitivity_low'),
    },
    {
      value: 1,
      label: t('Settings.sensitivity_medium'),
    },
    {
      value: 2,
      label: t('Settings.sensitivity_high'),
    },
    // {
    //   value: 0,
    //   label: t('Settings.sensitivity_lowest'),
    // },
  ];

  function getSliderValue(category) {
    const categoryValueMapping = {
      low: 0,
      medium: 1,
      high: 2,
    };

    if (!categoryValueMapping.hasOwnProperty(category)) {
      console.log('Bad sensitivity:', category);
      return;
    }

    return categoryValueMapping[category];
  }

  useEffect(() => {
    const newSocket = io(AI_ENDPOINT, {
      withCredentials: true,
      credentials: 'include',
    });

    newSocket.on('connect', () => {
      newSocket.on('reprocessed', (val) => setProgress(val));
    });

    newSocket.on('disconnect', () => {
      newSocket.off('reprocessed');
    });

    return () => newSocket.close();
  }, []);

  return (
    <Grid container direction="column" spacing={1}>
      {/* Diagnostic Sensitivity  */}
      {props.managementAccess && (
        <Grid item>
          <Card elevation={1}>
            <CardHeader
              disableTypography={true}
              classes={{
                action: classes.action,
              }}
              title={
                <Typography className={classes.titleSettingsCard} variant="h6">
                  {t('Settings.diagnostic_sensitivity')}
                </Typography>
              }
            />
            <CardContent
              style={{
                paddingTop: '0px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Slider
                className={classes.Slider}
                value={getSliderValue(props.diagnostic_sensitivity)}
                step={1}
                min={0}
                max={2}
                valueLabelDisplay="off"
                marks={sensitivityMarks}
                onChange={(_, val) => props.setSensitivity(val)}
              />
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Display setting */}
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography={true}
            classes={{
              action: classes.action,
            }}
            title={
              <Typography className={classes.titleSettingsCard} variant="h6">
                {t('Settings.display')}
              </Typography>
            }
          />
          <CardContent style={{ paddingTop: '0px' }}>
            {somePremiumDiagnostics && (
              <FormControl
                component="fieldset"
                style={{
                  width: '100%',
                }}
              >
                <FormLabel color="secondary">b-DIAGNOSTICS</FormLabel>
                <RadioGroup
                  aria-label="display"
                  name="display"
                  value={diagnoseRadioValue}
                  onChange={(e) => props.updateDiagnosticsPresentationDisplay(e)}
                  row
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <FormControlLabel value="contour" control={<Radio />} label={t('Settings.contour')} />
                  <FormControlLabel value="overlay" control={<Radio />} label={t('Settings.overlays')} />
                  <FormControlLabel value="boxes" control={<Radio />} label={t('Settings.only_boxes')} />
                </RadioGroup>
              </FormControl>
            )}
            <Divider style={{ margin: '10px' }} />
            <FormControl
              component="fieldset"
              style={{
                width: '100%',
              }}
            >
              <FormLabel color="secondary">b-QUALITY</FormLabel>
              <RadioGroup
                aria-label="display"
                name="display"
                value={qualityRadioValue.replace('_boxes', '')}
                onChange={(e) => props.updateQualityPresentationDisplay(e)}
                row
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                }}
              >
                <FormControlLabel value="contour" control={<Radio />} label={t('Settings.contour')} />
                <FormControlLabel value="overlay" control={<Radio />} label={t('Settings.overlays')} />
                <FormControlLabel value="boxes" control={<Radio />} label={t('Settings.only_boxes')} />
              </RadioGroup>
              <div
                style={{
                  width: '70%',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '30px',
                }}
              >
                {(props?.settings?.presentation_quality?.includes('contour') ||
                  props?.settings?.presentation_quality?.includes('overlay')) && (
                  <FormControlLabel
                    value={'_boxes'}
                    control={
                      <Checkbox
                        checked={props?.settings?.presentation_quality?.includes('_boxes')}
                        onChange={(e) => props.updateQualityPresentationDisplay(e)}
                      />
                    }
                    label={t('Settings.with_boxes')}
                  />
                )}
              </div>
            </FormControl>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Configuration;
