import React, { useState, useRef, useEffect } from 'react';
import * as actions from '../../../../../store/index';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { catalog } from '../../../../../language/catalog';
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  setActiveDialog,
  setSnackbar,
  setResults,
} from '../../../../../store/index';
import { fetchGet, fetchPut } from '../../../../../helpers/fetching';
import { useTheme } from '@material-ui/core/styles';
import { densityColor, highDensity } from '../../../../../helpers/density';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import { useStyles } from './RiskAssessment.style';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import animation from '../../../../../resources/processing_animation.gif';

const RiskAssessmentForm = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  //Store selectors
  const results = useSelector((state) => state.results);
  const { pat_name, birth, patient_id, shown_density, applied_density } =
    useSelector((state) => state.results);
  const { density_module } = useSelector(
    (store) => store.global_settings.premium_features
  );
  const { activeDialog, landscape } = useSelector((state) => state.gui);
  const { exam_list } = useSelector((state) => state.examinations);
  const currentDensityShown = shown_density || applied_density;
  const evaluation = useAppliedEvaluation(
    'density',
    currentDensityShown,
    false
  );

  const { name: patientName, patient_id: patientId } = results;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    resetField,
    unregister,
    getValues,
    setValue,
  } = useForm();

  //Local states
  const [patientGeneralInfo, setPatientGeneralInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedRadioOptions, setSelectedRadioOptions] = useState([]);
  const [yearSelected, setYearSelected] = useState({});
  const [monthSelected, setMonthSelected] = useState({});
  const [selectedCheckboxOption, setSelectedCheckboxOption] = useState([]);
  const [additionalFields, setAdditionalFields] = useState({});
  const [loading, setLoading] = useState(true);
  //Variables
  const patientUDI = patientId || patient_id;
  const formContent = catalog.resources.en.translation.risk_improved_form;
  const today = new Date();
  const birthDate = new Date(birth);
  const watchedValues = useWatch({ control });

  useEffect(() => {
    const newEntry = {
      ...patientGeneralInfo,
      pat_name: patientName ? patientName : pat_name,
      pat_dob: patientName ? '' : birth,
      pat_age: patientId ? '' : getAge(),
      pat_id: patientId ? patientId : patient_id,
      pat_density: highDensity(evaluation),
    };
    setPatientGeneralInfo(newEntry);
  }, [props.open, dispatch, patientName, pat_name]);

  //Functions
  const handleAddField = (opt_id) => {
    setAdditionalFields((prevFields) => {
      const newFields = { ...prevFields };
      if (!newFields[opt_id]) {
        newFields[opt_id] = [];
      }
      newFields[opt_id].push({ value: '' });
      return newFields;
    });
  };

  const handleDeleteField = (field, opt_id, idx) => {
    unregister(field);
    unregister(`${field}_type`);
    setAdditionalFields((prevFields) => {
      const newFields = { ...prevFields };
      if (newFields[opt_id]) {
        newFields[opt_id] = newFields[opt_id].filter(
          (_, index) => index !== idx
        );
      }
      return newFields;
    });
  };

  const getAge = () => {
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear();
    const min = max - 60;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years.map((yearOption, i) => (
      <option className={classes.select_options} value={yearOption} key={i}>
        {yearOption}
      </option>
    ));
  };

  const generateArrayOfMonth = () => {
    const max = 12;
    const min = 1;
    const months = ['-'];

    for (let i = max; i >= min; i--) {
      months.push(i);
    }
    return months.map((monthOption, i) => (
      <option className={classes.select_options} value={monthOption} key={i}>
        {monthOption}
      </option>
    ));
  };

  //Handle form logic
  const handleRadioSelectedOption = (riskId) => {
    if (!selectedRadioOptions.includes(riskId)) {
      setSelectedRadioOptions([...selectedRadioOptions, riskId]);
    } else {
      setSelectedRadioOptions(
        selectedRadioOptions.filter((id) => id !== riskId)
      );
    }
  };
  const handleCheckboxChange = (item, optId, checked) => {
    if (!checked) {
      const formData = getValues();
      const keysToUnregister = Object.keys(formData).filter((key) =>
        key.startsWith(item)
      );

      keysToUnregister.forEach((key) => unregister(key));

      setSelectedCheckboxOption((prevOptions) =>
        prevOptions.filter((option) => option.family !== optId)
      );
    } else {
      setSelectedCheckboxOption((prevOptions) => [
        ...prevOptions,
        { family: optId },
      ]);
      {
        if (item === 'family_other') setValue(`${item}`, ' ');
        else setValue(`${item}`, 'Yes');
      }
    }
  };
  const handleFamilyRemove = (item, e) => {
    const formData = getValues();
    const keysToUnregister = Object.keys(formData).filter((key) =>
      key.startsWith(item)
    );
    keysToUnregister.forEach((key) => key !== 'family' && unregister(key));
    setValue('family', 'No');
  };

  // Fetch patient saved answers
  useEffect(() => {
    const radioOptions = [
      'diagnosed_cancer',
      'hrt',
      'hormones',
      'chemo',
      'radiation',
      'operated_or_biopsy',
      'age_menopause',
      'births',
      'alcohol',
      'family',
    ];

    const monthOptions = ['chemo', 'operated_or_biopsy', 'radiation'];

    const familyMembers = [
      'family_mother',
      'family_grandmother_mother',
      'family_aunt_mother',
      'family_daughter',
      'family_sister',
      'family_grandmother_father',
      'family_aunt_father',
      'family_other',
    ];

    const fetchPatientForm = async () => {
      try {
        const response = await fetchGet(`risk_model/${patientUDI}`, 'bbox');

        if (response.success) {
          if (response?.data?.risk_factors) {
            const apiData = response.data.risk_factors;

            const reverseBoolean = (value) =>
              value !== null || value !== '' || value !== undefined
                ? value === 1
                  ? 'Yes'
                  : 'No'
                : '';

            const reverseDate = (date) => {
              if (!date || date === '-') return { year: '', month: '' };
              const [month, year] = date.split('.');
              return { year, month };
            };

            const reverseBiopsy = (dateString) => {
              if (!dateString || dateString === '-')
                return { year: '', month: '', location: '' };
              const [date, location] = dateString.split(' - ');
              const [month, year] = date.split('.');
              return { year, month, location: location.replace('at', '') };
            };

            const getFamily = (formData) => {
              const familyKeys = Object.keys(formData).filter((key) =>
                key.startsWith('family_')
              );
              const familyItems = {};
              for (const key of familyKeys) {
                familyItems[key] = formData[key];
              }
              return familyItems;
            };

            const getRadioAnswers = (answer) => {
              if (answer === undefined || answer === '' || answer === null)
                return '';
              else if (answer === 1 || answer === 0)
                return reverseBoolean(answer);
              else if (answer === 'No') return 'No';
              else return 'Yes';
            };

            const getMenopauseAnswers = (answer) => {
              if (answer === undefined || answer === '' || answer === null)
                return '';
              else if (answer === 'Yes' || answer === 'not yet') return 'Yes';
              else return 'No';
            };

            const transformedData = {
              mammo: getRadioAnswers(apiData.mammo),
              sonography: getRadioAnswers(apiData.sonography),
              mri: getRadioAnswers(apiData.mri),
              diagnosed_cancer: getRadioAnswers(apiData.diagnosed_cancer),
              operated_or_biopsy: getRadioAnswers(apiData.operated_or_biopsy),
              radiation: getRadioAnswers(apiData.radiation),
              chemo: getRadioAnswers(apiData.chemo),
              hrt: getRadioAnswers(apiData.hrt),
              hormones: getRadioAnswers(apiData.hormones),
              alcohol: getRadioAnswers(apiData.alcohol),
              family: getRadioAnswers(apiData.family),
              age_menopause: getMenopauseAnswers(apiData.age_menopause),
              births: getRadioAnswers(apiData.births),
              age_menarche_: apiData.age_menarche,
              age_menopause_:
                apiData.age_menopause === 'not yet' ||
                apiData.age_menopause === null ||
                apiData.age_menopause === undefined
                  ? null
                  : apiData.age_menopause,
              alcohol_: apiData.alcohol,
              births_: apiData.births > 0 ? apiData.births : null,
              height: apiData.height ?? '',
              weight: apiData.weight ?? '',
              doctor: apiData.doctor,
              reason: apiData.reason,
              diagnosed_cancer_: apiData.diagnosed_cancer,
              diagnosed_cancer_num_: apiData.diagnosed_cancer_age,
              operated_or_biopsy_: apiData.operated_or_biopsy_breast,
              operated_or_biopsy_date: reverseBiopsy(
                apiData.operated_or_biopsy_date
              ).year,
              operated_or_biopsy_month: reverseBiopsy(
                apiData.operated_or_biopsy_date
              ).month,
              operated_or_biopsy_where: reverseBiopsy(
                apiData.operated_or_biopsy_date
              ).location,
              radiation_date: reverseDate(apiData.radiation_date).year,
              radiation_month: reverseDate(apiData.radiation_date).month,
              chemo_date: reverseDate(apiData.chemo_date).year,
              chemo_month: reverseDate(apiData.chemo_date).month,
              hrt_:
                apiData.hrt !== 'No' ||
                apiData.hrt !== '' ||
                apiData.hrt !== undefined
                  ? apiData.hrt === 1
                    ? 'past'
                    : 'currently'
                  : '',
              hrt_num_: apiData.hrt_years,
              hormones_num_: apiData.hormones_date,
              ...getFamily(apiData.family_members),
            };

            const selectedRadioOptions = radioOptions.filter((option) => {
              if (option === 'age_menopause') {
                return (
                  apiData[option] !== 'not yet' &&
                  apiData[option] !== null &&
                  apiData[option] !== undefined &&
                  apiData[option] !== ''
                );
              }
              return (
                apiData[option] &&
                apiData[option] !== 'no' &&
                apiData[option] !== ''
              );
            });

            setSelectedRadioOptions(selectedRadioOptions);

            const selectedMonthoptions = monthOptions.filter(
              (option) => apiData[option]
            );
            setMonthSelected(selectedMonthoptions);

            const selectedFamilies = familyMembers.filter(
              (option) => apiData.family_members?.[option]
            );

            const transformedFamilies = selectedFamilies.map((family) => ({
              family: family.replace('family_', ''),
            }));
            setSelectedCheckboxOption(transformedFamilies);

            Object.keys(transformedData).forEach((key) => {
              setValue(key, transformedData[key]);
            });
          }
        } else {
          setLoading(false);
          return;
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching patient form:', error);
        return;
      }
      setLoading(false);
    };

    fetchPatientForm();
  }, []);

  // Transform data to request
  const transformDataToRequest = (data) => {
    const transformData = (answer, answer_select) => {
      if (answer === 'true' || answer?.includes('Yes') || answer === 'Yes') {
        if (answer_select === 'past') return 1;
        if (answer_select === 'currently') return 2;
      } else if (
        answer === 'false' ||
        answer?.includes('No') ||
        answer === 'No' ||
        answer === 'Never' ||
        answer_select === 'never'
      )
        return 0;
      else return '';
    };

    const transformBoolean = (answer) => {
      if (answer === null) return '';
      else if (answer === 'true' || answer?.includes('Yes') || answer === 'Yes')
        return 1;
      else if (answer === 'false' || answer?.includes('No') || answer === 'No')
        return 0;
      else return null;
    };

    const transformBirths = (births, birthsNums) => {
      if (birthsNums) {
        if (birthsNums === '+ 3') {
          return 3;
        } else {
          return parseInt(birthsNums);
        }
      } else {
        if (births === null || births === undefined) {
          return null;
        } else if (births === 'Yes') {
          return 'Yes';
        } else if (births === 'false' || births === 'No') {
          return 0;
        } else {
          return births;
        }
      }
    };

    const transformMenarche = (age) => {
      if (age === '- 11') return 11;
      if (age === '+ 17') return 17;
      else return parseInt(age);
    };

    const transformMenopause = (answer, value) => {
      if (answer === 'true' || answer === 'Yes') return 'not yet';
      else if (answer === 'No') return value;
      else return value;
    };

    const getFamilyCount = (members) => {
      const familyKeys = Object.keys(members).filter(
        (key) =>
          key.startsWith('family_') &&
          !key.includes('_type') &&
          !key.includes('_0') &&
          !key.includes('_1') &&
          !key.includes('_2')
      );
      const familyItems = {};
      for (const key of familyKeys) {
        familyItems[key] = members[key];
      }
      return Object.keys(familyItems).length;
    };

    const transformFamily = (family, members) => {
      if (family === 'Yes') return getFamilyCount(members);
      else if (family === 'No') return 0;
      else return null;
    };

    const getFamily = (formData) => {
      const familyKeys = Object.keys(formData).filter((key) =>
        key.includes('family_')
      );
      const familyItems = {};
      for (const key of familyKeys) {
        familyItems[key] = formData[key];
      }
      return Object.keys(familyItems).length === 0 ? 0 : familyItems;
    };

    const transformDate = (year, month) => {
      if (!month && !year) return '-';
      else return `${month}.${year}`;
    };

    const transformBiopsy = (year, month, biopsyLocation) => {
      if (!year && !month && !biopsyLocation) return '';
      else
        return `${month}.${year} - ${t('patient_risk.in')} ${biopsyLocation}`;
    };

    const adjustedForm = {
      patient_id: patientGeneralInfo.pat_id,
      form: {
        age_menarche: transformMenarche(data.age_menarche_),
        age_menopause: transformMenopause(
          data.age_menopause,
          data?.age_menopause_
        ),
        births: transformBirths(data.births, data.births_),
        family: transformFamily(data.family, getFamily(data)),
        family_members: getFamily(data),
        hormones: transformData(data.hormones, data.hormones_),
        hrt: transformData(data.hrt, data.hrt_),
        hrt_years: data.hrt_num_,
        weight: parseInt(data.weight),
        height: parseInt(data.height),
        doctor: data.doctor,
        mammo: transformBoolean(data.mammo),
        mammo_date: transformDate(data.mammo_date, data?.mammo_month),
        sonography: transformBoolean(data.sonography),
        sonography_date: transformDate(
          data.sonography_date,
          data?.sonography_month
        ),
        mri: transformBoolean(data.mri),
        mri_date: transformDate(data.mri_date, data?.mri_month),
        radiation: transformBoolean(data.radiation),
        radiation_date: transformDate(
          data.radiation_date,
          data.radiation_month
        ),
        chemo: transformBoolean(data.chemo),
        chemo_date: transformDate(data.chemo_date, data.chemo_month),
        operated_or_biopsy: transformBoolean(data.operated_or_biopsy),
        operated_or_biopsy_breast: data.operated_or_biopsy_,
        operated_or_biopsy_date: transformBiopsy(
          data?.operated_or_biopsy_date,
          data?.operated_or_biopsy_month,
          data?.operated_or_biopsy_where
        ),
        operated_or_biopsy_results: data?.operated_or_biopsy_biopsy,
        reason: data.reason,
        alcohol:
          data.alcohol === 'Yes'
            ? data.alcohol_
            : transformBoolean(data.alcohol),
        gender: data.gender,
        diagnosed_cancer:
          data.diagnosed_cancer === 'Yes'
            ? data.diagnosed_cancer_
            : transformBoolean(data.diagnosed_cancer),
        diagnosed_cancer_age: data.diagnosed_cancer_num_,
        hormones: transformBoolean(data.hormones),
        hormones_date: data.hormones_num_,
      },
    };
    postRiskAssessment(adjustedForm);
  };

  const postRiskAssessment = async (adjustedForm) => {
    try {
      const response = await fetchPut('risk_model', adjustedForm, 'bbox');
      const idx = exam_list?.findIndex(
        (e) => e?.patient_id === patientGeneralInfo?.pat_id
      );

      if (response.data) {
        if (exam_list && idx !== -1) {
          const newExamList = [...exam_list];
          const newElement = { ...newExamList[idx], form_filled: 1 };
          newExamList[idx] = newElement;
          dispatch(actions.setExaminationList(newExamList));
        }

        const newResults = {
          ...results,
          risk_evaluation: {
            life_time_risk: response.data.life_time_risk,
          },
        };
        dispatch(setResults(newResults));
        resetForm();
        dispatch(
          setSnackbar({
            msg: t('api_messages.pat_risk_sent'),
            severity: 'success',
          })
        );
        dispatch(actions.fetchResults());
      } else if (response.success === true) {
        dispatch(
          setSnackbar({
            msg: t('patient_risk.saved_risk'),
            severity: 'warning',
          })
        );
        dispatch(setActiveDialog(null));
      }
    } catch (error) {
      console.log('Catch error', { response, error });
      dispatch(
        setSnackbar({
          msg: t('patient_risk.error_posting'),
          severity: 'error',
        })
      );
      console.error(error);
    }
  };

  const resetForm = () => {
    setPatientGeneralInfo({});
    setErrorMessage(false);
    setSelectedRadioOptions([]);
    setYearSelected({});
    setSelectedCheckboxOption([]);
    reset();
    dispatch(setActiveDialog(null));
  };

  return (
    <Dialog
      className={classes.form_container}
      maxWidth="md"
      fullWidth={true}
      open={activeDialog === 'risk_management_form'}
    >
      <div className={classes.draggable_title_container}>
        <div className={classes.patient_container}>
          <Typography className={classes.form_patient}>
            {patientName
              ? `${t('patient_risk.name')}: ${patientName}`
              : `${t('patient_risk.name')}: ${pat_name}`}
          </Typography>
          <Typography className={classes.form_patient_dob}>
            {patientGeneralInfo.pat_dob
              ? `${t('risk_management.dob')}: ${birth.slice(0, 10)}`
              : ''}
          </Typography>

          <Typography className={classes.form_patient_dob}>
            {`${t('patient_risk.age')}: ${getAge()}`}
          </Typography>
        </div>
        <div className={classes.header_right_container}>
          {patientName
            ? ''
            : density_module && (
                <div className={classes.density_container}>
                  <Typography>{t('patient_risk.density')}</Typography>
                  <Avatar
                    style={{
                      backgroundColor: densityColor(
                        patientGeneralInfo?.pat_density,
                        theme
                      ),
                      width: landscape ? '5vh' : '3vh',
                      height: landscape ? '5vh' : '3vh',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontSize: '125%',
                      }}
                    >
                      {patientGeneralInfo?.pat_density}
                    </div>
                  </Avatar>
                </div>
              )}
          <Tooltip placement="top-start" title={t('risk_management.close')}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                resetForm();
                dispatch(setActiveDialog(null));
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <DialogContent
        style={{
          background: '#757575',
          overflowY: 'scroll',
          scrollbarColor: '#d6d4d4 gray',
          scrollbarWidth: 'thin',
          paddingLeft: '36px',
        }}
      >
        {loading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              src={animation}
              alt="logoanimation"
            />
          </div>
        ) : (
          <form>
            {formContent.map((item, i) => (
              <div key={item.id} className={classes.question_container}>
                <div className={classes.question_title_container}>
                  <Typography className={classes.question_title}>
                    {item.id} - {t(`risk_improved_form.${i}.question_title`)}
                  </Typography>
                  <p style={{ color: '#e21616' }}>
                    {errors?.[`${item.risk_id}`]?.message}
                  </p>
                </div>
                {item.answer_type === 'radio' && (
                  <div className={classes.answer_container}>
                    <div className={classes.answer_radio}>
                      <div>
                        <input
                          className={classes.radio_button_circle}
                          type="radio"
                          value={item.radio_value_true || ''}
                          {...register(`${item.risk_id}`, {})}
                          onChange={(e) => {
                            if (item.risk_id === 'age_menopause') {
                              if (selectedRadioOptions.includes(item.risk_id)) {
                                const updatedOptions =
                                  selectedRadioOptions.filter(
                                    (id) => id !== item.risk_id
                                  );
                                setSelectedRadioOptions(updatedOptions);
                              }
                            }
                            if (
                              item.risk_id !== 'age_menopause' &&
                              item.answer_expandable === 'true'
                            ) {
                              handleRadioSelectedOption(item.risk_id);
                            }
                          }}
                        />
                        <label className={classes.radio_buttons}>
                          {t(`risk_improved_form.${i}.radio_value_true`)}
                        </label>
                      </div>
                      <div>
                        <input
                          className={classes.radio_button_circle}
                          type="radio"
                          value={item.radio_value_false || ''}
                          {...register(`${item.risk_id}`, {})}
                          onChange={(e) => {
                            if (item.risk_id === 'age_menopause') {
                              handleRadioSelectedOption(item.risk_id);
                            }
                            if (
                              selectedRadioOptions.includes(item.risk_id) &&
                              item.risk_id !== 'age_menopause'
                            ) {
                              const updatedOptions =
                                selectedRadioOptions.filter(
                                  (id) => id !== item.risk_id
                                );
                              setSelectedRadioOptions(updatedOptions);
                              if (item.risk_id === 'family') {
                                handleFamilyRemove(item.risk_id, e);
                              }
                            }
                          }}
                        />
                        <label className={classes.radio_buttons}>
                          {t(`risk_improved_form.${i}.radio_value_false`)}
                        </label>
                      </div>
                    </div>
                    {selectedRadioOptions.includes(item.risk_id) &&
                      item.answer_expandable === 'true' && (
                        <>
                          <div className={classes.multiple_answers}>
                            {item.complementary_type === 'select' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.complementary_title`
                                  )}
                                </Typography>

                                <select
                                  className={classes.selectLabel}
                                  style={{
                                    minWidth: '145px',
                                    marginRight: '10px',
                                  }}
                                  {...register(`${item.risk_id}_`, {
                                    required: true,
                                  })}
                                >
                                  {item.complementary_items_list.map(
                                    (option, index) => (
                                      <option
                                        className={classes.select_options}
                                        key={index}
                                        value={option.opt_id}
                                      >
                                        {t(
                                          `risk_improved_form.${i}.complementary_items_list.${index}.item_title`
                                        )}
                                      </option>
                                    )
                                  )}
                                </select>
                              </>
                            )}
                            {item.extra_info_type === 'select' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.extra_info_title`
                                  )}
                                </Typography>
                                <select
                                  className={classes.selectLabel}
                                  style={{
                                    minWidth: '145px',
                                    marginRight: '10px',
                                  }}
                                  {...register(`${item.risk_id}_`, {
                                    required: true,
                                  })}
                                >
                                  {item.extra_info_options.map(
                                    (option, index) => (
                                      <option
                                        className={classes.select_options}
                                        key={index}
                                        value={option.opt_id}
                                      >
                                        {t(
                                          `risk_improved_form.${i}.extra_info_options.${index}.item_title`
                                        )}
                                      </option>
                                    )
                                  )}
                                </select>
                              </>
                            )}
                            {item.complementary_type === 'date' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.complementary_title`
                                  )}
                                </Typography>
                                <div>
                                  <select
                                    style={{
                                      minWidth: '80px',
                                      marginRight: '10px',
                                    }}
                                    {...register(`${item.risk_id}_month`)}
                                    className={classes.selectLabel}
                                    onChange={(e) =>
                                      setMonthSelected((prev) => ({
                                        ...prev,
                                        [item.risk_id]: e.target.value,
                                      }))
                                    }
                                    name={`${item.risk_id}_month`}
                                    value={monthSelected[item.risk_id] || ''}
                                  >
                                    {generateArrayOfMonth()}
                                  </select>
                                  <select
                                    style={{
                                      minWidth: '145px',
                                      marginRight: '10px',
                                    }}
                                    {...register(`${item.risk_id}_date`)}
                                    className={classes.selectLabel}
                                    onChange={(e) =>
                                      setYearSelected((prev) => ({
                                        ...prev,
                                        [item.risk_id]: e.target.value,
                                      }))
                                    }
                                    name={`${item.risk_id}_date`}
                                    value={yearSelected[item.risk_id] || ''}
                                  >
                                    {generateArrayOfYears()}
                                  </select>
                                </div>
                              </>
                            )}
                            {item.extra_info_type === 'date' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.extra_info_title`
                                  )}
                                </Typography>
                                <div>
                                  <select
                                    style={{
                                      minWidth: '80px',
                                      marginRight: '10px',
                                    }}
                                    {...register(`${item.risk_id}_month`)}
                                    className={classes.selectLabel}
                                    onChange={(e) =>
                                      setMonthSelected((prev) => ({
                                        ...prev,
                                        [item.risk_id]: e.target.value,
                                      }))
                                    }
                                    name={`${item.risk_id}_month`}
                                    value={monthSelected[item.risk_id] || ''}
                                  >
                                    {generateArrayOfMonth()}
                                  </select>
                                  <select
                                    className={classes.selectLabel}
                                    style={{
                                      minWidth: '145px',
                                    }}
                                    {...register(`${item.risk_id}_date`)}
                                    onChange={(e) =>
                                      setYearSelected((prev) => ({
                                        ...prev,
                                        [item.risk_id]: e.target.value,
                                      }))
                                    }
                                    name={`${item.risk_id}_date`}
                                    value={yearSelected[item.risk_id] || ''}
                                  >
                                    {generateArrayOfYears()}
                                  </select>
                                </div>
                              </>
                            )}
                            {item.extra_info_type_two === 'input' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.extra_info_title_two`
                                  )}
                                </Typography>
                                <div>
                                  <Controller
                                    name={item.risk_id + '_where'}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        autoComplete="off"
                                        size="small"
                                        margin="normal"
                                        className={classes.form_input}
                                        style={{
                                          backgroundColor: '#939393',
                                          borderRadius: '5px',
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </>
                            )}

                            {item.extra_info_type_three === 'select' && (
                              <>
                                <Typography className={classes.question_helper}>
                                  {t(
                                    `risk_improved_form.${i}.extra_info_title_three`
                                  )}
                                </Typography>
                                <div>
                                  <select
                                    className={classes.selectLabel}
                                    {...register(`${item.risk_id}_biopsy`)}
                                  >
                                    {item.extra_info_type_three_items_list?.map(
                                      (option, index) => (
                                        <option
                                          className={classes.select_options}
                                          key={index}
                                          value={option.opt_id}
                                        >
                                          {t(
                                            `risk_improved_form.${i}.extra_info_type_three_items_list.${index}.item_title`
                                          )}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </>
                            )}
                            {(item.complementary_type === 'input' ||
                              item.extra_info_type === 'input') && (
                              <Controller
                                name={item.risk_id + '_num_'}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    autoComplete="off"
                                    variant="filled"
                                    size="small"
                                    margin="normal"
                                    helperText={
                                      item.complementary_title &&
                                      item.complementary_type === 'input'
                                        ? t(
                                            `risk_improved_form.${i}.complementary_title`
                                          )
                                        : t(
                                            `risk_improved_form.${i}.extra_info_title`
                                          )
                                    }
                                    className={classes.form_input}
                                    style={{
                                      backgroundColor: '#939393',
                                      borderRadius: '5px',
                                    }}
                                  />
                                )}
                              />
                            )}
                          </div>
                        </>
                      )}
                  </div>
                )}
                <div
                  className={
                    item.complementary_type === 'checkbox_multiple'
                      ? classes.answer_container_multiple_list
                      : classes.answer_container
                  }
                >
                  {selectedRadioOptions.includes(item.risk_id) &&
                    item.answer_expandable === 'true' &&
                    item.complementary_type === 'checkbox_multiple' && (
                      <>
                        {item.complementary_items_list.map(
                          (complementaryItem, index) => (
                            <div
                              key={index}
                              className={classes.checkbox_multiple_options}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      handleCheckboxChange(
                                        `${item.risk_id}_${complementaryItem.opt_id}`,
                                        complementaryItem.opt_id,
                                        e.target.checked
                                      );
                                    }}
                                    color="primary"
                                    checked={
                                      watchedValues[
                                        `${item.risk_id}_${complementaryItem.opt_id}`
                                      ]
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    className={classes.selectLabelTexts}
                                  >
                                    {t(
                                      `risk_improved_form.${i}.complementary_items_list.${index}.item_title`
                                    )}
                                  </Typography>
                                }
                              />
                              {selectedCheckboxOption.some(
                                (option) =>
                                  option.family === complementaryItem.opt_id
                              ) && (
                                <div className={classes.family_container}>
                                  <>
                                    {/*  TYPE FAMILY */}
                                    <select
                                      {...register(
                                        `${item.risk_id}_${complementaryItem.opt_id}_type`
                                      )}
                                      id={
                                        complementaryItem.risk_id +
                                        complementaryItem.select_option
                                      }
                                      label={t(
                                        `risk_improved_form.${i}.complementary_items_list.${index}.select_option`
                                      )}
                                      className={
                                        classes.form_input_family_select
                                      }
                                    >
                                      {item.complementary_items_list[
                                        index
                                      ]?.select_options?.map(
                                        (option, index) => (
                                          <option
                                            className={classes.select_options}
                                            key={index}
                                            value={option.opt_id}
                                          >
                                            {t(
                                              `risk_improved_form.${i}.complementary_items_list.${index}.select_options.${index}.item_title`
                                            )}
                                          </option>
                                        )
                                      )}
                                    </select>

                                    {/* WHEN EXTRA FAMILY */}
                                    <Controller
                                      name={`${item.risk_id}_${complementaryItem.opt_id}`}
                                      control={control}
                                      defaultValue=""
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          type={
                                            complementaryItem?.helper_type ===
                                            'text'
                                              ? 'text'
                                              : 'number'
                                          }
                                          autoComplete="off"
                                          variant="filled"
                                          size="small"
                                          margin="normal"
                                          id={
                                            complementaryItem.risk_id +
                                            complementaryItem.opt_id
                                          }
                                          helperText={t(
                                            `risk_improved_form.${i}.complementary_items_list.${index}.helper_title`
                                          )}
                                          className={classes.form_input_family}
                                        />
                                      )}
                                    />
                                    {additionalFields[
                                      complementaryItem.opt_id
                                    ] &&
                                      additionalFields[
                                        complementaryItem.opt_id
                                      ].map((field, idx) => (
                                        <>
                                          <select
                                            className={
                                              classes.form_input_family_select
                                            }
                                            {...register(
                                              `${item.risk_id}_${complementaryItem.opt_id}_${idx}_type`
                                            )}
                                            id={
                                              complementaryItem.risk_id +
                                              complementaryItem.select_option
                                            }
                                            label={t(
                                              `risk_improved_form.${i}.complementary_items_list.${idx}.select_option`
                                            )}
                                          >
                                            {item.complementary_items_list[
                                              index
                                            ]?.select_options?.map(
                                              (option, index) => (
                                                <option
                                                  className={
                                                    classes.select_options
                                                  }
                                                  key={index}
                                                  value={option.opt_id}
                                                >
                                                  {t(
                                                    `risk_improved_form.${i}.complementary_items_list.${idx}.select_options.${index}.item_title`
                                                  )}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <Controller
                                            key={idx}
                                            name={`${item.risk_id}_${complementaryItem.opt_id}_${idx}`}
                                            control={control}
                                            render={({ field }) => (
                                              <TextField
                                                {...field}
                                                type={
                                                  complementaryItem.opt_id.includes(
                                                    'other'
                                                  )
                                                    ? 'text'
                                                    : 'number'
                                                }
                                                autoComplete="off"
                                                variant="filled"
                                                size="small"
                                                margin="normal"
                                                className={
                                                  classes.form_input_family
                                                }
                                                id={`${complementaryItem.risk_id}_${complementaryItem.item_title}_${idx}`}
                                                helperText={t(
                                                  `risk_improved_form.${i}.complementary_items_list.${index}.helper_title`
                                                )}
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        aria-label="delete field"
                                                        size="small"
                                                        onClick={() => {
                                                          resetField(
                                                            `${item.risk_id}_${complementaryItem.opt_id}_${idx}`
                                                          );
                                                          handleDeleteField(
                                                            `${item.risk_id}_${complementaryItem.opt_id}_${idx}`,
                                                            complementaryItem.opt_id,
                                                            idx
                                                          );
                                                        }}
                                                      >
                                                        <DeleteIcon fontSize="small" />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                          />
                                        </>
                                      ))}
                                    {complementaryItem?.helper_extra_info_type && (
                                      <Fab
                                        size="small"
                                        color="secondary"
                                        aria-label="add"
                                        className={classes.add_more_fab}
                                        onClick={() =>
                                          handleAddField(
                                            complementaryItem.opt_id
                                          )
                                        }
                                      >
                                        <AddIcon fontSize="small" />
                                      </Fab>
                                    )}
                                  </>
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </>
                    )}

                  {item.answer_type === 'input' && (
                    <div>
                      {item.answer_expandable === 'true' &&
                      item.complementary_type === 'input_multiple' ? (
                        <div>
                          {item.complementary_items_list.map(
                            (inputValues, i) => (
                              <Controller
                                key={inputValues.id}
                                name={inputValues.opt_id}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    autoComplete="off"
                                    variant="filled"
                                    size="small"
                                    margin="normal"
                                    helperText={t(
                                      `risk_improved_form.${
                                        item.id - 1
                                      }.complementary_items_list.${i}.item_title`
                                    )}
                                    autoFocus={inputValues.id === 0}
                                    className={classes.form_input_small}
                                  />
                                )}
                              />
                            )
                          )}
                        </div>
                      ) : (
                        <Controller
                          name={item.risk_id}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              autoComplete="off"
                              variant="filled"
                              size="small"
                              margin="normal"
                              helperText={
                                item.complementary_title
                                  ? t(
                                      `risk_improved_form.${
                                        item.id - 1
                                      }.complementary_title`
                                    )
                                  : undefined
                              }
                              className={classes.form_input}
                              style={{
                                backgroundColor: '#939393',
                                borderRadius: '5px',
                              }}
                            />
                          )}
                        />
                      )}
                    </div>
                  )}

                  {item.answer_type === 'select' && (
                    <select
                      className={classes.selectLabel}
                      style={{
                        width: '145px',
                        marginRight: '10px',
                      }}
                      {...register(`${item.risk_id}_`, { required: true })}
                    >
                      {item.complementary_items_list.map((option, index) => (
                        <option
                          className={classes.select_options}
                          key={index}
                          value={option.opt_id}
                        >
                          {option.item_title}
                        </option>
                      ))}
                    </select>
                  )}
                </div>

                <div className={classes.answer_container_list}>
                  {item.answer_type === 'checkbox' && (
                    <div className={classes.checkbox_container}>
                      {item.complementary_items_list.map(
                        (checkboxElement, index) => (
                          <div key={index}>
                            <div
                              className={
                                checkboxElement?.helper_type ||
                                checkboxElement?.helper_extra_type
                                  ? classes.checkbox_form_multiple_container
                                  : classes.checkbox_form_container
                              }
                            >
                              <Controller
                                name={`${item.risk_id}[${index}].${checkboxElement.opt_id}`}
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    key={checkboxElement.id}
                                    className={classes.checkbox_label}
                                    label={
                                      <Typography
                                        className={classes.selectLabelTexts}
                                      >
                                        {t(
                                          `risk_improved_form.${i}.complementary_items_list.${index}.item_title`
                                        )}
                                      </Typography>
                                    }
                                    control={
                                      <Checkbox
                                        onChange={field.onChange}
                                        color={'primary'}
                                        checked={
                                          watchedValues[item?.risk_id]?.[
                                            index
                                          ]?.[checkboxElement?.opt_id] === true
                                            ? true
                                            : field.value
                                        }
                                      />
                                    }
                                  />
                                )}
                              />
                              {checkboxElement.opt_id === 'other' && (
                                <Controller
                                  name={`${item.risk_id}[${index}].other_reason`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className={classes.textField}
                                      label={t(
                                        `risk_improved_form.${i}.complementary_items_list.${index}.helper_title`
                                      )}
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </form>
        )}
      </DialogContent>

      <DialogActions
        style={{
          position: 'sticky',
          bottom: 0,
          background: theme.palette.complementary.generalTitleBackgrounds,
        }}
      >
        {errorMessage && (
          <Typography className={classes.error_msg}>
            {t('risk_management.form_error')}
          </Typography>
        )}

        {
          <div className={classes.required_msg_container}>
            <Typography className={classes.required_msg}>
              {t('patient_risk.form_required')}
            </Typography>
          </div>
        }

        <Button
          data-test-id="dialog-cancel-action"
          className={classes.actionButton}
          onClick={() => {
            resetForm();
            dispatch(setActiveDialog(null));
          }}
          color="primary"
          key="draft"
        >
          {t('risk_management.cancel')}
        </Button>

        <span>
          <Button
            data-test-id="dialog-accept-action"
            className={classes.actionButton}
            onClick={handleSubmit(transformDataToRequest)}
            color="primary"
            key="save"
            variant="contained"
          >
            {t('risk_management.save')}
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  );
};

export default RiskAssessmentForm;
