const DENSITY_INITIAL_VALUES = ['A', 'B', 'C', 'D'];
const QUALITY_INITIAL_VALUES = [];
const POSTOP_INITIAL_VALUES = ['yes', 'no'];
const MODALITY_INITIAL_VALUES = ['mammo', 'tomo'];
const SCREENING_INITIAL_VALUES = ['yes', 'no'];
const MICROCALC_INITIAL_VALUES = ['birads2', 'birads3', 'birads4', 'birads5', 'none'];
const OPACITIES_INITIAL_VALUES = ['birads2', 'birads3', 'birads4', 'birads5', 'none'];
const MULTIEXAMS_INITIAL_VALUES = false;
const MULTICAPTURES_INITIAL_VALUES = false;
const REVISION_INITIAL_VALUES = [
  'not_confirmed',
  'confirmed_density',
  'confirmed_quality',
  'confirmed_opacities',
  'confirmed_microcalc',
];
const RISK_INITIAL_VALUES = ['normal', 'higher', 'increased'];

export const getFiltersInitialData = (qualityValues) => ({
  density: DENSITY_INITIAL_VALUES,
  quality: qualityValues ?? QUALITY_INITIAL_VALUES,
  postop: POSTOP_INITIAL_VALUES,
  modality: MODALITY_INITIAL_VALUES,
  screening: SCREENING_INITIAL_VALUES,
  opacities: OPACITIES_INITIAL_VALUES,
  microcalc: MICROCALC_INITIAL_VALUES,
  multiExams: MULTIEXAMS_INITIAL_VALUES,
  multiCaptures: MULTICAPTURES_INITIAL_VALUES,
  revision: REVISION_INITIAL_VALUES,
  risk: RISK_INITIAL_VALUES,
});
