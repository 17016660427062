import React, { useState, useEffect } from 'react';
import { Tooltip, Typography, IconButton } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from '../../ExaminationTable/ExaminationTable.style';
import * as actions from '../../../../../../store/index';

const ExaminationTableRisk = ({ exam_id, form_filled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentExam, fetchingExamList, pendingReprocessing } = useSelector((state) => state.examinations);
  const [aboutToOpen, setAboutToOpen] = useState(false);

  const openForm = (exam_id) => {
    dispatch(actions.setActiveDialog('risk_management'));
  };

  const openCompletedForm = (exam_id) => {
    dispatch(actions.setActiveDialog('risk_management_form'));
  };

  useEffect(() => {
    if (aboutToOpen) {
      dispatch(actions.setCurrentExam(exam_id));
    }
  }, [aboutToOpen]);

  useEffect(() => {
    if (!aboutToOpen) return;

    if (form_filled) {
      openForm(exam_id);
    } else {
      openCompletedForm(exam_id);
    }

    setAboutToOpen(false);
  }, [currentExam, exam_id, aboutToOpen]);

  return (
    <Tooltip
      title={
        <Typography style={{ fontSize: '0.8rem' }}>
          {form_filled > 0 ? t('risk_management.form_filled') : t('risk_management.form_incomplete')}
        </Typography>
      }
      arrow
      interactive
      placement="top-start"
    >
      <span>
        {form_filled > 0 ? (
          <IconButton size="small" onClick={() => setAboutToOpen(true)}>
            <AssignmentTurnedInIcon
              fontSize="small"
              className={classes.risk_icon_complete}
              style={{ color: fetchingExamList && '#5b8787' }}
            />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            onClick={() => setAboutToOpen(true)}
            disabled={fetchingExamList || pendingReprocessing}
          >
            <AssignmentIcon
              fontSize="small"
              className={classes.risk_icon_incomplete}
              style={{ color: fetchingExamList ? '#a9a375' : '#dac837' }}
            />
          </IconButton>
        )}
      </span>
    </Tooltip>
  );
};

export default ExaminationTableRisk;
