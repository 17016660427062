import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  useTheme,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useStyles } from '../ExaminationTable.style';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import SyncIcon from '@material-ui/icons/Sync';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../../store/index';
import { fetchGet, fetchPost, fetchPut } from '../../../../../../helpers/fetching';
import {
  setActionToConfirm,
  setSnackbar,
  deleteExams,
  deletePatient,
  fetchPriorExaminations,
} from '../../../../../../store';
import RepeatIcon from '@material-ui/icons/Repeat';

function ExaminationTableActions({ study_id, patient_id, isNestedExam, nestedRowTitle, singleExam, prior }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const currentRole = localStorage.getItem('role');

  const managementAccess =
    currentRole === 'admin' || currentRole === 'supervisor' || currentRole === 'radiologist';

  const { currentlyProcessed, currentExam, fetchingExamList } = useSelector((state) => state.examinations);

  const selected = currentExam === study_id;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteExam = (current_id) => {
    dispatch(
      setActionToConfirm({
        action: () => dispatch(deleteExams([current_id], t)),
        text: t('Confirm.delete_exam'),
      })
    );
    handleClose();
  };

  const handleDeletePatient = (current_id, t) => {
    dispatch(
      setActionToConfirm({
        action: () => dispatch(deletePatient(current_id, t)),
        text: t('Confirm.patient_delete') + '?',
      })
    );
    handleClose();
  };

  const handleRefreshExam = async (current_id) => {
    const response = await fetchPut('reprocess', { exam_ids: [current_id] }, 'bbox');
    if (response.success) {
      dispatch(
        setSnackbar({
          msg: t('Table.reprocessing_begun'),
          severity: 'success',
        })
      );
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
    handleClose();
  };

  const handleSearchPriors = (current_id) => {
    dispatch(fetchPriorExaminations([patient_id], t));
    handleClose();
  };

  return (
    <TableCell className={classes.tr_icon_padding} align="left" key="actions">
      {study_id === currentlyProcessed ? (
        <IconButton size="small">
          <CircularProgress
            style={{
              maxWidth: '16px',
              height: '16px',
              marginRight: '2px',
            }}
            color="secondary"
          />
        </IconButton>
      ) : (
        <>
          {fetchingExamList ||
          currentlyProcessed !== null ||
          (nestedRowTitle && !isNestedExam && !managementAccess) ? (
            <IconButton style={{ color: '#a1a1a1' }} size="small" disabled>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          ) : (
            <>
              <IconButton
                style={{ color: theme.palette.complementary.turquoise }}
                size="small"
                onClick={handleMenuClick}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <Menu
                id="menu-popular-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {((nestedRowTitle && !isNestedExam) || singleExam) && (
                  <MenuItem
                    onClick={() => {
                      handleSearchPriors(study_id);
                    }}
                    className={classes.table_actions_menu}
                  >
                    <RepeatIcon fontSize="small" className={classes.menu_icon} />
                    <Typography className={classes.menu_text}>{t('Confirm.fetch_priors')}</Typography>
                  </MenuItem>
                )}
                {((nestedRowTitle && isNestedExam) || !nestedRowTitle) && !prior && (
                  <MenuItem
                    onClick={() => handleRefreshExam(study_id)}
                    className={classes.table_actions_menu}
                  >
                    <SyncIcon fontSize="small" className={classes.menu_icon} />
                    <Typography className={classes.menu_text}> {t('Confirm.refresh')} </Typography>
                  </MenuItem>
                )}
                {isNestedExam ? (
                  <MenuItem onClick={() => handleDeleteExam(study_id)} className={classes.table_actions_menu}>
                    <DeleteIcon fontSize="small" className={classes.menu_icon} />
                    <Typography className={classes.menu_text}> {t('Confirm.delete')} </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => handleDeletePatient(study_id, t)}
                    className={classes.table_actions_menu}
                  >
                    <PersonAddDisabled fontSize="small" className={classes.menu_icon} />
                    <Typography className={classes.menu_text}>{t('Confirm.patient_delete')}</Typography>
                  </MenuItem>
                )}
                {((nestedRowTitle && isNestedExam) || !nestedRowTitle) && (
                  <MenuItem onClick={() => handleRefreshExam(exam_id)} className={classes.table_actions_menu}>
                    <SyncIcon fontSize="small" className={classes.menu_icon} />
                    <Typography className={classes.menu_text}> {t('Confirm.refresh')} </Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </>
      )}
    </TableCell>
  );
}

export default ExaminationTableActions;
