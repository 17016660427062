import { Tooltip, Typography, IconButton, Divider } from '@material-ui/core';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import DoneAllIcon from '@material-ui/icons/DoneAll';

export const getCurrentModuleStatus = (
  appliedResult,
  shownResult,
  isConfirmed,
  isRelabeled,
  moduleToConfirm,
  theme,
  t
) => {
  if (isRelabeled) {
    return {
      buttonText: t('ResultView.reannotated'),
      currentApplied: `${t('ResultView.appliedRevision')}: ${appliedResult}`,
      tooltipAction:
        appliedResult === shownResult ? t('ResultView.label_discard_icon') : t('ResultView.confirm_current'),
      fabIcon:
        appliedResult === shownResult ? (
          <SpellcheckIcon fontSize="medium" style={{ width: '18px', height: '18px' }} />
        ) : (
          <CheckIcon
            fontSize="medium"
            style={{
              width: '18px',
              height: '18px',
              fill: 'white',
            }}
          />
        ),
      color: appliedResult === shownResult ? theme.palette.complementary.turquoiseDark : '#6d6d6d',
    };
  } else if (isConfirmed)
    return {
      buttonText: t('ResultView.label_confirmed'),
      currentApplied: `${t('ResultView.label_confirmed')}: ${appliedResult}`,
      tooltipAction:
        appliedResult === shownResult ? t('ResultView.label_discard_icon') : t('ResultView.confirm_current'),
      fabIcon:
        appliedResult === shownResult ? (
          <DoneAllIcon fontSize="medium" style={{ width: '18px', height: '18px' }} />
        ) : (
          <CheckIcon
            fontSize="medium"
            style={{
              width: '18px',
              height: '18px',
              fill: 'white',
            }}
          />
        ),
      color: appliedResult === shownResult ? theme.palette.complementary.turquoiseDark : '#6d6d6d',
    };
  else
    return {
      buttonText: t('ResultView.confirm'),
      currentApplied:
        shownResult === 'bbox' ? t('ResultView.org_pred') : `${t('ResultView.relabeled')}: ${shownResult}`,
      tooltipAction: t('ResultView.btn_confirm', { module: moduleToConfirm }),
      fabIcon: (
        <CheckIcon
          fontSize="medium"
          style={{
            width: '18px',
            height: '18px',
            fill: 'white',
          }}
        />
      ),
      color: '#515151',
    };
};
