import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store';
import { useAppliedEvaluation, useEvaluation } from '../../../../helpers/hooks/useEvaluation';
import { getCurrentModuleStatus } from '../Summary/SummaryCommon/SummaryCommon';
import { Button, Tooltip, Typography, Fab, Divider } from '@material-ui/core';
import { confirmEvaluation, setCustomResults } from '../../../../store/slices/labeling';
import { ExitToApp } from '@material-ui/icons';
import ReplayIcon from '@material-ui/icons/Replay';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  button_group_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '4px',
  },

  revise_card_fabs: {
    margin: '6px 10px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  save_action_fabs: {
    margin: '6px 10px',
    height: '26px',
    minHeight: '26px',
    minWidth: '135px',
    backgroundColor: theme.palette.complementary.turquoise,
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  discard_action_fabs: {
    margin: '6px 10px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: 'rgb(109, 109, 109)',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoiseDark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },
}));

const ReviseCardActions = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  // Store state
  const { premium_features } = useSelector((store) => store.global_settings || {});

  const {
    densityInRevisionMode,
    diagnosticsInRevisionMode,
    qualityInDrawingMode,
    localChangesMicrocalc,
    localChangesOpacities,
    localChangesQuality,
    localChangesDensity,
    qualityInRevisionMode,
  } = useSelector((state) => state.labeling);
  const results = useSelector((state) => state.results);
  const {
    applied_density,
    applied_quality,
    confirmed_quality,
    confirmed_density,
    shown_density,
    shown_microcalc,
    shown_opacities,
    shown_quality,
  } = useSelector((state) => state.results);

  const { data_annotation } = premium_features;

  const densityShown = shown_density || applied_density;
  const qualityShown = shown_quality || applied_quality;
  const resultMicrocalcEvaluation = results?.evaluations?.microcalc?.[shown_microcalc];
  const resultOpacitiesEvaluation = results?.evaluations?.opacities?.[shown_opacities];
  const resultDensityEvaluation = results?.evaluations?.density?.[shown_density];

  const moduleConfirmed = qualityInRevisionMode
    ? applied_quality === 'bbox' && confirmed_quality
    : applied_density === 'bbox' && confirmed_density;
  const moduleRelabeled = qualityInRevisionMode
    ? confirmed_quality && applied_quality !== 'bbox'
    : confirmed_density && applied_density !== 'bbox';

  const shownResult = qualityInRevisionMode ? qualityShown : densityShown;
  const appliedresult = qualityInRevisionMode ? applied_quality : applied_density;
  const annotatorRole = data_annotation && localStorage.getItem('annotation') === 'true';

  const someLocalChanges =
    (qualityInRevisionMode && localChangesQuality) ||
    (densityInRevisionMode && localChangesDensity) ||
    (diagnosticsInRevisionMode && localChangesMicrocalc && localChangesOpacities);

  const onConfirmClick = () => {
    if (qualityInRevisionMode) {
      const state = shown_quality === applied_quality && confirmed_quality;
      dispatch(confirmEvaluation(qualityShown, 'quality', !state, t));
      dispatch(actions.setQualityInDrawingMode(false));
      dispatch(actions.setQualityLocalChanges(false));
    }

    if (densityInRevisionMode) {
      const state = shown_density === applied_density && confirmed_density;
      dispatch(confirmEvaluation(densityShown, 'density', !state, t));
      dispatch(actions.hideAllLesions());
      dispatch(actions.setDensityLocalChanges(false));
    }
    dispatch(actions.setTabsIndex(0));
  };

  const exitRevisionMode = () => {
    if (localChangesDensity || localChangesMicrocalc || localChangesOpacities || localChangesQuality) {
      dispatch(actions.setActiveDialog('unsavedChanges'));
    } else {
      if (qualityInRevisionMode) {
        dispatch(actions.setQualityInRevisionMode(true));
        dispatch(actions.setCustomQualityOverlays({}));
        dispatch(actions.setQualityLocalChanges(false));
        dispatch(actions.setQualityInDrawingMode(false));
      } else if (densityInRevisionMode) {
        dispatch(actions.setDensityInRevisionMode(false));
        dispatch(actions.setDensityLocalChanges(false));
        dispatch(actions.setCustomDensity(resultDensityEvaluation));
      } else if (diagnosticsInRevisionMode) {
        dispatch(actions.setDiagnosticsInRevisionMode(false));
        dispatch(actions.setMicrocalcLocalChanges(false));
        dispatch(actions.setCustomMicrocalc(resultMicrocalcEvaluation));
        dispatch(actions.setOpacitiesLocalChanges(false));
        dispatch(actions.setCustomOpacities(resultOpacitiesEvaluation));
      }
      dispatch(actions.setTabsIndex(0));
      dispatch(actions.hideAllLesions());
    }
  };

  const discardCurrentChanges = () => {
    if (localChangesQuality) {
      dispatch(actions.setQualityInRevisionMode(true));
      dispatch(actions.setCustomQualityOverlays({}));
      dispatch(actions.setQualityLocalChanges(false));
      dispatch(actions.setQualityInDrawingMode(false));
    } else if (diagnosticsInRevisionMode) {
      dispatch(actions.setDiagnosticsInRevisionMode(true));
      dispatch(actions.setOpacitiesLocalChanges(false));
      dispatch(actions.setCustomOpacities(resultOpacitiesEvaluation));
      dispatch(actions.setMicrocalcLocalChanges(false));
      dispatch(actions.setCustomMicrocalc(resultMicrocalcEvaluation));
    } else if (densityInRevisionMode) {
      dispatch(actions.setCustomDensity(resultDensityEvaluation));
      dispatch(actions.setDensityLocalChanges(false));
      dispatch(actions.setDensityInRevisionMode(true));
    }
    dispatch(setCustomResults(results));
  };

  const saveResults = () => {
    if (qualityInRevisionMode) {
      dispatch(actions.saveEvaluation('quality', t));
      dispatch(actions.setQualityInRevisionMode(false));
      dispatch(actions.setInitialImages());
      dispatch(actions.setQualityLocalChanges(false));
      dispatch(actions.setQualityInDrawingMode(false));
      dispatch(actions.setTabsIndex(1));
    } else if (densityInRevisionMode) {
      dispatch(actions.saveEvaluation('density', t));
      dispatch(actions.setDensityLocalChanges(false));
      dispatch(actions.setTabsIndex(0));
    } else if (diagnosticsInRevisionMode) {
      dispatch(actions.saveEvaluation('opacities', t));
      dispatch(actions.saveEvaluation('microcalc', t));
      dispatch(actions.setDiagnosticsInRevisionMode(false));
      dispatch(actions.setInitialImages());
      dispatch(actions.setMicrocalcLocalChanges(false));
      dispatch(actions.setOpacitiesLocalChanges(false));
      dispatch(actions.setTabsIndex(0));
    }
    dispatch(actions.setZoomedProjection(null));
  };

  return (
    <div className={classes.button_group_container}>
      <>
        <Tooltip
          title={<Typography className={classes.tooltipText}>{t('ResultView.btn_exit_revision')}</Typography>}
          arrow
          placement="top-start"
        >
          <Fab
            size="small"
            onClick={() => exitRevisionMode()}
            color="primary"
            className={classes.revise_card_fabs}
          >
            <ExitToApp fontSize="medium" style={{ width: '22px', height: '22px', transform: 'scaleX(-1)' }} />
          </Fab>
        </Tooltip>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <>
            {someLocalChanges ? (
              <>
                {!qualityInDrawingMode && (
                  <Tooltip
                    title={
                      <Typography className={classes.tooltipText}>{t('ResultView.btn_discard')}</Typography>
                    }
                    arrow
                    placement="top-start"
                  >
                    <Fab
                      size="small"
                      className={classes.discard_action_fabs}
                      color="primary"
                      onClick={() => {
                        discardCurrentChanges();
                      }}
                    >
                      <ReplayIcon />
                    </Fab>
                  </Tooltip>
                )}
                <Tooltip
                  title={<Typography className={classes.tooltipText}>{t('ResultView.btn_save')}</Typography>}
                  arrow
                  placement="top-start"
                >
                  <Button
                    size="small"
                    onClick={() => saveResults()}
                    className={classes.save_action_fabs}
                    startIcon={<SaveIcon fontSize="medium" style={{ width: '22px', height: '22px' }} />}
                  >
                    {t('ResultView.save')}
                  </Button>
                </Tooltip>
              </>
            ) : (
              annotatorRole &&
              (qualityInRevisionMode || densityInRevisionMode) && (
                <>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <>
                        <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                          {
                            getCurrentModuleStatus(
                              appliedresult,
                              shownResult,
                              moduleConfirmed,
                              moduleRelabeled,
                              qualityInRevisionMode ? t('ResultView.quality') : t('ResultView.density'),
                              theme,
                              t
                            ).currentApplied
                          }
                        </Typography>
                        <Divider style={{ margin: '3% 0' }} />
                        <Typography className={classes.tooltipText}>
                          {
                            getCurrentModuleStatus(
                              appliedresult,
                              shownResult,
                              moduleConfirmed,
                              moduleRelabeled,
                              qualityInRevisionMode ? t('ResultView.quality') : t('ResultView.density'),
                              theme,
                              t
                            ).tooltipAction
                          }
                        </Typography>
                      </>
                    }
                  >
                    <Button
                      color="primary"
                      startIcon={
                        getCurrentModuleStatus(
                          appliedresult,
                          shownResult,
                          moduleConfirmed,
                          moduleRelabeled,
                          qualityInRevisionMode ? t('ResultView.quality') : t('ResultView.density'),
                          theme,
                          t
                        ).fabIcon
                      }
                      style={{
                        color: '#fff',
                        background: `${
                          getCurrentModuleStatus(
                            appliedresult,
                            shownResult,
                            moduleConfirmed,
                            moduleRelabeled,
                            qualityInRevisionMode ? t('ResultView.quality') : t('ResultView.density'),
                            theme,
                            t
                          ).color
                        }`,
                        margin: '4px',
                        textWrap: 'nowrap',
                      }}
                      className={classes.save_action_fabs}
                      onClick={() => {
                        onConfirmClick();
                      }}
                    >
                      {
                        getCurrentModuleStatus(
                          appliedresult,
                          shownResult,
                          moduleConfirmed,
                          moduleRelabeled,
                          qualityInRevisionMode ? t('ResultView.quality') : t('ResultView.density'),
                          theme,
                          t
                        ).buttonText
                      }
                    </Button>
                  </Tooltip>
                </>
              )
            )}
          </>
        </div>
      </>
    </div>
  );
};
export default ReviseCardActions;
