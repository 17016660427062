import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getProjectionPoint } from '../../../../../helpers/vectorOperations';

const useStyles = makeStyles((theme) => ({
  svg_lines: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '1px',
    zIndex: 9,
    userSelect: 'none',
  },

  Line: {
    position: 'absolute',
    strokeDasharray: '8, 6',
    zIndex: 10,
    borderColor: '#ccc',
    strokeWidth: '2px',
  },

  pect_level_line: {
    position: 'absolute',
    strokeDasharray: '6,4',
    zIndex: 9,
    strokeWidth: '1.5px',
  },

  EdgePoint: {
    position: 'absolute',
    width: '12px',
    height: '12px',
    background: '#F6685E',
    zIndex: 460,
    borderRadius: '50%',
    opacity: 0.8,
  },

  IMF_Point: {
    position: 'absolute',
    width: '12px',
    height: '12px',
    background: '#F6685E',
    zIndex: 10,
    borderRadius: '50%',
    opacity: 0.8,
  },

  nippleEdgePoint: {
    position: 'absolute',
    width: '28px',
    height: '28px',
    background: 'transparent',
    zIndex: 10,
    border: '1px solid #46B0B0',
    borderRadius: '5px',
  },

  IMFEdgePoint: {
    position: 'absolute',
    background: 'transparent',
    zIndex: 10,
    border: '1px solid #46B0B0',
    borderRadius: '5px',
  },
}));

export default function LineComponent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { quality_system } = useSelector((store) => store.global_settings);
  const [lines, setLines] = useState([0, 0]);
  const [points, setPoints] = useState([0, 0]);
  const [currentValues, setCurrentValues] = useState(null);
  const defaultColor = '#46B0B0';
  const isMLOProjection = props.proj_name.includes('mlo');
  const { pnl_from_edge } = quality_system.quality_thresholds;
  const { pect_level_classes } = quality_system.quality_features;

  const projectionPoint = () => {
    if (isMLOProjection) {
      const newProjPoint = getProjectionPoint(
        currentValues?.location_pecto_top,
        currentValues?.location_pecto_bottom,
        currentValues?.location_nipple,
        props?.projection?.img_size_x,
        props?.projection?.img_size_y,
        props?.proj_name?.charAt(0) === 'r',
        pnl_from_edge
      );
      newProjPoint[0] = `${(100 * newProjPoint[0]) / props?.projection.img_size_x}%` ?? 0;
      newProjPoint[1] = `${(100 * newProjPoint[1]) / props?.projection.img_size_y}%` ?? 0;
      return newProjPoint;
    } else {
      return [
        props.proj_name?.charAt(0) === 'r' ? '100%' : '0',
        `${100 * currentValues?.location_nipple[1]}%`,
      ];
    }
  };

  const nipplePoint = () => {
    let newProjPoint = [0, 0];
    newProjPoint[0] = `${(100 * 3) / props?.projection.img_size_x}%` ?? 0;
    newProjPoint[1] = `${(100 * 6) / props?.projection.img_size_y}%` ?? 0;
    return newProjPoint;
  };

  useEffect(() => {
    const newCurrentValues = { ...props.evaluation };
    if (!newCurrentValues.hasOwnProperty('location_nipple_projection')) {
      newCurrentValues['location_nipple_projection'] = [
        props.proj_name?.charAt(0) === 'r' ? 1 : 0,
        newCurrentValues?.location_nipple?.[1],
      ];
    }
    setCurrentValues(newCurrentValues);
  }, [props.evaluation]);

  useEffect(() => {
    if (!!currentValues && !!currentValues.location_nipple) {
      if (
        props.showRevisionLines ||
        props.showProjLines ||
        props.showPetcAngleLine ||
        props.showNippleEdge ||
        props.showIMF ||
        props.showPectoLevel
      ) {
        if (
          props.proj_name.includes('cc') ||
          (!!currentValues?.location_pecto_top && !!currentValues?.location_pecto_bottom) ||
          (isMLOProjection &&
            (!currentValues?.location_pecto_top || !currentValues?.location_pecto_bottom) &&
            currentValues?.location_nipple)
        ) {
          if (
            isMLOProjection &&
            (!currentValues?.location_pecto_top || !currentValues?.location_pecto_bottom) &&
            currentValues?.location_nipple
          ) {
            setLines([
              {
                x1: `${100 * currentValues?.location_nipple[0]}%`,
                y1: `${100 * currentValues?.location_nipple[1]}%`,
                x2: nipplePoint()[0],
                y2: nipplePoint()[1],
                p1: true,
                p2: false,
                onFirstPointClick: () => props.setMovePoint('location_nipple'),
              },
            ]);
          } else {
            setLines([
              {
                x1: `${100 * currentValues?.location_nipple[0]}%`,
                y1: `${100 * currentValues?.location_nipple[1]}%`,
                x2: isMLOProjection
                  ? projectionPoint()[0]
                  : `${100 * currentValues?.location_nipple_projection[0]}%`,
                y2: isMLOProjection
                  ? projectionPoint()[1]
                  : `${100 * currentValues?.location_nipple_projection[1]}%`,
                p1: true,
                p2: !isMLOProjection,
                onFirstPointClick: () => props.setMovePoint('location_nipple'),
                onSecondPointClick: () => props.setMovePoint('location_nipple_projection'),
              },
              isMLOProjection && {
                x1: `${100 * currentValues?.location_pecto_top[0]}%`,
                y1: `${100 * currentValues?.location_pecto_top[1]}%`,
                x2: `${100 * currentValues?.location_pecto_bottom[0]}%`,
                y2: `${100 * currentValues?.location_pecto_bottom[1]}%`,
                p1: false,
                p2: false,
                onFirstPointClick: () => props.setMovePoint('location_pecto_top'),
                // onSecondPointClick: () =>
                //    props.setMovePoint('location_pecto_bottom'),
              },
            ]);
          }
        }

        if ((props.showRevisionLines || props.showIMF) && isMLOProjection) {
          setPoints([
            {
              x: `${100 * currentValues?.location_imf?.[0]}%`,
              y: `${100 * currentValues?.location_imf?.[1]}%`,
              onFirstPointClick: () => props.setMovePoint('location_imf'),
            },
          ]);
        }
      }
    } else return;
  }, [
    currentValues,
    props.showProjLines,
    props.showRevisionLines,
    props.showPetcAngleLine,
    props.showNippleEdge,
    props.showIMF,
    props.showPectoLevel,
  ]);

  const lineColor = (value) => {
    if (props.parenchymaShow || props.parenchymaMedialShow || props.parenchymaLateralShow) return '#dfdfdf';
    if (!value) return '#dfdfdf';
    if (value === 'insufficient') return theme.palette.projections.insufficient;
    if (value === 'moderate') return theme.palette.projections.moderate;
    if (value === 'correct') return theme.palette.projections.nippleStraight;
    return '#b9b9b9';
  };

  const pectLinesColor = (value, feature) => {
    const defaultColor = '#46B0B0';

    if (!value) return defaultColor;

    switch (value) {
      case 'correct':
        return theme.palette.projections.adequate;
      case 'moderate':
        return feature === 'pnl'
          ? theme.palette.projections.adequate
          : theme.palette.projections.insufficient;
      case 'insufficient':
        return theme.palette.projections.insufficient;
      default:
        return defaultColor;
    }
  };

  const pnlLines = () => {
    if (props.showPectoLevel) {
      return lines
        .filter((line) => line.p1)
        .map((line, i) => (
          <React.Fragment key={`line-${i}`}>
            {isMLOProjection && pect_level_classes?.length > 2 && (
              <line
                className={classes.pect_level_line}
                x1={props.proj_name === 'rmlo' ? `calc(${line.x2} + 6px)` : line.x1}
                y1={line.y1}
                x2={props.proj_name === 'rmlo' ? line.x1 : `calc(${line.x2} - 6px)`}
                y2={line.y1}
                stroke={pectLinesColor(props?.evaluation?.PectLevel, 'nippleLine')}
              />
            )}

            <line
              key={`pnl-line-${i}`}
              className={classes.Line}
              x1={line.x1}
              y1={line.y1}
              x2={line.x2}
              y2={line.y2}
              stroke={pectLinesColor(props?.evaluation?.PectLevel, 'pnl')}
            />
            {isMLOProjection && lines && (
              <line
                key={`lines-${i}`}
                className={classes.Line}
                x1={lines[1]?.x1 ?? 0}
                y1={lines[1]?.y1 ?? 0}
                x2={lines[1]?.x2 ?? 0}
                y2={lines[1]?.y2 ?? 0}
                stroke={defaultColor}
              />
            )}
          </React.Fragment>
        ));
    }

    if (props.showProjLines) {
      return lines
        .filter((line) => line.p1)
        .map((line, i) => (
          <line
            key={i + 'line'}
            className={classes.Line}
            x1={line.x1}
            y1={line.y1}
            x2={line.x2}
            y2={line.y2}
            stroke={props.showProjLines ? lineColor(props?.evaluation?.PNL) : '#F6685E'}
          />
        ));
    }

    if (props.showNippleEdge && !props.showPectoLevel) {
      return lines
        .filter((line) => line.p1)
        .map((line, i) => (
          <div
            key={i + 'p1'}
            className={classes.nippleEdgePoint}
            style={{
              left: `calc(${line.x1} - 6px`,
              top: `calc(${line.y1} - 6px`,
            }}
          />
        ));
    }

    if (props.showPetcAngleLine && lines) {
      return (
        <line
          key={lines[1] + 'line'}
          className={classes.Line}
          x1={lines[1]?.x1 ?? 0}
          y1={lines[1]?.y1 ?? 0}
          x2={lines[1]?.x2 ?? 0}
          y2={lines[1]?.y2 ?? 0}
          stroke={props.showPetcAngleLine ? lineColor(props?.evaluation?.PectAngle) : '#F6685E'}
        />
      );
    } else if ((props.parenchymaShow || props.parenchymaMedialShow || props.parenchymaLateralShow) && lines) {
      return (
        <line
          key={lines[0] + 'line'}
          className={classes.Line}
          x1={lines[0]?.x1}
          y1={lines[0]?.y1}
          x2={lines[0]?.x2}
          y2={lines[0]?.y2}
          stroke={'#dfdfdf'}
        />
      );
    } else
      return lines.map((line, i) => (
        <line
          key={i + 'line'}
          className={classes.Line}
          x1={line.x1}
          y1={line.y1}
          x2={line.x2}
          y2={line.y2}
          stroke={props.showProjLines ? lineColor(props?.evaluation?.PNL) : '#F6685E'}
        />
      ));
  };

  const linesComponent = () => (
    <>
      {(props.showRevisionLines ||
        props.showProjLines ||
        props.showPetcAngleLine ||
        props.showPectoLevel ||
        props.showNippleEdge) && (
        <svg
          className={classes.svg_lines}
          onMouseUp={props.onMouseUp}
          onMouseMove={props.onMouseMove}
          onMouseDown={props.onMouseDown}
          onWheel={props.onWheel}
          onContextMenu={props.onContextMenu}
        >
          {!props.showIMF &&
            !(
              isMLOProjection &&
              (!currentValues?.location_pecto_top || !currentValues?.location_pecto_bottom) &&
              currentValues?.location_nipple
            ) &&
            pnlLines()}
        </svg>
      )}
      {/* Nipple */}
      {(props.showRevisionLines || props.showNippleEdge) &&
        !props.showProjLines &&
        !props.showPetcAngleLine &&
        !props.parenchymaShow &&
        !props.showIMF &&
        !props.showPectoLevel &&
        lines
          .filter((line) => line.p1)
          .map((line, i) => (
            <div
              key={i + 'p1'}
              className={props.showNippleEdge ? classes.nippleEdgePoint : classes.EdgePoint}
              style={{
                left: `calc(${line.x1} - ${props.showNippleEdge ? '12px' : '6px'})`,
                top: `calc(${line.y1} - ${props.showNippleEdge ? '12px' : '6px'})`,
                cursor: 'move',
              }}
              onMouseDown={line.onFirstPointClick}
              onMouseUp={props.onMouseUp}
              onMouseMove={props.onMouseMove}
            />
          ))}
      {/* Nipple line end */}
      {!isMLOProjection &&
        (props.showRevisionLines || props.showNippleEdge) &&
        !props.showProjLines &&
        !props.showPetcAngleLine &&
        !props.parenchymaShow &&
        !props.showIMF &&
        !props.showPectoLevel &&
        !props.showNippleEdge &&
        lines
          .filter((line) => line.p2)
          .map((line, i) => (
            <div
              key={i + 'p2'}
              className={classes.EdgePoint}
              style={{
                left: `calc(${line.x2} - ${props.showNippleEdge ? '12px' : '6px'})`,
                top: `calc(${line.y2} - ${props.showNippleEdge ? '12px' : '6px'})`,
                cursor: 'move',
              }}
              onMouseDown={line.onSecondPointClick}
              onMouseUp={props.onMouseUp}
              onMouseMove={props.onMouseMove}
            />
          ))}
      {/* INFRAMAMMARY FOLD POINT */}
      {(props.showRevisionLines || props.showIMF) &&
        !props.showProjLines &&
        !props.showPetcAngleLine &&
        !props.showNippleEdge &&
        !props.showPectoLevel &&
        points &&
        isMLOProjection &&
        points.map((point, i) => (
          <div
            key={i + 'p'}
            className={classes.IMF_Point}
            style={{
              left: `calc(${point.x} - 6px)`,
              top: `calc(${point.y} - 6px)`,
              cursor: 'move',
            }}
            onMouseDown={point.onFirstPointClick}
            onMouseUp={props.onMouseUp}
            onMouseMove={props.onMouseMove}
          />
        ))}
      {/* INFRAMAMMARY BOX  */}
      {isMLOProjection &&
        props.showIMF &&
        !props.showProjLines &&
        !props.showPetcAngleLine &&
        !props.parenchymaShow &&
        !props.parenchymaMedialShow &&
        !props.parenchymaLateralShow &&
        !props.showPectoLevel &&
        points &&
        points.map((point, i) => (
          <div
            key={i + 'imf'}
            className={classes.IMFEdgePoint}
            style={{
              width: point.x === '0%' || point.x === '100%' ? '5%' : '15%',
              height: '13%',
              left: `calc(${point.x} - ${point.x === '0%' || point.x === '100%' ? '2.5%' : '7.5%'})`,
              top: `calc(${point.y} - 6.5%)`,
            }}
          />
        ))}
    </>
  );

  return <>{linesComponent()}</>;
}
