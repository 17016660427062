import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    letterSpacing: '0.1rem',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.texts.secondary,
    whiteSpace: 'pre-line',
  },
  dialogUserName: {
    letterSpacing: '0.1rem',
    textTransform: 'Uppercase',
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.primary.main,
  },
}));

function ConfirmDialog(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const activeDialog = useSelector((state) => state.gui.activeDialog);

  const { actionToConfirm } = useSelector((state) => state.gui);

  return (
    <DraggableDialog
      maxWidth="sm"
      title={props?.dialogTitle ? props.dialogTitle : t('Confirm.header')}
      open={props.open}
      actions={[
        {
          onClick: () => {
            props.action();
            props.onClose();
          },
          color: 'primary',
          label:
            activeDialog === 'report'
              ? t('Confirm.send')
              : actionToConfirm?.buttonOk
              ? actionToConfirm.buttonOk
              : t('Confirm.confirm'),
        },
        {
          onClick: props.onClose,
          color: 'primary',
          label: actionToConfirm?.butonCancel ? actionToConfirm?.butonCancel : t('Confirm.cancel'),
        },
      ]}
      onClose={props.onClose}
    >
      <Typography className={classes.dialogText}>{props.text}</Typography>
    </DraggableDialog>
  );
}

export default ConfirmDialog;
