/* eslint no-eval: 0 */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Tooltip,
  Typography,
  Tabs,
  Tab,
  CardHeader,
  Card,
  CardActions,
  Fab,
  Icon,
  CardContent,
  Badge,
  Paper,
  Popper,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  ListSubheader,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReplayIcon from '@material-ui/icons/Replay';
import SpellCheckIcon from '@material-ui/icons/Spellcheck';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import * as actions from '../../../../store';
import ReviewSelect from '../CardActions/ReviewSelect/ReviewSelect';
import Logo from '../../../../resources/coorporate_images/brayz_logo_small.png';
import DraggableDialog from '../../Dialogs/DraggableDialog/DraggableDialog';
import BarChartIcon from '@material-ui/icons/BarChart';
import DensitySection from './Density/DensitySection';
import GroupIcon from '@material-ui/icons/Group';
import DiagnosticRevisionTable from './Diagnostic/DiagnosticRevisionTable';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import QualityView from '../QualityView/QualityView';

const useStyles = makeStyles((theme) => ({
  diagnostics_main_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  titleResultCard: {
    marginLeft: '10px',
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveBig,
    alignSelf: 'center',
    minWidth: 'fit-content',
    textTransform: 'none',
  },

  mloRow: {
    marginBottom: theme.spacing(3),
  },

  progress: {
    width: '100%',
    margin: '2%',
  },

  premium: {
    fontSize: '2rem',
    color: theme.palette.complementary.mainActions,
  },

  premium_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  premium_content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },

  logo: {
    height: '28px',
    width: 'auto',
  },
  premium_cta: {
    fontSize: '1rem',
    color: theme.palette.complementary.mainActions,
  },
  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  labeling_stats_fab: {
    margin: '2%',
    width: '32px',
    minWidth: '32px',
    height: '32px',
    minHeight: '32px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  density_container: {
    width: '100%',
    margin: '2% 2% 0 2%',
  },

  diagnostic_container: {
    height: '100%',
    width: '100%',
    maxHeight: '85vh',
    overflowX: 'auto',
  },

  quality_container: {
    width: '100%',
    height: '84vh',
  },

  quality_container_revising: {
    width: '100%',
    height: '87vh',
  },

  form_display: {
    width: '25%',
  },

  form_display_texts: {
    fontSize: theme.fonts.responsiveMedium,
  },
  reviewer_fabs: {
    margin: '6px 10px',
    height: '22px',
    width: '22px',
    minHeight: '22px',
    minWidth: '22px',
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  reviewer_badge: {
    height: '20px',
    minHeight: '20px',
    fontSize: theme.fonts.responsiveMedium,
    minWidth: '70px',
    border: `1px solid ${theme.palette.complementary.turquoise}`,
    color: theme.palette.complementary.turquoise,
  },

  tooltip_container: {
    display: 'flex',
    alignItems: 'center',
  },

  density_reviewer_shape: {
    marginLeft: '16px',
  },
}));

function DanaiTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const results = useSelector((state) => state.results);

  // Store state
  const { tabsIndex } = useSelector((store) => store.gui);
  const { densityInRevisionMode, diagnosticsInRevisionMode, qualityInRevisionMode } = useSelector(
    (state) => state.labeling
  );
  const {
    projections: projData,
    applied_density,
    shown_density,
    applied_microcalc,
    applied_opacities,
    shown_microcalc,
    shown_opacities,
  } = useSelector((state) => state.results);
  const anchorDensityRef = useRef(null);

  // Local State
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [generalSettings, setGeneralSettings] = useState({});
  const [densityReviewerTooltip, setDensityReviewerTooltip] = useState(false);
  const [densityPopperOpen, setDensityPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //Reviewer menu handler
  const handlePopperToggle = () => {
    setDensityPopperOpen((prevOpen) => !prevOpen);
    setDensityReviewerTooltip(false);
  };

  const clickAwayHandler = () => {
    setAnchorEl(null);
    setDensityPopperOpen(false);
    setDensityReviewerTooltip(false);
  };

  useEffect(() => {
    if (!shown_density) dispatch(actions.setShownDensity(applied_density));
    if (!shown_microcalc) dispatch(actions.setShownMicrocalc(applied_microcalc));
    if (!shown_opacities) dispatch(actions.setShownOpacities(applied_opacities));
  }, [applied_density, applied_microcalc, applied_opacities, results]);

  return (
    <Card
      style={{
        height: '100%',
        border: 'none !important',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        justifyContent: 'space-between',
      }}
      elevation={0}
    >
      <CardContent style={{ padding: 0 }}>
        {/* b-Density  revision*/}
        <div className={classes.diagnostics_main_container}>
          {densityInRevisionMode && (
            <div className={classes.density_container} style={{ height: densityInRevisionMode && '80vh' }}>
              <div>
                <DensitySection />
              </div>
            </div>
          )}

          {/* b-Diagnose revision*/}
          <div className={classes.diagnostic_container}>
            {diagnosticsInRevisionMode && <DiagnosticRevisionTable />}
          </div>

          {/* b-Quality revision*/}

          {qualityInRevisionMode && (
            <div className={tabsIndex === 2 ? classes.quality_container_revising : classes.quality_container}>
              <QualityView />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default DanaiTab;
