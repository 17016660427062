import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../../../helpers/formatDate.js';
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useDispatch } from 'react-redux';
import { setActionToConfirm } from '../../../../store/index.js';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  menu_item_dates: {
    fontSize: 12,
    '&.Mui-selected': {
      background: theme.palette.complementary.turquoiseDark,
      fontWeight: 'bold',
    },
    '&.Mui-selected:hover': {
      background: theme.palette.complementary.turquoise,
    },
  },

  selectIcon: {
    right: '0',
  },

  selectRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '0 24px 0 0',
    fontSize: '12px',
    height: '20px',
  },

  select: {
    fontSize: '12px',
    height: '20px',
    padding: '4px 12px',
  },
}));

const DatePicker = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [datePreset, setDatePreset] = useState('');

  const { customDate } = props;

  useEffect(() => {
    setDatePreset(props.selectedRange || 'day');
  }, [props.selectedRange]);

  const handleDatePreset = (newPreset) => {
    const todayBegin = formatDate(new Date(), '00:00');
    const todayEnd = formatDate(new Date(), '23:59');
    switch (newPreset) {
      case 'day':
        props.setDates([todayBegin, todayEnd]);
        props.setSelectedRange('day');
        break;
      case 'week':
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 6);
        props.setDates([formatDate(lastWeek, '00:00'), todayEnd]);
        props.setSelectedRange('week');
        break;
      case 'all':
        props.setDates(null);
        props.setSelectedRange('all');
        break;
      case 'custom':
        props.setDates(customDate);
        props.setSelectedRange('custom');
        break;
      default:
        throw new Error('Incorrect date preset.');
    }
    setDatePreset(newPreset);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        id="date-selector-id"
        value={datePreset}
        onChange={(e) => handleDatePreset(e.target.value)}
        displayEmpty
        classes={{
          icon: classes.selectIcon,
          root: classes.select,
        }}
        renderValue={(selected) => (
          <div className={classes.selectRoot}>
            <CalendarTodayIcon fontSize="small" style={{ height: '16px' }} />
            {t(`DatePicker.${selected || 'day'}`)}
          </div>
        )}
        SelectDisplayProps={{
          style: {
            minWidth: '80px',
            padding: '4px 28px 4px 20px',
            fontSize: '12px',
            textOverflow: 'ellipsis',
          },
        }}
      >
        <MenuItem className={classes.menu_item_dates} value={'day'}>
          {t('DatePicker.day')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'week'}>
          {t('DatePicker.week')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'all'}>
          {t('DatePicker.all')}
        </MenuItem>
        <MenuItem className={classes.menu_item_dates} value={'custom'}>
          {t('DatePicker.custom')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default DatePicker;
