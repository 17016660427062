import QualityChips from './QualityChips';
import { symmetryInfo } from '../utils/qualityUtils';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';
import { evaluationClassColor } from '../../../../../helpers/qualityColor';
import { Tooltip } from '@material-ui/core';

// SYMMETRY CHIP
export const SymmetrySection = ({
  qualityEvaluation,
  onClickSymmetry,
  projections,
  symmetry_classes,
  qualityInRevisionMode,
  classes,
  theme,
  t,
  dispatch,
  actions,
}) => {
  return (
    <>
      {qualityEvaluation?.[projections[0]]?.symmetry && (
        <QualityChips
          evaluationClass={qualityEvaluation?.[projections[0]]?.symmetry}
          evaluationLabel={useQualityTranslation(qualityEvaluation?.[projections[0]]?.symmetry, t)}
          chipAction={() =>
            onClickSymmetry('symmetry', projections[0]?.toUpperCase(), symmetry_classes, dispatch, actions)
          }
          tooltipText={symmetryInfo(
            qualityEvaluation?.[projections[0]]?.symmetry,
            projections[0]?.toUpperCase(),
            theme,
            t
          )}
          extraStyles={{ display: qualityInRevisionMode ? 'flex' : 'none' }}
        />
      )}
      <div className={classes.symmetry_icon}>
        {qualityEvaluation?.[projections[0]]?.symmetry && qualityEvaluation?.[projections[1]]?.symmetry && (
          <>
            <Tooltip
              title={symmetryInfo(
                qualityEvaluation?.[projections[0]]?.symmetry,
                projections[0]?.toUpperCase(),
                theme,
                t
              )}
              arrow
              placement="bottom"
            >
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '16px 16px 0 8px',
                  borderColor: `transparent ${evaluationClassColor(
                    qualityEvaluation?.[projections[0]]?.symmetry,
                    theme
                  )} transparent transparent`,
                  marginRight: '1px',
                }}
              />
            </Tooltip>

            <Tooltip
              title={symmetryInfo(
                qualityEvaluation?.[projections[1]]?.symmetry,
                projections[1]?.toUpperCase(),
                theme,
                t
              )}
              arrow
              placement="top"
            >
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderStyle: 'solid',
                  borderWidth: '16px 8px 0 16px',
                  borderColor: `transparent transparent transparent ${evaluationClassColor(
                    qualityEvaluation?.[projections[1]]?.symmetry,
                    theme
                  )}`,
                  marginLeft: '1px',
                }}
              />
            </Tooltip>
          </>
        )}
      </div>
      {qualityEvaluation?.[projections[1]]?.symmetry && (
        <QualityChips
          evaluationClass={qualityEvaluation?.[projections[1]]?.symmetry}
          evaluationLabel={useQualityTranslation(qualityEvaluation?.[projections[1]]?.symmetry, t)}
          chipAction={() =>
            onClickSymmetry('symmetry', projections[1]?.toUpperCase(), symmetry_classes, dispatch, actions)
          }
          tooltipText={symmetryInfo(
            qualityEvaluation?.[projections[1]]?.symmetry,
            projections[1]?.toUpperCase(),
            theme,
            t
          )}
          extraStyles={{ display: qualityInRevisionMode ? 'flex' : 'none' }}
        />
      )}
    </>
  );
};
