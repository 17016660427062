import React, { useState, useEffect } from 'react';
import * as actions from '../../../../../store/index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { fetchPut, fetchPost } from '../../../../../helpers/fetching';
import { setActionToConfirm, setSnackbar, deleteExams } from '../../../../../store';
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { fetchPriorExaminations, movePriorExaminations } from '../../../../../store/slices/examinations';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },

  spinner: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  progressLabel: {
    display: 'flex',
    justifyContent: 'center',
  },

  Slider: {
    width: '60%',
  },

  server_run_container: {
    display: 'flex',
    alignItems: 'center',
  },

  server_run_icon: {
    marginRight: '12px',
  },

  button: {
    minWidth: '8vw',
  },

  spinner_container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Processing(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentlyProcessed, pendingReprocessing, priors_list, totalExaminations } = useSelector(
    (state) => state.examinations
  );
  const filter = useSelector((state) => state.filter);

  // Local state
  const [studyIDs, setStudyIDs] = useState([]);
  const [patientIDs, setPatientIDs] = useState([]);
  const [priorStudyIDs, setPriorStudyIDs] = useState([]);
  const [processStatus, setProcessStatus] = useState('init');

  // Effects
  useEffect(() => {
    const abortPullingIDs = new AbortController();
    fetchStudyIDs(abortPullingIDs.signal);
    return () => abortPullingIDs.abort();
  }, []);

  useEffect(() => {
    if (currentlyProcessed || pendingReprocessing) {
      setProcessStatus('reprocessing');
    } else {
      if (processStatus === 'reprocessing') {
        setProcessStatus('ready');
      }
    }
  }, [currentlyProcessed, pendingReprocessing]);

  useEffect(() => {
    const priorStudyIDArr = [];
    for (const patientID of patientIDs) {
      if (priors_list[patientID]) {
        priorStudyIDArr.push(priors_list[patientID].map((study) => study.id));
      }
    }
    setPriorStudyIDs(priorStudyIDArr);
  }, [patientIDs, priors_list]);

  // Handlers
  function reprocessHandler() {
    dispatch(
      setActionToConfirm({
        action: reprocessStudies,
        text: t('Settings.confirm_reprocess', {
          aproxTime: `${Math.ceil((totalExaminations * 30) / 60)}`,
        }),
      })
    );
  }

  function deleteStudies() {
    dispatch(deleteExams(studyIDs, t));
  }

  function deleteHandler() {
    dispatch(
      setActionToConfirm({
        action: deleteStudies,
        text: t('Settings.confirm_delete'),
      })
    );
  }

  async function reprocessStudies() {
    const response = await fetchPut('reprocess', { exam_ids: studyIDs }, 'bbox');

    if (response.success) {
      dispatch(
        setSnackbar({
          msg: t('Settings.reprocess_start'),
          severity: 'info',
        })
      );
    } else {
      dispatch(actions.setPendingReprocessing(false));
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  }

  function reprocessHandler() {
    dispatch(
      setActionToConfirm({
        action: reprocessStudies,
        text: t('Settings.confirm_reprocess'),
      })
    );
  }

  function onFindPriors() {
    setProcessStatus('finding_priors'); // TODO: translations please
    const callback = () => setProcessStatus('ready');
    dispatch(fetchPriorExaminations(patientIDs, t, callback));
  }

  function searchPriorsHandler() {
    const totalTime = totalExaminations * 30;
    dispatch(
      setActionToConfirm({
        action: patientIDs.length > 0 ? onFindPriors : () => {},
        buttonOk: patientIDs.length > 0 ? t('Confirm.confirm') : t('Settings.dialog_ok'),
        text:
          patientIDs.length > 0
            ? t('Settings.confirm_search', {
                totalExams: patientIDs.length,
                time_aprox: `${Math.max(1, Math.ceil(totalTime / 60))}`,
              })
            : t('Settings.confirm_search_null'),
      })
    );
  }

  function onMovePriors() {
    setProcessStatus('moving_priors'); // TODO: translations please
    const callback = () => setProcessStatus('ready');
    dispatch(movePriorExaminations(priorStudyIDs, t, callback));
  }

  function movePriorsHandler() {
    const totalPriors = Object.keys(priors_list).length;
    const totalTime = totalPriors * 30;

    dispatch(
      setActionToConfirm({
        action: !!totalPriors ? onMovePriors : () => {},
        text: !!totalPriors
          ? t('Settings.confirm_move', {
              totalExams: totalPriors,
              time_aprox: `${Math.max(1, Math.ceil(totalTime / 60))}`,
            })
          : t('api_messages.no_priors_found'),
        buttonOk: !totalPriors ? t('Settings.dialog_ok') : t('Confirm.confirm'),
      })
    );
  }

  // Fetching
  async function fetchStudyIDs(abortSignal = null) {
    setProcessStatus('pulling_ids');
    const response = await fetchPost('study_ids', { filter }, undefined, undefined, undefined, abortSignal);
    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      setProcessStatus('failed');
      setSnackbar({ msg: t(`Settings.${processStatus}, errorMsg: ${response.msg}`), severity: 'error' });
      return;
    }

    const studyIDArr = response.data.map((item) => item.id);
    const patientIDArr = response.data.map((item) => item.patient_id);

    setStudyIDs(studyIDArr);
    setPatientIDs(patientIDArr);
    setProcessStatus('ready');
  }

  return (
    <Grid container direction="column" spacing={1}>
      {props.managementAccess && (
        <Grid item>
          <Card elevation={1}>
            <CardHeader
              disableTypography={true}
              classes={{
                action: classes.action,
              }}
              title={
                <Typography className={classes.titleSettingsCard} variant="h6">
                  {t('Settings.dicom_receive')}
                </Typography>
              }
            />
            <CardContent>
              <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                <Grid item>
                  {props.loadingOrthanc ? (
                    <CircularProgress className={classes.spinner} />
                  ) : props.serverActive ? (
                    <div className={classes.server_run_container}>
                      <OfflinePinIcon
                        fontSize="small"
                        color="secondary"
                        className={classes.server_run_icon}
                      />
                      <Typography>{t('Settings.server')}</Typography>
                    </div>
                  ) : (
                    <div className={classes.server_run_container}>
                      <RemoveCircleRoundedIcon
                        fontSize="small"
                        color="secondary"
                        className={classes.server_run_icon}
                      />
                      <Typography>{t('Settings.server_unavailable')}</Typography>
                    </div>
                  )}
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      props.loadingWatcher ? (
                        <>
                          <CircularProgress className={classes.spinner} />
                        </>
                      ) : (
                        <Switch checked={props.watcherActive} onChange={props.setWatcher} />
                      )
                    }
                    label={t('Settings.watcher')}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      <>
        <Grid item>
          <Card elevation={1}>
            <CardHeader
              disableTypography={true}
              classes={{
                action: classes.action,
              }}
              title={
                <Typography className={classes.titleSettingsCard} variant="h6">
                  {t('Settings.reprocess_all')}
                </Typography>
              }
              subheader={<Typography variant="subtitle2">{t('Settings.affected_studies')}</Typography>}
            />
            <CardContent>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item style={{ flexGrow: 1 }}>
                  {processStatus === 'ready' ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: props.managementAccess ? 'space-around' : 'flex-start',
                        }}
                      >
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={reprocessHandler}
                        >
                          {t('Settings.reprocess')}
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={searchPriorsHandler}
                        >
                          {t('Settings.find_priors_btn')}
                        </Button>
                        <Button
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          onClick={movePriorsHandler}
                        >
                          {t('Settings.pull_priors_btn')}
                        </Button>
                        {props.managementAccess && (
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={deleteHandler}
                          >
                            {t('Settings.delete')}
                          </Button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={classes.spinner_container}>
                      <>
                        <CircularProgress className={classes.spinner} />
                      </>
                      <Typography className={classes.progressLabel}>
                        {t(`Settings.${processStatus}`)}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </>
    </Grid>
  );
}

export default Processing;
