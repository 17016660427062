import React, { useCallback, useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListSubheader,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
  CardHeader,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButonGroupDicomFilter from '../ButtonGroup';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  action: {
    margin: 0,
  },
  listItem: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.texts.secondary,
  },
  listHeader: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
  list: {
    width: '100%',
    minHeight: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  },
  module_report_title: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },

  module_title: {
    color: theme.palette.texts.secondary,
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    marginLeft: '10px',
    '&.Mui-focused': {
      color: theme.palette.texts.secondary,
    },
  },

  module_report_container: {
    margin: '2% 0',
  },

  classes_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '2% 1%',
  },

  form_control: {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: '14px',
  },
}));

const emptyFields = {
  Name: '',
  AET: '',
  IP: '',
  Port: '',
};

const ReportModule = ({ title, options, settings, updateReportConfig }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset" className={classes.form_control}>
      <FormLabel component="legend" className={classes.module_title}>
        {t(title)}
      </FormLabel>
      <FormGroup className={classes.classes_container}>
        {options.map(({ key, label }) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={!!settings.report_customization?.[key]}
                onChange={updateReportConfig}
                name={key}
              />
            }
            label={t(label)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const ReportSettings = ({ settings, updateSettingsCheckbox, updateReportConfig }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const premium_features = useSelector((state) => state.global_settings.premium_features);

  const somePremiumDiagnostics = useMemo(
    () => premium_features.diagnostics_microcalc_module || premium_features.diagnostics_opacities_module,
    [premium_features]
  );

  const handleCheckboxChange = useCallback(
    (event) => updateSettingsCheckbox(event),
    [updateSettingsCheckbox]
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Card elevation={1}>
          <CardHeader
            disableTypography
            classes={{ action: classes.action }}
            title={
              <Typography className={classes.titleSettingsCard} variant="h6">
                {t('Settings.report_settings')}
              </Typography>
            }
          />
          <CardContent sx={{ pt: 0 }}>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!settings.hide_operator_name}
                    onChange={handleCheckboxChange}
                    name="hide_operator_name"
                  />
                }
                label={t('Settings.hide_operator_name')}
              />
            </Grid>

            {somePremiumDiagnostics && (
              <div className={classes.module_report_container}>
                <Typography className={classes.module_report_title}>
                  {t('report.report_modules_title')}
                </Typography>

                {premium_features.diagnostics_microcalc_module && (
                  <ReportModule
                    title="ResultView.diagnosticsMicrocalc"
                    options={[
                      { key: 'calc_vessel', label: 'projections.vessels' },
                      { key: 'calc_birads2', label: 'projections.birads_2' },
                      { key: 'calc_birads3', label: 'projections.birads_3' },
                      { key: 'calc_birads4', label: 'projections.birads_4' },
                      { key: 'calc_birads5', label: 'projections.birads_5' },
                    ]}
                    settings={settings}
                    updateReportConfig={updateReportConfig}
                  />
                )}

                {premium_features.diagnostics_opacities_module && (
                  <ReportModule
                    title="ResultView.diagnosticsLesions"
                    options={[
                      { key: 'opac_birads2', label: 'projections.birads_2' },
                      { key: 'opac_birads3', label: 'projections.birads_3' },
                      { key: 'opac_birads4', label: 'projections.birads_4' },
                      { key: 'opac_birads5', label: 'projections.birads_5' },
                      { key: 'opac_lesionKnown', label: 'projections.lesionKnown' },
                    ]}
                    settings={settings}
                    updateReportConfig={updateReportConfig}
                  />
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ReportSettings;
