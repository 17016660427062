import React from 'react';
import {
  Grid,
  Button,
  Tooltip,
  Switch,
  FormControl,
  FormControlLabel,
  ButtonGroup,
  Box,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  GridItem: {
    height: '100%',
  },
}));

export default function ButtonGroupDiagnostics(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const examinationListDrawer = useSelector((state) => state.gui.examinationListDrawer);

  return (
    <Grid wrap="nowrap" container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
      <Grid className={classes.GridItem} item>
        {props.showButtons && (
          <ButtonGroup disableElevation variant="contained" size="small">
            <Tooltip
              arrow
              placement="bottom"
              title={`${t('Dashboard.view')} ${t('ResultView.diagnosticsLesions')}`}
            >
              <Button
                variant="contained"
                onClick={() =>
                  props.diagnosticCategory === 'microcalc'
                    ? props.setDiagnosticCategory('opacities')
                    : props.setDiagnosticCategory('microcalc')
                }
                style={{
                  backgroundColor:
                    props.diagnosticCategory === 'opacities'
                      ? theme.palette.complementary.turquoiseDark
                      : 'transparent',
                  color:
                    props.diagnosticCategory === 'opacities'
                      ? 'white'
                      : theme.palette.complementary.turquoiseDark,
                  border: `1px solid ${theme.palette.complementary.turquoiseDark}`,
                  height: '25px',
                }}
                id="diagnostic_category"
                size="small"
              >
                {t('Dashboard.opacities_short')}
              </Button>
            </Tooltip>

            <Tooltip
              arrow
              placement="bottom"
              title={`${t('Dashboard.view')} ${t('ResultView.diagnosticsMicrocalc')}`}
            >
              <Button
                variant="contained"
                onClick={() =>
                  props.diagnosticCategory === 'microcalc'
                    ? props.setDiagnosticCategory('opacities')
                    : props.setDiagnosticCategory('microcalc')
                }
                style={{
                  backgroundColor:
                    props.diagnosticCategory === 'microcalc'
                      ? theme.palette.complementary.turquoiseDark
                      : 'transparent',
                  color:
                    props.diagnosticCategory === 'microcalc'
                      ? 'white'
                      : theme.palette.complementary.turquoiseDark,
                  border: `1px solid ${theme.palette.complementary.turquoiseDark}`,
                  height: '25px',
                }}
                id="diagnostic_category"
                size="small"
              >
                {t('Dashboard.microcalc_short')}
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
      </Grid>
    </Grid>
  );
}
