import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchGet } from '../../../../helpers/fetching';
import { jsPDF } from 'jspdf';
import { setActionToConfirm, setSnackbar, setActiveDialog, sendReports, sendSR } from '../../../../store';
import { useTranslation } from 'react-i18next';
import animation from '../../../../resources/processing_animation.gif';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  reportButton: {
    background: theme.palette.complementary.turquoiseDark,
    color: 'white',
    '&:hover': {
      background: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px 0px rgb(180 180 180 / 78%)',
    },
  },
}));

const Report = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const theme = useTheme();
  const classes = useStyles();

  const {
    confirmed_density,
    confirmed_quality,
    confirmed_opacities,
    confirmed_microcalc,
    pat_name,
    applied_density,
    applied_quality,
    applied_opacities,
    applied_microcalc,
  } = useSelector((state) => state.results);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const { premium_features } = useSelector((state) => state.global_settings);

  const [qualitySrc, setQualitySrc] = useState(null);
  const [bothReportsSrc, setBothReportsSrc] = useState(null);
  const [diagnosticsSrc, setDiagnosticsSrc] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pending, setPending] = useState(null);
  const [open, setOpen] = useState(false);

  const { diagnostics_microcalc_module, diagnostics_opacities_module } = premium_features;
  const somePremiumDiagnostics =
    (diagnostics_microcalc_module || diagnostics_opacities_module) &&
    localStorage.getItem('annotation') === 'true';

  const downloadBtnOptions = [
    { text: t('report.download_quality'), opt: 'quality' },
    somePremiumDiagnostics && { text: t('report.download_diagnostics'), opt: 'diagnose' },
    somePremiumDiagnostics && { text: t('report.download_both'), opt: 'both' },
  ];

  const fetchReports = useCallback(
    async (abortSignal) => {
      setPending(t('report.generating'));

      let responseDiagnostics = null;
      let objectURLDiagnostics = null;
      const responseQuality = await fetchGet(
        `report_quality/${currentExam}`,
        'bbox',
        false,
        'blob',
        abortSignal
      );
      if (somePremiumDiagnostics) {
        responseDiagnostics = await fetchGet(
          `report_diagnostics/${currentExam}`,
          'bbox',
          false,
          'blob',
          abortSignal
        );
      }
      if (
        responseQuality.status === 200 &&
        (!somePremiumDiagnostics || (responseDiagnostics && responseDiagnostics.status === 200))
      ) {
        const objectURLQuality = URL.createObjectURL(responseQuality.blob);
        setQualitySrc(objectURLQuality);
        if (responseDiagnostics) {
          objectURLDiagnostics = URL.createObjectURL(responseDiagnostics.blob);
          setDiagnosticsSrc(objectURLDiagnostics);
        }
        if (somePremiumDiagnostics) {
          setBothReportsSrc([objectURLQuality, objectURLDiagnostics]);
        }
        setPending(null);
      } else {
        setQualitySrc('nothing');
        dispatch(
          setSnackbar({
            msg: `${t('report.failed_code')} ${responseQuality.status} ${
              responseDiagnostics ? responseDiagnostics.status : ''
            }`,
            severity: 'error',
          })
        );
        setPending(null);
      }
    },
    [currentExam, somePremiumDiagnostics]
  );

  const generatePDF = (imageSrcArray, fileName) => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [4008.19, 4669.29],
    });

    pdf.setFillColor(74, 74, 74);
    pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), 'F');

    if (!Array.isArray(imageSrcArray) || imageSrcArray.length === 0) {
      console.error('No valid image sources provided.');
      return;
    }

    const loadImage = (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
      });
    };

    Promise.all(imageSrcArray.map(loadImage)).then((images) => {
      images.forEach((img, index) => {
        if (index > 0) pdf.addPage();

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = img.width;
        const imgHeight = img.height;
        let scaleFactor = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

        const newWidth = imgWidth * scaleFactor;
        const newHeight = imgHeight * scaleFactor;
        const xOffset = (pageWidth - newWidth) / 2;
        const yOffset = 20;
        pdf.setFillColor(74, 74, 74);
        pdf.addImage(img, 'JPEG', xOffset, yOffset, newWidth, newHeight);
      });

      pdf.setFillColor(74, 74, 74);
      pdf.save(fileName);
    });
  };

  const onSRSend = () => {
    if (pending) return;

    if (![confirmed_density, confirmed_quality, confirmed_opacities].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_structure_pacs'));
            dispatch(sendSR(currentExam, t));
          },
          text: t('report.not_confirmed'),
        })
      );
    else {
      setPending(t('report.send_structure_pacs'));
      dispatch(sendSR(currentExam));
      setPending(null);
    }
  };

  const onQualityReportSend = () => {
    if (pending) return;
    if (![confirmed_density, confirmed_quality].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('quality', currentExam, t));
          },
          text: t('report.not_confirmed_send'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('quality', currentExam, t));
      setPending(null);
    }
  };

  const onDiagnoseReportSend = () => {
    if (pending) return;
    if (![confirmed_density, confirmed_opacities, confirmed_microcalc].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('diagnostics', currentExam, t));
            setPending(null);
          },
          text: t('report.not_confirmed_send'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('diagnostics', currentExam, t));
      setPending(null);
    }
  };

  const handleClick = (optionId) => {
    if (optionId === 'both') {
      generatePDF(bothReportsSrc, `b-box_Reports_${pat_name}.pdf`);
    } else if (optionId === 'quality') {
      generatePDF([qualitySrc], `b-QUALITY_Report_${pat_name}.pdf`);
    } else if (optionId === 'diagnose') {
      generatePDF([diagnosticsSrc], `b-DIAGNOSTICS_Report_${pat_name}.pdf`);
    }
  };

  const handleMenuItemClick = (option, index) => {
    setSelectedIndex(index);
    handleClick(option.opt);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const abortReports = new AbortController();
    fetchReports(abortReports.signal);
    return () => abortReports.abort();
  }, []);

  return (
    <DraggableDialog
      maxWidth="xl"
      title={t('ResultView.report_title', { user: pat_name })}
      open={props.open}
      customStyle={{ overflow: 'auto' }}
      actions={[
        somePremiumDiagnostics && {
          onClick: onDiagnoseReportSend,
          color: 'primary',
          label: t('report.send_diagnose'),
        },
        {
          onClick: onQualityReportSend,
          color: 'primary',
          label: t('report.send_quality'),
        },
        {
          onClick: onSRSend,
          color: 'primary',
          label: t('report.send_sr'),
        },
      ]}
      onClose={() => dispatch(setActiveDialog(null))}
    >
      {pending ? (
        <>
          <img
            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            src={animation}
            alt="logoanimation"
          />
          <p>{pending}</p>
        </>
      ) : (
        <>
          <div
            style={{
              minWidth: '1600px',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="Report_type">
              <Button
                onClick={() => handleClick(downloadBtnOptions[selectedIndex]?.opt)}
                variant="contained"
                className={classes.reportButton}
              >
                {downloadBtnOptions[selectedIndex]?.text}
              </Button>
              <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select_report_type"
                aria-haspopup="menu"
                onClick={handleToggle}
                variant="contained"
                className={classes.reportButton}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{ zIndex: 1 }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {downloadBtnOptions.map((option, index) => (
                          <MenuItem
                            key={option.opt}
                            selected={index === selectedIndex}
                            onClick={() => handleMenuItemClick(option, index)}
                          >
                            {option?.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          {/* Quality Report */}
          <img src={qualitySrc} alt={t('report.quality_unavailable')} />

          <Divider light orientation="horizontal" variant="fullWidth" style={{ margin: '10px' }} />
          {/* Diagnose Report */}
          {somePremiumDiagnostics && (
            <>
              <img src={diagnosticsSrc} alt={t('report.diagnostics_unavailable')} />
            </>
          )}
        </>
      )}
    </DraggableDialog>
  );
};

export default Report;
