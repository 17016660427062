import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Tooltip, Typography, Zoom } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import * as actions from '../../../../../store';
import useResponsive from '../../../../../helpers/hooks/useResponsive';
import { qualityEvaluationChip } from '../../../../../helpers/qualityProjection';
import {
  parenchymaClass,
  nippleProfileClass,
  pectoAngleClass,
  inframFoldClass,
  pectoLevelClass,
  blurredAreaInfo,
  skinFoldsInfo,
  buckyHeightInfo,
} from '../utils/qualityUtils';
import QualityChips from '../common/QualityChips';
import { useQualityTranslation } from '../../../../../helpers/hooks/useQualityTranslation';

import style from '../styles/QualityProjections.module.css';
import { useStyles } from '../styles/QualityProjections.style';

import concept_rmlo from '../../../../../resources/concept_images/Concept_RMLO.png';
import concept_mlo_concave from '../../../../../resources/concept_images/ConceptImg_MLO_concave.png';
import concept_mlo_convex from '../../../../../resources/concept_images/ConceptImg_MLO_convex.png';
import concept_mlo_straight from '../../../../../resources/concept_images/ConceptImg_MLO_straight.png';

import { Icon } from '@iconify/react';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import WavesIcon from '@material-ui/icons/Waves';
import arrowCollapseVertical from '@iconify-icons/mdi/arrow-collapse-vertical';
import { onClickQualityFeaturesChip, onClickParen, onClickQualityChip } from '../hooks/useQualityHandlers';

const QualityMLO = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isXSmallScreen, isSmallScreen, isMediumScreen, isLargeScreen } = useResponsive();
  const { examinationListDrawer, tabsIndex, zoomedProjection, landscape, darkState } = useSelector(
    (state) => state.gui
  );
  const projections = useSelector((state) => state.results.projections);
  const { qualityInRevisionMode, qualityInDrawingMode } = useSelector((state) => state.labeling ?? {});

  const quality_system = useSelector((store) => store.global_settings?.quality_system ?? 'swiss');

  const { compression_low, compression_high, compression_tolerance, premium_features } = useSelector(
    (state) => state.global_settings
  );

  const {
    blur_classes,
    elevation_classes,
    skin_folds_classes,
    compression_classes,
    pnl_diff_classes,
    imf_classes,
    pectoral_muscle_classes,
    pect_angle_classes,
    parenchyma_classes,
    pect_shape_classes,
    pect_level_classes,
    nipple_profile_classes,
  } = useSelector((state) => state.global_settings?.quality_system.quality_features);

  const { view_position, result } = props;

  const [flip, setFlip] = useState(null);
  const [pnlIsHovered, setPnlIsHovered] = useState(false);
  const [flip_rotate, setFlipRotate] = useState(null);
  const [conceptImg, setConceptImg] = useState(null);
  const [pectLabel, setPectLabel] = useState('');

  const left = view_position === 'lmlo';
  const upperProjection = view_position?.toUpperCase();
  const blurPremium = premium_features?.blur;

  // Revision mode

  const qualityFeatInRevision = tabsIndex === 2 && qualityInRevisionMode && !!qualityInDrawingMode;

  const qualityInfoMode =
    tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode);

  const qualityInRevison = tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode;

  // Effects
  useEffect(() => {
    if (left) {
      setFlip({ transform: 'scaleX(-1)' });
      setFlipRotate({ transform: 'scaleX(-1) rotate(19deg)' });
    } else setFlip(null);
  }, [props]);

  useEffect(() => {
    const preloadImages = [concept_rmlo, concept_mlo_straight, concept_mlo_concave, concept_mlo_convex];

    preloadImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    let label = t('ResultView.is_not_evaluated');
    let selectedConceptImage = concept_rmlo;
    if (qualityFeatInRevision) {
      setConceptImg(selectedConceptImage);
      setPectLabel(label);
    } else {
      if (pect_shape_classes?.length === 0) {
        selectedConceptImage = concept_rmlo;
      } else if (result?.PectShape === 'straight') {
        selectedConceptImage = concept_mlo_straight;
        label = t('ResultView.straight_pect_shape');
      } else if (result?.PectShape === 'concave') {
        selectedConceptImage = concept_mlo_concave;
        label = t('ResultView.concave_pect_shape');
      } else if (result?.PectShape === 'convex') {
        selectedConceptImage = concept_mlo_convex;
        label = t('ResultView.convex_pect_shape');
      }
    }
    setConceptImg(selectedConceptImage);
    setPectLabel(label);
  }, [result?.PectShape, qualityFeatInRevision]);

  const smallView =
    zoomedProjection ||
    isXSmallScreen ||
    isSmallScreen ||
    isMediumScreen ||
    (examinationListDrawer && isLargeScreen);

  const handlePnlMouseEnter = () => {
    setPnlIsHovered(true);
    dispatch(actions.setLinesToShow(view_position));
  };

  const handlePectAngleMouseEnter = () => {
    dispatch(actions.setPectAngleLinesToShow(view_position));
  };

  const handlePnlMouseLeave = () => {
    setPnlIsHovered(false);
    dispatch(actions.hideLinesShown());
  };

  const showParencOverlays = (proj) => {
    if (proj !== 'correct') dispatch(actions.setParenchymaCutsToShow(view_position));
    if (proj === 'correct') dispatch(actions.hideParenchymaCutsShown(view_position));

    const newLesionsToShow = {
      [`parenchyma${view_position?.toUpperCase()}`]: true,
    };

    if (view_position === 'rmlo' || view_position === 'lmlo') {
      dispatch(actions.setLinesToShow(view_position));
    }
    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hideParencOverlays = () => {
    dispatch(actions.hideParenchymaCutsShown(view_position));
    dispatch(actions.hideAllLesions());
    if (!pnlIsHovered) dispatch(actions.hideLinesShown());
  };

  const showPectOverlays = () => {
    const newLesionsToShow = {
      [`pectoralis${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hidePectOverlays = () => {
    dispatch(actions.hideAllLesions());
  };

  const showNippleProfile = () => {
    dispatch(actions.setNippleProfileToShow(view_position));
  };

  const showIMF = () => {
    dispatch(actions.setIMFToShow(view_position));
  };

  const handlePectoLevelMouseEnter = () => {
    dispatch(actions.setPectLevelToShow(view_position));
  };

  const handlePectoLevelMouseLeave = () => {
    dispatch(actions.hidePectLevel(view_position));
  };

  const showSkinFolds = () => {
    if (result?.skinFolds) {
      const newLesionsToShow = {
        [`skinFolds${view_position.toUpperCase()}`]: true,
      };

      dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
    }
  };

  return (
    <div
      className={classes.quality_projection_container}
      style={{ justifyContent: flip ? 'flex-start' : 'flex-end' }}
    >
      {/* Quality Features Chips */}
      {!!result && (
        <div
          className={classes.quality_chips_container}
          style={{ right: flip ? '25px' : undefined, left: flip ? undefined : '25px' }}
        >
          {/* BUCKY HEIGH CHIP */}
          {elevation_classes?.length > 0 && !qualityInDrawingMode && (
            <QualityChips
              chipIcon={buckyHeightInfo(result?.elevation, t).icon}
              evaluationClass={buckyHeightInfo(result?.elevation, t).color}
              evaluationLabel={buckyHeightInfo(result?.elevation, t).label}
              chipAction={() =>
                qualityInRevisionMode &&
                onClickQualityFeaturesChip('elevation', upperProjection, elevation_classes, dispatch, actions)
              }
              tooltipText={
                <Typography className={classes.tooltip_texts}>
                  {buckyHeightInfo(result?.elevation, t).tooltipLabel}
                </Typography>
              }
              tooltipAction={showSkinFolds}
              tooltipLeave={hidePectOverlays}
            />
          )}

          {/* SKIN FOLDS CHIP */}
          {skin_folds_classes?.length > 0 && !qualityInRevisionMode && (
            <QualityChips
              chipIcon={<WavesIcon fontSize="small" className={classes.chips_icon} />}
              evaluationClass={skinFoldsInfo(result?.skinFolds, view_position, t, theme).class}
              evaluationLabel={
                result?.skinFolds ? t('ResultView.skin_folds') : t('ResultView.none_skin_folds')
              }
              chipAction={() => null}
              tooltipText={skinFoldsInfo(result?.skinFolds, view_position, t, theme).tooltip}
              tooltipAction={showSkinFolds}
              tooltipLeave={hidePectOverlays}
              extraStyles={{ cursor: 'help' }}
            />
          )}

          {/* BLUR CHIP */}
          {blur_classes?.length > 0 && !qualityInDrawingMode && blurPremium && (
            <QualityChips
              chipIcon={<BlurCircularIcon fontSize="small" className={classes.chips_icon} />}
              evaluationClass={result?.blur}
              evaluationLabel={blurredAreaInfo(result?.blur, theme, t)?.label}
              chipAction={() =>
                onClickQualityFeaturesChip('blur', upperProjection, blur_classes, dispatch, actions)
              }
              tooltipText={
                <Typography className={classes.tooltip_texts}>
                  {blurredAreaInfo(result?.blur, theme, t)?.tooltip}
                </Typography>
              }
            />
          )}
        </div>
      )}

      {/* Quality Evaluation Chip */}
      <div
        className={classes.quality_evaluation_chip_container}
        style={{
          right: left ? '10px' : undefined,
          left: left ? undefined : '10px',
          alignItems: smallView ? 'flex-end' : 'center',
          justifyContent: smallView ? (left ? 'flex-end' : 'flex-start') : 'center',
          marginTop: '40px',
          height: smallView ? '90%' : '100%',
        }}
      >
        {!!result &&
          !qualityInDrawingMode &&
          qualityEvaluationChip(
            view_position,
            result?.quality,
            result?.quality_explanation,
            smallView,
            () => onClickQualityChip(upperProjection, t, dispatch, actions),
            qualityInfoMode,
            qualityInRevison,
            theme,
            t
          )}
      </div>

      {/* Concept Img */}
      <div className={style.QualityProj} style={flip}>
        <img
          style={{
            maxHeight: landscape ? '35vh' : 'calc(33vh - 170px)',
            filter: darkState ? '' : 'contrast(0.1)',
          }}
          src={conceptImg || concept_rmlo}
          alt="not found"
        />

        {/* Nipple */}
        {nipple_profile_classes?.length > 0 && (
          <Tooltip
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {nippleProfileClass(result?.Nipple, t)?.text}
              </Typography>
            }
            arrow
            interactive
            placement="bottom"
            TransitionComponent={Zoom}
            onOpen={() => qualityInfoMode && showNippleProfile()}
            onClose={() => qualityInfoMode && dispatch(actions.hideLinesShown())}
          >
            <button
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              className={
                qualityFeatInRevision
                  ? [classes.nippleMLOBase, classes.neutral]
                  : `${classes.nippleMLOBase}  ${classes[nippleProfileClass(result?.Nipple, t)?.classColor]}`
              }
              id={style.nipple_mlo}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickQualityFeaturesChip(
                    'Nipple',
                    upperProjection,
                    nipple_profile_classes,
                    dispatch,
                    actions
                  );
              }}
            />
          </Tooltip>
        )}

        {/* Inframammary fold */}
        {imf_classes?.length > 0 && (
          <Tooltip
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {inframFoldClass(result?.Infram, t)?.text}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
            onOpen={() => qualityInfoMode && showIMF()}
            onClose={() => qualityInfoMode && dispatch(actions.hideLinesShown())}
          >
            <button
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              className={
                qualityFeatInRevision
                  ? [classes.buttonBase, classes.inframBase, classes.neutral]
                  : `${classes.buttonBase} ${classes.inframBase} ${
                      classes[inframFoldClass(result?.Infram, t)?.classColor]
                    }`
              }
              id={'infram'}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickQualityFeaturesChip('Infram', upperProjection, imf_classes, dispatch, actions);
              }}
            />
          </Tooltip>
        )}

        {/* Pecto Angle */}
        {pect_angle_classes?.length > 0 && (
          <Tooltip
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {pectoAngleClass(result?.PectAngle, result?.PectAngleValue, t)?.text}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
          >
            <button
              onMouseEnter={() => qualityInfoMode && handlePectAngleMouseEnter}
              onMouseLeave={() => qualityInfoMode && handlePnlMouseLeave}
              style={{
                cursor: qualityInRevison ? 'pointer' : 'help',
              }}
              className={
                qualityFeatInRevision
                  ? [classes.buttonBase, classes.neutral]
                  : `${classes.buttonBase} ${classes.pectAngleBase} ${
                      classes[pectoAngleClass(result?.PectAngle, result?.PectAngleValue, t).classColor]
                    }`
              }
              id={style.angle}
              onClick={() => {
                if (qualityInRevison)
                  onClickQualityFeaturesChip(
                    'PectAngle',
                    upperProjection,
                    pect_angle_classes,
                    dispatch,
                    actions
                  );
              }}
            />
          </Tooltip>
        )}

        {/* Parenchyma */}
        {parenchyma_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && showParencOverlays(result?.ParenchymaCuts)}
            onClose={() => qualityInfoMode && hideParencOverlays()}
            title={
              <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
                {parenchymaClass(result?.ParenchymaCuts, t)?.text}
              </Typography>
            }
            arrow
            interactive
            placement="top-end"
            TransitionComponent={Zoom}
          >
            <button
              className={
                qualityFeatInRevision
                  ? [classes.parenMLOBase, classes.neutral]
                  : `${classes.parenMLOBase} ${
                      classes[parenchymaClass(result?.ParenchymaCuts, t)?.classColor]
                    }`
              }
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              id={'parenMLO'}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickParen(upperProjection, '', parenchyma_classes, dispatch, actions);
              }}
            />
          </Tooltip>
        )}

        {/* PNL */}
        {pnl_diff_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && handlePnlMouseEnter()}
            onClose={() => qualityInfoMode && handlePnlMouseLeave()}
            arrow
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {result?.PectoNippleLength
                  ? `${t('ResultView.pnl_measures')}
                   ${result?.PectoNippleLength?.toFixed(0)}mm`
                  : t('ResultView.is_not_evaluated')}
              </Typography>
            }
          >
            <svg style={flip_rotate} className={classes.pnlSvgMLO}>
              <text
                x={left ? '40%' : '50%'}
                y="35%"
                fill="white"
                style={{
                  fontSize: theme.fonts.responsiveSmall,
                  cursor: 'help',
                  fontWeight: pnlIsHovered ? 'bold' : 'normal',
                  textShadow: pnlIsHovered ? '2px 2px 4px rgb(52 52 52)' : 'none',
                }}
              >
                {result && result.PectoNippleLength ? result.PectoNippleLength.toFixed(0) : null}
              </text>

              <line
                x1="0"
                y1="50%"
                x2="100%"
                y2="50%"
                className={`${classes.buttonBase} ${classes.pnlBase} ${classes.neutral}`}
              />
            </svg>
          </Tooltip>
        )}

        {/* Pecto Level */}
        {pect_level_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && handlePectoLevelMouseEnter()}
            onClose={() => qualityInfoMode && handlePectoLevelMouseLeave()}
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {pectoLevelClass(result?.PectLevel, pect_level_classes, t).text}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'bottom-start' : 'bottom-end'}
            TransitionComponent={Zoom}
          >
            <svg
              className={style.svg_pecto}
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              id="svg_pecto"
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickQualityFeaturesChip(
                    'PectLevel',
                    upperProjection,
                    pect_level_classes,
                    dispatch,
                    actions
                  );
              }}
            >
              <line
                x1="30%"
                y1="0"
                x2="30%"
                y2="100%"
                className={
                  qualityFeatInRevision
                    ? [classes.PectLevelBase, classes.neutral]
                    : `${classes.PectLevelBase}  ${classes[result?.PectLevel]}`
                }
              />
            </svg>
          </Tooltip>
        )}

        {/* Convexity */}
        {pect_shape_classes?.length > 0 && (
          <Tooltip
            onOpen={() => qualityInfoMode && showPectOverlays()}
            onClose={() => qualityInfoMode && hidePectOverlays()}
            title={
              <Typography
                style={{
                  fontSize: '1.3vh',
                  lineHeight: 1.3,
                }}
              >
                {pectLabel}
              </Typography>
            }
            arrow
            interactive
            placement={left ? 'right-start' : 'left-start'}
            TransitionComponent={Zoom}
            id="svg_convexity"
          >
            <div
              className={style.pect_convexity}
              id="svg_convexity"
              style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
              onClick={() => {
                if (!!result && qualityInRevison)
                  onClickQualityFeaturesChip(
                    'PectShape',
                    upperProjection,
                    pect_shape_classes,
                    dispatch,
                    actions
                  );
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default QualityMLO;
